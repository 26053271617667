//Services
import axios from 'services/axios';

//Types
import {AxiosTransformer} from 'axios';
import {Offer} from 'store/types';

type MoneyReceived = {amount: string; currency: string};

type ReceivedOffer = Omit<
    Offer.OfferDetailed['offer'],
    'createdAt' | 'closedAt' | 'expiredAt' | 'price' | 'delivery'
> & {
    createdAt: string;
    closedAt: string;
    expiredAt: string;
    price: MoneyReceived;
    delivery: {method?: Offer.DeliveryMethod; price: MoneyReceived};
};

type ReceivedOfferDetails = {
    offer: ReceivedOffer;
    stats: any;
    buyable: boolean;
};

export const transformResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    ({offer, ...data}: ReceivedOfferDetails) => ({
        ...data,
        offer: {
            ...offer,
            createdAt: new Date(offer.createdAt),
            expiredAt: offer.expiredAt ? new Date(offer.expiredAt) : offer.expiredAt,
            closedAt: offer.closedAt ? new Date(offer.closedAt) : offer.closedAt,
            price: offer.price
                ? {...offer.price, amount: parseInt(offer.price.amount)}
                : offer.price,
            delivery: offer.delivery
                ? {
                      ...offer.delivery,
                      price: {
                          ...offer.delivery.price,
                          amount: parseInt(offer.delivery.price.amount)
                      }
                  }
                : offer.delivery
        }
    })
];
