//Components
import Skeleton from 'react-loading-skeleton';
import Icon from 'components/ui/Icon';

//Utils
import cx from 'classnames';

export type MessageProps = {
    loading?: undefined;
    size?: 'sm' | 'md';
    variant: 'error' | 'info';
    children: React.ReactNode;
};

export type MessageLoadingProps = {
    loading: boolean;
    size?: 'sm' | 'md';
    variant?: undefined;
    children?: undefined;
};

const Message: React.FC<MessageProps | MessageLoadingProps> = ({
    children,
    size = 'sm',
    loading,
    variant
}) => {
    if (loading) {
        return (
            <div
                className={cx(
                    {'min-h-16': size === 'md'},
                    {'min-h-12': size === 'sm'},
                    'w-full h-12 text-xs bg-white rounded-md flex items-center'
                )}
            >
                <Skeleton width={16} height={20} className={'mx-3 rounded-full'} />

                <Skeleton width={200} height={20} className={'rounded-full'} />
            </div>
        );
    }

    return (
        <div
            role={'alert'}
            className={cx(
                {'min-h-16': size === 'md'},
                {'min-h-12': size === 'sm'},
                {'bg-secondary': variant === 'info'},
                {'bg-red': variant === 'error'},
                'w-full text-xs bg-opacity-15 rounded-md flex items-center'
            )}
        >
            <Icon
                icon={'notification'}
                className={cx(
                    {'text-secondary': variant === 'info'},
                    {'text-red': variant === 'error'},
                    'mx-3'
                )}
            />

            {children}
        </div>
    );
};

export default Message;
