const allLocalesLanguages: Map<string, string> = new Map<string, string>();
allLocalesLanguages.set('us', 'en');
allLocalesLanguages.set('pl', 'pl');

const defaultLocale = 'us';
const defaultDateFormat = 'MM-DD-YYYY';

// window.location.href == "http://pl.somedomain.com/somedir/somepage.html"
let url = window.location.href;
url = url.replace('www.', '');
const urlLocaleArray = /:\/\/([^.]+)/.exec(url);
const urlLocale = urlLocaleArray && 1 in urlLocaleArray ? urlLocaleArray[1] : null;

export const locale =
    urlLocale && allLocalesLanguages.has(urlLocale) ? urlLocale : defaultLocale;
export const currentLanguage = allLocalesLanguages.get(locale);

export const dateFormat = locale === 'pl' ? 'DD-MM-YYYY' : defaultDateFormat;
