//Hooks
import {useState} from 'react';
import {useSelector} from 'hooks/store';

export const useAccountSettings = () => {
    const user = useSelector(state => state?.user?.user);
    const savedCards = useSelector(state => state.user?.cards?.data);

    const [isEditMyPhoneModalOpen, setEditMyPhoneModalOpen] = useState(false);
    const [isEditMyPhoneConfirmModalOpen, setEditMyPhoneConfirmModalOpen] = useState(false);
    const [isEditMyEmailModalOpen, setEditMyEmailModalOpen] = useState(false);
    const [isEditMyEmailConfirmModalOpen, setEditMyEmailConfirmModalOpen] = useState(false);
    const [isEditMyAddressModalOpen, setEditMyAddressModalOpen] = useState(false);
    const [isEditBankAccountModalOpen, setEditBankAccountModalOpen] = useState(false);
    const [isEditMyPasswordModalOpen, setEditMyPasswordModalOpen] = useState(false);
    const [isEditCreditCardModalOpen, setEditCreditCardModalOpen] = useState(false);

    return {
        user,
        savedCards,
        isEditMyPhoneModalOpen,
        openEditMyPhoneModal: () => setEditMyPhoneModalOpen(true),
        closeEditMyPhoneModal: () => setEditMyPhoneModalOpen(false),
        isEditMyPhoneConfirmModalOpen,
        openEditMyPhoneConfirmModal: () => {
            setEditMyPhoneModalOpen(false);
            setEditMyPhoneConfirmModalOpen(true);
        },
        closeEditMyPhoneConfirmModal: () => setEditMyPhoneConfirmModalOpen(false),
        isEditMyEmailModalOpen,
        openEditMyEmailModal: () => setEditMyEmailModalOpen(true),
        closeEditMyEmailModal: () => setEditMyEmailModalOpen(false),
        isEditMyEmailConfirmModalOpen,
        openEditMyEmailConfirmModal: () => {
            setEditMyEmailModalOpen(false);
            setEditMyEmailConfirmModalOpen(true);
        },
        closeEditMyEmailConfirmModal: () => setEditMyEmailConfirmModalOpen(false),
        isEditMyAddressModalOpen,
        openEditMyAddressModal: () => setEditMyAddressModalOpen(true),
        closeEditMyAddressModal: () => setEditMyAddressModalOpen(false),
        isEditBankAccountModalOpen,
        openEditBankAccountModal: () => setEditBankAccountModalOpen(true),
        closeEditBankAccountModal: () => setEditBankAccountModalOpen(false),
        isEditMyPasswordModalOpen,
        openEditMyPasswordModal: () => setEditMyPasswordModalOpen(true),
        closeEditMyPasswordModal: () => setEditMyPasswordModalOpen(false),
        isEditCreditCardModalOpen,
        openEditCreditCardModal: () => setEditCreditCardModalOpen(true),
        closeEditCreditCardModal: () => setEditCreditCardModalOpen(false)
    };
};
