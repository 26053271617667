import {Errors} from 'store/types';
import {createAction, createAsyncAction} from 'typesafe-actions';

export const getVersion = createAsyncAction(
    'GET_VERSION_REQUEST',
    'GET_VERSION_SUCCESS',
    'GET_VERSION_FAILURE'
)<{lat: number; lng: number}, {short_name: string}, Errors.API>();

export const setVersion = createAction('SET_VERSION', (version: string) => version)();
