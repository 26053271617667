import * as React from 'react';

//Hooks
import {useDropdown} from './Dropdown.hook';

//Components
import DropdownMenu from './Menu';
import DropdownTrigger from './Trigger';

//Utils
import cx from 'classnames';

//Types
import {DropdownMenuProps} from './Menu';
import {DropdownTriggerProps} from './Trigger';

export type DropdownProps = {
    className?: string;
    children: [
        React.ReactElement<DropdownTriggerProps, typeof DropdownTrigger>,
        React.ReactElement<DropdownMenuProps, typeof DropdownMenu>
    ];
    position: 'right' | 'left';
};
const Dropdown: React.VFC<DropdownProps> & {
    Menu: typeof DropdownMenu;
    Trigger: typeof DropdownTrigger;
} = ({className, children, position}) => {
    const {isOpen, open, menuRef} = useDropdown();

    return (
        <div ref={menuRef} className={cx(className, 'relative inline-block')}>
            {React.Children.map(children, child => {
                if (!child || child.type.displayName !== 'DropdownTrigger') {
                    return null;
                }
                return React.cloneElement(child as React.ReactElement, {
                    ...child.props,
                    onClick: open,
                    className: 'cursor-pointer'
                });
            })}

            {isOpen &&
                React.Children.map(children, child => {
                    if (!child || child.type.displayName !== 'DropdownMenu') {
                        return null;
                    }

                    return React.cloneElement(child as React.ReactElement, {
                        ...child.props,
                        position
                    });
                })}
        </div>
    );
};

Dropdown.Menu = DropdownMenu;
Dropdown.Trigger = DropdownTrigger;

export default Dropdown;
