//Hooks
import {useHomeView} from './HomeView.hook';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import SearchForm from 'components/search/SearchForm';
import CategoryView from 'components/ui/CategoryView';
import Banner from 'components/images/Banner';
import Button from 'components/ui/Button';
import AdsCarouselView, {Ad} from 'components/images/AdsCarouselView';
import Footer from 'components/nav/Footer';
import CompanyBarView from 'components/nav/CompanyBarView';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import PopularSearches from 'components/nav/PopularSearches';
import OfferView from 'components/offer/OfferView';

//Utils
import _ from 'lodash-es';

//Types
import {RouteComponentProps} from '@reach/router';

const HomeView: React.VFC<RouteComponentProps> = props => {
    const {
        categories,
        areCategoriesLoaded,
        advertsBottom,
        advertsBetween,
        advertsMobile,
        adSenseMobileAdvert,
        areAdvertsLoaded,
        token,
        topOffers,
        areOffersLoading
    } = useHomeView();
    const {t} = useTranslation('offers');

    return (
        <div>
            <div className={'bg-gray-200 flex-grow flex flex-col relative'}>
                <SearchForm />

                <Banner />

                <div className={'bg-white hidden md:block'}>
                    <div
                        className={
                            'w-full py-6 px-4 mx-auto max-w-292 flex flex-row justify-end'
                        }
                    >
                        <Button
                            variant={'primary'}
                            size={'sm'}
                            as={Link}
                            to={!!token ? '/offers/create' : '/auth/login'}
                        >
                            {t('addOffer')}
                        </Button>
                    </div>
                </div>

                <div className={'w-full max-w-292 py-4 md:px-4 self-center'}>
                    {areCategoriesLoaded ? (
                        <CategoryView categories={categories!} />
                    ) : (
                        <CategoryView loading />
                    )}

                    {areAdvertsLoaded ? (
                        !!advertsMobile?.length && (
                            <AdsCarouselView
                                intervalSeconds={30}
                                className={'h-25 md:hidden'}
                                role={'region'}
                                aria-label={'mobile advertisements'}
                            >
                                {advertsMobile.map((ad, i) => (
                                    <Ad href={ad.referenceUrl} key={i} src={ad.imageUrl} />
                                ))}
                            </AdsCarouselView>
                        )
                    ) : (
                        <div className={'md:hidden'}>
                            <Skeleton height={100} />
                        </div>
                    )}

                    <div className={'flex flex-col md:flex-row gap-y-3 gap-x-8 my-10'}>
                        <div className={'flex flex-col gap-y-3 justify-between md:w-7/12'}>
                            {areOffersLoading
                                ? _.times(6, i => (
                                      <OfferView key={i} variant={'home'} loading />
                                  ))
                                : topOffers
                                      ?.slice(0, 6)
                                      .map(offer => (
                                          <OfferView
                                              offer={offer}
                                              variant={'home'}
                                              key={offer.id}
                                          />
                                      ))}
                        </div>

                        <div className={'flex flex-col justify-between gap-y-3 md:w-5/12'}>
                            {areOffersLoading
                                ? _.times(
                                      areAdvertsLoaded && !advertsBetween?.length ? 9 : 4,
                                      i => (
                                          <OfferView
                                              key={i}
                                              variant={'home'}
                                              size={'sm'}
                                              loading
                                          />
                                      )
                                  )
                                : topOffers
                                      ?.slice(6, !!advertsBetween?.length ? 10 : 15)
                                      .map(offer => (
                                          <OfferView
                                              size={'sm'}
                                              offer={offer}
                                              variant={'home'}
                                              key={offer.id}
                                          />
                                      ))}

                            {areAdvertsLoaded ? (
                                !!advertsBetween?.length && (
                                    <AdsCarouselView
                                        intervalSeconds={30}
                                        className={'h-148 hidden md:flex'}
                                        role={'region'}
                                        aria-label={'advertisements between offers'}
                                    >
                                        {advertsBetween.map((ad, i) => (
                                            <Ad
                                                href={ad.referenceUrl}
                                                key={i}
                                                src={ad.imageUrl}
                                            />
                                        ))}
                                    </AdsCarouselView>
                                )
                            ) : (
                                <div className={'hidden md:block'}>
                                    <Skeleton height={592} />
                                </div>
                            )}
                        </div>

                        {adSenseMobileAdvert && (
                            <div
                                className={'lg:hidden w-[320px] h-[100px]'}
                                dangerouslySetInnerHTML={{
                                    __html: (adSenseMobileAdvert as any).script
                                }}
                            />
                        )}
                    </div>
                </div>

                {areAdvertsLoaded ? (
                    !!advertsBottom?.length && (
                        <AdsCarouselView
                            intervalSeconds={30}
                            className={'h-50 hidden md:flex'}
                            role={'region'}
                            aria-label={'bottom advertisements'}
                        >
                            {advertsBottom.map((ad, i) => (
                                <Ad href={ad.referenceUrl} key={i} src={ad.imageUrl} />
                            ))}
                        </AdsCarouselView>
                    )
                ) : (
                    <div>
                        <Skeleton height={200} />
                    </div>
                )}
            </div>

            <CompanyBarView />

            <PopularSearches />

            <Footer />

            <MobileBottomNav />
        </div>
    );
};

export default HomeView;
