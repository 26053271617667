//Hooks
import {useFavoritesView} from './FavoritesView.hook';
import {useWindowSize} from 'hooks/windowSize';
import {useTranslation} from 'react-i18next';

//Components
import SearchForm from 'components/search/SearchForm';
import OfferView from 'components/offer/OfferView';
import PaginationView from 'components/ui/PaginationView';
import CompanyBarView from 'components/nav/CompanyBarView';
import Banner from 'components/images/Banner';
import Footer from 'components/nav/Footer';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import Button from 'components/ui/Button';
import {Link} from '@reach/router';
import PopularSearches from 'components/nav/PopularSearches';

//Types
import {RouteComponentProps} from '@reach/router';

//Utils
import _ from 'lodash-es';
import cx from 'classnames';

const FavoritesView: React.VFC<RouteComponentProps> = () => {
    const {
        offers,
        areOffersLoading,
        handlePageChange,
        pageFromSearch,
        ref
    } = useFavoritesView();
    const {breakpoint} = useWindowSize();

    const {t} = useTranslation(['offers', 'common']);

    return (
        <div className={'bg-gray-200 h-full flex flex-col'}>
            {breakpoint !== 'xs' && <SearchForm />}

            <Banner />

            <div className={'w-full max-w-292 mx-auto md:my-12 my-5 lg:px-4 flex-grow'}>
                <h2 className={'md:hidden mx-4 mb-4.5'}>{t('common:favorites')}</h2>

                <div
                    className={cx('w-full flex flex-col gap-y-3 h-full', {
                        'lg:w-7/12': areOffersLoading || offers?.items.length
                    })}
                >
                    <span ref={ref} />

                    {areOffersLoading || offers?.items.length ? (
                        <>
                            {breakpoint !== 'xs' && (
                                <PaginationView
                                    currentPage={pageFromSearch}
                                    totalPages={offers?.pagination.totalPages ?? 0}
                                    onPageChange={handlePageChange}
                                />
                            )}

                            {areOffersLoading
                                ? _.times(4, i => (
                                      <OfferView key={i} variant={'home'} loading />
                                  ))
                                : offers?.items.map((offer, key) => (
                                      <OfferView
                                          key={offer.id ?? key}
                                          offer={offer}
                                          variant={'home'}
                                      />
                                  ))}

                            <PaginationView
                                currentPage={pageFromSearch}
                                totalPages={offers?.pagination.totalPages ?? 0}
                                onPageChange={handlePageChange}
                            />
                        </>
                    ) : (
                        <div className={'flex flex-col items-center'}>
                            <h2 className={'font-semibold mt-8'}>{t('offers:noFavorites')}</h2>

                            <span className={'text-xs mt-12'}>
                                {t('offers:noFavoritesDescription')}
                            </span>

                            <Button
                                as={Link}
                                to={'/'}
                                variant={'primary'}
                                size={'sm'}
                                className={'mt-16'}
                            >
                                {t('common:goToMainPage')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <CompanyBarView />

            <PopularSearches />

            <Footer />

            <MobileBottomNav />
        </div>
    );
};

export default FavoritesView;
