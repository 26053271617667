//Axios
import axios from 'axios';

//Utils
import camelcase from 'camelcase-keys';
import snakecase from 'snakecase-keys';

//Types
import {AxiosTransformer} from 'axios';

import {locale} from 'localeApp';

export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'X-Sales-Channel': locale
    },
    transformRequest: [
        ...(axios.defaults.transformRequest as AxiosTransformer[]),
        data =>
            !data || data instanceof FormData
                ? data
                : JSON.stringify(snakecase(JSON.parse(data)))
    ],
    transformResponse: [
        ...(axios.defaults.transformResponse as AxiosTransformer[]),
        data => camelcase(data, {deep: true})
    ]
});
