import * as React from 'react';

//Hooks
import {useEffect, useLayoutEffect, useState} from 'react';
import {useInfoView} from 'views/InfoView/InfoView.hook';
import {useNotificationsView} from 'views/NotificationsView/NotificationsView.hook';
import {useValidationLocale} from 'hooks/validation';

//Components
import Navbar from 'components/nav/Navbar';
import TokenLoader from 'components/auth/TokenLoader';
import ToasterView from 'components/ui/ToasterView';
import GoogleAnalyticsTracker from 'components/trackers/GoogleAnalyticsTracker';
import PWAPromptWrapper from './components/wrappers/PWAPromptWrapper';

//Routing
import {Router} from '@reach/router';

//Views
import InfoView from 'views/InfoView';
import StaticPageView from 'views/StaticPageView';
import HomeView from 'views/HomeView';
import AuthView from 'views/AuthView';
import LoginView from 'views/LoginView';
import BasketView from 'views/BasketView';
import RegisterView from 'views/RegisterView';
import RegisterInfoView from 'views/RegisterInfoView';
import RegisterAuthenticationView from 'views/RegisterMethodView';
import RegisterConfirmView from 'views/RegisterConfirmView';
import RegistrationSuccessViews from 'views/RegisterSuccessView';
import ContactView from 'views/ContactView';
import ForgotPasswordView from 'views/ForgotPasswordView';
import ForgotPasswordRequestView from 'views/ForgotPasswordRequestView';
import ForgotPasswordSuccessView from 'views/ForgotPasswordSuccessView';
import ForgotPasswordResetView from 'views/ForgotPasswordResetView';
import ForgotPasswordResetSuccessView from 'views/ForgotPasswordResetSuccessView';
import PaymentStepperView from 'views/PaymentStepperView';
import SearchView from 'views/SearchView';
import MessagesView from 'views/MessagesView';
import ChatView from 'views/ChatView';
import ProfileView from 'views/ProfileView';
import MyProfileView from 'views/MyProfileView';
import MyOffersView from 'views/MyOffersView';
import FavoritesView from 'views/FavoritesView';
import OfferDetailsView from 'views/OfferDetailsView';
import OfferFormView from 'views/OfferFormView';
import UserProfileView from 'views/UserProfileView';
import NotificationsView from 'views/NotificationsView';
import AccountSettingsView from 'views/AccountSettingsView';
import PaymentMethodView from 'views/PaymentMethodView';
import MyPurchasesView from 'views/MyPurchasesView';
import MyPurchaseDetailsView from 'views/MyPurchaseDetailsView';
import MyOffersDetailsView from 'views/MyOffersDetailsView';
import PaymentCardView from 'views/PaymentCardView';
import PaymentSuccessView from 'views/PaymentSuccessView';
import ReviewsView from './views/ReviewsView';

//Services
import 'services/i18n/i18n';
import NotFoundView from 'views/NotFoundView';

//Types
import {StaticPages} from 'store/types';
import {RouteComponentProps} from '@reach/router';
import {useVersion} from './hooks/version';
import {useAdverts} from './hooks/adverts';

const ScrollToTop: React.FC<RouteComponentProps> = ({children, location}) => {
    useLayoutEffect(() => window.scrollTo(0, 0), [location?.pathname]);
    return React.isValidElement(children)
        ? React.cloneElement(children, {className: 'h-full flex flex-col'})
        : null;
};

const App: React.VFC = () => {
    useValidationLocale();
    const {staticPages} = useInfoView();
    useNotificationsView();
    useVersion();

    const {data: allAdverts, isLoaded: areAdvertsLoaded} = useAdverts();

    const [adSenseLeft, setAdSenseLeft] = useState<any>();
    const [adSenseRight, setAdSenseRight] = useState<any>();

    useEffect(() => {
        setAdSenseLeft(allAdverts?.find(advert => advert.area === 'web_left'));
        setAdSenseRight(allAdverts?.find(advert => advert.area === 'web_right'));
    }, [allAdverts, areAdvertsLoaded]);

    return (
        <>
            <div className={'flex flex-col h-full relative'}>
                {adSenseLeft && (
                    <div
                        className={
                            'hidden lg:block w-48 h-96 top-96 left-0 top-full mb-96 fixed z-50'
                        }
                        dangerouslySetInnerHTML={{__html: adSenseLeft.script}}
                    ></div>
                )}

                {adSenseRight && (
                    <div
                        className={
                            'hidden lg:block w-48 h-96 top-96 right-0 bottom-0 fixed z-50'
                        }
                        dangerouslySetInnerHTML={{__html: adSenseRight.script}}
                    ></div>
                )}

                <Navbar />

                <Router className={'flex flex-col flex-grow'}>
                    <ScrollToTop path={'/'}>
                        <HomeView path={'/'} />

                        <AuthView path={'auth'}>
                            <LoginView path={'login'} />

                            <RegisterView path={'register'}>
                                <RegisterInfoView path={'/'} />

                                <RegisterAuthenticationView path={'/method'} />
                            </RegisterView>

                            <RegisterConfirmView path={'register/confirm/:uuid'} />

                            <RegistrationSuccessViews path={'register/success'} />
                        </AuthView>

                        <ForgotPasswordView path={'auth/forgot-password'}>
                            <ForgotPasswordRequestView path={'/'} />

                            <ForgotPasswordSuccessView path={'success'} />

                            <ForgotPasswordResetView path={'reset'} />

                            <ForgotPasswordResetSuccessView path={'reset/success'} />
                        </ForgotPasswordView>

                        <SearchView path={'search'} />

                        <MessagesView path={'messages'} />

                        <ChatView path={'chat/:id'} />

                        <OfferDetailsView path={'offer/:id'} />

                        <ProfileView path={'profile'}>
                            <MyProfileView path={'my-profile'} />

                            <MyOffersView path={'my-offers'} />

                            <MyOffersView path={'my-offers/:status'} />

                            <AccountSettingsView path={'account-settings'} />

                            <MyOffersDetailsView path={'my-offers/sold/:orderId'} />

                            <MyPurchasesView path={'my-purchases'} />

                            <MyPurchaseDetailsView path={'my-purchases/:orderId'} />
                        </ProfileView>

                        <FavoritesView path={'favorites'} />

                        <BasketView path={'basket'} />

                        <PaymentStepperView path={'payment'}>
                            <PaymentMethodView path={'method/extras'} />

                            <PaymentMethodView path={'method'} />
                        </PaymentStepperView>

                        <PaymentCardView path={'payment/card'} />

                        <PaymentCardView path={'payment/card/extras'} />

                        <PaymentSuccessView path={'payment/success'} />

                        <OfferFormView path={'offers/create'} />

                        <OfferFormView path={'offers/edit/:id'} />

                        <InfoView path={'info'}>
                            {staticPages?.map((list: StaticPages.StaticPages) =>
                                list.pages.map((page: StaticPages.StaticPageListItem) => {
                                    if (page.slug === 'contact-us') {
                                        return <ContactView path={page.slug} />;
                                    } else return <StaticPageView path={page.slug} />;
                                })
                            )}

                            <StaticPageView default />
                        </InfoView>

                        <UserProfileView path={'user/:id'} />

                        <ReviewsView path={'reviews/:type/:id'} />

                        <ReviewsView path={'reviews/:type'} />

                        <NotificationsView path={'notifications'} />

                        <NotFoundView default />
                    </ScrollToTop>
                </Router>
            </div>

            <TokenLoader />

            <ToasterView />

            <GoogleAnalyticsTracker />

            <PWAPromptWrapper />
        </>
    );
};

export default App;
