//Hooks
import {useInfoView} from './InfoView.hook';

//Components
import {Link} from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import Footer from 'components/nav/Footer';
import LinkGroup from 'components/nav/LinkGroup';
import Card from 'components/ui/Card';

//Utils
import _ from 'lodash-es';

//Types
import {RouteComponentProps} from '@reach/router';
import {StaticPages} from 'store/types';

const InfoView: React.FC<RouteComponentProps> = ({children}) => {
    const {isLoaded, staticPages} = useInfoView();

    return (
        <div className={'flex flex-col h-full'}>
            <div className={'flex justify-start flex-grow p-10 bg-gray-100 gap-14'}>
                <div className={'hidden lg:flex flex-col gap-y-6'}>
                    {isLoaded &&
                        staticPages?.map((list, i) => (
                            <LinkGroup
                                key={i}
                                title={list.title}
                                className={'w-72'}
                                aria-label={`Link group-${i}`}
                            >
                                {list.pages.map((page: StaticPages.StaticPageListItem, j) => (
                                    <Link to={page.slug} key={j}>
                                        {page.title}
                                    </Link>
                                ))}
                            </LinkGroup>
                        ))}

                    {!staticPages &&
                        _.times(2, i => (
                            <LinkGroup
                                key={i}
                                className={'w-72'}
                                aria-label={`Link group-${i}`}
                                loading
                            >
                                {_.times(3, j => (
                                    <Link to={'#'} key={j}>
                                        <Skeleton width={200} />
                                    </Link>
                                ))}
                            </LinkGroup>
                        ))}
                </div>

                <Card className={'flex-grow pb-12 max-w-static pt-7 px-14'}>
                    <Card.Body>{children}</Card.Body>
                </Card>
            </div>

            <Footer />
        </div>
    );
};

export default InfoView;
