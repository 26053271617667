/* istanbul ignore file */

//Styles
import {defaultTheme} from 'react-select';
import Colors from 'styles/colors';

//Types
import {StylesConfig, Theme} from 'react-select';

export const styles: StylesConfig<any, false> = {
    control: (provided, {theme}) => ({
        ...provided,
        border: `0.0625px solid ${theme.colors.gray300} !important`,
        boxShadow: 'none',
        width: '100%'
    }),
    placeholder: (provided, {theme}) => ({
        ...provided,
        color: theme.colors.gray400,
        margin: '0'
    }),
    option: (_provided, {isSelected, theme}) => ({
        padding: '0.75rem 0rem',
        margin: '0rem 1.125rem',
        fontSize: '0.875rem',
        fontWeight: isSelected ? 'bold' : 'normal',
        cursor: 'pointer',
        color: theme.colors.gray900,
        backgroundColor: 'transparent',
        '&:not(:last-child)': {
            borderBottom: `0.0625px solid transparent`,
            borderColor: theme.colors.gray400
        }
    }),
    valueContainer: provided => ({
        ...provided,
        padding: '0.5rem 1rem',
        fontSize: '0.875rem',
        cursor: 'pointer'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    menu: provided => ({
        ...provided,
        backgroundColor: 'white',
        border: `0.0625px solid ${Colors.gray300}`,
        borderRadius: '0.25rem',
        boxShadow: 'none',
        marginTop: '0.5rem'
    }),
    menuList: provided => ({
        ...provided,
        padding: '0'
    })
};

export const errorStyles: StylesConfig<any, false> = {
    ...styles,
    control: (provided, {theme}) => ({
        ...provided,
        border: `0.0625px solid ${theme.colors.red} !important`,
        boxShadow: 'none'
    })
};

export const disabledStyles: StylesConfig<any, false> = {
    ...styles,
    control: (provided, {theme}) => ({
        ...provided,
        backgroundColor: theme.colors.gray200,
        border: `0.0625px solid ${theme.colors.gray300} !important`,
        boxShadow: 'none'
    })
};

export const theme: Theme = {
    borderRadius: 4,
    colors: {
        primary: Colors.primary,

        red: Colors.red,

        gray100: Colors.gray100,
        gray200: Colors.gray200,
        gray300: Colors.gray300,
        gray400: Colors.gray400,
        gray600: Colors.gray600,
        gray800: Colors.gray800,
        gray900: Colors.gray900,
        white: Colors.white
    },
    spacing: {
        ...defaultTheme?.spacing,
        controlHeight: 48
    }
};
