//Hooks
import {useJobForm} from './JobForm.hook';
import {useTranslation} from 'react-i18next';
import {useInputHelperHook} from 'hooks/input';

//Components
import Options from 'components/ui/Options';
import Checkbox from 'components/ui/Checkbox';
import Input, {InputIcon} from 'components/ui/Input';

//Types
import {Offer} from 'store/types';

const JobForm: React.VFC = () => {
    const {values, errors, touched, handleChange, toggleShowSalary} = useJobForm();

    const {onBlur} = useInputHelperHook();

    const {t} = useTranslation(['offers', 'common']);

    return (
        <>
            <Checkbox
                id={'offer.showSalary'}
                name={'offer.showSalary'}
                className={'md:mt-17 mt-10'}
                label={t('offers:dontShowSalary')}
                labelClassName={'text-form ml-28'}
                checked={!values.offer.showSalary}
                onChange={toggleShowSalary}
            />

            <h2 id={'salary'} className={'mt-6 hidden md:flex'}>
                {t('offers:salary')}
            </h2>

            <div className={'md:mt-3.5 mt-11 md:w-86'}>
                <Options
                    name={'offer.priceVariant'}
                    value={values.offer.priceVariant ?? 'fixed'}
                    onChange={handleChange}
                >
                    <Options.Option
                        label={t('offers:fixed')}
                        id={'salary-fixed'}
                        value={'fixed'}
                    />

                    <Options.Option
                        label={t('offers:range')}
                        id={'salary-range'}
                        value={'range'}
                    />
                </Options>

                {values.offer.priceVariant !== Offer.PriceVariant.Range && (
                    <Input
                        id={'offer.salaryFrom.tbbcAmount'}
                        name={'offer.salaryFrom.tbbcAmount'}
                        className={'mt-3.5'}
                        value={values.offer.salaryFrom?.tbbcAmount?.toString() ?? ''}
                        onChange={handleChange}
                        onBlur={onBlur}
                        type={'text'}
                        error={
                            touched.offer?.salaryFrom?.tbbcAmount &&
                            errors.offer?.salaryFrom?.tbbcAmount
                        }
                        showControls={false}
                        disabled={!values.offer.showSalary}
                        aria-labelledby={'salary'}
                    >
                        <InputIcon placement={'right'}>
                            <span className={'text-gray-600 text-form'}>
                                {t('common:currencyShort')}
                            </span>
                        </InputIcon>
                    </Input>
                )}

                {values.offer.priceVariant === Offer.PriceVariant.Range && (
                    <div
                        className={
                            'flex flex-row items-end tracking-tighter text-gray-800 mt-3.5 text-form'
                        }
                    >
                        <label htmlFor={'offer.salaryFrom.tbbcAmount'}>
                            {t('common:from')}
                        </label>

                        <Input
                            id={'offer.salaryFrom.tbbcAmount'}
                            name={'offer.salaryFrom.tbbcAmount'}
                            className={'ml-2 w-30 mr-11'}
                            value={values.offer.salaryFrom?.tbbcAmount?.toString() ?? ''}
                            onChange={handleChange}
                            onBlur={onBlur}
                            type={'text'}
                            error={
                                touched.offer?.salaryFrom?.tbbcAmount &&
                                errors.offer?.salaryFrom?.tbbcAmount
                            }
                            showControls={false}
                            disabled={!values.offer.showSalary}
                        >
                            <InputIcon placement={'right'}>
                                <span className={'text-gray-600 text-form'}>
                                    {t('common:currencyShort')}
                                </span>
                            </InputIcon>
                        </Input>

                        <label htmlFor={'offer.salaryTo.tbbcAmount'}>{t('common:to')}</label>

                        <Input
                            id={'offer.salaryTo.tbbcAmount'}
                            name={'offer.salaryTo.tbbcAmount'}
                            className={'ml-2 w-30'}
                            value={values.offer.salaryTo?.tbbcAmount?.toString() ?? ''}
                            onChange={handleChange}
                            onBlur={onBlur}
                            type={'text'}
                            error={
                                touched?.offer?.salaryTo?.tbbcAmount &&
                                errors?.offer?.salaryTo?.tbbcAmount
                            }
                            showControls={false}
                            disabled={!values.offer.showSalary}
                        >
                            <InputIcon placement={'right'}>
                                <span className={'text-gray-600 text-form'}>
                                    {t('common:currencyShort')}
                                </span>
                            </InputIcon>
                        </Input>
                    </div>
                )}
            </div>
        </>
    );
};

export default JobForm;
