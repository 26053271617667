//Hooks
import {useEffect} from 'react';
import {useLocation} from '@reach/router';
import {useDispatch, useSelector} from 'hooks/store';
import {useCategories} from 'hooks/categories';
import {useCoordinates} from '../coordinates.hook';
import {useFormikContext} from 'formik';

//Actions
import * as actions from 'store/actions';

//Types
import {Offer, Files} from 'store/types';
import {CreateBaseFormProps} from './BaseForm';

export const useBaseForm = ({
    setUploadedFiles
}: Pick<CreateBaseFormProps, 'setUploadedFiles'>) => {
    const {data: existingOffer} = useSelector(state => state.offers.editedOffer);
    const {data: categories, isLoaded: areCategoriesLoaded} = useCategories();
    const {lastCheckedAddress, handleGetCoords} = useCoordinates();
    const dispatch = useDispatch();
    const location = useLocation();

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        setFieldValue
    } = useFormikContext<Offer.Form>();

    const handleSaveBeforeExtraPhotos = async (status: Offer.Status) => {
        // make sure coords match submitted address
        if (
            !values.hideLocation &&
            lastCheckedAddress !==
                `${values.address?.street}, ${values.address?.city}, ${values.address?.state}`
        ) {
            await handleGetCoords(values.address!);
        }
        if (!values.address?.geoCoordinate) {
            await setFieldValue('hideLocation', true);
        }
        await setFieldValue('offer.status', status, false);
        handleSubmit();
    };

    const isEditable = !!existingOffer && location.pathname.includes('edit');

    const handleFilesUpload = (values: Files.UploadedPhoto[]) => {
        setUploadedFiles(values);
        setFieldValue('offer.images', values);
    };

    const handleCategoryChange = async (categoryId: number) => {
        const category = categories?.find(item => item.id === categoryId);
        const offerType = category?.offerType ?? Offer.Type.Product;

        setFieldValue('offer.category', category);
        setFieldValue('type', offerType);
    };

    useEffect(() => {
        if (!values.offer.category) {
            dispatch(actions.resetCategoryCustomFormFields());
            return;
        }

        dispatch(actions.getCategoryCustomFormFields.request(values.offer.category));
    }, [values.offer.category, dispatch]);

    return {
        categories: isEditable
            ? categories?.filter(({offerType}) => offerType === existingOffer!.type)
            : categories,
        areCategoriesLoaded,
        values,
        errors,
        touched,
        isEditable,
        existingOffer,
        handleChange,
        handleFilesUpload,
        handleCategoryChange,
        handleSaveBeforeExtraPhotos
    };
};
