//Utils
import cx from 'classnames';

//Components
import ReactTooltip from 'react-tooltip';

//Styles
import Styles from './Tooltip.module.scss';
import Colors from 'styles/colors';

export type TooltipContentProps = {
    id?: string;
    className?: string;
};

const Content: React.FC<TooltipContentProps> = ({id, className, children}) => (
    <ReactTooltip
        id={id}
        className={cx(Styles.tooltip, className)}
        backgroundColor={Colors.secondary}
        arrowColor={'transparent'}
        effect={'solid'}
        event={'click'}
        globalEventOff={'click'}
        role={'tooltip'}
    >
        {children}
    </ReactTooltip>
);

export default Content;
