//Utils
import cx from 'classnames';

export type TextAreaProps = {
    value?: string;
    className?: string;
    error?: string | undefined | null | false;
    maxLength?: number;
    label?: string;
} & Omit<React.HTMLProps<HTMLTextAreaElement>, 'value'>;

const TextArea: React.VFC<TextAreaProps> = ({
    value,
    className,
    error,
    disabled,
    maxLength,
    label,
    ...props
}) => (
    <div className={cx('flex flex-col', className)} role={'region'} aria-label={props.name}>
        {label && (
            <label className={'mb-3 text-sm'} htmlFor={props.id}>
                {label}
            </label>
        )}

        <textarea
            className={cx(
                {
                    'bg-gray-200': disabled
                },
                {
                    'border-gray-300 focus:border-primary': !error,
                    'border-red': !!error
                },
                'p-4 bg-white border rounded text-sm tracking-tight text-gray-900 placeholder-gray-600 transition focus:ring-4 ring-primary ring-opacity-50 focus:outline-none'
            )}
            value={value}
            disabled={disabled}
            maxLength={maxLength}
            {...props}
        />

        {(!!maxLength || !!error) && (
            <div
                className={cx(
                    {
                        'justify-end': !error,
                        'justify-between': !!error
                    },
                    'flex mt-2.5'
                )}
            >
                {!!error && (
                    <span className={'text-xs text-red'} role={'alert'} aria-label={'error'}>
                        {error}
                    </span>
                )}

                {!!maxLength && (
                    <span
                        className={'text-xs text-gray-600'}
                        role={'alert'}
                        aria-label={'length'}
                    >
                        {`${value?.length ?? 0}/${maxLength}`}
                    </span>
                )}
            </div>
        )}
    </div>
);

export default TextArea;
