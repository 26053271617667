//Services
import axios from 'services/axios';

//Types
import {AxiosTransformer} from 'axios';
import {Offer, Pagination} from 'store/types';

type ReceivedOffer = Omit<Offer.ListItem, 'createdAt' | 'closedAt' | 'expiredAt'> & {
    createdAt: string;
    closedAt: string;
    expiredAt: string;
};

type ReceivedOfferList = {
    pagination: Pagination;
    items: ReceivedOffer[];
};

export const transformResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    ({items, ...data}: ReceivedOfferList) => ({
        ...data,
        items: items.map(({createdAt, expiredAt, closedAt, ...offer}) => ({
            ...offer,
            createdAt: new Date(createdAt),
            expiredAt: expiredAt ? new Date(expiredAt) : expiredAt,
            closedAt: closedAt ? new Date(closedAt) : closedAt
        }))
    })
];
