//Hooks
import {useEffect} from 'react';
import {useSelector, useDispatch} from './store';

//Actions
import * as actions from 'store/actions';

export const useContactSubjects = () => {
    const {isLoaded, isErrored, data} = useSelector(state => state.staticPages.contactSubjects);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoaded || isErrored) {
            return;
        }

        dispatch(actions.getConstants.request());
    }, [isLoaded, isErrored, dispatch]);

    return {isLoaded, data};
};
