//Services
import axios from 'services/axios';

//Utils
import _ from 'lodash-es';

//Types
import {AxiosTransformer} from 'axios';
import {Offer, Search, Pagination} from 'store/types';

type MoneySerializedReceived = {
    amount: string;
    currency: string;
};
type ReceivedOffer = Omit<Offer.ListItem, 'createdAt' | 'closedAt' | 'expiredAt'> & {
    createdAt: string;
    closedAt: string;
    expiredAt: string;
    price?: MoneySerializedReceived;
    priceFrom?: MoneySerializedReceived;
    priceTo?: MoneySerializedReceived;
    salaryTo?: MoneySerializedReceived;
    salaryFrom?: MoneySerializedReceived;
};
type ReceivedOfferList = {
    pagination: Pagination;
    items: ReceivedOffer[];
};

const transformProduct = (
    {priceFrom, priceTo, free, ...product}: Search.ProductFilters = {
        priceFrom: undefined,
        priceTo: undefined,
        free: false
    }
) => ({
    ...product,
    priceFrom: free ? 0 : !!priceFrom ? priceFrom * 100 : priceFrom,
    priceTo: free ? 0 : !!priceTo ? priceTo * 100 : priceTo
});

const transformService = (
    {priceFrom, priceTo, ...service}: Search.ServiceFilters = {
        priceFrom: undefined,
        priceTo: undefined,
        period: Offer.Period.Hourly
    }
) =>
    !!priceFrom || !!priceTo
        ? [
              {
                  ...service,
                  priceFrom: !!priceFrom ? priceFrom * 100 : priceFrom,
                  priceTo: !!priceTo ? priceTo * 100 : priceTo
              }
          ]
        : undefined;

const transformJob = ({salaryFrom, salaryTo, ...job}: Search.JobFilters<Offer.Period>) => ({
    ...job,
    salaryFrom: !!salaryFrom ? salaryFrom * 100 : salaryFrom,
    salaryTo: !!salaryTo ? salaryTo * 100 : salaryTo
});

const jobFilter = (job: Search.JobFilters<Offer.Period>): boolean =>
    ![undefined, ''].includes(job.salaryFrom?.toString()) ||
    ![undefined, ''].includes(job.salaryTo?.toString());

export const transformRequest = [
    ({attributes, category, job, service, product, ...data}: Search.FormValues) => ({
        attributes: !_.isEmpty(attributes) ? attributes : undefined,
        category: category?.id,
        job:
            category?.offerType === Offer.Type.Job
                ? job?.map(item => transformJob(item)).filter(jobFilter)
                : undefined,
        service:
            category?.offerType === Offer.Type.Service ? transformService(service) : undefined,
        product:
            category?.offerType === Offer.Type.Product ? transformProduct(product) : undefined,
        ...data
    }),
    ...(axios.defaults.transformRequest as AxiosTransformer[])
];

export const transformResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    ({items, ...data}: ReceivedOfferList) => ({
        ...data,
        items: items.map(({createdAt, expiredAt, closedAt, ...offer}) => ({
            ...offer,
            createdAt: new Date(createdAt),
            expiredAt: expiredAt ? new Date(expiredAt) : expiredAt,
            closedAt: closedAt ? new Date(closedAt) : closedAt,
            price: offer.price
                ? {...offer.price, amount: parseInt(offer.price.amount)}
                : offer.price,
            priceFrom: offer.priceFrom
                ? {...offer.priceFrom, amount: parseInt(offer.priceFrom.amount)}
                : offer.priceFrom,
            priceTo: offer.priceTo
                ? {...offer.priceTo, amount: parseInt(offer.priceTo.amount)}
                : offer.priceTo,
            salaryFrom: offer.salaryFrom
                ? {...offer.salaryFrom, amount: parseInt(offer.salaryFrom.amount)}
                : offer.salaryFrom,
            salaryTo: offer.salaryTo
                ? {...offer.salaryTo, amount: parseInt(offer.salaryTo.amount)}
                : offer.salaryTo
        }))
    })
];
