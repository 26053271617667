//Assets
import ContactUsIcon from 'assets/icons/contact-us.png';
import HaulWithtUsIcon from 'assets/icons/haul-with-us.png';
import WorkWithAllspaceIcon from 'assets/icons/work-with-allspace.png';

//Hooks
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useWindowSize} from 'hooks/windowSize';

//Utils
import _ from 'lodash-es';

//Types
import {Categories, Offer} from 'store/types';

export const useCategoryView = (categories: Categories.Category[]) => {
    const {t} = useTranslation('nav');
    const [activeId, setActiveId] = useState<number | null>(null);
    const {breakpoint, isMobile} = useWindowSize();
    const parentCategories = categories.filter(category => !category.parentId);
    const rowLengths = {xs: 3, sm: 4, md: 5, lg: 7, xl: 7, '2xl': 7};

    const rows = _.chunk(
        [
            ...parentCategories,
            {
                id: -1,
                name: t('footer.haulWithUs'),
                children: [],
                offerType: Offer.Type.Product,
                imageUrl: HaulWithtUsIcon
            } as Categories.Category,
            {
                id: -2,
                name: t('footer.workWithAllspace'),
                children: [],
                offerType: Offer.Type.Product,
                imageUrl: WorkWithAllspaceIcon
            } as Categories.Category,
            {
                id: -3,
                name: t('footer.contactUs'),
                children: [],
                offerType: Offer.Type.Product,
                imageUrl: ContactUsIcon
            } as Categories.Category
        ],
        rowLengths[breakpoint]
    );
    const subcategories = categories.filter(category => category.parentId === activeId);
    const rowLength = rowLengths[breakpoint];
    const activeCategory = categories.find(({id}) => id === activeId);

    return {
        activeCategory,
        setActiveId,
        rows,
        subcategories,
        rowLength,
        isMobile
    };
};
