//Hooks
import {useTranslation} from 'react-i18next';

//Components
import Modal from 'components/ui/Modal';

export type ContactInfoModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const ContactInfoModal: React.VFC<ContactInfoModalProps> = ({isOpen, onClose}) => {
    const {t} = useTranslation('staticPage');

    return (
        <Modal isOpen={isOpen} size={'lg'} onClose={onClose}>
            <div className={'flex justify-center items-center flex-col py-8'}>
                <h2 className={'text-lg font-semibold'}>{t('thanksForYourMessage')}</h2>

                <p role={'paragraph'} className={'text-xs-checkbox mt-8'}>
                    {t('weWillContactYouShortly')}
                </p>
            </div>
        </Modal>
    );
};

export default ContactInfoModal;
