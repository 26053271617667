import * as React from 'react';

//Utils
import cx from 'classnames';

export type BreadcrumbProps = {
    className?: string;
    children: React.ReactElement | React.ReactElement[];
} & Omit<React.HTMLProps<HTMLDivElement>, 'className' | 'children'>;

const Breadcrumbs: React.VFC<BreadcrumbProps> = ({className, children, ...props}) => (
    <nav className={cx(className, 'flex gap-2')} role={'region'} {...props}>
        {React.Children.map(children, (child, i) => (
            <span
                className={
                    'pr-2 border-r last:border-0 border-gray-700 text-sm text-gray-900 last:text-gray-700 last:font-bold'
                }
                aria-current={i === React.Children.count(children) - 1 && 'page'}
            >
                {child}
            </span>
        ))}
    </nav>
);

export default Breadcrumbs;
