//Hooks
import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'hooks/store';
import {useWindowSize} from 'hooks/windowSize';

//Actions
import * as actions from 'store/actions';
import {Reviews} from 'store/types';

type UseReviewsViewArgs = {
    type: 'seller' | 'offer' | 'me' | undefined;
    id: string | undefined;
};

export const useReviewsView = ({type, ...args}: UseReviewsViewArgs) => {
    const {isLoading, isLoaded, isErrored, data, props} = useSelector(state => {
        if (type !== 'seller' && type !== 'me' && type !== 'offer') {
            return {
                isLoading: false,
                isLoaded: false,
                isErrored: true,
                data: null,
                props: null
            };
        }

        return state.reviews[type];
    });
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [isCreateReviewModalOpen, setCreateReviewModalOpen] = useState(false);
    const [isDisputeReviewModalOpen, setDisputeReviewModalOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState<Reviews.Review | undefined>(undefined);
    const user = useSelector(state => state.user.user);
    const userId = useSelector(state => state.auth?.notifuseAuthentication?.userId);

    const id = parseInt(args.id!);

    useEffect(() => {
        if (
            !type ||
            isLoading ||
            (isLoaded && props?.page === currentPage && type === 'me') ||
            (isLoaded && props?.id === id && props?.page === currentPage) ||
            (isErrored && props?.id === id && props?.page === currentPage)
        ) {
            return;
        }

        switch (type) {
            case 'seller':
                dispatch(actions.getSellerReviews.request({id, page: currentPage}));
                break;
            case 'offer':
                dispatch(actions.getOfferReviews.request({id, page: currentPage}));
                break;
            case 'me':
                dispatch(actions.getMyReviews.request({page: currentPage}));
                break;
        }
    }, [type, id, isLoading, isLoaded, isErrored, props, currentPage, dispatch]);

    const handlePageChange = (currentPage: number) => {
        setCurrentPage(currentPage);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    const isDispute =
        !!user &&
        ((!data?.offer && data?.seller && !!userId && id === parseInt(userId)) ||
            (!data?.seller && !data?.offer));

    const openCreateReviewModal = useCallback(() => {
        setCreateReviewModalOpen(true);
    }, [setCreateReviewModalOpen]);

    const closeCreateReviewModal = useCallback(() => {
        setCreateReviewModalOpen(false);
    }, [setCreateReviewModalOpen]);

    const openDisputeReviewModal = useCallback(
        (review: Reviews.Review) => {
            setSelectedReview(review);
            setDisputeReviewModalOpen(true);
        },
        [setDisputeReviewModalOpen, setSelectedReview]
    );

    const closeDisputeReviewModal = useCallback(() => {
        setDisputeReviewModalOpen(false);
    }, [setDisputeReviewModalOpen]);

    const {breakpoint} = useWindowSize();

    return {
        isLoading,
        isLoaded,
        isErrored,
        data,
        user,
        isDispute,
        currentPage,
        selectedReview,
        handlePageChange,
        isDisputeReviewModalOpen,
        isCreateReviewModalOpen,
        openCreateReviewModal,
        closeCreateReviewModal,
        openDisputeReviewModal,
        closeDisputeReviewModal,
        breakpoint
    };
};
