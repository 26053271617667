//Redux saga
import {put, call, select, take} from 'redux-saga/effects';

//Actions
import * as actions from 'store/actions';

//Services
import axios from 'services/axios';

//Utils
import {transformError} from 'utils/transformError';

//Types
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {RootState} from 'store';

export function* request<T>(
    config: AxiosRequestConfig,
    useAuthorization = false
): Generator<any, AxiosResponse<T>, any> {
    if (useAuthorization) {
        yield waitForToken();
    }

    const token = useAuthorization
        ? yield select<(state: RootState) => string | null>(state => state.auth.token)
        : null;

    try {
        return yield call(
            axios.request,
            useAuthorization && token
                ? {
                      ...config,
                      headers: {
                          ...(config.headers ?? {}),
                          Authorization: `Bearer ${token}`
                      }
                  }
                : config
        );
    } catch (error: any) {
        if (useAuthorization && error.response?.status === 401) {
            yield put(actions.refreshAccessToken.request());
            yield take(actions.refreshAccessToken.success);
            return yield call(request, config, useAuthorization);
        }

        throw transformError(error);
    }
}

function* waitForToken() {
    const isTokenLoaded: boolean = yield select<(state: RootState) => boolean>(
        state => !!state.auth.token || state.auth.isTokenErrored
    );
    if (isTokenLoaded) {
        return;
    }

    while (true) {
        yield take('*');

        const isTokenLoaded: boolean = yield select<(state: RootState) => boolean>(
            state => !!state.auth.token || state.auth.isTokenErrored
        );
        if (isTokenLoaded) {
            return;
        }
    }
}
