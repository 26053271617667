//Redux saga
import {call, put, debounce} from 'redux-saga/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';

//Transformers
import {transformers} from 'utils';

//Types
import {AxiosResponse} from 'axios';
import {Search} from 'store/types';

export function* search(action: ReturnType<typeof actions.search.request>) {
    try {
        const {page, ...data} = action.payload;
        const resp: AxiosResponse<Search.Result> = yield call(request, {
            method: 'POST',
            url: `/api/search/offers${page ? `?page=${page}` : ''}`,
            data,
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: transformers.search.transformRequest,
            transformResponse: transformers.search.transformResponse
        });
        yield put(actions.checkFavorites.request(resp.data.items));
        yield put(actions.search.success(resp.data));
    } catch (err) {
        yield put(actions.search.failure(err));
    }
}

export const saga = [debounce(1000, actions.search.request, search)];
