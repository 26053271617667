//Hooks
import {useEffect} from 'react';
import {useDispatch} from 'hooks/store';
import {useFormik} from 'formik';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import {CardNumberElement} from '@stripe/react-stripe-js';

//Actions
import * as actions from 'store/actions';

//Utils
import {object, string} from 'yup';

//Services
import toast from 'react-hot-toast';

//Types
import {User} from 'store/types';
import {PaymentMethod, StripeError} from '@stripe/stripe-js';
import {useTranslation} from 'react-i18next';

export type PaymentMethodSuccessResult = {
    paymentMethod: PaymentMethod;
    error?: undefined;
};

export const useEditCreditCardView = (closeModal: () => void) => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const {t} = useTranslation('errors');

    const validationSchema = object({
        holder: string().required()
    });

    const handleError = (error: StripeError) => {
        toast.error(error.message ?? t('errorCreditCard'));
    };

    const handleSuccess = async (response: PaymentMethodSuccessResult | undefined) => {
        await dispatch(actions.setCard.request(response!.paymentMethod!.id));
        await dispatch(actions.getCards.request());
        closeModal();
    };

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm
    } = useFormik<Pick<User.PayoutReturn, 'holder'>>({
        initialValues: {
            holder: ''
        },
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            const card = elements?.getElement(CardNumberElement);
            try {
                const response = await stripe?.createPaymentMethod({
                    type: 'card',
                    card: card!,
                    billing_details: {
                        name: payload.holder
                    }
                });

                if (response?.error) {
                    handleError(response?.error);
                } else {
                    handleSuccess(response);
                }
            } catch (err) {
                if (err.message) {
                    toast.error(err.message);
                }

                if (err.errors) {
                    setErrors(err.errors);
                }
            }
        },
        enableReinitialize: true
    });

    useEffect(() => {
        resetForm();
    }, [closeModal, resetForm]);

    return {values, touched, errors, handleChange, handleSubmit, isSubmitting};
};
