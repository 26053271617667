//Hooks
import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'hooks/store';
import {useLocationSearchPagination} from 'hooks/locationSearchPagination';

//Actions
import * as actions from 'store/actions';

export const useFavoritesView = () => {
    const dispatch = useDispatch();
    const {isLoading: areOffersLoading, data: offers} = useSelector(
        state => state.favorites.offers
    );
    const ref = useRef<HTMLSpanElement>(null);
    const {changePage, pageFromSearch} = useLocationSearchPagination({
        ref
    });

    useEffect(() => {
        if (offers && offers.pagination.totalPages < pageFromSearch) {
            changePage(offers.pagination.totalPages);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offers, changePage]);

    useEffect(() => {
        dispatch(actions.getFavorites.request(pageFromSearch));
    }, [dispatch, pageFromSearch]);

    const handlePageChange = (page: number) => changePage(page);

    return {offers, areOffersLoading, handlePageChange, pageFromSearch, ref};
};
