import * as React from 'react';

//Hooks
import {useModal} from './Modal.hook';

//Components
import {Transition} from 'react-transition-group';
import Icon from 'components/ui/Icon';

//Utils
import {createPortal} from 'react-dom';
import cx from 'classnames';

export type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    size?: 'lg' | 'md';
    className?: string;
};

const backdropStyles = {
    entering: 'bg-opacity-0',
    entered: 'bg-opacity-50',
    exiting: 'bg-opacity-0',
    exited: 'bg-opacity-0'
};
const modalStyles = {
    entering: 'scale-90 opacity-0',
    entered: 'scale-100 opacity-100',
    exiting: 'scale-90 opacity-0',
    exited: 'scale-90 opacity-0'
};

const Modal: React.FC<ModalProps> = ({isOpen, onClose, size = 'md', children, className}) => {
    const {modalRef, element} = useModal({isOpen, onClose});

    return createPortal(
        <Transition
            in={isOpen}
            timeout={{
                appear: 0,
                exit: 200
            }}
        >
            {state => {
                if (state === 'unmounted' || state === 'exited') {
                    return;
                }

                return (
                    <div
                        className={cx(
                            backdropStyles[state],
                            'fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black transition-all duration-200'
                        )}
                    >
                        <div
                            ref={modalRef}
                            className={cx(
                                modalStyles[state],
                                {
                                    'w-100': size === 'md',
                                    'w-200': size === 'lg'
                                },
                                'p-6 bg-white rounded-2xl transform-gpu transition-all duration-200',
                                className
                            )}
                            role={'dialog'}
                            aria-hidden={(!isOpen).toString() as 'true' | 'false'}
                        >
                            <div className={'flex justify-end'}>
                                <button
                                    className={'focus:outline-none'}
                                    onClick={onClose}
                                    aria-label={'Close modal'}
                                >
                                    <Icon icon={'close'} />
                                </button>
                            </div>

                            {children}
                        </div>
                    </div>
                );
            }}
        </Transition>,
        element
    );
};

export default Modal;
