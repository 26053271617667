//Hooks
import {useMemo, useCallback} from 'react';
import {useDispatch} from 'hooks/store';
import {useNavigate} from '@reach/router';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, string, mixed, boolean} from 'yup';

//Types
import {ObjectSchema} from 'yup';
import {FormikHelpers} from 'formik';
import {Auth, User} from 'store/types';

export const useRegisterView = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const initialValues = useMemo<Auth.RegisterInfoFormValues>(
        () => ({
            account: {
                type: User.Type.Person,
                data: {
                    username: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    birthDay: '',
                    password: ''
                },
                address: {
                    street: '',
                    apartmentNumber: '',
                    city: '',
                    postCode: '',
                    state: ''
                }
            },
            checkbox1: false,
            checkbox2: false,
            checkbox3: false,
            checkbox4: false
        }),
        []
    );

    const handleSubmit = useCallback(
        async (
            values: Auth.RegisterInfoFormValues,
            {setErrors}: FormikHelpers<Auth.RegisterInfoFormValues>
        ) => {
            try {
                const {uuid} = await dispatch(actions.register.request(values));
                await navigate(`/auth/register/confirm/${uuid}`);
            } catch (error) {
                if (error.message) {
                    toast.error(error.message);
                }

                if (error.errors) {
                    setErrors(error.errors);
                    await navigate('/auth/register');
                }
            }
        },
        [dispatch, navigate]
    );

    const validationSchema = object<Auth.RegisterInfoFormValues>({
        account: object({
            type: mixed<User.Type>().defined(),
            data: object({
                username: string().required(),
                email: string().email().required(),
                phone: string().required(),
                password: string().required()
            })
                .when('type', (type: User.Type, schema: ObjectSchema<any>) =>
                    type === User.Type.Person
                        ? schema.shape({
                              firstName: string().required(),
                              lastName: string().required(),
                              birthDay: string().required()
                          })
                        : schema.shape({
                              companyName: string().required(),
                              vatId: string().required()
                          })
                )
                .required() as ObjectSchema<Auth.RegisterInfoFormValues['account']['data']>,
            address: object({
                street: string().required(),
                apartmentNumber: string(),
                city: string().required(),
                postCode: string().required(),
                state: string().required()
            }).required()
        }).required(),
        checkbox1: boolean().required(),
        checkbox2: boolean().required(),
        checkbox3: boolean().required(),
        checkbox4: boolean().required()
    });

    return {initialValues, handleSubmit, validationSchema};
};
