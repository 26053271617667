//Hooks
import {useTranslation} from 'react-i18next';
import {useRegisterConfirmView} from './RegisterConfirmView.hook';

//Components
import Button from 'components/ui/Button';
import Input from 'components/ui/Input';

//Types
import {RouteComponentProps} from '@reach/router';
import {Auth} from 'store/types';

const RegisterConfirmView: React.VFC<RouteComponentProps<Auth.RegisterUUID>> = ({uuid}) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useRegisterConfirmView({uuid});

    const {t} = useTranslation('auth');

    return (
        <form className={'flex flex-col'} onSubmit={handleSubmit}>
            <h1 className={'text-lg font-semibold'}>{t('authentication')}</h1>

            <p className={'text-xs-checkbox mt-3.5'}>{t('confirmDescription')}</p>

            <Input
                id={'code'}
                name={'code'}
                value={values.code}
                onChange={handleChange}
                label={t('code')}
                error={touched.code && errors.code}
                className={'mt-12 sm:w-7/12'}
            />

            <Button
                className={'self-center mt-28 w-full max-w-88'}
                variant={'primary'}
                type={'submit'}
                disabled={isSubmitting}
            >
                {t('confirm')}
            </Button>
        </form>
    );
};

export default RegisterConfirmView;
