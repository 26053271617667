//Hooks
import {useFormikContext} from 'formik';
import {useEffect} from 'react';
import {useParams} from '@reach/router';
import {useSelector} from 'hooks/store';
import {useCoordinates} from '../coordinates.hook';

//Types
import {Offer} from 'store/types';

export const useSubmitForm = () => {
    const {
        isSubmitting,
        setFieldValue,
        handleSubmit,
        errors,
        values,
        isValidating
    } = useFormikContext<Offer.Form>();

    const {data: existingOffer} = useSelector(state => state.offers.editedOffer);
    const {lastCheckedAddress, handleGetCoords} = useCoordinates();
    const id = useParams()?.id;

    const handleSubmitWithStatus = async (status: Offer.Status) => {
        // make sure coords match submitted address
        if (
            !values.hideLocation &&
            lastCheckedAddress !==
                `${values.address?.street}, ${values.address?.city}, ${values.address?.state}`
        ) {
            await handleGetCoords(values.address!);
        }
        await setFieldValue('offer.status', status, false);
        handleSubmit();
    };

    useEffect(() => {
        const getFormikKeysFromObj = (
            obj: Record<string, any>,
            prefix: string = ''
        ): string[] | string =>
            Object.keys(obj).reduce((res: string[], el) => {
                if (Array.isArray(obj[el])) {
                    return res;
                } else if (typeof obj[el] === 'object' && obj[el] !== null) {
                    return [
                        ...res,
                        ...(getFormikKeysFromObj(obj[el], prefix + el + '.') as string[])
                    ];
                } else {
                    return [...res, prefix + el];
                }
            }, []);

        const keys = getFormikKeysFromObj(errors);
        if (isSubmitting && !isValidating && !!keys.length) {
            const errorElement =
                keys[0] === 'address.geoCoordinate'
                    ? document.querySelector('[id="location-heading"]')
                    : document.querySelector(`label[for="${keys[0]}"]`) ??
                      document.querySelector(`[name="${keys[0]}"]`);
            if (errorElement) {
                errorElement.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, [errors, isSubmitting, isValidating]);

    return {isSubmitting, handleSubmitWithStatus, status: id && existingOffer?.status};
};
