//Styles
import Styles from './Switch.module.scss';

//Utils
import cx from 'classnames';

export type SwitchProps = {
    label?: string;
} & React.HTMLProps<HTMLInputElement>;

const Switch: React.VFC<SwitchProps> = ({checked, label, className, ...props}) => (
    <div className={cx(className, 'flex items-center')}>
        <input
            {...props}
            className={cx(
                Styles.switch,
                'appearance-none w-10 h-5 border border-gray-900 rounded-full relative transition-colors focus:outline-none cursor-pointer'
            )}
            type={'checkbox'}
            role={'switch'}
            aria-checked={checked}
            aria-label={label}
        />

        {label && <label className={'ml-24 text-sm'}>{label}</label>}
    </div>
);

export default Switch;
