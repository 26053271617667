//Images
import {ReactComponent as LogoImage} from 'assets/files/logo.svg';

//Styles
import Styles from './Logo.module.scss';

//Utils
import cx from 'classnames';

export type LogoProps = {
    white?: boolean;
    className?: string;
};

const Logo: React.VFC<LogoProps> = ({white, className}) => (
    <LogoImage
        className={cx(Styles.logo, {[Styles.white]: white}, className)}
        aria-label={'logo'}
    />
);

export default Logo;
