enum Colors {
    primary = '#FC654A',
    secondary = '#0ABAB5',

    white = '#FFFFFF',
    black = '#000000',
    yellow = '#F4E04D',
    red = '#FF2D55',

    gray100 = 'F8F8F8',
    gray200 = '#EEEDEB',
    gray300 = '#BEBEBC',
    gray400 = '#989896',
    gray600 = '#7A7A78',
    gray700 = '#50555C',
    gray800 = '#323232',
    gray900 = '#202020'
}

export default Colors;
