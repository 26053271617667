import * as React from 'react';

//Utils
import cx from 'classnames';

export type DropdownMenuProps = {
    children: React.ReactElement | React.ReactElement[];
    className?: string;
    position?: 'left' | 'right';
    onClick?: () => void;
};

const DropdownMenu: React.VFC<DropdownMenuProps> = ({children, position, className}) => (
    <ul
        className={cx(
            'border border-gray-300 px-7 absolute mt-1',
            {
                'left-0': position === 'left',
                'right-0': position === 'right'
            },
            className
        )}
    >
        {React.Children.map(children, (child, i) => (
            <li
                key={i}
                className={
                    'flex flex-row items-center py-3.5 text-sm border-b border-gray-300 last:border-0 tracking-medium-tight'
                }
            >
                {React.cloneElement(child)}
            </li>
        ))}
    </ul>
);
DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
