//Hooks
import {useStatsView} from './StatsView.hook';
import {useTranslation} from 'react-i18next';

//Components
import Skeleton from 'react-loading-skeleton';
import LoadingWrapperFactory from 'components/wrappers/LoadingWrapperFactory';

type StatsViewProps = {
    className?: string;
};

const StatsView: React.VFC<StatsViewProps> = ({className}) => {
    const {stats} = useStatsView();

    const {t} = useTranslation(['stats', 'offers']);

    return (
        <div className={className}>
            <div className={'mb-6'}>
                <h2 className={'mb-2 text-xl'}>{t('stats:aboutYourActivity')}</h2>

                <p className={'text-xs'}>{t('stats:activityDescription')}</p>
            </div>

            <div className={'flex border-t border-b border-gray-200 py-4'}>
                <div className={'flex flex-col px-8 border-r border-gray-200'}>
                    <span
                        className={'mb-1 text-lg font-bold'}
                        role={'figure'}
                        aria-label={'Deals count'}
                    >
                        {stats?.offers.offersCounter}

                        {!stats && (
                            <Skeleton
                                width={35}
                                wrapper={LoadingWrapperFactory({
                                    role: 'figure',
                                    'aria-label': 'Loading'
                                })}
                            />
                        )}
                    </span>

                    <span className={'text-sm'}>{t('stats:deals')}</span>
                </div>

                <div className={'flex flex-col px-8 border-r border-gray-200'}>
                    <span
                        className={'mb-1 text-lg font-bold'}
                        role={'figure'}
                        aria-label={'Active offers count'}
                    >
                        {stats?.offers.activeOffersCounter}

                        {!stats && (
                            <Skeleton
                                width={35}
                                wrapper={LoadingWrapperFactory({
                                    role: 'figure',
                                    'aria-label': 'Loading'
                                })}
                            />
                        )}
                    </span>

                    <span className={'text-sm'}>{t('stats:activeOffers')}</span>
                </div>

                <div className={'flex flex-col px-8 border-r border-gray-200'}>
                    <span
                        className={'mb-1 text-lg font-bold'}
                        role={'figure'}
                        aria-label={'Active offers value'}
                    >
                        {stats &&
                            t('offers:displayPrice', {
                                priceFrom: stats?.offers.activeOffersValue
                            })}

                        {!stats && (
                            <Skeleton
                                width={35}
                                wrapper={LoadingWrapperFactory({
                                    role: 'figure',
                                    'aria-label': 'Loading'
                                })}
                            />
                        )}
                    </span>

                    <span className={'text-sm'}>{t('stats:activeOffersValue')}</span>
                </div>

                <div className={'flex flex-col px-8'}>
                    <span
                        className={'mb-1 text-lg font-bold'}
                        role={'figure'}
                        aria-label={'Earned value'}
                    >
                        {stats && t('offers:displayPrice', {priceFrom: stats?.offers.earned})}

                        {!stats && (
                            <Skeleton
                                width={35}
                                wrapper={LoadingWrapperFactory({
                                    role: 'figure',
                                    'aria-label': 'Loading'
                                })}
                            />
                        )}
                    </span>

                    <span className={'text-sm'}>{t('stats:earned')}</span>
                </div>
            </div>
        </div>
    );
};

export default StatsView;
