//Hooks
import {useFiltersForm} from './FiltersForm.hook';

//Components
import {Formik} from 'formik';
import CategoryFilter from './CategoryFilter';
import LocalFilter from './LocalFilter';
import JobFilters from './JobFilters';
import ServiceFilters from './ServiceFilters';
import ProductFilters from './ProductFilters';
import CategoryCustomFilters from './CategoryCustomFilters/CategoryCustomFilters';
import FormSubmit from './FormSubmit';

//Utils
import cx from 'classnames';

//Types
import {Categories, Offer, Search} from 'store/types';

export type FiltersProps = {
    className?: string;
    category: Categories.Category | null;
    sorting?: Search.Sort | null;
    onSubmit?: () => void;
};

const FiltersForm: React.VFC<FiltersProps> = ({className, category, sorting, onSubmit}) => {
    const {initialValues, handleSubmit} = useFiltersForm({
        className,
        category,
        sorting,
        onSubmit
    });

    return (
        <Formik<Search.FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({handleSubmit}) => (
                <form
                    className={cx(
                        'flex flex-col gap-y-4 lg:px-12 px-3 py-8 lg:bg-white',
                        className
                    )}
                    onSubmit={handleSubmit}
                >
                    <CategoryFilter />

                    <LocalFilter />

                    {category?.offerType === Offer.Type.Job && <JobFilters />}

                    {category?.offerType === Offer.Type.Service && <ServiceFilters />}

                    {category?.offerType === Offer.Type.Product && <ProductFilters />}

                    <CategoryCustomFilters />

                    <FormSubmit sorting={sorting} />
                </form>
            )}
        </Formik>
    );
};

export default FiltersForm;
