//Hooks
import {useFooter} from './Footer.hook';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import SocialIcon from 'components/ui/SocialIcon';

//Utils
import _ from 'lodash-es';

const Footer: React.VFC = () => {
    const {
        isLoading,
        staticPages,
        facebookLink,
        instagramLink,
        linkedInLink,
        tiktokLink
    } = useFooter();

    const {t} = useTranslation('nav');

    return (
        <footer
            className={
                'hidden md:flex flex-wrap text-gray-800 border border-gray-300 px-34 py-11 gap-x-52 bg-white'
            }
        >
            {staticPages?.map(list => (
                <div key={list.id}>
                    <h6 className={'text-lg font-bold mb-3.5'}>{list.title}</h6>

                    <div className={'flex flex-col gap-y-2'}>
                        {list.pages.map(page => (
                            <Link
                                key={page.slug}
                                to={`/info/${page.slug}`}
                                aria-label={page.slug}
                            >
                                {page.title}
                            </Link>
                        ))}
                    </div>
                </div>
            ))}

            {isLoading &&
                _.times(2, i => (
                    <div key={i} role={'region'} aria-label={'loading'}>
                        <h6 className={'text-lg font-bold mb-3.5'}>
                            <Skeleton width={125} />
                        </h6>

                        <div className={'flex flex-col -my-2'}>
                            {_.times(4, i => (
                                <Skeleton key={i} className={'my-2'} width={150} />
                            ))}
                        </div>
                    </div>
                ))}

            <div>
                <h6 className={'text-lg font-bold mb-3.5'}>{t('footer.followUs')}</h6>

                <div className={'flex gap-x-3.5'}>
                    <a href={facebookLink} target={'_blank'} rel={'noreferrer'}>
                        <SocialIcon icon={'facebook'} />
                    </a>

                    <a href={instagramLink} target={'_blank'} rel={'noreferrer'}>
                        <SocialIcon icon={'instagram'} />
                    </a>

                    <a href={tiktokLink} target={'_blank'} rel={'noreferrer'}>
                        <SocialIcon icon={'tiktok'} />
                    </a>

                    <a href={linkedInLink} target={'_blank'} rel={'noreferrer'}>
                        <SocialIcon icon={'linked-in'} />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
