//Action creators
import {createAsyncAction} from 'typesafe-actions';

//Types
import {User, Errors, Offer} from 'store/types';

/**
 * Retrieves user profile data
 */
export const getUserProfile = createAsyncAction(
    'GET_PROFILE_REQUEST',
    'GET_PROFILE_SUCCESS',
    'GET_PROFILE_FAILURE'
)<number, User.Profile, Errors.API>();

/**
 * Retrieves user offers
 */
export const getUserOffers = createAsyncAction(
    'GET_USER_OFFERS_REQUEST',
    'GET_USER_OFFERS_SUCCESS',
    'GET_USER_OFFERS_FAILURE'
)<Offer.UserOffersRequest, Offer.ListResponse, Errors.API>();
