//Hooks
import {
    useSelector as useDefaultSelector,
    useDispatch as useDefaultDispatch
} from 'react-redux';

//Types
import {TypedUseSelectorHook} from 'react-redux';
import {RootState, AppDispatch} from 'store';

export const useSelector: TypedUseSelectorHook<RootState> = useDefaultSelector;
export const useDispatch = () => useDefaultDispatch<AppDispatch>();
