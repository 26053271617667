//Hooks
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useFavorite} from 'hooks/favorite';
import {useDisplayPrice} from 'hooks/displayPrice';

//Utils
import _ from 'lodash-es';

//Types
import {Offer, Order} from 'store/types';
import {Variant} from './OfferView';

type UseOfferViewArgs = {
    variant?: Variant;
    offer?: Offer.ListItem | Offer.RemovedItem;
    order?: Order.Order;
    product?: Order.Product;
    isBuyer?: boolean;
};

export const useOfferView = ({variant, offer, order, product, isBuyer}: UseOfferViewArgs) => {
    const {t} = useTranslation(['offers', 'common', 'orders']);

    const validOffer = !!offer?.id && offer;

    const {isFavorite, isFavoriteDisabled, onFavoriteToggle} = useFavorite(
        validOffer ? validOffer : undefined
    );

    const displayPrice = useDisplayPrice({
        offer: validOffer ? validOffer : undefined,
        order,
        product
    });

    const tags = useMemo<string[]>(() => {
        if (order?.boughtAt && order?.validUntil) {
            return [
                t('orders:bought', {date: order.boughtAt}),
                t('offers:validTill', {date: order.validUntil})
            ];
        }
        if (!offer || !offer?.id) {
            return [];
        }

        if (variant === 'profile') {
            const tags: string[] = [t('offers:created', {date: offer.createdAt})];

            if (offer.status === Offer.Status.Closed && !!offer.closedAt) {
                return [...tags, t('offers:archived', {date: offer.closedAt})];
            }

            if (offer.expiredAt) {
                return [...tags, t('offers:validTill', {date: offer.expiredAt})];
            }

            return tags;
        }

        const tags = offer.address ? [`${offer.address.city}, ${offer.address.state}`] : [];

        return [...tags, t('offers:date', {date: offer.createdAt})];
    }, [variant, offer, order, t]);

    const mobileTags = useMemo(() => [variant === 'profile' ? _.last(tags)! : tags], [
        tags,
        variant
    ]);

    const linkToDetails = useMemo<string>(() => {
        if (!!offer && !!offer.id && offer.status !== Offer.Status.Draft) {
            return `/offer/${offer.id}`;
        }
        if (!!order) {
            return isBuyer
                ? `/profile/my-purchases/${order.orderId}`
                : `/profile/my-offers/sold/${order.orderId}`;
        }
        if (!!product) {
            return `/offer/${product.productId}`;
        }
        return '';
    }, [offer, order, isBuyer, product]);

    const orderBadges: Record<Order.Status, string> = {
        [Order.Status.Canceled]: t('orders:canceled'),
        [Order.Status.Failed]: t('orders:failed'),
        [Order.Status.NotPaid]: t('orders:toBePaid'),
        [Order.Status.Paid]: t('orders:toBeConfirmed'),
        [Order.Status.Confirmed]: t('orders:inShipment'),
        [Order.Status.Completed]: t('orders:completed')
    };

    const displayBadge = order ? orderBadges[order.status] ?? '' : '';

    return {
        displayPrice,
        displayBadge,
        tags,
        mobileTags,
        isFavorite,
        isFavoriteDisabled,
        onFavoriteToggle,
        linkToDetails
    };
};
