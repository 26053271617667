//Hooks
import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'hooks/store';
import {useLogout} from 'hooks/logout';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Types
import {User} from 'store/types';

export const useMyProfileView = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const {data: stats, isLoading: areStatsLoading} = useSelector(state => state.user.stats);
    const {data: staticPages} = useSelector(state => state.staticPages.staticPages);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        dispatch(actions.getMyStats.request());
    }, [dispatch]);

    const displayName = user?.type === User.Type.Person ? user.firstName : user?.companyName;

    const {handleLogout} = useLogout();

    const handleUploadAvatar = async (files: FileList | null) => {
        const file = files?.[0];
        if (!file) return;
        try {
            await dispatch(actions.uploadAvatar.request(file));
            setModalOpen(false);
        } catch (err) {
            if (err.message || err.errors?.avatar) {
                toast.error(err.errors?.avatar ?? err.message);
            }
        }
    };

    const handleDeleteAvatar = async () => {
        try {
            await dispatch(actions.deleteAvatar.request());
            setModalOpen(false);
        } catch (err) {
            if (err.message) {
                toast.error(err.message);
            }
        }
    };

    return {
        user,
        stats,
        areStatsLoading,
        staticPages,
        displayName,
        handleUploadAvatar,
        handleDeleteAvatar,
        handleLogout,
        isModalOpen,
        setModalOpen
    };
};
