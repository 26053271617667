//Components
import Icon from 'components/ui/Icon';

//Utils
import cx from 'classnames';

//Types
import {Files} from 'store/types';

export type PhotoPreviewProps = {
    photo: Files.UploadedPhoto;
    className?: string;
    onPhotoDelete: () => void;
};

const PhotoPreview: React.VFC<PhotoPreviewProps> = ({photo, className, onPhotoDelete}) => (
    <div className={cx('relative', className)} role={'img'} aria-label={photo.name}>
        <img
            className={'object-cover w-full h-full'}
            src={photo.preview}
            alt={photo.name}
            aria-label={'preview'}
        />

        <button
            className={
                'absolute px-4 py-2 text-white border border-white rounded-md right-2 bottom-2 focus:outline-none filter drop-shadow-xs'
            }
            onClick={onPhotoDelete}
            type={'button'}
        >
            <Icon icon={'delete'} className={'filter drop-shadow-xs'} />
        </button>
    </div>
);

export default PhotoPreview;
