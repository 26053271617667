export type InputIconProps = {
    placement: 'left' | 'right';
    children: React.ReactElement | string;
} & React.HTMLProps<HTMLDivElement>;

const InputIcon: React.VFC<InputIconProps> = ({placement, children, ...props}) => (
    <div {...props}>{children}</div>
);

export default InputIcon;
