//Hooks
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'hooks/store';
import {useFormik} from 'formik';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, string} from 'yup';

//Actions
import * as actions from 'store/actions';

type useMyPurchaseDetailsProps = {
    orderId?: number;
};

export const useMyPurchaseDetails = ({orderId}: useMyPurchaseDetailsProps) => {
    const {data: order, isLoading} = useSelector(state => state.myOffers.soldDetails) || {};
    const [showShipmentLinkForm, setShowShipmentLinkForm] = useState(!order?.shipmentLink);

    const dispatch = useDispatch();

    const validationSchema = object({
        shipmentLink: string()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue
    } = useFormik<{
        shipmentLink?: string;
    }>({
        initialValues: {
            shipmentLink: order?.shipmentLink
        },
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(
                    actions.setShipmentLink.request({
                        orderId: order?.orderId,
                        shipmentLink: payload.shipmentLink
                    })
                );
                setShowShipmentLinkForm(false);
            } catch (error) {
                if (error.errors?.shipmentLink) {
                    toast.error(error.errors?.shipmentLink);
                } else if (error.message) {
                    toast.error(error.message);
                }
                setErrors(error.errors ?? {});
            }
        }
    });

    useEffect(() => {
        orderId && dispatch(actions.getMySoldOrderById.request(orderId));
    }, [dispatch, orderId]);

    useEffect(() => {
        setShowShipmentLinkForm(!order?.shipmentLink);
        setFieldValue('shipmentLink', order?.shipmentLink);
    }, [order?.shipmentLink, setFieldValue]);

    return {
        order,
        isLoading,
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        showShipmentLinkForm,
        setShowShipmentLinkForm,
        isSubmitting
    };
};
