//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Errors, Offer} from 'store/types';

export type OffersAction = ActionType<typeof actions>;

export type State = Readonly<{
    editedOffer: Data<Offer.ListItem>;
    error?: Errors.API;
}>;

export const initialState: State = {
    editedOffer: {
        data: null,
        isLoading: false,
        isLoaded: false,
        isErrored: false
    }
};

export default createReducer<State, OffersAction>(initialState)
    .handleAction(actions.getEditedOffer.request, state => ({
        ...state,
        editedOffer: {
            data: state.editedOffer.data,
            isLoading: true,
            isLoaded: false,
            isErrored: false
        }
    }))
    .handleAction(actions.getEditedOffer.success, (state, action) => ({
        ...state,
        editedOffer: {
            data: action.payload,
            isLoading: false,
            isLoaded: true,
            isErrored: false
        }
    }))
    .handleAction(actions.getEditedOffer.failure, (state, action) => ({
        ...state,
        editedOffer: {
            data: null,
            isLoading: false,
            isLoaded: false,
            isErrored: true
        },
        error: action.payload
    }))
    .handleAction(actions.clearOffer, state => ({
        ...state,
        editedOffer: {
            data: null,
            isLoading: false,
            isLoaded: false,
            isErrored: false
        }
    }));
