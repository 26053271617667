//Hooks
import {useDispatch, useSelector} from 'hooks/store';
import {useLogout} from 'hooks/logout';

//Services
import {navigate} from '@reach/router';

//Types
import {User} from 'store/types';

// Actions
import * as actions from 'store/actions';

export function useNavbar() {
    const dispatch = useDispatch();
    const version = useSelector(state => state.version.version);
    const versions = useSelector(state => state.version.versions);
    const isTokenLoading = useSelector(state => state.auth.isTokenLoading);
    const isTokenLoaded = useSelector(state => !!state.auth.token);
    const isTokenErrored = useSelector(state => state.auth.isTokenErrored);
    const isLoggingOut = useSelector(state => state.auth.isLoggingOut);
    const user = useSelector(state => state.user.user);
    const notifications = useSelector(state => state.notifications?.notifications?.data);

    const {handleLogout} = useLogout();
    const handleBack = () => navigate(-1);

    const displayName =
        user?.type === User.Type.Person
            ? `${user.firstName} ${user.lastName}`
            : user?.companyName;

    const onVersionChanged = async (
        v: {label: string; value: string; origin: string | undefined} | null
    ) => {
        if (v) {
            dispatch(actions.setVersion(v.value));
            localStorage.setItem('siteVersion', v.value);
            if (v.origin && v.origin !== window.location.origin) {
                if (user) {
                    await handleLogout();
                }
                window.location.href = v.origin as string;
            }
        }
    };

    return {
        isTokenLoading,
        isTokenLoaded,
        isTokenErrored,
        isLoggingOut,
        user,
        displayName,
        handleLogout,
        handleBack,
        notifications,
        versions,
        onVersionChanged,
        version
    };
}
