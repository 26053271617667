//Hooks
import {useTranslation} from 'react-i18next';

//Components
import LoadingWrapperFactory from 'components/wrappers/LoadingWrapperFactory';
import Skeleton from 'react-loading-skeleton';

//Types
import {Offer} from 'store/types';

type BasketOfferRowProps = {
    offer: Offer.ProductListItem;
    loading?: undefined;
};

type LoadingBasketOfferRowProps = {
    offer?: undefined;
    loading: boolean;
};

const BasketOfferRow: React.VFC<BasketOfferRowProps | LoadingBasketOfferRowProps> = ({
    offer,
    loading
}) => {
    const {t} = useTranslation('offers');

    return (
        <li className={'mb-1.5'}>
            <div className={'flex mt-3 justify-between items-center'}>
                {loading ? (
                    <Skeleton
                        width={150}
                        height={16}
                        wrapper={LoadingWrapperFactory({
                            role: 'heading',
                            'aria-level': 4,
                            'aria-label': 'Offer title row loading',
                            style: {marginTop: 4}
                        })}
                    />
                ) : (
                    <p role={'paragraph'} className={'text-xs pr-2'}>
                        {offer!.title}
                    </p>
                )}

                {loading ? (
                    <Skeleton
                        width={50}
                        height={16}
                        wrapper={LoadingWrapperFactory({
                            role: 'heading',
                            'aria-level': 4,
                            'aria-label': 'Offer price row loading',
                            style: {marginTop: 4}
                        })}
                    />
                ) : (
                    <p role={'paragraph'} className={'text-xs font-bold'}>
                        {t('displayPrice', {
                            priceFrom: {
                                amount:
                                    offer!.price.amount.toString() === '0'
                                        ? '0'
                                        : offer!.price.amount * (offer!.quantity ?? 1),
                                currency: offer!.price.currency
                            }
                        })}
                    </p>
                )}
            </div>

            <div className={'flex mt-3 justify-between items-center'}>
                <p className={'text-xs font-normal text-gray-400'}>{t('shipment')}</p>

                {loading ? (
                    <Skeleton
                        width={50}
                        height={16}
                        wrapper={LoadingWrapperFactory({
                            role: 'heading',
                            'aria-level': 4,
                            'aria-label': 'Offer seller row loading',
                            style: {marginTop: 4}
                        })}
                    />
                ) : (
                    <p className={'text-xs font-normal text-gray-400'}>
                        {!offer!.delivery ||
                        offer!.delivery.method === Offer.DeliveryMethod.Pickup
                            ? '-'
                            : t('displayPrice', {
                                  priceFrom: offer!.delivery.price
                              })}
                    </p>
                )}
            </div>
        </li>
    );
};

export default BasketOfferRow;
