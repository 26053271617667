//Hooks
import {useTranslation} from 'react-i18next';
import {useWindowSize} from 'hooks/windowSize';
import {useMyOffersView} from './MyOffersView.hook';

//Components
import {Link} from '@reach/router';
import Tabs from 'components/nav/Tabs';
import Button from 'components/ui/Button';
import OfferView from 'components/offer/OfferView';
import PaginationView from 'components/ui/PaginationView';
import AdditionalOptionsModal from 'components/modal/AdditionalOptionsModal';

//Utils
import _ from 'lodash-es';

//Types
import {RouteComponentProps} from '@reach/router';

const MyOffersView: React.VFC<RouteComponentProps> = () => {
    const {
        offers,
        orders,
        pageFromSearch,
        pages,
        isLoading,
        displayOrders,
        openPromoteOfferModal,
        closePromoteOfferModal,
        selectedOffer,
        handlePageChange,
        handlePublish,
        handleStopPublishing,
        handleConfirm,
        handleCancel,
        ref
    } = useMyOffersView();

    const {t} = useTranslation(['profile', 'common', 'nav']);
    const {breakpoint} = useWindowSize();

    return (
        <div>
            <h2 className={'my-5 mx-4 md:hidden'}>{t('nav:navbar.myOffers')}</h2>

            <Tabs
                className={
                    'pt-1 pb-4 bg-white border border-gray-200 rounded md:px-7.5 px-3 mx-4'
                }
            >
                <Link to={'/profile/my-offers'}>{t('profile:myOffers.active')}</Link>

                <Link to={'/profile/my-offers/sold'}>{t('profile:myOffers.sold')}</Link>

                <Link to={'/profile/my-offers/draft'}>{t('profile:myOffers.drafts')}</Link>

                <Link to={'/profile/my-offers/closed'}>{t('profile:myOffers.archived')}</Link>
            </Tabs>

            <span ref={ref} />

            <div className={'md:mt-7 mt-5 md:px-4'}>
                {isLoading || offers?.length || orders?.length ? (
                    <div className={'flex flex-col gap-y-3'}>
                        <Button
                            className={'ml-auto mb-1'}
                            as={Link}
                            to={'/offers/create'}
                            size={'xs'}
                            variant={'primary'}
                        >
                            {t('common:addOffer')}
                        </Button>

                        {breakpoint !== 'xs' && (
                            <div className={'self-end'}>
                                <PaginationView
                                    currentPage={pageFromSearch}
                                    totalPages={pages ?? 0}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        )}

                        {isLoading
                            ? _.times(4, i => (
                                  <OfferView
                                      key={i}
                                      variant={'profile'}
                                      loadingOrder={displayOrders}
                                      loading
                                  />
                              ))
                            : !!offers
                            ? offers?.map(offer => (
                                  <OfferView
                                      offer={offer}
                                      variant={'profile'}
                                      key={offer.id}
                                      onPublish={() => handlePublish(offer)}
                                      onPromote={() => openPromoteOfferModal(offer)}
                                      onStopPublishing={() => handleStopPublishing(offer)}
                                  />
                              ))
                            : orders?.map(order => (
                                  <OfferView
                                      order={order}
                                      variant={'profile'}
                                      key={order.orderId}
                                      onConfirm={() => handleConfirm(order)}
                                      onCancel={() => handleCancel(order)}
                                  />
                              ))}

                        <div className={'self-end'}>
                            <PaginationView
                                currentPage={pageFromSearch}
                                totalPages={pages ?? 0}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={'flex flex-col items-center mx-4'}>
                        <h2 className={'font-semibold mt-8'} aria-label={'No offers'}>
                            {t('profile:myOffers.emptyOffersTitle')}
                        </h2>

                        <span className={'text-xs mt-12 text-center'}>
                            {t('profile:myOffers.emptyOffersDescription')}
                        </span>

                        <Button
                            as={Link}
                            to={'/offers/create'}
                            variant={'primary'}
                            size={'sm'}
                            className={'mt-16'}
                        >
                            {t('common:addOffer')}
                        </Button>
                    </div>
                )}

                {!!selectedOffer && (
                    <AdditionalOptionsModal
                        type={'highlight'}
                        offer={selectedOffer}
                        isOpen={!!selectedOffer}
                        onClose={closePromoteOfferModal}
                    />
                )}
            </div>
        </div>
    );
};

export default MyOffersView;
