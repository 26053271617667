//Hooks
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

//Components
import {FieldArray} from 'formik';
import Checkbox from 'components/ui/Checkbox';
import Input from 'components/ui/Input';

//Types
import {Categories, Search} from 'store/types';

export type FieldProps = {
    name: string;
    field: Categories.CustomFormField.Field;
};

const Field: React.VFC<FieldProps> = ({name, field}) => {
    const {values, handleChange} = useFormikContext<Search.FormValues>();

    const {t} = useTranslation('common');

    switch (field.type) {
        case 'string':
            if (field.enum) {
                return (
                    <FieldArray name={`attributes.${name}`}>
                        {arrayHelpers =>
                            field.enum.map(value => (
                                <Checkbox
                                    key={value}
                                    id={`attributes.${name}.${value}`}
                                    checked={
                                        ((values.attributes?.[name] as
                                            | string[]
                                            | undefined)?.indexOf(value) ?? -1) !== -1
                                    }
                                    onChange={e => {
                                        if (e.currentTarget.checked) {
                                            arrayHelpers.push(value);
                                        } else {
                                            arrayHelpers.remove(
                                                (values.attributes?.[name] as
                                                    | string[]
                                                    | undefined)!.indexOf(value)
                                            );
                                        }
                                    }}
                                    label={value}
                                />
                            ))
                        }
                    </FieldArray>
                );
            }

            return (
                <Input
                    id={`attributes.${name}`}
                    name={`attributes.${name}`}
                    value={(values.attributes?.[name] as string | undefined) ?? ''}
                    onChange={handleChange}
                    aria-label={field.title}
                />
            );
        case 'integer':
        case 'number':
            return (
                <div className={'flex flex-row gap-8'}>
                    <div className={'flex flex-row items-end gap-2'}>
                        <span>{t('from')}</span>

                        <Input
                            id={`attributes.${name}__from`}
                            name={`attributes.${name}__from`}
                            value={values.attributes?.[`${name}__from`]?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            aria-label={`${field.title} from`}
                        />
                    </div>

                    <div className={'flex flex-row items-end gap-2'}>
                        <span>{t('to')}</span>

                        <Input
                            id={`attributes.${name}__to`}
                            name={`attributes.${name}__to`}
                            value={values.attributes?.[`${name}__to`]?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            aria-label={`${field.title} to`}
                        />
                    </div>
                </div>
            );
    }

    return null;
};

export default Field;
