//Hooks
import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'hooks/store';
import {useFormik} from 'formik';

//Actions
import * as actions from 'store/actions';

//Utils
import {object, string} from 'yup';

//Services
import toast from 'react-hot-toast';

//Types
import {User} from 'store/types';

import {locale} from 'localeApp';

export const useEditMyBankAccountView = (closeModal: () => void) => {
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();

    const initialValues = useMemo<User.Payout>(() => {
        return locale === 'pl'
            ? {
                  iban: user?.payout?.account.iban,
                  holder: user?.payout?.holder
              }
            : {
                  accountNumber: user?.payout?.account.accountNumber,
                  routingNumber: user?.payout?.account.routingNumber,
                  holder: user?.payout?.holder
              };
    }, [user]);

    const validationSchema = object({
        iban: string().when(locale, {
            is: 'pl',
            then: string().required()
        }),
        accountNumber: string().when(locale, {
            is: 'us',
            then: string().required()
        }),
        routingNumber: string().when(locale, {
            is: 'us',
            then: string().required()
        }),
        holder: string().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm
    } = useFormik<User.Payout>({
        initialValues,
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.editMyPayout.request(payload));
                closeModal();
            } catch (err) {
                if (!!err.errors?.account?.iban) {
                    toast.error(err.errors?.account?.iban);
                } else if (!!err.errors?.account?.accountNumber) {
                    toast.error(err.errors?.account?.accountNumber);
                } else if (!!err.errors?.account?.routingNumber) {
                    toast.error(err.errors?.account?.routingNumber);
                } else if (err.message) {
                    toast.error(err.message);
                }

                if (err.errors) {
                    setErrors(err.errors);
                }
            }
        },
        enableReinitialize: true
    });

    useEffect(() => {
        resetForm();
    }, [closeModal, resetForm]);

    return {values, touched, errors, handleChange, handleSubmit, isSubmitting};
};
