import {useState, useEffect, useCallback, useMemo} from 'react';

type WindowSize = {
    width: number;
    height: number;
};

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

type UseWindowSizeResult = WindowSize & {breakpoint: Breakpoint; isMobile: boolean};

export const useWindowSize = (): UseWindowSizeResult => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleResize = useCallback(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }, [setWindowSize]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    });

    const breakpoint = useMemo<Breakpoint>(() => {
        //TODO: Possibly put breakpoints in object and iterate through object entries
        if (windowSize.width >= 1536) {
            return '2xl';
        }
        if (windowSize.width >= 1280) {
            return 'xl';
        }
        if (windowSize.width >= 1024) {
            return 'lg';
        }
        if (windowSize.width >= 768) {
            return 'md';
        }
        if (windowSize.width >= 640) {
            return 'sm';
        }
        return 'xs';
    }, [windowSize.width]);

    const isMobile = breakpoint === 'xs' || breakpoint === 'sm';

    return {
        ...windowSize,
        breakpoint,
        isMobile
    };
};
