//Hooks
import {useEffect, useMemo, useCallback, useState} from 'react';
import {useDispatch} from 'hooks/store';
import {useCategories} from 'hooks/categories';
import {useLocation} from '@reach/router';

//Actions
import * as actions from 'store/actions';

//Utils
import qs from 'querystring';

//Types
import {FiltersProps} from './FiltersForm';
import {Search, Offer} from 'store/types';

export const useFiltersForm = ({category, sorting, onSubmit}: FiltersProps) => {
    const {data: categories} = useCategories();
    const dispatch = useDispatch();

    const location = useLocation();
    const search = qs.parse(location.search.substring(1));
    const phrase = (search['phrase'] as string | undefined) ?? '';
    const categoryId = search['categoryId']
        ? parseInt(search['categoryId'] as string)
        : undefined;
    const local = !!search['local'];
    const [coords, setCoords] = useState<Search.Geodistance | undefined>(undefined);

    const getCoords = useCallback(async () => {
        try {
            const position: GeolocationPosition = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            setCoords({
                lat: position.coords.latitude,
                lon: position.coords.longitude,
                distance: 'nearby'
            });
        } catch (err) {
            setCoords(undefined);
        }
    }, []);

    useEffect(() => {
        if (!category) {
            dispatch(actions.resetCategoryCustomFormFields());
            return;
        }

        dispatch(actions.getCategoryCustomFormFields.request(category));
    }, [category, dispatch]);

    useEffect(() => {
        if (local) {
            getCoords();
        }
    }, [local, getCoords]);

    const initialValues = useMemo<Search.FormValues>(
        () => ({
            phrase,
            category: categories?.find(({id}) => id === categoryId),
            accountType: undefined,
            job: [
                {
                    salaryFrom: undefined,
                    salaryTo: undefined,
                    period: Offer.Period.Hourly
                },
                {
                    salaryFrom: undefined,
                    salaryTo: undefined,
                    period: Offer.Period.Monthly
                }
            ],
            service: {
                priceFrom: undefined,
                priceTo: undefined,
                period: Offer.Period.Hourly
            },
            product: {
                priceFrom: undefined,
                priceTo: undefined,
                free: false,
                deliveryMethod: undefined
            },
            sort: undefined,
            attributes: {},
            geoDistance: local ? coords : undefined
        }),
        [phrase, categories, categoryId, local, coords]
    );

    const handleSubmit = useCallback(
        (values: Search.FormValues) => {
            dispatch(
                actions.search.request({
                    ...values,
                    sort: sorting ?? undefined
                })
            );
            onSubmit?.();
        },
        [dispatch, sorting, onSubmit]
    );

    return {initialValues, handleSubmit};
};
