//Hooks
import {useMyPurchaseDetails} from './MyPurchaseDetailsView.hook';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import {Trans} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/ui/Button';
import DisputeOrderModal from 'components/modal/DisputeOrderModal';
import Icon from 'components/ui/Icon';
import OfferView from 'components/offer/OfferView';
import CreateReviewModal from 'components/modal/CreateReviewModal';

//Utils
import _ from 'lodash-es';

//Types
import {RouteComponentProps} from '@reach/router';
import {Order} from 'store/types';

const MyPurchaseDetailsView: React.VFC<RouteComponentProps<{orderId: number}>> = ({
    orderId
}) => {
    const {
        isLoading,
        order,
        reviewedProduct,
        isCreateReviewModalVisible,
        openCreateReviewModal,
        closeCreateReviewModal,
        closeDisputeOrderModal,
        openDisputeOrderModal,
        isDisputeOrderModalOpen
    } = useMyPurchaseDetails({orderId});

    const {t} = useTranslation(['orders', 'common']);

    return (
        <>
            <div className={'flex flex-col gap-y-5'}>
                {isLoading ? (
                    <>
                        <div className={'p-3 md:p-0'}>
                            <Skeleton height={18} width={200} />
                        </div>

                        {_.times(5, i => (
                            <OfferView variant={'home'} loading key={i} />
                        ))}
                    </>
                ) : (
                    <>
                        <h2 className={'p-3 md:p-0'}>{t('orders:orderDetails')}</h2>

                        {order?.products?.map((product: Order.Product) => (
                            <OfferView
                                variant={'profile'}
                                product={product}
                                key={product.productId}
                                showDetails={false}
                                onReview={openCreateReviewModal(product)}
                            />
                        ))}

                        <div
                            className={
                                'bg-white py-6 px-9 flex flex-col sm:flex-row gap-x-24 gap-y-12 text-sm'
                            }
                        >
                            <div>
                                <p className={'font-semibold mb-5'}>
                                    {t('orders:myContactDetails')}
                                </p>

                                <Link to={`/user/${order?.buyer?.id}`}>
                                    <Trans
                                        i18nKey={'orders:user'}
                                        values={{username: order?.buyer?.username}}
                                        components={{
                                            span: <span className={'text-secondary'} />
                                        }}
                                    />
                                </Link>

                                <p>{t('orders:phone', {phone: order?.buyer?.phone})}</p>
                            </div>

                            <div>
                                <p className={'font-semibold mb-5 flex items-center gap-x-2'}>
                                    <span className={'text-gray-600'}>
                                        <Icon icon={'transport'} size={'md'} />
                                    </span>

                                    {t('orders:myShipmentDetails')}
                                </p>

                                <p>
                                    {order?.shippingAddress
                                        ? t('orders:addressDetails', {
                                              street: order?.shippingAddress?.street,
                                              apNumber: order?.shippingAddress?.apartmentNumber
                                          })
                                        : t('common:pickupAtSeller')}
                                </p>

                                <p>
                                    {order?.shippingAddress &&
                                        t('orders:addressBase', {
                                            city: order?.shippingAddress?.city,
                                            state: order?.shippingAddress?.state,
                                            postCode: order?.shippingAddress?.postCode
                                        })}
                                </p>
                            </div>
                        </div>

                        {order?.shipmentLink && (
                            <div className={'bg-white py-6 px-9 text-sm'}>
                                <p className={'font-semibold mb-5'}>
                                    {t('orders:shipmentLink')}
                                </p>

                                <div className={'flex gap-x-9 justify-between items-center'}>
                                    <a
                                        href={order.shipmentLink}
                                        target={'_blank'}
                                        className={'font-bold'}
                                        rel={'noreferrer'}
                                    >
                                        {order.shipmentLink}
                                    </a>

                                    <Button
                                        variant={'primary'}
                                        outline
                                        size={'xs'}
                                        onClick={() =>
                                            navigator.clipboard.writeText(order.shipmentLink)
                                        }
                                    >
                                        {t('orders:copy')}
                                    </Button>
                                </div>
                            </div>
                        )}

                        <div className={'bg-white py-6 px-9 text-sm'}>
                            <p className={'font-semibold mb-5'}>{t('orders:sellerDetails')}</p>

                            <div
                                className={'flex justify-between flex-col sm:flex-row gap-y-2'}
                            >
                                <p>
                                    <Trans
                                        i18nKey={'orders:user'}
                                        values={{username: order?.seller?.username}}
                                        components={{span: <span />}}
                                    />
                                </p>

                                <p>{t('orders:phone', {phone: order?.seller?.phone})}</p>

                                <p>{t('orders:email', {email: order?.seller?.email})}</p>
                            </div>
                        </div>

                        <div className={'bg-white py-6 px-9 text-sm'}>
                            <p className={'font-semibold mb-5'}>
                                {t('orders:purchaseProblems')}
                            </p>

                            <div className={'flex gap-x-11 flex-col sm:flex-row gap-y-6'}>
                                <p>{t('orders:purchaseProblemsDescription')}</p>

                                <Button
                                    onClick={openDisputeOrderModal}
                                    variant={'primary'}
                                    outline
                                    size={'xs'}
                                >
                                    {t('orders:dispute')}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {!!order && (
                <DisputeOrderModal
                    order={order}
                    isOpen={isDisputeOrderModalOpen}
                    onClose={closeDisputeOrderModal}
                />
            )}

            <CreateReviewModal
                isOpen={isCreateReviewModalVisible}
                onClose={closeCreateReviewModal}
                product={reviewedProduct}
            />
        </>
    );
};

export default MyPurchaseDetailsView;
