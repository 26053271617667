//Hooks
import {useCreateReviewModal} from './CreateReviewModal.hook';
import {useTranslation} from 'react-i18next';

//Components
import Modal from 'components/ui/Modal';
import RatingInput from 'components/ui/RatingInput';
import TextArea from 'components/ui/TextArea';
import Button from 'components/ui/Button';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';
import defaultPhoto from 'assets/files/defaultOffer.jpg';

//Types
import {Reviews, Offer, Order} from 'store/types';

type CreateReviewModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

type CreateSellerReviewModalProps = {
    seller: Reviews.Seller;
    offer?: undefined;
    product?: undefined;
};

type CreateOfferReviewModalProps = {
    offer: Offer.OfferDetails;
    seller?: undefined;
    product?: undefined;
};

type CreateProductReviewModalProps = {
    product: Order.Product | null;
    seller?: undefined;
    offer?: undefined;
};

const CreateSellerReviewModal: React.VFC<
    CreateReviewModalProps &
        (
            | CreateSellerReviewModalProps
            | CreateOfferReviewModalProps
            | CreateProductReviewModalProps
        )
> = ({isOpen, onClose, seller, offer, product}) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleRateChange,
        handleSubmit,
        isSubmitting
    } = useCreateReviewModal({isOpen, onClose, seller, offer, product});

    const {t} = useTranslation(['reviews', 'common']);

    return (
        <Modal className={'flex flex-col'} isOpen={isOpen} onClose={onClose} size={'lg'}>
            <h1 className={'font-normal'}>
                {seller
                    ? t('reviews:whatIsYourOpinionAboutThisUser')
                    : t('reviews:whatIsYourOpinionAboutThisOffer')}
            </h1>

            <div className={'flex mt-10'}>
                <img
                    className={'w-18 h-18 object-cover rounded'}
                    src={
                        seller
                            ? seller.avatarUrl ?? defaultAvatar
                            : offer?.images?.[0]?.small ?? product?.image ?? defaultPhoto
                    }
                    alt={seller?.name ?? offer?.title}
                    aria-label={seller ? 'Seller avatar' : 'Offer photo'}
                />

                <span
                    className={'ml-5.5'}
                    role={'heading'}
                    aria-level={2}
                    aria-label={seller ? 'Seller name' : 'Offer title'}
                >
                    {seller?.name ?? offer?.title ?? product?.title}
                </span>
            </div>

            <form className={'mt-10'} onSubmit={handleSubmit}>
                <div role={'region'} aria-label={'rate'}>
                    <label className={'text-sm'}>
                        {seller ? t('reviews:rateUser') : t('reviews:rateOffer')}
                    </label>

                    <RatingInput
                        className={'mt-2'}
                        size={'xl'}
                        value={values.rate}
                        onChange={handleRateChange}
                    />

                    {touched.rate && errors.rate && (
                        <span
                            className={'text-xs text-red'}
                            role={'alert'}
                            aria-label={'error'}
                        >
                            {errors.rate}
                        </span>
                    )}
                </div>

                <div className={'mt-8'}>
                    <label className={'text-sm'} htmlFor={'comment'}>
                        {t('reviews:leaveSomeComment')}
                    </label>

                    <TextArea
                        id={'comment'}
                        name={'comment'}
                        className={'mt-4'}
                        value={values.comment ?? ''}
                        onChange={handleChange}
                        error={touched.comment && errors.comment}
                        maxLength={300}
                    />
                </div>

                <div className={'flex justify-center mt-4'}>
                    <Button variant={'primary'} disabled={isSubmitting}>
                        {t('common:send')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default CreateSellerReviewModal;
