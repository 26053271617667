//Hooks
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import {Trans} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/ui/Button';
import RatingInput from 'components/ui/RatingInput';
import LoadingWrapperFactory from 'components/wrappers/LoadingWrapperFactory';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';

//Utils
import cx from 'classnames';
import dayjs from 'dayjs';

//Types
import {Reviews} from 'store/types';

export type ReviewViewProps = {
    className?: string;
    review: Reviews.Review;
    loading?: undefined;
    onDispute?: (review: Reviews.Review) => void;
    dispute?: boolean;
};

export type ReviewViewLoadingProps = {
    className?: string;
    loading: true;
    review?: undefined;
    dispute?: undefined;
    onDispute?: undefined;
};

const ReviewView: React.VFC<ReviewViewProps | ReviewViewLoadingProps> = ({
    className,
    onDispute,
    review,
    loading,
    dispute
}) => {
    const {t} = useTranslation('reviews');
    return (
        <div
            className={cx(
                className,
                'flex flex-col pt-4 pb-8 border-b last:border-b-0 border-gray-200'
            )}
        >
            {review?.boughtProduct && (
                <div className={'flex justify-end md:hidden mb-3'}>
                    <Link
                        className={'text-sm text-gray-600'}
                        to={`/offer/${review.boughtProduct.id}`}
                    >
                        <Trans
                            i18nKey={'reviews:bought'}
                            values={{product: review.boughtProduct}}
                            components={{
                                span: <span className={'text-secondary'} />
                            }}
                        />
                    </Link>
                </div>
            )}

            <div className={'flex w-full'}>
                <div className={'mr-8 md:w-50 md:min-w-50'}>
                    <div className={'flex'}>
                        {review && (
                            <img
                                className={'hidden md:block w-10 h-10 mr-4 rounded-full'}
                                src={review.reviewer?.avatarUrl ?? defaultAvatar}
                                alt={review.reviewer?.name ?? t('accountDeactivated')}
                                aria-label={'Reviewer avatar'}
                            />
                        )}

                        {loading && (
                            <Skeleton
                                width={40}
                                height={40}
                                wrapper={LoadingWrapperFactory({
                                    className: 'hidden md:block mr-4',
                                    role: 'figure',
                                    'aria-label': 'Reviewer avatar loading'
                                })}
                                circle
                            />
                        )}

                        <div className={'flex flex-col justify-between'}>
                            {review && (
                                <>
                                    <h5
                                        className={'text-sm font-bold'}
                                        aria-label={'Reviewer name'}
                                    >
                                        {review.reviewer?.name ?? t('accountDeactivated')}
                                    </h5>

                                    <span className={'mt-1 text-xs text-gray-300'}>
                                        {dayjs(review.createdAt).fromNow()}
                                    </span>
                                </>
                            )}

                            {loading && (
                                <>
                                    <Skeleton
                                        width={60}
                                        wrapper={LoadingWrapperFactory({
                                            className: 'text-sm',
                                            role: 'figure',
                                            'aria-label': 'Reviewer name loading'
                                        })}
                                    />

                                    <Skeleton
                                        width={75}
                                        wrapper={LoadingWrapperFactory({
                                            className: 'text-xs',
                                            role: 'figure',
                                            'aria-label': 'Review created at loading'
                                        })}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className={'w-full flex flex-col'}>
                    <div className={'flex flex-col flex-grow'}>
                        <div className={'flex justify-between'}>
                            {review && (
                                <>
                                    <div className={'flex'}>
                                        <span
                                            className={'font-bold'}
                                            role={'note'}
                                            aria-label={'Review rate'}
                                        >
                                            {review.rate.toFixed(1)}
                                        </span>

                                        <div className={'ml-4'}>
                                            <RatingInput
                                                value={review.rate}
                                                size={'sm'}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    {review.boughtProduct && (
                                        <Link
                                            className={
                                                'hidden md:block max-w-64 lg:max-w-max text-sm text-gray-600 truncate'
                                            }
                                            to={`/offer/${review.boughtProduct.id}`}
                                        >
                                            <Trans
                                                i18nKey={'reviews:bought'}
                                                values={{product: review.boughtProduct}}
                                                components={{
                                                    span: <span className={'text-secondary'} />
                                                }}
                                            />
                                        </Link>
                                    )}
                                </>
                            )}

                            {loading && (
                                <Skeleton
                                    width={100}
                                    wrapper={LoadingWrapperFactory({
                                        role: 'figure',
                                        'aria-label': 'Review rate loading'
                                    })}
                                />
                            )}
                        </div>

                        {review?.comment && (
                            <p className={'text-sm mt-1'} role={'paragraph'}>
                                {review.comment}
                            </p>
                        )}

                        {loading && (
                            <Skeleton
                                count={2}
                                wrapper={LoadingWrapperFactory({
                                    role: 'figure',
                                    'aria-label': 'Review comment loading'
                                })}
                            />
                        )}
                    </div>

                    {dispute && onDispute && review && (
                        <div className={'w-full flex justify-end mt-2'}>
                            <Button
                                onClick={() => onDispute(review)}
                                size={'xs'}
                                outline
                                variant={'primary'}
                            >
                                {t('dispute')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReviewView;
