//Redux saga
import {select, call} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/dist/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';
import {AxiosResponse} from 'axios';

//Utils
import {formatZipCode} from 'utils/account';

//Types
import {RootState} from 'store';
import {Offer, Order} from 'store/types';

export function* createOrder() {
    const savedOrderData: Order.SavedOrderData | null = yield select<
        (state: RootState) => Order.SavedOrderData | null
    >(state => state.payment.savedOrderData);
    const payment: Offer.BuyFeatureResponse | null = yield select<
        (state: RootState) => Order.CreateOrderResponse | Offer.BuyFeatureResponse | null
    >(state => state.payment.payment ?? null);
    if (!savedOrderData && !payment) {
        return null;
    }

    const resp: AxiosResponse<Order.CreateOrderResponse> = yield call(
        request,
        {
            method: 'POST',
            url: '/api/orders',
            data: {
                ...savedOrderData,
                shipping_address: savedOrderData?.shipping_address
                    ? {
                          ...savedOrderData.shipping_address,
                          postCode: formatZipCode(savedOrderData.shipping_address.postCode)
                      }
                    : null
            }
        },
        true
    );

    return resp.data;
}

export function* buyAdditionalFeature(
    action: ReturnType<typeof actions.buyAdditionalFeature.request>
) {
    const {offerId, ...data} = action.payload;
    const resp: AxiosResponse<Offer.BuyFeatureResponse> = yield call(
        request,
        {
            method: 'POST',
            url: `/api/offers/${offerId}/buy-features`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {features: data}
        },
        true
    );

    return resp.data;
}

export const saga = [
    takeEveryPromiseAction(actions.createOrder, createOrder),
    takeEveryPromiseAction(actions.buyAdditionalFeature, buyAdditionalFeature)
];
