//Hooks
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

//Components
import Input, {InputIcon} from 'components/ui/Input';
import Section from '../Section';
import AccountTypeFilter from '../AccountTypeFilter';

//Types
import {Search} from 'store/types';

const ServiceFilters: React.VFC = () => {
    const {values, handleChange} = useFormikContext<Search.FormValues>();

    const {t} = useTranslation(['search', 'common']);

    return (
        <>
            <Section title={t('search:priceFilterPerHour')}>
                <div className={'flex flex-row gap-8'}>
                    <div className={'flex flex-row items-end gap-2 text-sm tracking-tighter'}>
                        <span>{t('common:from')}</span>

                        <Input
                            id={'service.priceFrom'}
                            name={'service.priceFrom'}
                            value={values.service?.priceFrom?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            aria-label={'Service price from'}
                        >
                            <InputIcon placement={'right'} className={'text-gray-400'}>
                                {t('common:currencyShort')}
                            </InputIcon>
                        </Input>
                    </div>

                    <div className={'flex flex-row items-end gap-2 text-sm tracking-tighter'}>
                        <span>{t('common:to')}</span>

                        <Input
                            id={'service.priceTo'}
                            name={'service.priceTo'}
                            value={values.service?.priceTo?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            aria-label={'Service price to'}
                        >
                            <InputIcon placement={'right'} className={'text-gray-400'}>
                                {t('common:currencyShort')}
                            </InputIcon>
                        </Input>
                    </div>
                </div>
            </Section>

            <Section title={t('search:offerType')}>
                <AccountTypeFilter />
            </Section>
        </>
    );
};

export default ServiceFilters;
