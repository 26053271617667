//Utils
import cx from 'classnames';

export type BadgeProps = {
    variant: 'white' | 'gray-200' | 'gray-900' | 'secondary' | 'lightgreen';
    size?: 'lg' | 'sm';
    disabled?: boolean;
    children: string;
    className?: string;
};

const Badge: React.VFC<BadgeProps> = ({
    variant,
    size,
    disabled = false,
    className,
    children,
    ...props
}) => (
    <span
        className={cx(
            {
                'bg-white border-white': variant === 'white' || disabled,
                'bg-gray-200 border-gray-200': variant === 'gray-200' && !disabled,
                'bg-gray-900 border-gray-900': variant === 'gray-900' && !disabled,
                'bg-secondary bg-opacity-40 border-secondary border-opacity-0':
                    variant === 'secondary' && !disabled,
                'bg-opacity-100 bg-green border-secondary border-opacity-0':
                    variant === 'lightgreen' && !disabled
            },
            {
                'border-gray-300': disabled
            },
            {
                'px-7': size === 'lg',
                'px-3.5': size === 'sm'
            },
            {
                'text-gray-900': variant !== 'gray-900' || disabled,
                'text-white': variant === 'gray-900'
            },
            'border rounded-full py-1 text-xs font-bold tracking-tight',
            className
        )}
        {...props}
    >
        {children}
    </span>
);

export default Badge;
