//Hooks
import {useNavbar} from './Navbar.hook';
import {useBasket} from 'hooks/basket';
import {useTranslation} from 'react-i18next';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';

//Components
import {Link, Match} from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import Logo from 'components/images/Logo';
import Button from 'components/ui/Button';
import NavIcon from 'components/ui/NavIcon';
import Icon from 'components/ui/Icon';
import Dropdown from 'components/ui/Dropdown';
import NavigationNotifications from 'components/ui/NavigationNotifications';

//Utils
import cx from 'classnames';

//Styles
import Styles from './Navbar.module.scss';
import Select from 'react-select';
import {styles, theme} from 'styles/select';

const Navbar: React.VFC<{onBack?: () => void}> = ({onBack}) => {
    const {
        isTokenLoading,
        isTokenLoaded,
        isTokenErrored,
        isLoggingOut,
        user,
        displayName,
        handleLogout,
        handleBack,
        notifications,
        versions,
        onVersionChanged,
        version
    } = useNavbar();

    const {isBasketEmpty} = useBasket();

    const {t} = useTranslation(['nav', 'auth']);
    const location = window.location;
    const isHomePage = location.pathname === '/';

    return (
        <Match path={'/'}>
            {({match}) => (
                <nav className={'w-full flex flex-col border-gray-200 border-b md:border-b-0'}>
                    <div className={'md:hidden h-11 bg-gray-700'} />

                    <div
                        className={
                            'bg-white flex flex-row items-center justify-between w-full max-w-292 mx-auto px-4 md:py-4 py-2'
                        }
                    >
                        {(!match || !!onBack) && (
                            <button
                                onClick={onBack ?? handleBack}
                                className={'md:hidden'}
                                aria-label={'Go back'}
                            >
                                <Icon icon={'previous'} />
                            </button>
                        )}

                        {isHomePage ? (
                            <a href={'/'}>
                                <Logo
                                    className={cx('md:h-auto md:w-52 h-6 w-auto', {
                                        'md:flex': !match || !!onBack
                                    })}
                                />
                            </a>
                        ) : (
                            <Link to={'/'}>
                                <Logo
                                    className={cx('md:h-auto md:w-52 h-6 w-auto', {
                                        'md:flex': !match || !!onBack
                                    })}
                                />
                            </Link>
                        )}

                        <div className={'flex flex-row items-center'}>
                            <div className={'flex flex-row -mx-3 items-center'}>
                                <Link to={!!user ? '/favorites' : '/auth/login'}>
                                    <NavIcon
                                        icon={'favorite'}
                                        className={'mx-3 hidden md:flex'}
                                    />
                                </Link>

                                <Link to={!!user ? '/messages' : '/auth/login'}>
                                    <NavIcon
                                        icon={'message'}
                                        className={'mx-3 hidden md:flex'}
                                    />
                                </Link>

                                {!!user ? (
                                    <>
                                        <NavigationNotifications
                                            notifications={notifications}
                                            className={'hidden md:flex'}
                                        />

                                        <Link to={'/notifications'} className={Styles.link}>
                                            <NavIcon
                                                icon={'notification'}
                                                className={'mx-3 md:hidden'}
                                                notification={!!notifications?.unread}
                                            />
                                        </Link>
                                    </>
                                ) : (
                                    <Link to={'/auth/login'}>
                                        <NavIcon icon={'notification'} className={'mx-3'} />
                                    </Link>
                                )}

                                <Link
                                    to={!!user ? '/basket' : '/auth/login'}
                                    className={cx('mx-3', {[Styles.link]: !!user})}
                                >
                                    <NavIcon icon={'basket'} notification={!isBasketEmpty} />
                                </Link>

                                <Select
                                    id={'version'}
                                    name={'version'}
                                    className={'w-full'}
                                    options={versions}
                                    value={version}
                                    onChange={onVersionChanged}
                                    theme={theme}
                                    styles={styles}
                                />
                            </div>

                            <div
                                className={
                                    'w-64 md:ml-10 lg:ml-16 flex-row items-center justify-center hidden md:flex'
                                }
                            >
                                {!!user && isTokenLoaded && !isLoggingOut && (
                                    <>
                                        <img
                                            src={user.avatar ?? defaultAvatar}
                                            onError={e => {
                                                e.currentTarget.src =
                                                    user.avatarFallback ?? defaultAvatar;
                                            }}
                                            alt={displayName}
                                            className={'h-8 w-8 rounded-full object-cover'}
                                        />

                                        <div className={'flex flex-col ml-8 flex-grow'}>
                                            <span
                                                className={
                                                    'text-gray-900 text-base tracking-wide uppercase'
                                                }
                                            >
                                                {displayName}
                                            </span>

                                            <span
                                                className={
                                                    'text-gray-600 text-xs tracking-wide'
                                                }
                                            >{`${user.address.city}, ${user.address.state}`}</span>
                                        </div>

                                        <Dropdown position={'right'}>
                                            <Dropdown.Trigger>
                                                <span
                                                    className={'px-6'}
                                                    aria-label={'nav-dropdown'}
                                                >
                                                    <Icon
                                                        icon={'chevron-right'}
                                                        size={'sm'}
                                                        className={'transform rotate-90'}
                                                    />
                                                </span>
                                            </Dropdown.Trigger>

                                            <Dropdown.Menu
                                                className={'mt-12 bg-white w-68 z-50'}
                                            >
                                                <Link
                                                    to={'/profile/my-profile'}
                                                    className={'w-full'}
                                                >
                                                    {t('nav:navbar.myProfile')}
                                                </Link>

                                                <Link
                                                    to={'/profile/my-offers'}
                                                    className={'w-full'}
                                                >
                                                    {t('nav:navbar.myOffers')}
                                                </Link>

                                                <Link
                                                    to={'/profile/my-purchases'}
                                                    className={'w-full'}
                                                >
                                                    {t('nav:navbar.myPurchases')}
                                                </Link>

                                                <Link
                                                    to={'/profile/account-settings'}
                                                    className={'w-full'}
                                                >
                                                    {t('nav:navbar.accountSettings')}
                                                </Link>

                                                <button
                                                    className={
                                                        'w-full text-left focus:outline-none'
                                                    }
                                                    onClick={handleLogout}
                                                >
                                                    {t('nav:navbar.logout')}
                                                </button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                )}

                                {isTokenErrored && (
                                    <Button
                                        as={Link}
                                        to={'/auth/login'}
                                        className={'uppercase'}
                                        variant={'primary'}
                                    >
                                        {t('auth:login')}
                                    </Button>
                                )}

                                {(isTokenLoading || isLoggingOut) && (
                                    <div role={'region'} aria-label={'Loading'}>
                                        <Skeleton width={58.74} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </nav>
            )}
        </Match>
    );
};

export default Navbar;
