//Hooks
import {useEffect, useCallback} from 'react';
import {useDispatch} from 'hooks/store';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';

//Actions
import * as actions from 'store/actions';

//Utils
import {object, number, string} from 'yup';

//Services
import toast from 'react-hot-toast';

//Types
import {Reviews, Offer, Order} from 'store/types';

type UseCreateReviewModalArgs = {
    isOpen: boolean;
    onClose: () => void;
    seller?: Reviews.Seller;
    offer?: Offer.OfferDetails;
    product?: Order.Product | null;
};

export const useCreateReviewModal = ({
    isOpen,
    onClose,
    seller,
    offer,
    product
}: UseCreateReviewModalArgs) => {
    const dispatch = useDispatch();

    const {t} = useTranslation(['reviews', 'validation']);

    const validationSchema = object({
        rate: number()
            .min(1, t('validation:pickRate'))
            .max(5, t('validation:pickRate'))
            .required(),
        comment: string().nullable()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        resetForm
    } = useFormik<Reviews.CreateReviewRequest>({
        initialValues: {
            rate: 0,
            comment: null
        },
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                if (seller) {
                    await dispatch(
                        actions.createSellerReview.request({
                            ...payload,
                            seller
                        })
                    );
                }

                if (offer) {
                    await dispatch(
                        actions.createOfferReview.request({
                            ...payload,
                            offer
                        })
                    );
                }

                if (product) {
                    await dispatch(
                        actions.createProductReview.request({
                            ...payload,
                            product
                        })
                    );
                }

                onClose();
                toast.success(t('reviews:reviewSubmitted'));
            } catch (err) {
                if (err.errors?.additionalFirstError) {
                    toast.error(err.errors?.additionalFirstError);
                } else if (err.message) {
                    toast.error(err.message);
                }

                setErrors(err.errors ?? {});
            }
        }
    });

    const handleRateChange = useCallback(
        (rate: number) => {
            setFieldValue('rate', rate);
        },
        [setFieldValue]
    );

    useEffect(() => {
        resetForm();
    }, [isOpen, resetForm]);

    return {
        values,
        touched,
        errors,
        handleChange,
        handleRateChange,
        handleSubmit,
        isSubmitting
    };
};
