//Services
import axios from 'services/axios';

//Types
import {AxiosTransformer} from 'axios';
import {Reviews} from 'store/types';

interface Stats {
    oneStarCount: number;
    twoStarsCount: number;
    threeStarsCount: number;
    fourStarsCount: number;
    fiveStarsCount: number;
    count: number;
    average: number | null;
}

type SellerReviewsResponse = Omit<Reviews.Response.Seller, 'stats'> & {stats: Stats};
type OfferReviewsResponse = Omit<Reviews.Response.Offer, 'stats'> & {stats: Stats};
type MyReviewsResponse = Omit<Reviews.Response.MyReview, 'stats'> & {stats: Stats};

export const transformGetSellerReviewsResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    (data: SellerReviewsResponse): Reviews.Response.Seller => ({
        ...data,
        stats: {
            1: data.stats.oneStarCount,
            2: data.stats.twoStarsCount,
            3: data.stats.threeStarsCount,
            4: data.stats.fourStarsCount,
            5: data.stats.fiveStarsCount,
            average: data.stats.average,
            count: data.stats.count
        }
    })
];

export const transformGetOfferReviewsResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    (data: OfferReviewsResponse): Reviews.Response.Offer => ({
        ...data,
        stats: {
            1: data.stats.oneStarCount,
            2: data.stats.twoStarsCount,
            3: data.stats.threeStarsCount,
            4: data.stats.fourStarsCount,
            5: data.stats.fiveStarsCount,
            average: data.stats.average,
            count: data.stats.count
        }
    })
];

export const transformGetMyReviewsResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    (data: MyReviewsResponse): Reviews.Response.MyReview => ({
        ...data,
        stats: {
            1: data.stats.oneStarCount,
            2: data.stats.twoStarsCount,
            3: data.stats.threeStarsCount,
            4: data.stats.fourStarsCount,
            5: data.stats.fiveStarsCount,
            average: data.stats.average,
            count: data.stats.count
        }
    })
];
