//Hooks
import {useToaster} from './ToasterView.hook';

//Components
import ToastView from './ToastView';

const Toaster: React.VFC = () => {
    const {toasts, handleMouseEnter, handleMouseLeave} = useToaster();

    return (
        <div
            className={'fixed top-4 right-4 w-full max-w-sm z-50'}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            role={'alertdialog'}
        >
            {toasts.map(toast => (
                <ToastView key={toast.id} toast={toast} />
            ))}
        </div>
    );
};

export default Toaster;
