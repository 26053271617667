//Hooks
import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'hooks/store';

//Actions
import {navigate, useLocation} from '@reach/router';
import * as actions from 'store/actions';

//Types
import {Offer} from 'store/types';

export const useBasket = (offer?: Offer.ListItem) => {
    const {isLoading, isLoaded, isErrored, data: basketOffers} = useSelector(
        state => state.basket.basket
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoading || isLoaded || isErrored) {
            return;
        }

        dispatch(actions.getBasketOffers.request());
    }, [isLoading, isLoaded, isErrored, basketOffers, dispatch]);

    const isInBasket = () => basketOffers?.some(item => item.id === offer?.id) ?? false;

    const isBasketEmpty = !basketOffers?.length;

    return {
        isLoaded,
        isLoading,
        isInBasket,
        basketOffers: basketOffers ?? [],
        isBasketEmpty
    };
};

export const usePaymentMethod = () => {
    const paymentOptions = useSelector(state => state.basket.payment);
    const paymentResponse = useSelector(state => state.payment.payment);
    const location = useLocation();

    const isExtras = location.pathname.includes('/extras');
    useEffect(() => {
        if (
            (isExtras && !!paymentOptions.extraFeature) ||
            (!!paymentOptions.offers.length && !isExtras)
        ) {
            return;
        } else {
            navigate(isExtras ? '/' : '/basket');
        }
    }, [paymentOptions, isExtras, paymentResponse]);

    const totalCost = useMemo(() => {
        if (!paymentOptions.offers.length) {
            return;
        }

        const cost = paymentOptions.offers.reduce(
            (acc, curr) => {
                acc.amount +=
                    Number(curr.delivery?.price?.amount) +
                    Number(curr?.price?.amount) * Number(curr?.quantity!! ?? 1);
                acc.currency = curr?.price?.currency;
                return acc;
            },
            {currency: 'USD', amount: 0} as Offer.MoneySerialized
        );

        return cost;
    }, [paymentOptions]);

    const isFree = useMemo(
        () =>
            paymentOptions.offers.every(
                offer =>
                    offer.price.amount === 0 &&
                    (offer?.delivery?.method === Offer.DeliveryMethod.Pickup ||
                        offer?.delivery?.price?.amount === 0)
            ),
        [paymentOptions.offers]
    );

    const isShipment = useMemo(
        () =>
            paymentOptions.offers.every(
                offer => offer.delivery && offer.delivery.method === Offer.DeliveryMethod.Pickup
            ),
        [paymentOptions.offers]
    );

    return {totalCost, payment: paymentOptions, isExtras, isFree, isShipment};
};
