import * as React from 'react';

//Icons
import {ReactComponent as Basket} from 'assets/icons/nav/basket.svg';
import {ReactComponent as Favorite} from 'assets/icons/nav/favorite.svg';
import {ReactComponent as Message} from 'assets/icons/nav/message.svg';
import {ReactComponent as Notification} from 'assets/icons/nav/notification.svg';
import {ReactComponent as Home} from 'assets/icons/nav/home.svg';
import {ReactComponent as Add} from 'assets/icons/nav/add.svg';
import {ReactComponent as Profile} from 'assets/icons/nav/profile.svg';

//Utils
import cx from 'classnames';

const icons = {
    basket: Basket,
    favorite: Favorite,
    message: Message,
    notification: Notification,
    home: Home,
    add: Add,
    profile: Profile
};

export type NavIconName = keyof typeof icons;

export type NavIconProps = {
    notification?: boolean;
    className?: string;
    icon: 'basket' | 'favorite' | 'message' | 'notification' | 'home' | 'add' | 'profile';
    onClick?: () => void;
};

const NavIcon: React.VFC<NavIconProps> = ({
    notification = false,
    className,
    icon,
    ...props
}) => (
    <div className={cx('relative max-w-max', className)} {...props}>
        {notification && (
            <span
                role={'figure'}
                className={
                    'absolute border-gray-900 rounded-full w-2.5 h-2.5 -bottom-0.5 -right-0.5 bg-yellow border-1.5'
                }
            />
        )}

        {React.createElement(icons[icon], {
            role: 'img',
            'aria-label': icon,
            className: 'stroke-current fill-current'
        })}
    </div>
);

export default NavIcon;
