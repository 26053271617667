//Hooks
import {useState, useRef, useCallback} from 'react';
import useOnClickOutside from 'use-onclickoutside';

//Utils
import _ from 'lodash-es';

export const useDatePicker = () => {
    const [isMonthDropdownVisible, setMonthDropdownVisible] = useState(false);
    const [isYearDropdownVisible, setYearDropdownVisible] = useState(false);

    const monthDropdownRef = useRef(null);
    const yearDropdownRef = useRef(null);

    useOnClickOutside(monthDropdownRef, () => setMonthDropdownVisible(false));
    useOnClickOutside(yearDropdownRef, () => setYearDropdownVisible(false));

    const toggleMonthDropdown = useCallback(() => {
        setMonthDropdownVisible(!isMonthDropdownVisible);
    }, [isMonthDropdownVisible]);

    const toggleYearDropdown = useCallback(() => {
        setYearDropdownVisible(!isYearDropdownVisible);
    }, [isYearDropdownVisible]);

    const years = _.range(1900, new Date().getFullYear() + 1);

    return {
        isMonthDropdownVisible,
        toggleMonthDropdown,
        monthDropdownRef,
        isYearDropdownVisible,
        toggleYearDropdown,
        yearDropdownRef,
        years
    };
};
