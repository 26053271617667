//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Conversations, Data} from 'store/types';
import {Client} from 'twilio-chat/lib/client';

export type UserAction = ActionType<typeof actions>;

export type State = Readonly<{
    conversations: Data<Conversations.Conversations>;
    conversation: Data<Conversations.Item>;
    client: Client | null;
}>;

export const initialState: State = {
    conversations: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    conversation: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    client: null
};

export default createReducer<State, UserAction>(initialState)
    .handleAction(actions.getConversations.request, state => ({
        ...state,
        conversations: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: null
        }
    }))
    .handleAction(actions.getConversations.success, (state, action) => ({
        ...state,
        conversations: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload
        }
    }))
    .handleAction(actions.getConversations.failure, state => ({
        ...state,
        conversations: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }))
    .handleAction(actions.getConversation.request, state => ({
        ...state,
        conversation: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: null
        }
    }))
    .handleAction(actions.getConversation.success, (state, action) => ({
        ...state,
        conversation: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload
        }
    }))
    .handleAction(actions.getConversation.failure, state => ({
        ...state,
        conversation: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }))
    .handleAction(actions.createConversation.request, (state, action) => ({
        ...state,
        conversation: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: null
        }
    }))
    .handleAction(actions.createConversation.success, (state, action) => ({
        ...state,
        conversation: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload
        }
    }))
    .handleAction(actions.createConversation.failure, state => ({
        ...state,
        conversation: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }))
    .handleAction(actions.setClient, (state, action) => ({
        ...state,
        client: action.payload || state.client
    }))
    .handleAction(actions.clearConversation, state => ({
        ...state,
        conversation: {
            isLoading: false,
            isLoaded: false,
            isErrored: false,
            data: null
        }
    }))
    .handleAction(actions.contactAdmin.request, state => ({
        ...state,
        conversation: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: null
        }
    }))
    .handleAction(actions.contactAdmin.success, (state, action) => ({
        ...state,
        conversation: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload
        }
    }))
    .handleAction(actions.contactAdmin.failure, (state, action) => ({
        ...state,
        conversation: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }));
