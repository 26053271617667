//Hooks
import {useCallback, useMemo, useState} from 'react';
import {useFormik} from 'formik';
import {useLocation, useNavigate} from '@reach/router';
import {useTranslation} from 'react-i18next';
import {useCategories} from 'hooks/categories';

//Utils
import qs from 'querystring';
import _ from 'lodash-es';

//Types
import {Search} from 'store/types';

export const useSearchForm = () => {
    const {data: categories} = useCategories();
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation('search');

    const [locationDenied, setLocationDenied] = useState(false);
    const search = qs.parse(location.search.substring(1));
    const phrase = (search['phrase'] as string | undefined) ?? '';
    const categoryId = search['categoryId']
        ? parseInt(search['categoryId'] as string)
        : undefined;
    const local = !!search['local'];

    const initialValues = useMemo<Search.FormValues>(
        () => ({
            phrase,
            category: categories?.find(({id}) => id === categoryId),
            geoDistance: local ? {lon: 0, lat: 0, distance: 'nearby'} : undefined
        }),
        [phrase, categories, categoryId, local]
    );

    const {values, handleChange, setFieldValue, handleSubmit} = useFormik<Search.FormValues>({
        initialValues,
        onSubmit: async ({phrase, category, geoDistance}) => {
            const values = _.pickBy(
                {
                    phrase: !!phrase?.length ? phrase : undefined,
                    categoryId: category?.id,
                    local: !!geoDistance ? true : undefined
                },
                _.identity
            );

            const queryString = `/search${
                Object.values(values).length ? '?' : ''
            }${qs.stringify(values)}`;

            await navigate(queryString);
        },
        enableReinitialize: true
    });

    const handleCategoryChange = useCallback(
        (categoryId: number) => {
            setFieldValue(
                'category',
                categories?.find(({id}) => id === categoryId)
            );
        },
        [categories, setFieldValue]
    );

    const locationOptions = Object.values(Search.Location).map(value => ({
        value,
        label: t(value)
    }));

    const handleLocationChange = useCallback(
        async option => {
            if (option.value === Search.Location.Local) {
                setLocationDenied(false);
                try {
                    const position: GeolocationPosition = await new Promise(
                        (resolve, reject) => {
                            navigator.geolocation.getCurrentPosition(resolve, reject);
                        }
                    );
                    setFieldValue('geoDistance', {
                        lon: position.coords.longitude,
                        lat: position.coords.latitude,
                        distance: 'nearby'
                    });
                    return;
                } catch (err) {
                    setLocationDenied(true);
                }
            }
            setFieldValue('geoDistance', undefined);
        },
        [setFieldValue]
    );

    return {
        categories,
        values,
        locationOptions,
        locationDenied,
        handleChange,
        handleCategoryChange,
        handleLocationChange,
        handleSubmit
    };
};
