import * as React from 'react';

export type DropdownTriggerProps = {
    children: React.ReactElement;
    className?: string;
    onClick?: () => void;
};

const DropdownTrigger: React.VFC<DropdownTriggerProps> = ({children, onClick, className}) =>
    React.cloneElement(children, {...children.props, onClick, className});
DropdownTrigger.displayName = 'DropdownTrigger';

export default DropdownTrigger;
