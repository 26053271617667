//Hooks
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

export const useNotificationsView = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.token);
    const notifications = useSelector(
        state => state.notifications.notifications.data?.messages
    );

    useEffect(() => {
        if (auth) {
            dispatch(actions.getNotifications.request());
        }
    }, [auth, dispatch]);

    useEffect(() => {
        if (window.location.pathname === '/notifications') {
            dispatch(actions.markAllAsRead.request());
        }
        return () => {
            dispatch(actions.getNotifications.request());
        };
    }, [dispatch]);

    return {notifications};
};
