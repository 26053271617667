export type BodyProps = {
    className?: string;
};

const Body: React.FC<BodyProps> = ({className, children}) => (
    <div className={className}>{children}</div>
);
Body.displayName = 'Body';

export default Body;
