//Hooks
import {useCallback} from 'react';
import {useCategories} from 'hooks/categories';
import {useFormikContext} from 'formik';
import {useNavigate} from '@reach/router';

//Utils
import qs from 'querystring';
import _ from 'lodash-es';

//Types
import {Search} from 'store/types';

export const useCategoryFilter = () => {
    const {data: categories} = useCategories();

    const {values, setFieldValue} = useFormikContext<Search.FormValues>();

    const navigate = useNavigate();

    const handleCategoryChange = useCallback(
        (categoryId: number) => {
            const category = categories?.find(({id}) => id === categoryId);

            setFieldValue('category', category);

            const query = _.pickBy(
                {
                    phrase: !!values.phrase?.length ? values.phrase : undefined,
                    categoryId: category?.id,
                    local: !!values.geoDistance ? true : undefined
                },
                _.identity
            );
            navigate(`/search?${qs.stringify(query)}`);
        },
        [categories, values, setFieldValue, navigate]
    );

    return {categories, values, handleCategoryChange};
};
