//Utils
import cx from 'classnames';
import dayjs from 'dayjs';

//Components
import {Link} from '@reach/router';
import Icon from 'components/ui/Icon';

//Types
import {Notifications} from 'store/types';

//Hooks
import {useTranslation} from 'react-i18next';

import {dateFormat} from 'localeApp';

export type NotificationProps = {
    notification: Notifications.Notification;
    full?: boolean;
    className?: string;
};

const Notification: React.VFC<NotificationProps> = ({notification, full, className}) => {
    const {t} = useTranslation('notifications');
    const getNotificationLink = (notification: Notifications.Notification) => {
        switch (notification.notificationId) {
            case Notifications.NotificationId.NewChatMessage:
                return `/chat/${notification.data.conversationId}`;
            case Notifications.NotificationId.ExpiringOffer:
                return `/offer/${notification.data.offerId}`;
            default:
                return `/profile/my-purchases/${notification.data.orderId}`;
        }
    };
    return (
        <Link
            to={getNotificationLink(notification)}
            className={cx(
                'flex flex-col items-start md:flex-row md:items-center p-3.5',
                {
                    'border-b border-gray-300': !full,
                    'justify-between md:px-12': full,
                    'bg-white': notification?.readAt,
                    'bg-opacity-20 bg-secondary': !notification?.readAt && full
                },
                className
            )}
        >
            <div className={'flex w-full md:w-auto justify-start items-center gap-x-4'}>
                <Icon icon={'notification'} size={'lg'} className={'text-secondary'} />

                <span className={'text-sm'} aria-label={'notification content'}>
                    {t(notification?.notificationId, {data: notification?.data})}
                </span>
            </div>

            {full && (
                <span
                    className={'text-sm ml-9.5 text-gray-400'}
                    aria-label={'notification date'}
                >
                    {dayjs(notification?.createdAt).format(`${dateFormat} HH:mm`)}
                </span>
            )}
        </Link>
    );
};
export default Notification;
