//Hooks
import {useDispatch} from 'hooks/store';
import {useFormik} from 'formik';
import {useNavigate} from '@reach/router';
import {useTranslation} from 'react-i18next';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, string} from 'yup';

//Types
import {Auth} from 'store/types';

export function useRegisterConfirmView({uuid}: Partial<Auth.RegisterUUID>) {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {t} = useTranslation('errors');

    const validationSchema = object({
        code: string().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useFormik<Auth.ConfirmFormValues>({
        initialValues: {
            code: ''
        },
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.confirmAccount.request({...payload, uuid: uuid!}));
                await navigate('/auth/register/success');
            } catch (err) {
                if (err.code === 404 || err.response?.status === 404) {
                    toast.error(t('incorrectCode'));
                } else if (err.message) {
                    toast.error(err.message);
                }

                setErrors(err.errors ?? {});
            }
        }
    });

    return {values, touched, errors, handleChange, handleSubmit, isSubmitting};
}
