//Hooks
import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

import {locale} from 'localeApp';

export const useFooter = () => {
    const {isLoading, isLoaded, data: staticPages} = useSelector(
        state => state.staticPages.staticPages
    );
    const dispatch = useDispatch();

    const facebookLink = useMemo(() => {
        switch (locale) {
            case 'us':
                return 'https://www.facebook.com/allspaceus';
            case 'pl':
                return 'https://www.facebook.com/allspacepl';
            default:
                return '';
        }
    }, []);

    const instagramLink = useMemo(() => {
        switch (locale) {
            case 'us':
                return 'https://www.instagram.com/allspaceus';
            case 'pl':
                return 'https://www.instagram.com/allspacepl';
            default:
                return '';
        }
    }, []);

    const linkedInLink = 'https://www.linkedin.com/company/allspaceinc';
    const tiktokLink = 'https://www.tiktok.com/@allspaceallspace';

    useEffect(() => {
        if (isLoading || isLoaded) {
            return;
        }

        dispatch(actions.loadStaticPages.request());
    }, [isLoading, isLoaded, dispatch]);

    return {
        isLoading,
        staticPages,
        instagramLink,
        facebookLink,
        linkedInLink,
        tiktokLink
    };
};
