//Services
import axios from 'services/axios';

//Utils
import _ from 'lodash-es';

//Types
import {AxiosTransformer} from 'axios';
import {Categories} from 'store/types';

export const transformResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    ({customFormFields}: Categories.CustomFormField.Response) =>
        _.fromPairs(
            _.toPairs(customFormFields.properties).map(([name, field]) => [
                name,
                {
                    ...field,
                    required: customFormFields.required.includes(name)
                }
            ])
        )
];
