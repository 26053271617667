//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Reviews} from 'store/types';

export type ReviewsAction = ActionType<typeof actions>;

export type State = Readonly<{
    seller: Data<Reviews.Response.Seller, Reviews.Request>;
    offer: Data<Reviews.Response.Offer, Reviews.Request>;
    me: Data<Reviews.Response.MyReview, Reviews.Request>;
}>;

export const initialState: State = {
    seller: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    offer: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    me: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    }
};

export default createReducer<State, ReviewsAction>(initialState)
    .handleAction(actions.getSellerReviews.request, (state, action) => ({
        ...state,
        seller: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.seller.data,
            props: action.payload
        }
    }))
    .handleAction(actions.getSellerReviews.success, (state, action) => ({
        ...state,
        seller: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload,
            props: state.seller.props
        }
    }))
    .handleAction(actions.getSellerReviews.failure, state => ({
        ...state,
        seller: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null,
            props: state.seller.props
        }
    }))
    .handleAction(actions.getOfferReviews.request, (state, action) => ({
        ...state,
        offer: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.offer.data,
            props: action.payload
        }
    }))
    .handleAction(actions.getOfferReviews.success, (state, action) => ({
        ...state,
        offer: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload,
            props: state.offer.props
        }
    }))
    .handleAction(actions.getOfferReviews.failure, (state, action) => ({
        ...state,
        offer: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null,
            props: state.offer.props
        }
    }))
    .handleAction(actions.getMyReviews.request, (state, action) => ({
        ...state,
        me: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.me.data,
            props: action.payload
        }
    }))
    .handleAction(actions.getMyReviews.success, (state, action) => ({
        ...state,
        me: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload,
            props: state.me.props
        }
    }))
    .handleAction(actions.getMyReviews.failure, (state, action) => ({
        ...state,
        me: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null,
            props: state.me.props
        }
    }));
