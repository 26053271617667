//Hooks
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

//Components
import Input, {InputIcon} from 'components/ui/Input';
import Section from '../Section';
import AccountTypeFilter from '../AccountTypeFilter';

//Types
import {Search} from 'store/types';

const JobFilters: React.VFC = () => {
    const {values, handleChange} = useFormikContext<Search.FormValues>();

    const {t} = useTranslation(['search', 'common']);

    return (
        <>
            <Section title={t('search:salaryHourly')}>
                <div className={'flex flex-row gap-8'}>
                    <div className={'flex flex-row items-end gap-2 text-sm tracking-tighter'}>
                        <span>{t('common:from')}</span>

                        <Input
                            id={'job[0].salaryFrom'}
                            name={'job[0].salaryFrom'}
                            value={values.job?.[0].salaryFrom?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            aria-label={'Hourly salary from'}
                        >
                            <InputIcon placement={'right'} className={'text-gray-400'}>
                                {t('common:currencyShort')}
                            </InputIcon>
                        </Input>
                    </div>

                    <div className={'flex flex-row items-end gap-2 text-sm tracking-tighter'}>
                        <span>{t('common:to')}</span>

                        <Input
                            id={'job[0].salaryTo'}
                            name={'job[0].salaryTo'}
                            value={values.job?.[0].salaryTo?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            aria-label={'Hourly salary to'}
                        >
                            <InputIcon placement={'right'} className={'text-gray-400'}>
                                {t('common:currencyShort')}
                            </InputIcon>
                        </Input>
                    </div>
                </div>
            </Section>

            <Section title={t('search:salaryMonthly')}>
                <div className={'flex flex-row gap-8'}>
                    <div className={'flex flex-row items-end gap-2 text-sm tracking-tighter'}>
                        <span>{t('common:from')}</span>

                        <Input
                            id={'job[1].salaryFrom'}
                            name={'job[1].salaryFrom'}
                            value={values.job?.[1].salaryFrom?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            aria-label={'Monthly salary from'}
                        >
                            <InputIcon placement={'right'} className={'text-gray-400'}>
                                {t('common:currencyShort')}
                            </InputIcon>
                        </Input>
                    </div>

                    <div className={'flex flex-row items-end gap-2 text-sm tracking-tighter'}>
                        <span>{t('common:to')}</span>

                        <Input
                            id={'job[1].salaryTo'}
                            name={'job[1].salaryTo'}
                            value={values.job?.[1].salaryTo?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            aria-label={'Monthly salary to'}
                        >
                            <InputIcon placement={'right'} className={'text-gray-400'}>
                                {t('common:currencyShort')}
                            </InputIcon>
                        </Input>
                    </div>
                </div>
            </Section>

            <Section title={t('search:offerType')}>
                <AccountTypeFilter />
            </Section>
        </>
    );
};

export default JobFilters;
