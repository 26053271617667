//Hooks
import {useCallback} from 'react';

//Types
import {OptionProps} from './Option';

export const useOption = ({
    active,
    item,
    selectOption,
    setActiveOption,
    expandOnHover,
    preventSelectParentOption
}: OptionProps) => {
    const hasChildren = !!item.children.length;

    const handleClick = useCallback(() => {
        if (expandOnHover && preventSelectParentOption && item.children.length) {
            return;
        }

        if (expandOnHover || !item.children.length) {
            selectOption(item);
        } else {
            setActiveOption(active ? null : item.id);
        }
    }, [item, active, selectOption, setActiveOption, expandOnHover, preventSelectParentOption]);

    const handleMouseEnter = useCallback(() => {
        if (item.children.length) {
            setActiveOption(item.id);
        } else {
            setActiveOption(null);
        }
    }, [item, setActiveOption]);

    return {
        hasChildren,
        handleClick,
        handleMouseEnter
    };
};
