type SectionProps = {
    title: string;
    'aria-label'?: string;
};

const Section: React.FC<SectionProps> = ({title, children, ...props}) => (
    <section className={'pb-6 border-b border-gray-200'} {...props}>
        <h3 className={'mb-4'}>{title}</h3>

        {children}
    </section>
);

export default Section;
