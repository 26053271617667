//Utils
import cx from 'classnames';

export type RadioProps = {
    className?: string;
    label?: string | [string, string];
} & Omit<React.HTMLProps<HTMLInputElement>, 'label'>;

const Radio: React.VFC<RadioProps> = ({className, label, ...props}) => (
    <div
        className={cx('flex flex-row items-center', className)}
        role={'region'}
        aria-label={props.id}
    >
        <input
            {...props}
            type={'radio'}
            className={
                'min-w-5 min-h-5 border border-gray-900 appearance-none cursor-pointer rounded-2xl box-border checked:bg-gray-900 focus:outline-none'
            }
        />

        {!!label && (
            <div className={'flex flex-col ml-12 sm:ml-36'} role={'group'}>
                <label
                    className={'tracking-tight text-radio-label'}
                    htmlFor={props.id}
                    role={'listitem'}
                >
                    {Array.isArray(label) ? label[0] : label}
                </label>

                {Array.isArray(label) && label.length === 2 && (
                    <label
                        className={'mt-2 text-xs-radio tracking-tight text-gray-600'}
                        htmlFor={props.id}
                        role={'listitem'}
                    >
                        {label[1]}
                    </label>
                )}
            </div>
        )}
    </div>
);

export default Radio;
