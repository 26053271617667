import {Categories, Offer} from 'store/types';

export const transformEditOfferValues = (
    offer: Offer.ListItem,
    categories: Categories.Category[]
): Offer.Form => ({
    type: offer.type,
    offer: {
        title: offer.title,
        description: offer.description ?? '',
        category:
            categories.find(({id}) => offer.category?.id === id || offer.categoryId === id) ??
            null,
        status: offer.status,
        images: offer.images.map(img => ({fileId: img.id, preview: img.small, name: img.name})),
        attributes: {}, // this is being set in the custom form fields hook
        price: {
            tbbcAmount: 'price' in offer && !!offer.price ? offer.price.amount / 100 : null
        },
        delivery: {
            method: 'delivery' in offer ? offer.delivery!.method : null,
            price: {
                tbbcAmount:
                    'delivery' in offer && offer.delivery?.price.amount
                        ? offer.delivery!.price.amount / 100
                        : null
            }
        },
        quantity: 'quantity' in offer ? offer.quantity ?? 0 : 0,
        showSalary: 'showSalary' in offer ? offer.showSalary : true,
        priceVariant:
            ('salaryTo' in offer && !!offer.salaryTo) || ('priceTo' in offer && !!offer.priceTo)
                ? Offer.PriceVariant.Range
                : Offer.PriceVariant.Fixed,
        salaryFrom:
            'salaryFrom' in offer && !!offer.salaryFrom
                ? {tbbcAmount: offer.salaryFrom.amount / 100}
                : {tbbcAmount: null},
        salaryTo:
            'salaryTo' in offer && !!offer.salaryTo
                ? {tbbcAmount: offer.salaryTo.amount / 100}
                : {tbbcAmount: null},
        period: 'period' in offer ? offer.period : null,
        priceFrom:
            'priceFrom' in offer && !!offer.priceFrom
                ? {tbbcAmount: offer.priceFrom.amount / 100}
                : {tbbcAmount: null},
        priceTo:
            'priceTo' in offer && !!offer.priceTo
                ? {tbbcAmount: offer.priceTo.amount / 100}
                : {tbbcAmount: null}
    },
    hideLocation: !offer.address,
    lastCheckedAddress: !!offer.address
        ? `${offer.address.street}, ${offer.address.city}, ${offer.address.state}`
        : '',
    address: offer.address
        ? {
              ...offer.address,
              geoCoordinate: {
                  lat: parseFloat(offer.address.geoCoordinate?.lat),
                  lon: parseFloat(offer.address.geoCoordinate?.lon)
              }
          }
        : undefined
});
