import {Offer, User} from 'store/types';
import {Pagination} from '../pagination';
import {Address} from '../user';

export enum Status {
    Failed = 'failed',
    Paid = 'paid',
    NotPaid = 'not_paid',
    Canceled = 'canceled',
    Confirmed = 'confirmed',
    Completed = 'completed'
}

export interface Base {
    orderId: number;
    status: Status;
    amount: Offer.MoneySerialized;
    boughtAt: Date;
    validUntil: Date;
    shipmentLink: string;
    image?: string;
    title?: string;
}

export interface Order extends Base {
    price?: string;
}

export interface Product {
    title: string;
    image: string;
    productId: number;
    orderProductId: number;
    quantity: number;
    price: {
        amount: string;
        currency: string;
    };
    shippingCost: {
        amount: string;
        currency: string;
    };
    total: {
        amount: string;
        currency: string;
    };
}

export interface OrderDetails extends Base {
    image: string;
    title: string;
    price: {
        amount: string;
        currency: string;
    };
    buyer: {
        id: number;
        username: string;
        phone: string;
    };
    seller: {
        id: number;
        username: string;
        phone: string;
        email: string;
    };
    shippingAddress: User.Address;
    products: Product[];
}

export type ListResponse = {
    items: Order[];
    pagination: Pagination;
};

export type BasketItem = {
    product: number;
    quantity: number;
};

export type BoughtProduct = {
    product: number;
    quantity: number;
    price: {
        amount: string;
        currency: string;
    };
    shippingCost: {
        amount: string;
        currency: string;
    };
    total: {
        amount: string;
        currency: string;
    };
};

export type SavedOrderData = {
    shipping_address: Address | null;
    basket: {
        products: BasketItem[];
    };
};

export type CreateOrderResponse = {
    order: {
        orderId: number;
    };
    payment: {
        gatewayData: {
            clientSecret: string;
        };
    };
    basket: {
        products: BoughtProduct[];
        total: Offer.MoneySerialized;
    };
};

export interface DisputeOrderForm {
    description: string;
}

export interface DisputeOrderRequest extends DisputeOrderForm {
    orderId: number;
}
