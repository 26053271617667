//Hooks
import {useFormik} from 'formik';
import {useDispatch} from 'hooks/store';
import {useEffect} from 'react';

//Actions
import * as actions from 'store/actions';

//Utils
import {object, string} from 'yup';

//Services
import toast from 'react-hot-toast';

//Types
import {User} from 'store/types';

export const useEditMyPasswordView = (closeModal: () => void) => {
    const dispatch = useDispatch();

    const validationSchema = object<User.Password>({
        oldPassword: string().required(),
        newPassword: string().required(),
        repeatNewPassword: string().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm
    } = useFormik<User.Password>({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: ''
        },
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.editMyPassword.request(payload));
                closeModal();
            } catch (err) {
                if (err.message) {
                    toast.error(err.message);
                }

                if (err.errors) {
                    if (err.errors.newPassword) {
                        err.errors.newPassword =
                            err.errors.newPassword.first || err.errors.newPassword.second;
                    }
                    setErrors(err.errors);
                }
            }
        }
    });

    useEffect(() => {
        resetForm();
    }, [closeModal, resetForm]);

    return {values, touched, errors, handleChange, handleSubmit, isSubmitting};
};
