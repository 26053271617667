//Hooks
import {useReviewsView} from './ReviewsView.hook';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import SearchForm from 'components/search/SearchForm';
import Button from 'components/ui/Button';
import ReviewRatingView from 'components/review/ReviewRatingView';
import ReviewView from 'components/review/ReviewView';
import PaginationView from 'components/ui/PaginationView';
import Footer from 'components/nav/Footer';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import DisputeReviewModal from 'components/modal/DisputeReviewModal';
import CreateReviewModal from 'components/modal/CreateReviewModal';
import PopularSearches from 'components/nav/PopularSearches';

//Utils
import _ from 'lodash-es';

//Types
import {RouteComponentProps} from '@reach/router';

type ReviewsViewProps = {
    type: 'seller' | 'offer' | 'me';
    id?: string;
};

const ReviewsView: React.VFC<RouteComponentProps<ReviewsViewProps>> = ({type, id}) => {
    const {
        isLoading,
        isErrored,
        data,
        user,
        isDispute,
        currentPage,
        selectedReview,
        handlePageChange,
        isDisputeReviewModalOpen,
        isCreateReviewModalOpen,
        openDisputeReviewModal,
        closeDisputeReviewModal,
        openCreateReviewModal,
        closeCreateReviewModal,
        breakpoint
    } = useReviewsView({type, id});

    const {t} = useTranslation(['reviews', 'common', 'errors']);
    return (
        <>
            <div className={'flex flex-col flex-grow pb-10 bg-gray-100'}>
                {breakpoint !== 'xs' && <SearchForm />}

                <div className={'bg-white py-6.5 border-b border-gray-200 hidden md:block'}>
                    <div className={'max-w-292 flex justify-end m-0-auto px-4'}>
                        <Button as={Link} to={'/offers/create'} variant={'primary'}>
                            {t('common:addOffer')}
                        </Button>
                    </div>
                </div>

                <div className={'flex justify-center mt-8 bg-white'}>
                    {data?.seller && (
                        <ReviewRatingView seller={data.seller} stats={data.stats} />
                    )}

                    {data?.offer && <ReviewRatingView offer={data.offer} stats={data.stats} />}

                    {!data?.seller && !data?.offer && data?.stats && type === 'me' && (
                        <ReviewRatingView user={user} stats={data?.stats} />
                    )}

                    {!data?.seller && !data?.offer && !data?.reviews && !isErrored && (
                        <ReviewRatingView loading />
                    )}
                </div>

                <div className={'flex flex-col items-center mt-8'}>
                    <div className={'w-full md:px-4 max-w-292'}>
                        {!!user && data?.seller && data.seller.username !== user.username && (
                            <div className={'flex justify-end mb-8 px-4 md:px-0'}>
                                <Button
                                    className={'hidden md:block'}
                                    variant={'primary'}
                                    onClick={openCreateReviewModal}
                                    outline
                                >
                                    {t('reviews:reviewUser')}
                                </Button>

                                <Button
                                    className={'block md:hidden'}
                                    variant={'primary'}
                                    size={'xs'}
                                    onClick={openCreateReviewModal}
                                    outline
                                >
                                    {t('reviews:reviewUser')}
                                </Button>
                            </div>
                        )}

                        <div className={'flex justify-between items-end px-4 md:px-0'}>
                            {data && (
                                <>
                                    <h1
                                        className={'text-sm md:text-xl'}
                                        aria-label={'Reviews count'}
                                    >
                                        {t('reviews:reviewsCount', {
                                            count: data.reviews.pagination.totalItems
                                        })}
                                    </h1>

                                    {!!data.reviews.items?.length && breakpoint !== 'xs' && (
                                        <PaginationView
                                            currentPage={currentPage}
                                            totalPages={data.reviews.pagination.totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    )}
                                </>
                            )}

                            {!data && !isErrored && (
                                <h1 role={'figure'} aria-label={'Reviews count loading'}>
                                    <Skeleton width={125} />
                                </h1>
                            )}
                        </div>

                        {data && !data.reviews.pagination.totalItems && (
                            <div className={'flex flex-col items-center my-8 py-8 bg-white'}>
                                <h2 className={'font-semibold'}>{t('reviews:noReviews')}</h2>

                                <span className={'mt-12'}>
                                    {t(
                                        isDispute
                                            ? 'reviews:youHaveNotBeenReviewed'
                                            : data.seller
                                            ? 'reviews:thisSellerHasNotBeenReviewed'
                                            : 'reviews:thisOfferHasNotBeenReviewed'
                                    )}
                                </span>
                            </div>
                        )}

                        {!isLoading && !!data?.reviews.items?.length && (
                            <>
                                <div className={'flex flex-col my-8 px-8 py-4 bg-white'}>
                                    {data.reviews.items.map(review => (
                                        <ReviewView
                                            key={review.id}
                                            className={'mb-4'}
                                            review={review}
                                            dispute={isDispute}
                                            onDispute={openDisputeReviewModal}
                                        />
                                    ))}
                                </div>

                                <div className={'flex justify-end'}>
                                    <PaginationView
                                        currentPage={currentPage}
                                        totalPages={data.reviews.pagination.totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </>
                        )}

                        {isLoading && (
                            <div className={'flex flex-col my-8 px-8 py-4 bg-white'}>
                                {_.times(30, i => (
                                    <ReviewView key={i} loading />
                                ))}
                            </div>
                        )}

                        {isErrored && (
                            <div className={'flex flex-col items-center my-8 py-8 bg-white'}>
                                <h2 className={'font-semibold'}>{t('errors:errorOccurred')}</h2>

                                <span className={'mt-12'}>
                                    {t('errors:pleaseTryAgainLater')}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <PopularSearches />

            <Footer />

            <MobileBottomNav />

            {data?.seller && (
                <CreateReviewModal
                    isOpen={isCreateReviewModalOpen}
                    onClose={closeCreateReviewModal}
                    seller={data.seller}
                />
            )}

            {!!selectedReview && (
                <DisputeReviewModal
                    review={selectedReview}
                    isOpen={isDisputeReviewModalOpen}
                    onClose={closeDisputeReviewModal}
                />
            )}
        </>
    );
};

export default ReviewsView;
