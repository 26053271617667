//Hooks
import {useAccountSettings} from './AccountSettingsView.hook';
import {useTranslation} from 'react-i18next';
import {usePaymentCardView} from 'views/PaymentCardView/PaymentCardView.hook';

//Components
import Icon from 'components/ui/Icon';
import {Elements} from '@stripe/react-stripe-js';

//Modals
import EditMyPhoneModal from 'components/modal/EditMyPhoneModal';
import EditMyPhoneConfirmModal from 'components/modal/EditMyPhoneConfirmModal';
import EditMyEmailModal from 'components/modal/EditMyEmailModal';
import EditMyEmailConfirmModal from 'components/modal/EditMyEmailConfirmModal';
import EditMyAddressModal from 'components/modal/EditMyAddressModal';
import EditBankAccountModal from 'components/modal/EditMyBankAccountModal';
import EditMyPasswordModal from 'components/modal/EditMyPasswordModal';
import EditCreditCardModal from 'components/modal/EditCreditCardModal';

//Types
import {RouteComponentProps} from '@reach/router';
import {User} from 'store/types';

const AccountSettingsView: React.VFC<RouteComponentProps> = () => {
    const {
        user,
        savedCards,
        isEditMyPhoneModalOpen,
        openEditMyPhoneModal,
        closeEditMyPhoneModal,
        isEditMyPhoneConfirmModalOpen,
        openEditMyPhoneConfirmModal,
        closeEditMyPhoneConfirmModal,
        isEditMyEmailModalOpen,
        openEditMyEmailModal,
        closeEditMyEmailModal,
        isEditMyEmailConfirmModalOpen,
        openEditMyEmailConfirmModal,
        closeEditMyEmailConfirmModal,
        isEditMyAddressModalOpen,
        openEditMyAddressModal,
        closeEditMyAddressModal,
        isEditBankAccountModalOpen,
        openEditBankAccountModal,
        closeEditBankAccountModal,
        isEditMyPasswordModalOpen,
        openEditMyPasswordModal,
        closeEditMyPasswordModal,
        isEditCreditCardModalOpen,
        openEditCreditCardModal,
        closeEditCreditCardModal
    } = useAccountSettings();
    const {stripePromise} = usePaymentCardView();

    const {t} = useTranslation('profile');

    return (
        <>
            <div className={'flex flex-col gap-y-8'}>
                <div className={'flex flex-col px-8 bg-white py-7'}>
                    <h1 className={'font-semibold text-base'}>{t('accountDetails')}</h1>

                    <div className={'flex flex-wrap mt-8 lg:flex-nowrap gap-x-14 gap-y-8'}>
                        <div className={'flex flex-col flex-grow w-6/12 text-sm lg:pr-8'}>
                            <div className={'flex justify-between mb-4'}>
                                <h2 className={'font-semibold text-sm'}>
                                    {t('contactDetails')}
                                </h2>
                            </div>

                            <div className={'flex flex-col gap-y-1'}>
                                <p>{t('name', {username: user?.username})}</p>

                                <div className={'flex justify-between'}>
                                    <p>{t('emailWithContent', {email: user?.email})}</p>

                                    <button
                                        className={
                                            'flex items-center focus:outline-none text-secondary text-xs-button gap-x-2'
                                        }
                                        onClick={openEditMyEmailModal}
                                    >
                                        {t('edit')}

                                        <Icon icon={'arrow-right'} size={'xs'} />
                                    </button>
                                </div>

                                <div className={'flex justify-between'}>
                                    <p>{t('phoneNumberWithContent', {phone: user?.phone})}</p>

                                    <button
                                        className={
                                            'flex items-center focus:outline-none text-secondary text-xs-button gap-x-2'
                                        }
                                        onClick={openEditMyPhoneModal}
                                    >
                                        {t('edit')}

                                        <Icon icon={'arrow-right'} size={'xs'} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={'flex flex-col flex-grow w-6/12 text-sm lg:pl-8'}>
                            <div className={'flex justify-between mb-4'}>
                                <p className={'font-semibold'}>{t('myAddresses')}</p>

                                <button
                                    className={
                                        'flex items-center focus:outline-none text-secondary text-xs-button gap-x-2'
                                    }
                                    onClick={openEditMyAddressModal}
                                >
                                    {t('edit')}

                                    <Icon icon={'arrow-right'} size={'xs'} />
                                </button>
                            </div>

                            <div className={'flex flex-col gap-y-1'}>
                                <p>
                                    {t('fullName', {
                                        firstName:
                                            user?.type === User.Type.Person
                                                ? user?.firstName
                                                : user?.companyName,
                                        lastName:
                                            user?.type === User.Type.Person
                                                ? user?.lastName
                                                : ''
                                    })}
                                </p>

                                <p>
                                    {t('addressMain', {
                                        street: user?.address?.street,
                                        apartmentNumber: user?.address?.apartmentNumber
                                    })}
                                </p>

                                <p>
                                    {t('addressSecondary', {
                                        city: user?.address?.city,
                                        state: user?.address?.state,
                                        postCode: user?.address?.postCode
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'flex flex-wrap lg:flex-nowrap gap-x-14 gap-y-8'}>
                    <div className={'flex flex-col flex-grow w-8/12 px-8 bg-white py-7'}>
                        <div className={'font-semibold'}>{t('payments')}</div>

                        <div className={'flex gap-x-8 items-start'}>
                            <div className={'w-6/12'}>
                                <div className={'flex justify-between mt-8 mb-4 text-sm'}>
                                    <p className={'font-semibold'}>{t('bankAccount')}</p>

                                    <button
                                        className={
                                            'flex items-center focus:outline-none text-secondary text-xs-button gap-x-2'
                                        }
                                        onClick={openEditBankAccountModal}
                                    >
                                        {t('edit')}

                                        <Icon icon={'arrow-right'} size={'xs'} />
                                    </button>
                                </div>

                                <p className={'text-sm'}>
                                    {user?.payout?.account?.accountNumber}
                                </p>
                            </div>

                            <div className={'w-6/12'}>
                                <div className={'flex justify-between mt-8 mb-4 text-sm'}>
                                    <p className={'font-semibold'}>{t('creditCard')}</p>

                                    <button
                                        className={
                                            'flex items-center focus:outline-none text-secondary text-xs-button gap-x-2'
                                        }
                                        onClick={openEditCreditCardModal}
                                    >
                                        {t('add')}

                                        <Icon icon={'arrow-right'} size={'xs'} />
                                    </button>
                                </div>

                                <p className={'text-sm'}>
                                    {savedCards &&
                                        [
                                            savedCards[0]?.creditCardNumber.slice(0, 4),
                                            savedCards[0]?.creditCardNumber.slice(4)
                                        ].join(' ')}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={'flex flex-col flex-grow w-4/12 px-8 bg-white py-7'}>
                        <div className={'font-semibold'}>{t('logIn')}</div>

                        <div className={'flex justify-between mt-8 mb-4 text-sm'}>
                            <p className={'font-semibold'}>{t('myPassword')}</p>

                            <button
                                className={
                                    'flex items-center focus:outline-none text-secondary text-xs-button gap-x-2'
                                }
                                onClick={openEditMyPasswordModal}
                            >
                                {t('edit')}

                                <Icon icon={'arrow-right'} size={'xs'} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <EditMyPhoneModal
                isOpen={isEditMyPhoneModalOpen}
                onClose={closeEditMyPhoneModal}
                onSubmit={openEditMyPhoneConfirmModal}
            />

            <EditMyPhoneConfirmModal
                isOpen={isEditMyPhoneConfirmModalOpen}
                onClose={closeEditMyPhoneConfirmModal}
            />

            <EditMyEmailModal
                isOpen={isEditMyEmailModalOpen}
                onClose={closeEditMyEmailModal}
                onSubmit={openEditMyEmailConfirmModal}
            />

            <EditMyEmailConfirmModal
                isOpen={isEditMyEmailConfirmModalOpen}
                onClose={closeEditMyEmailConfirmModal}
            />

            <EditMyAddressModal
                isOpen={isEditMyAddressModalOpen}
                onClose={closeEditMyAddressModal}
            />

            <EditBankAccountModal
                isOpen={isEditBankAccountModalOpen}
                onClose={closeEditBankAccountModal}
            />

            <EditMyPasswordModal
                isOpen={isEditMyPasswordModalOpen}
                onClose={closeEditMyPasswordModal}
            />

            <Elements stripe={stripePromise}>
                <EditCreditCardModal
                    isOpen={isEditCreditCardModalOpen}
                    onClose={closeEditCreditCardModal}
                />
            </Elements>
        </>
    );
};

export default AccountSettingsView;
