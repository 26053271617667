//Hooks
import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import {useDispatch, useSelector} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

//Types
import {FormikTouched, FormikErrors, FormikHandlers} from 'formik';
import {Offer, User} from 'store/types';

type UsePaymentViewResult<T> = {
    user: User.User;
    values: T;
    touched: FormikTouched<T>;
    errors: FormikErrors<T>;
    setFieldValue: (
        field: string,
        value: any,
        isTouched?: boolean,
        shouldValidate?: boolean
    ) => void;
    handleBlur: FormikHandlers['handleBlur'];
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    handleChange: FormikHandlers['handleChange'];
    isSubmitting: boolean;
    setErrors: (errors: FormikErrors<T>) => void;
    setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean) => void;
    orderValidationErrors: any;
};

export function usePaymentFormView<
    T extends Offer.PaymentFormValues
>(): UsePaymentViewResult<T> {
    const {
        values,
        touched,
        errors,
        setFieldValue,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        setErrors,
        setTouched
    } = useFormikContext<T>();

    const user = useSelector(state => state.user.user);
    const orderValidationErrors = useSelector(state => state.payment.orderValidationErrors);

    return {
        user: user!!,
        values,
        touched,
        errors,
        handleBlur,
        setFieldValue,
        handleChange,
        handleSubmit,
        isSubmitting,
        setErrors,
        setTouched,
        orderValidationErrors
    };
}

export const useOfferFeaturePrices = () => {
    const {isLoaded, isErrored, data} =
        useSelector(state => state?.payment?.offerFeaturePrices) || {};
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoaded || isErrored) {
            return;
        }

        dispatch(actions.getConstants.request());
    }, [isLoaded, isErrored, dispatch]);

    return {isLoaded, data};
};
