//Hooks
import {usePaymentFormView} from 'views/PaymentCardView/PaymentFormView/PaymentFormView.hook';

//Translations
import {useTranslation} from 'react-i18next';

//Components
import Button from 'components/ui/Button';
import Select from 'react-select';
import SuccessFeatureModal from 'components/modal/SuccessFeatureModal';

//Styles
import {theme, styles} from 'styles/select';

const PaymentSavedCardView: React.VFC = () => {
    const {t} = useTranslation('common');
    const {
        transformedCards,
        feature,
        handleSavedCard,
        usedCard,
        setUsedCard,
        closeSuccessModal,
        isSuccessModalVisible
    } = usePaymentFormView();
    return (
        <>
            <div className={'flex gap-x-8 text-sm font-semibold items-center'}>
                <p>{t('useSavedCard')}</p>

                <Select
                    value={usedCard}
                    noOptionsMessage={() => t('noOptions')}
                    placeholder={t('selectPlaceholderDefault')}
                    onChange={option => setUsedCard(option)}
                    options={transformedCards}
                    theme={theme}
                    styles={styles}
                    className={'w-48'}
                />
            </div>

            <Button
                variant={'primary'}
                outline
                size={'xs'}
                onClick={handleSavedCard}
                disabled={!usedCard}
            >
                {t('use')}
            </Button>

            {!!feature && (
                <SuccessFeatureModal
                    type={feature.featureType}
                    onClose={closeSuccessModal}
                    isOpen={isSuccessModalVisible}
                />
            )}
        </>
    );
};

export default PaymentSavedCardView;
