//Redux saga
import {call, put, takeLatest} from 'redux-saga/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';

//Transformers
import {transformers} from 'utils';

//Types
import {AxiosResponse} from 'axios';
import {Categories} from 'store/types';

export function* getCategoryCustomFormFields(
    action: ReturnType<typeof actions.getCategoryCustomFormFields.request>
) {
    try {
        const resp: AxiosResponse<Categories.CustomFormField.Fields> = yield call(request, {
            method: 'GET',
            url: `/api/category/${action.payload.id}/custom-form-fields`,
            transformResponse: transformers.customFormFields.transformResponse
        });

        yield put(actions.getCategoryCustomFormFields.success(resp.data));
    } catch (err) {
        yield put(actions.getCategoryCustomFormFields.failure(err));
    }
}

export const saga = [
    takeLatest(actions.getCategoryCustomFormFields.request, getCategoryCustomFormFields)
];
