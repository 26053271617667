//Action creators
import {createPromiseAction} from 'redux-saga-promise-actions';

//Types
import {FileWithPath} from 'react-dropzone';
import {Errors, Files} from '../../types';

/**
 * Uploads a photo and returns the file id.
 */
export const uploadFile = createPromiseAction('UPLOAD_FILE')<
    FileWithPath,
    Files.FileUploadResponse,
    Errors.API
>();

/**
 * Deletes a photo.
 */
export const deleteFile = createPromiseAction('DELETE_FILE')<
    Files.UploadedPhoto,
    number,
    Errors.API
>();
