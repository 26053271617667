//Hooks
import {useToaster} from 'react-hot-toast';

//Types
import {Toast} from '../toast';

type UseToastViewArgs = {
    toast: Toast;
};

export const useToastView = ({toast}: UseToastViewArgs) => {
    const {
        handlers: {calculateOffset, updateHeight}
    } = useToaster();

    const ref = (el: HTMLDivElement) => {
        if (!el || toast.height) {
            return;
        }

        const height = el.getBoundingClientRect().height;
        updateHeight(toast.id, height);
    };

    const offset = calculateOffset(toast.id, {reverseOrder: false, margin: 8});

    return {ref, offset};
};
