//Hooks
import {useDisputeOrderModal} from './DisputeOrderModal.hook';
import {useTranslation} from 'react-i18next';

//Components
import Modal from 'components/ui/Modal';
import TextArea from 'components/ui/TextArea';
import Button from 'components/ui/Button';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';

//Utils
import dayjs from 'dayjs';

//Types
import {Order} from 'store/types';
import OfferView from 'components/offer/OfferView';

type DisputeOrderModalProps = {
    isOpen: boolean;
    onClose: () => void;
    order: Order.OrderDetails;
};

const DisputeOrderModal: React.VFC<DisputeOrderModalProps> = ({order, isOpen, onClose}) => {
    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit
    } = useDisputeOrderModal({
        orderId: order.orderId,
        isOpen,
        onClose
    });
    const {t} = useTranslation('orders');

    return (
        <Modal
            className={'flex flex-col overflow-y-auto h-full md:h-auto'}
            isOpen={isOpen}
            onClose={onClose}
            size={'lg'}
        >
            <h1 className={'font-normal'}>{t('disputeOrder')}</h1>

            <div className={'flex flex-col md:flex-row justify-between mt-10'}>
                <div className={'flex'}>
                    <img
                        className={'w-18 h-18 object-cover rounded'}
                        src={order.image ?? defaultAvatar}
                        alt={order.title}
                        aria-label={'Order image'}
                    />

                    <div className={'flex flex-col mx-5.5'}>
                        <h2 aria-label={'Seller name'}>{order.seller.username}</h2>

                        <p role={'paragraph'} className={'mt-1 text-xs text-gray-400'}>
                            {order.seller.email}
                        </p>

                        <span className={'mt-1 text-xs text-gray-300'}>
                            {t('bought', {date: dayjs(order.boughtAt).fromNow()})}
                        </span>
                    </div>
                </div>

                <div
                    className={
                        'w-full md:w-8/12 mt-2 md:mt-0 h-auto md:overflow-y-auto md:max-h-72'
                    }
                >
                    {order?.products?.map((product: Order.Product) => (
                        <OfferView
                            size={'sm'}
                            variant={'profile'}
                            product={product}
                            key={product.productId}
                            showDetails={false}
                        />
                    ))}
                </div>
            </div>

            <form className={'mt-2'} onSubmit={handleSubmit}>
                <label className={'text-sm'} htmlFor={'description'}>
                    {t('disputeDescription')}
                </label>

                <TextArea
                    id={'description'}
                    name={'description'}
                    className={'mt-4'}
                    value={values.description ?? ''}
                    onChange={handleChange}
                    error={touched.description && errors.description}
                    maxLength={300}
                />

                <div className={'flex justify-center mt-4'}>
                    <Button type={'submit'} variant={'primary'} disabled={isSubmitting}>
                        {t('dispute')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default DisputeOrderModal;
