//Hooks
import {useTranslation} from 'react-i18next';
import {useForgotPasswordView} from './ForgotPasswordRequestView.hook';

//Components
import {Link} from '@reach/router';
import Button from 'components/ui/Button';
import Input from 'components/ui/Input';

//Types
import {RouteComponentProps} from '@reach/router';

const ForgotPasswordRequestView: React.FC<RouteComponentProps> = () => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useForgotPasswordView();

    const {t} = useTranslation(['auth', 'common']);

    return (
        <div className={'px-4 md:px-8 py-5 md:py-10 h-full flex flex-col'}>
            <h2 className={'py-2.5'}>{t('auth:forgotYourPassword')}</h2>

            <span className={'text-xs tracking-tighter text-gray-900'}>
                {t('auth:forgotYourPasswordDescription')}
            </span>

            <form onSubmit={handleSubmit} className={'flex-grow flex flex-col justify-between'}>
                <div className={'md:w-7/12 mt-8'}>
                    <Input
                        id={'email'}
                        name={'email'}
                        label={t('auth:emailAddress')}
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && errors.email}
                        disabled={!!values.phone?.length}
                    />

                    <span className={'block mt-5 tracking-tighter text-gray-900 text-form'}>
                        {t('common:or')}
                    </span>

                    <Input
                        id={'phone'}
                        name={'phone'}
                        className={'mt-5'}
                        label={t('auth:phoneNumber')}
                        value={values.phone}
                        onChange={handleChange}
                        error={touched.phone && errors.phone}
                        disabled={!!values.email?.length}
                    />
                </div>

                <div className={'flex flex-row justify-center md:mt-10 md:mb-0 my-14'}>
                    <Button
                        as={Link}
                        to={'/auth/login'}
                        className={'mx-4 hidden md:flex'}
                        variant={'primary'}
                        size={'sm'}
                        outline
                    >
                        {t('common:back')}
                    </Button>

                    <Button
                        className={'mx-4'}
                        variant={'primary'}
                        size={'sm'}
                        type={'submit'}
                        disabled={isSubmitting}
                    >
                        {t('common:send')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ForgotPasswordRequestView;
