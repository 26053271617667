//Components
import BasketOfferRow from '../BasketOfferRow';

//Hooks
import {usePaymentMethod} from 'hooks/basket';
import {useTranslation} from 'react-i18next';

//Types
import {Offer} from 'store/types';

type PaymentBasketSummaryViewProps = {
    children?: React.ReactNode;
};

const PaymentBasketSummaryView: React.FC<PaymentBasketSummaryViewProps> = ({children}) => {
    const {totalCost, payment, isExtras} = usePaymentMethod();
    const {t} = useTranslation(['offers', 'basket']);

    return (
        <div className={'p-5 pb-8 bg-white border-gray-300 border-1.5 lg:border-none'}>
            <h1 className={'hidden md:block font-semibold mb-6'}>{t('basket:yourBasket')}</h1>

            <ul>
                {isExtras && !!payment?.extraFeature ? (
                    <BasketOfferRow feature={payment?.extraFeature} />
                ) : (
                    payment?.offers.map((item: Offer.ProductListItem) => (
                        <BasketOfferRow key={item.id} offer={item} />
                    ))
                )}
            </ul>

            <div className={'flex mt-3 pt-4 justify-between border-gray-300 border-t-1.5'}>
                <h3>{t('basket:total')}</h3>

                <h3>
                    {t('offers:displayPrice', {
                        priceFrom:
                            isExtras && !!payment?.extraFeature
                                ? payment?.extraFeature?.price
                                : totalCost
                    })}
                </h3>
            </div>

            {children}
        </div>
    );
};

export default PaymentBasketSummaryView;
