//Hooks
import {usePaginationView} from './PaginationView.hook';
import {useTranslation} from 'react-i18next';

//Components
import Input, {InputIcon} from 'components/ui/Input';
import Icon from 'components/ui/Icon';

//Utils
import cx from 'classnames';

export type PaginationViewProps = {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
};

const PaginationView: React.VFC<PaginationViewProps> = ({
    currentPage,
    totalPages,
    onPageChange
}) => {
    const {value, handleChange, handleKeyDown, handleSubmit} = usePaginationView({
        currentPage,
        totalPages,
        onPageChange
    });

    const {t} = useTranslation('common');

    return (
        <div className={'flex flex-row items-center'} role={'region'} aria-label={'Pagination'}>
            <button
                className={'p-3 focus:outline-none'}
                onClick={() => {
                    if (currentPage === 1) {
                        return;
                    }
                    return onPageChange(currentPage - 1);
                }}
                aria-label={'Previous page'}
            >
                <Icon
                    className={cx({'text-gray-300': currentPage === 1}, 'transform rotate-180')}
                    icon={'chevron-right'}
                    size={'sm'}
                />
            </button>

            <Input
                className={'w-24 ml-3 mr-5 bg-white'}
                type={'number'}
                value={value?.toString()}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                showControls={false}
                isPagination={true}
            >
                <InputIcon placement={'right'}>
                    <button
                        className={'appearance-none py-3 focus:outline-none'}
                        onClick={handleSubmit}
                        aria-label={'Submit'}
                    >
                        <Icon icon={'search'} size={'md'} />
                    </button>
                </InputIcon>
            </Input>

            <span
                className={'tracking-tighter text-sm mr-3'}
                role={'note'}
                aria-label={'Total pages'}
            >
                {t('of', {number: totalPages})}
            </span>

            <button
                className={'p-3 focus:outline-none'}
                onClick={() => {
                    if (currentPage === totalPages) {
                        return;
                    }
                    return onPageChange(currentPage + 1);
                }}
                aria-label={'Next page'}
            >
                <Icon
                    className={cx({'text-gray-300': currentPage === totalPages})}
                    icon={'chevron-right'}
                    size={'sm'}
                />
            </button>
        </div>
    );
};

export default PaginationView;
