//Hooks
import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Types
import {Offer} from 'store/types';

export const useFavorite = (offer?: Offer.ListItem) => {
    const favoriteId = useSelector(
        state =>
            state.favorites.favorites.find(({offerId}) => offerId === offer?.id)?.favoriteId
    );
    const dispatch = useDispatch();

    const [isFavorite, setFavorite] = useState(false);
    const [isFavoriteDisabled, setFavoriteDisabled] = useState(false);

    useEffect(() => {
        setFavorite(!!favoriteId);
    }, [favoriteId]);

    const onFavoriteToggle = useCallback(async () => {
        if (!offer) {
            return;
        }

        try {
            setFavoriteDisabled(true);
            setFavorite(prev => !prev); //immediately update state for better UX

            if (favoriteId) {
                await dispatch(actions.removeFavorite.request({favoriteId}));
            } else {
                await dispatch(actions.addFavorite.request(offer));
            }
        } catch (err) {
            setFavorite(prevIsFavorite => !prevIsFavorite); // in case of error, revert state update

            if (err.message) {
                toast.error(err.message);
            }
        } finally {
            setFavoriteDisabled(false);
        }
    }, [offer, favoriteId, dispatch]);

    return {
        isFavorite,
        isFavoriteDisabled,
        onFavoriteToggle
    };
};
