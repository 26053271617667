//Hooks
import {useTranslation} from 'react-i18next';
import {useCustomFormFields} from './CustomFormFields.hook';

//Components
import Field from './Field';

const CustomFormFields: React.VFC = () => {
    const {customFormFields, shouldDisplayCustomFields} = useCustomFormFields();
    const {t} = useTranslation('offers');
    if (shouldDisplayCustomFields && customFormFields) {
        return (
            <>
                <h2 className={'mt-10 text-sm md:text-lg font-semibold md:font-bold '}>
                    {t('additionalInfo')}
                </h2>

                <div className={'mt-7 w-50'}>
                    {Object.entries(customFormFields).map(([key, field]) => (
                        <Field key={key} name={key} field={field} />
                    ))}
                </div>
            </>
        );
    }
    return null;
};

export default CustomFormFields;
