export const validationErrorToShowInToast = (error: any): string | null => {
    if (error.errors?.basket?.products[0]?.product) {
        return error.errors?.basket?.products[0]?.product;
    }

    if (error.errors?.basket?.products[0]?.quantity) {
        return error.errors?.basket?.products[0]?.quantity;
    }

    if (error.errors?.additionalFirstError) {
        return error.errors?.additionalFirstError;
    }

    return null;
};
