//Hooks
import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import {useParams} from '@reach/router';
import {useSelector} from 'hooks/store';
import {useCoordinates} from '../coordinates.hook';

//Assets
import countryStates from 'assets/data/states';

//Types
import {Offer} from 'store/types';

export const useLocationSelector = () => {
    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue
    } = useFormikContext<Offer.Form>();

    const id = useParams()?.id;
    const {user} = useSelector(state => state.user);
    const {handleGetCoords} = useCoordinates();

    const states = Object.entries(countryStates).map(([value, label]) => ({
        value,
        label
    }));

    const selectedState = states.find(({value}) => values.address?.state === value);

    useEffect(() => {
        if (user && !id) {
            handleGetCoords(user.address);
        }
    }, [handleGetCoords, user, id]);

    return {
        states,
        selectedState,
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleGetCoords
    };
};
