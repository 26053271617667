//Hooks
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

//Types
import {Offer} from 'store/types';

export const useFeatures = (type: Offer.AdditionalOptions) => {
    const {t} = useTranslation(['common', 'offers']);

    const featureTitle = useMemo(() => {
        if (type === 'highlight') {
            return t('offers:promoteMyOffer');
        } else if (type === 'offerPeriodExtension') {
            return t('offers:extendValidTime');
        } else {
            return t('offers:addMorePhotos');
        }
    }, [t, type]);

    const featureText = useMemo(() => {
        if (type === 'highlight') {
            return t('offers:promoteMyOffer');
        } else if (type === 'offerPeriodExtension') {
            return t('offers:extendtheOffer');
        } else {
            return t('offers:addMorePhotos');
        }
    }, [t, type]);

    const featureDescription = useMemo(() => {
        if (type === 'highlight') {
            return t('offers:promoteYourOffer');
        } else if (type === 'offerPeriodExtension') {
            return t('offers:extendTheOfferBy');
        } else {
            return t('offers:addAdditionalPhotos');
        }
    }, [t, type]);

    return {featureTitle, featureText, featureDescription};
};
