//Action creators
import {createPromiseAction} from 'redux-saga-promise-actions';

//Types
import {User, Errors, Geocoding} from 'store/types';
/**
 * Gets geo coordinates of given address from Google Maps.
 */
export const getGeoCoordinates = createPromiseAction('GET_GEO_COORDINATES')<
    User.Address,
    Geocoding.Response,
    Errors.API
>();
