//Hooks
import {useEditMyPhoneModal} from './EditMyPhoneModal.hook';
import {useTranslation} from 'react-i18next';

//Components
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

export type EditMyPhoneModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
};

const EditMyPhoneModal: React.VFC<EditMyPhoneModalProps> = ({isOpen, onClose, onSubmit}) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useEditMyPhoneModal(onSubmit);
    const {t} = useTranslation('profile');

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <form className={'flex flex-col'} onSubmit={handleSubmit}>
                <Input
                    id={'phone'}
                    name={'phone'}
                    value={values.phone}
                    onChange={handleChange}
                    className={'mt-3.5'}
                    label={t('phone')}
                    error={touched.phone && errors.phone}
                />

                <Button
                    className={'mt-18 w-full'}
                    variant={'primary'}
                    type={'submit'}
                    disabled={isSubmitting}
                >
                    {t('save')}
                </Button>
            </form>
        </Modal>
    );
};

export default EditMyPhoneModal;
