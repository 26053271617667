//Components
import {Link} from '@reach/router';
import Icon from 'components/ui/Icon';

type MobileLinkProps = {
    to: string;
    children: string;
};

const MobileLink: React.VFC<MobileLinkProps> = ({to, children}) => (
    <Link
        to={to!}
        className={
            'border-b border-gray-200 py-3.5 text-sm font-semibold tracking-tighter flex flex-row justify-between items-center w-full'
        }
    >
        {children}

        <Icon icon={'arrow-right'} />
    </Link>
);

export default MobileLink;
