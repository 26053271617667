//Assets
import {ReactComponent as Favorite} from 'assets/icons/ui/favorite.svg';

//Styles
import Styles from './FavoriteButton.module.scss';

//Utils
import cx from 'classnames';

export type FavoriteButtonProps = {
    size?: 'md' | 'sm' | 'xs';
} & Omit<React.HTMLProps<HTMLInputElement>, 'size'>;

const FavoriteButton: React.VFC<FavoriteButtonProps> = ({
    checked,
    size = 'md',
    className,
    ...props
}) => (
    <div className={className}>
        <input
            {...props}
            className={cx(Styles.checkbox, 'hidden')}
            checked={checked}
            type={'checkbox'}
        />

        <label htmlFor={props.id} className={'cursor-pointer'}>
            <Favorite
                className={cx(
                    {
                        'w-4': size === 'xs',
                        'w-5': size === 'sm',
                        'w-8': size === 'md'
                    },
                    'fill-current'
                )}
                role={'img'}
                aria-label={'favorite'}
            />
        </label>
    </div>
);

export default FavoriteButton;
