import React from 'react';

//Utils
import cx from 'classnames';

export type TagProps = {
    size?: 'sm' | 'md';
    children: string | string[];
    className?: string;
};

const Tag: React.VFC<TagProps> = ({children, className, size = 'md'}) => (
    <span
        className={cx(
            {
                'text-xs-button tracking-tightest p-1.5': size === 'md',
                'text-xs-icon-button tracking-tighter p-0.5': size === 'sm'
            },
            'bg-gray-200 rounded inline-block',
            className
        )}
        role={'listitem'}
        aria-label={'Tag'}
    >
        {React.Children.count(children) === 1
            ? children
            : React.Children.map(children, (child, i) => (
                  <span key={i} className={'mx-2 first:ml-0 last:mr-0'}>
                      {child}
                  </span>
              ))}
    </span>
);

export default Tag;
