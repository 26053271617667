//Hooks
import {useTranslation} from 'react-i18next';
import {useRegisterMethodView} from './RegisterMethodView.hook';

//Components
import {Link} from '@reach/router';
import Radio from 'components/ui/Radio';
import RadioGroup from 'components/ui/RadioGroup';
import Button from 'components/ui/Button';

//Types
import {RouteComponentProps} from '@reach/router';

const RegisterMethodView: React.VFC<RouteComponentProps> = () => {
    const {values, handleChange, handleSubmit, isSubmitting} = useRegisterMethodView();

    const {t} = useTranslation('auth');

    return (
        <form className={'flex flex-col'} onSubmit={handleSubmit}>
            <h1 className={'text-lg font-semibold'}>{t('authentication')}</h1>

            <p className={'text-xs-checkbox mt-3.5'}>{t('authenticationDescription')}</p>

            <RadioGroup
                className={'flex-wrap mt-16 gap-y-8'}
                name={'authType'}
                value={values.authType}
                onChange={handleChange}
            >
                <Radio
                    id={'email'}
                    value={'email'}
                    label={t('sendEmailMessage', {email: values.account.data.email})}
                />

                <Radio
                    id={'sms'}
                    value={'sms'}
                    label={t('sendTextMessage', {phone: values.account.data.phone})}
                />
            </RadioGroup>

            <div className={'flex justify-center sm:justify-start gap-x-7'}>
                <Button
                    as={Link}
                    to={'/auth/register'}
                    className={'self-center mt-20 w-6/12 invisible sm:visible'}
                    variant={'primary'}
                    size={'sm'}
                    outline
                >
                    {t('back')}
                </Button>

                <Button
                    className={'self-center mt-20 w-60 sm:w-6/12'}
                    variant={'primary'}
                    type={'submit'}
                    disabled={isSubmitting}
                    size={'sm'}
                >
                    {t('send')}
                </Button>
            </div>
        </form>
    );
};

export default RegisterMethodView;
