//Action creators
import {createPromiseAction} from 'redux-saga-promise-actions';
import {createAction, createAsyncAction} from 'typesafe-actions';

//Types
import {Offer, Errors} from '../../types';

/**
 * Fetches offers of currently logged in user and provided status.
 */
export const getMyOffers = createAsyncAction(
    'GET_MY_OFFERS_REQUEST',
    'GET_MY_OFFERS_SUCCESS',
    'GET_MY_OFFERS_FAILURE'
)<
    Offer.GetMyOffersRequest,
    Offer.ListResponse & {status: Offer.Status},
    Errors.API & {status: Offer.Status}
>();

/**
 * Publishes an offer.
 */
export const publishOffer = createPromiseAction('PUBLISH_OFFER')<
    Offer.BaseListItem,
    undefined,
    Errors.API
>();

/**
 * Closes an offer.
 */
export const closeOffer = createPromiseAction('CLOSE_OFFER')<
    Offer.BaseListItem,
    undefined,
    Errors.API
>();

/**
 * Creates a new offer.
 */
export const createOffer = createPromiseAction('CREATE_OFFER')<
    Offer.CreateOfferRequest,
    Offer.CreateOfferResponse,
    Errors.API<Offer.CreateOfferRequest>
>();

/**
 * Fetches existing offer data before edit
 */
export const getEditedOffer = createAsyncAction(
    'GET_OFFER_REQUEST',
    'GET_OFFER_SUCCESS',
    'GET_OFFER_FAILURE'
)<number, Offer.ListItem, Errors.API>();

/**
 * Edits an existing offer.
 */
export const editOffer = createPromiseAction('EDIT_OFFER')<
    Offer.EditOfferRequest,
    undefined,
    Errors.API<Offer.EditOfferRequest['data']>
>();

export const viewOffer = createPromiseAction('VIEW_OFFER')<
    {offerId: number; visits: number},
    undefined,
    Errors.API
>();

export const clearOffer = createAction('CLEAR_OFFER')();
