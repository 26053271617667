//Services
import axios from 'services/axios';

//Types
import {AxiosTransformer} from 'axios';
import {Constants} from 'store/types';

type ReceivedResponse = Constants.ConstantResponse & {
    categoryStats: {
        categoryId: number;
        nbOfOffers: number;
    }[];
};

export const transformResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    ({menu, categoryStats, ...constants}: ReceivedResponse) => ({
        ...constants,
        menu: menu.map(category => ({
            ...category,
            offersCount:
                categoryStats.find(stat => stat.categoryId === category.id)?.nbOfOffers ?? 0
        }))
    })
];
