//Hooks
import {useToastView} from './ToastView.hook';

//Components
import {Transition} from 'react-transition-group';
import Icon from 'components/ui/Icon';

//Utils
import cx from 'classnames';

//Types
import {Toast} from '../toast';

type ToastViewProps = {
    toast: Toast;
};

const ToastView: React.VFC<ToastViewProps> = ({toast}) => {
    const {ref, offset} = useToastView({toast});

    let icon: React.ReactNode | null = toast.icon;
    if (!icon) {
        switch (toast.type) {
            case 'success':
                icon = <Icon icon={'check-mark'} size={'lg'} />;
                break;
            case 'error':
                icon = <Icon icon={'close'} size={'lg'} />;
                break;
        }
    }

    return (
        <Transition in={toast.visible} timeout={0} appear>
            {state => (
                <div
                    ref={ref}
                    className={cx(
                        {
                            'opacity-100': state === 'entered'
                        },
                        {
                            'bg-secondary': toast.type === 'success',
                            'bg-red': toast.type === 'error',
                            'bg-gray-200': toast.type === 'loading' || toast.type === 'blank'
                        },
                        'absolute flex flex-row items-center p-4 w-full text-gray-900 rounded transition-all opacity-0'
                    )}
                    style={{transform: `translateY(${offset}px)`}}
                    role={toast.role}
                    aria-live={toast.ariaLive}
                >
                    {icon && <span className={'text-white'}>{icon}</span>}

                    <div className={'ml-4 flex-grow'}>
                        <span className={'text-white font-semibold'} role={'paragraph'}>
                            {toast.message}
                        </span>
                    </div>
                </div>
            )}
        </Transition>
    );
};

export default ToastView;
