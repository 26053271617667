import {Option} from 'store/types/version/option';

export const redirectedFromOtherVersion = (versions: Option[], current: Option): boolean => {
    if (!document.referrer || document.referrer === '') {
        return false;
    }

    let redirected = false;
    versions.forEach(version => {
        if (
            version.origin &&
            current.origin &&
            current.origin !== version.origin &&
            document.referrer.startsWith(version.origin)
        ) {
            redirected = true;
            return;
        }
    });
    return redirected;
};
