//Hooks
import {useState, useEffect, useCallback, useMemo} from 'react';
import {useNavigate} from '@reach/router';
import {useSelector, useDispatch} from 'hooks/store';
import {useCategories} from 'hooks/categories';
import {useFavorite} from 'hooks/favorite';
import {useTranslation} from 'react-i18next';
import {useDisplayPrice} from 'hooks/displayPrice';
import {useBasket} from 'hooks/basket';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Utils
import parsePhoneNumber from 'libphonenumber-js';

//Types
import {Categories} from 'store/types';

type OfferDetailsView = {
    id?: string;
};

export const useOfferDetailsView = ({id}: OfferDetailsView) => {
    const {data: categories} = useCategories();
    const user = useSelector(state => state.user.user);

    const {t} = useTranslation('offers');

    const isLoading = useSelector(state => state.offer.isLoading);
    const offer = useSelector(state => state.offer.data?.offer);
    const stats = useSelector(state => state.offer.data?.stats);
    const {data: seller, isLoaded: isSellerLoaded} = useSelector(
        state => state.visitedProfile.user
    );
    const isBuyable = useSelector(state => state.offer.data?.buyable);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isPhoneNumberVisible, setPhoneNumberVisible] = useState(false);
    const [isCreateReviewModalVisible, setCreateReviewModalVisible] = useState(false);
    const [isExtendOfferModalVisible, setExtendOfferModalVisible] = useState(false);

    const categoriesPath = useMemo<Categories.Category[]>(() => {
        if (!offer?.category || !categories) {
            return [];
        }

        return offer.category.path.map(i => categories.find(({id}) => i === id)!).reverse();
    }, [offer, categories]);

    const {isFavorite, isFavoriteDisabled, onFavoriteToggle} = useFavorite(offer);

    const displayPrice = useDisplayPrice({offer});

    const displayPhoneNumber = useMemo<string | undefined>(() => {
        if (!offer?.account) {
            return;
        }

        const phoneInternational = parsePhoneNumber(offer.account.phone)?.formatInternational();
        if (isPhoneNumberVisible) {
            return phoneInternational;
        }

        const index = phoneInternational?.indexOf(' ');
        const areaCode = phoneInternational?.substring(0, index);
        const phoneNumber = phoneInternational
            ?.substring(index! + 1)
            .replace(new RegExp('[0-9]', 'g'), 'X');

        return `${areaCode} ${phoneNumber}`;
    }, [offer?.account, isPhoneNumberVisible]);

    const contactUser = async () => {
        if (!user) {
            await navigate('/auth/login', {state: {from: `/offer/${id}`}});
            return;
        }
        const resp = await dispatch(
            actions.createConversation.request({
                offerId: offer!.id,
                userId: offer!.account!.id!
            })
        );
        await navigate(`/chat/${resp.conversation.id}`);
    };

    const togglePhoneNumberVisibility = useCallback(() => {
        setPhoneNumberVisible(!isPhoneNumberVisible);
    }, [isPhoneNumberVisible]);

    const openCreateReviewModal = () => setCreateReviewModalVisible(true);
    const closeCreateReviewModal = () => setCreateReviewModalVisible(false);

    const openExtendOfferModal = () => setExtendOfferModalVisible(true);
    const closeExtendOfferModal = () => setExtendOfferModalVisible(false);

    useEffect(() => {
        if (offer) {
            dispatch(actions.checkFavorites.request([offer]));
        }
    }, [offer, dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(actions.getOfferById.request(id));
            dispatch(actions.viewOffer.request({offerId: parseInt(id), visits: 1}));
        }
    }, [dispatch, id]);

    useEffect(() => {
        offer?.account?.id && dispatch(actions.getUserProfile.request(offer?.account?.id));
    }, [dispatch, offer?.account?.id]);

    const {isInBasket} = useBasket(offer);

    const offerQuantity = offer?.type === 'product' ? offer?.quantity ?? 0 : 0;

    const [counter, setCounter] = useState(offerQuantity > 0 ? 1 : 0);

    useEffect(() => {
        setCounter(offerQuantity > 0 ? 1 : 0);
    }, [offer, offerQuantity]);

    const onIncreaseQuantity = () => offerQuantity > counter && setCounter(counter + 1);

    const onDecreaseQuantity = () => counter > 1 && setCounter(counter - 1);

    const isOwnOffer = offer && offer.account?.email === user?.email;

    const addOfferToBasket = async () => {
        if (offer && offer.type === 'product' && !isOwnOffer) {
            try {
                dispatch(actions.addOfferToBasket.request({...offer, quantity: counter}));
                await toast.success(t('addedToBasket'));
            } catch (err) {
                if (err.message) {
                    toast.error(err.message);
                }
            }
        }
    };

    return {
        isLoading,
        isSellerLoaded,
        offer,
        stats,
        seller,
        counter,
        isBuyable,
        isOwnOffer,
        categoriesPath,
        isFavorite,
        isFavoriteDisabled,
        onFavoriteToggle,
        displayPrice,
        onIncreaseQuantity,
        onDecreaseQuantity,
        isOfferInBasket: isInBasket(),
        addOfferToBasket,
        displayPhoneNumber,
        isPhoneNumberVisible,
        togglePhoneNumberVisibility,
        isCreateReviewModalVisible,
        openCreateReviewModal,
        closeCreateReviewModal,
        isExtendOfferModalVisible,
        closeExtendOfferModal,
        openExtendOfferModal,
        contactUser,
        user
    };
};
