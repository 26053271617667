//Redux saga
import {call, put, takeLeading} from 'redux-saga/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';

//Types
import {AxiosResponse} from 'axios';
import {Adverts} from 'store/types';

export function* getAdvertisements() {
    try {
        const resp: AxiosResponse<Adverts.GetAdvertisementsResponse> = yield call(request, {
            method: 'GET',
            url: '/api/advertisements'
        });

        yield put(actions.getAdvertisements.success(resp.data.advertisements));
    } catch (err) {
        yield put(actions.getAdvertisements.failure(err));
    }
}

export const saga = [takeLeading(actions.getAdvertisements.request, getAdvertisements)];
