//Utils
import * as React from 'react';
import dayjs from 'dayjs';
import _ from 'lodash-es';
import {navigate} from '@reach/router';

//Hooks
import {useMessagesView} from './MessagesView.hook';
import {useChatView} from 'views/ChatView/ChatView.hook';
import {useTranslation} from 'react-i18next';

//Components
import Footer from 'components/nav/Footer';
import SearchForm from 'components/search/SearchForm';
import Button from 'components/ui/Button';
import Skeleton from 'react-loading-skeleton';
import PaginationView from 'components/ui/PaginationView';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';

//Types
import {RouteComponentProps} from '@reach/router';
import {Conversations} from 'store/types';

import {dateFormat} from 'localeApp';

const MessagesView: React.VFC<RouteComponentProps> = () => {
    const {
        conversations,
        getConversation,
        areConversationsLoading,
        onPageChange,
        contactAdmin
    } = useMessagesView();
    const {getParticipant} = useChatView({});
    const {t} = useTranslation('common');

    return (
        <>
            <div className={'flex flex-col flex-grow bg-gray-200'}>
                <SearchForm />

                <div className={'max-w-292 w-full m-auto flex justify-end my-6 px-6 lg:px-0'}>
                    <Button variant={'primary'} outline size={'sm'} onClick={contactAdmin}>
                        {t('contactAdmin')}
                    </Button>
                </div>

                <div
                    className={
                        'px-8 mb-6 h-full bg-white max-w-message w-full mx-auto min-h-chat overflow-scroll'
                    }
                >
                    {areConversationsLoading ? (
                        <>
                            {_.times(5, i => (
                                <div
                                    key={i}
                                    className={
                                        'py-6 border-b border-gray-200 last:border-0 flex gap-x-2 cursor-pointer relative'
                                    }
                                >
                                    <Skeleton circle={true} height={48} width={48} />

                                    <div className={'flex flex-col gap-y-1'}>
                                        <Skeleton width={150} />

                                        <Skeleton width={300} />
                                    </div>

                                    <Skeleton width={150} className={'absolute right-1'} />
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            {conversations?.items?.map(
                                (conversation: Conversations.Item, index: number) => (
                                    <div
                                        className={
                                            'py-6 border-b border-gray-200 last:border-0 flex gap-x-2 flex cursor-pointer relative'
                                        }
                                        key={index}
                                        onClick={() =>
                                            getConversation(conversation?.conversation?.id)
                                        }
                                    >
                                        <img
                                            src={
                                                getParticipant(false, conversation).avatarUrl ??
                                                defaultAvatar
                                            }
                                            className={'h-12 w-12 rounded-full object-cover'}
                                            alt={'user'}
                                            aria-label={'user avatar'}
                                        />

                                        <div className={'gap-y-0.5 flex flex-col w-6/12'}>
                                            <p
                                                className={'font-bold text-sm'}
                                                aria-label={'user name'}
                                            >
                                                {getParticipant(false, conversation).name ??
                                                    t('admin')}
                                            </p>

                                            {conversation?.conversation?.subjectName && (
                                                <p className={'text-sm'}>
                                                    {conversation?.conversation?.subjectName}
                                                </p>
                                            )}

                                            <p
                                                className={
                                                    'text-sm text-gray-400 overflow-ellipsis overflow-hidden'
                                                }
                                                aria-label={'last message'}
                                            >
                                                {conversation?.conversation?.lastMessagePrefix}
                                            </p>
                                        </div>

                                        <p
                                            className={'text-sm text-gray-400 absolute right-1'}
                                            aria-label={'last message timestamp'}
                                        >
                                            {conversation?.conversation?.lastMessageTime &&
                                                dayjs(
                                                    conversation.conversation.lastMessageTime.toString()
                                                ).format(`${dateFormat} HH:mm`)}
                                        </p>
                                    </div>
                                )
                            )}
                        </>
                    )}

                    {conversations?.items?.length === 0 && (
                        <div className={'py-20 px-12'}>
                            <p className={'text-2xl font-bold'}>{t('inboxEmpty')}</p>

                            <Button
                                variant={'primary'}
                                size={'xs'}
                                outline
                                onClick={() => navigate('/')}
                                className={'mt-10'}
                            >
                                {t('mainPage')}
                            </Button>
                        </div>
                    )}
                </div>

                <div className={'max-w-message w-full mx-auto mb-11'}>
                    <PaginationView
                        currentPage={conversations?.pagination?.currentPage!}
                        totalPages={conversations?.pagination?.totalPages!}
                        onPageChange={(page: number) => onPageChange(page)}
                    />
                </div>
            </div>

            <Footer />
        </>
    );
};

export default MessagesView;
