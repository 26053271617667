//Utils
import cx from 'classnames';

//Components
import Icon from '../../Icon';

export type ButtonProps = {
    id: string;
    icon: 'credit-card' | 'paypal';
    className?: string;
    children: string;
} & React.HTMLProps<HTMLInputElement>;

const Button: React.VFC<ButtonProps> = ({id, icon, checked, className, children, ...props}) => (
    <>
        <input id={id} type={'radio'} className={'hidden'} checked={checked} {...props} />

        <label
            htmlFor={id}
            className={cx(
                'flex flex-row items-center rounded-md h-15 w-50 focus:outline-none box-border cursor-pointer',
                {
                    'border-secondary border-2': checked,
                    'border border-gray-300 p-px': !checked
                },
                className
            )}
            aria-label={children}
            aria-labelledby={id}
            aria-selected={checked ? 'true' : 'false'}
        >
            <span className={'px-5 text-gray-400'}>
                <Icon icon={icon} />
            </span>

            <span className={'tracking-tighter text-gray-900 text-form'}>{children}</span>
        </label>
    </>
);

export default Button;
