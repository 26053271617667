//Services
import axios from 'services/axios';

//Types
import {AxiosTransformer} from 'axios';
import {User} from 'store/types';

type ReceivedProfile = {
    profile: Omit<User.Profile, 'lastLoggedIn'> & {lastLoggedIn: string};
};
export const transformResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    (data: any) => {
        if (!data.profile) return data;
        const {profile}: ReceivedProfile = data;
        return {
            ...profile,
            lastLoggedIn: profile.lastLoggedIn
                ? new Date(profile.lastLoggedIn)
                : profile.lastLoggedIn
        };
    }
];
