//Hooks
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {navigate} from '@reach/router';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

export const useLogout = () => {
    const dispatch = useDispatch();

    const handleLogout = useCallback(async () => {
        try {
            navigate('/');
            await dispatch(actions.logout.request());
        } catch (err) {
            toast.error(err.message);
        }
    }, [dispatch]);

    return {handleLogout};
};
