import {formatNumberEvent} from 'utils/input';
import {useFormikContext} from 'formik';

export const useInputHelperHook = () => {
    const {setFieldValue} = useFormikContext();

    const onBlur = (e: React.FormEvent<HTMLInputElement>) => {
        e = formatNumberEvent(e);
        setFieldValue(
            e.currentTarget.name,
            Number(e.currentTarget.value) || e.currentTarget.value
        );
    };

    return {onBlur};
};
