export enum NotificationId {
    OrderCanceled = 'ordercanceled',
    OrderConfirmed = 'orderconfirmed',
    NewChatMessage = 'newchatmessage',
    ExpiringOffer = 'expiringoffer'
}

interface BaseNotification {
    content: string;
    createdAt: Date;
    icon: string;
    id: string;
    incrementCounter: number;
    projectId: string;
    readAt?: string;
    readFromChannel: string;
    userId: string;
}
export interface ChatNotification extends BaseNotification {
    notificationId: NotificationId.NewChatMessage;
    data: {
        conversationId?: number;
        messageWriter?: string;
    };
}
export interface OrderNotification extends BaseNotification {
    notificationId: NotificationId.OrderCanceled | NotificationId.OrderConfirmed;
    data: {
        orderId?: number;
    };
}
export interface OfferNotification extends BaseNotification {
    notificationId: NotificationId.ExpiringOffer;
    data: {
        offerId?: number;
        offerTitle?: string;
    };
}

export type Notification = ChatNotification | OrderNotification | OfferNotification;

export interface Notifications {
    code: number;
    total: number;
    unread: number;
    messages: Notification[];
}
