//Redux saga
import {call} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions/staticPages';

//Sagas
import {request} from 'store/sagas/api';

//Types
import {AxiosResponse} from 'axios';
import {StaticPages} from 'store/types';

export function* loadStaticPages(action: ReturnType<typeof actions.loadStaticPages.request>) {
    const resp: AxiosResponse<StaticPages.StaticPagesResponse> = yield call(request, {
        method: 'GET',
        url: '/api/pages',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return resp.data.list;
}

export function* loadStaticPage(action: ReturnType<typeof actions.loadStaticPage.request>) {
    const resp: AxiosResponse<StaticPages.StaticPageResponse> = yield call(request, {
        method: 'GET',
        url: `/api/pages/${action.payload}`,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return resp.data.page;
}

export function* submitContactForm(
    action: ReturnType<typeof actions.submitContactForm.request>
) {
    const resp: AxiosResponse<StaticPages.ContactFormResponse> = yield call(request, {
        method: 'POST',
        url: `/api/contact`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: action.payload
    });

    return resp.data;
}

export const saga = [
    takeEveryPromiseAction(actions.loadStaticPages, loadStaticPages),
    takeEveryPromiseAction(actions.loadStaticPage, loadStaticPage),
    takeEveryPromiseAction(actions.submitContactForm, submitContactForm)
];
