//Hooks
import {useCategoryFilter} from './CategoryFilter.hook';
import {useTranslation} from 'react-i18next';

//Components
import CategoryPicker from 'components/ui/CategoryPicker';
import Section from '../Section';

import {isMobile} from 'react-device-detect';

const CategoryFilter: React.VFC = () => {
    const {categories, values, handleCategoryChange} = useCategoryFilter();

    const {t} = useTranslation('common');

    return (
        <Section title={t('category')}>
            <CategoryPicker
                className={'max-w-88 md:w-2/5 lg:w-full bg-white'}
                options={categories ?? []}
                value={values.category?.id ?? null}
                onChange={handleCategoryChange}
                expandOnHover={!isMobile}
            />
        </Section>
    );
};

export default CategoryFilter;
