//Translations
import auth from 'assets/translations/pl/auth.json';
import basket from 'assets/translations/pl/basket.json';
import common from 'assets/translations/pl/common.json';
import errors from 'assets/translations/pl/errors.json';
import nav from 'assets/translations/pl/nav.json';
import notifications from 'assets/translations/pl/notifications.json';
import staticPage from 'assets/translations/pl/static.json';
import offers from 'assets/translations/pl/offers.json';
import orders from 'assets/translations/pl/orders.json';
import payment from 'assets/translations/pl/payment.json';
import reviews from 'assets/translations/pl/reviews.json';
import search from 'assets/translations/pl/search.json';
import stats from 'assets/translations/pl/stats.json';
import profile from 'assets/translations/pl/profile.json';
import validation from 'assets/translations/pl/validation.json';

const pl = {
    auth,
    basket,
    common,
    errors,
    nav,
    notifications,
    offers,
    orders,
    profile,
    payment,
    reviews,
    search,
    staticPage,
    stats,
    validation
};

export default pl;
