//Hooks
import {useEffect, useState, useCallback, useRef} from 'react';
import {useSelector} from 'hooks/store';
import {useDispatch} from 'react-redux';

//Actions
import * as actions from 'store/actions';

//Types
import {Message} from 'twilio-chat/lib/message';
import {Channel} from 'twilio-chat/lib/channel';
import {Conversations} from 'store/types';

const Chat = require('twilio-chat');

export type UseChatViewProps = {
    id?: number;
};

export const useChatView = ({id}: UseChatViewProps) => {
    const {data: chat, isLoading: isConversationLoading} =
        useSelector(state => state.conversations.conversation) || {};
    const client = useSelector(state => state.conversations.client);
    const [messages, setMessages] = useState<Message[]>([]);
    const [channel, setChannel] = useState<Channel>();
    const [newMessage, setNewMessage] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const chatRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const scrollToBottom = () => {
        const scrollHeight = chatRef?.current?.scrollHeight;
        const height = chatRef?.current?.clientHeight;
        const maxScrollTop = scrollHeight! - height!;
        if (chatRef?.current) {
            chatRef!.current!.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    };

    const joinChannel = useCallback(async (channel: Channel) => {
        if (channel?.status !== 'joined') {
            await channel?.join();
        }

        setChannel(channel);

        channel?.on('messageAdded', async (message: any) => {
            const messages = await channel?.getMessages();
            setMessages(messages?.items!);
        });
    }, []);

    const sendMessage = () => {
        channel?.sendMessage(newMessage);
        dispatch(
            actions.getLastMessage.request({
                message: newMessage,
                conversationId: chat?.conversation.id
            })
        );
        setNewMessage('');
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setNewMessage(e.target.value);

    const getParticipant = (tokenOwner: boolean = true, data = chat) => {
        const participant = data?.conversation.participants?.filter(
            (participant: Conversations.Participant) =>
                tokenOwner
                    ? participant.externalUid === data.tokenOwnerExternalUid
                    : participant.externalUid !== data.tokenOwnerExternalUid
        );

        return Object.assign({}, ...participant!);
    };

    const connectToChannel = useCallback(async () => {
        setLoading(true);
        const channel = await client?.getChannelBySid(chat?.conversation?.externalUid!);
        const messages = await channel?.getMessages();
        setMessages(messages?.items!);
        joinChannel(channel!);
        setLoading(false);
    }, [client, chat?.conversation?.externalUid, joinChannel]);

    const initializeChat = useCallback(async () => {
        setLoading(true);
        const client = await Chat.Client.create(chat?.accessToken);
        dispatch(actions.setClient(client));
        setLoading(false);
    }, [dispatch, chat?.accessToken]);

    useEffect(() => {
        if (chat) {
            if (!client) {
                initializeChat();
            } else {
                connectToChannel();
            }
        }
    }, [chat, client, connectToChannel, initializeChat]);

    useEffect(() => {
        id && dispatch(actions.getConversation.request(id));
    }, [id, dispatch]);

    useEffect(() => {
        scrollToBottom();
    });

    return {
        chat,
        messages,
        sendMessage,
        newMessage,
        getParticipant,
        handleMessageChange,
        isConversationLoading,
        loading,
        chatRef
    };
};
