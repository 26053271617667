//Hooks
import {useContactView} from './ContactView.hook';
import {useTranslation} from 'react-i18next';

//Components
import ContactInfoModal from 'components/modal/ContactInfoModal';
import TextInput from 'components/ui/Input';
import TextArea from 'components/ui/TextArea';
import Button from 'components/ui/Button';
import Select from 'react-select';

//Styles
import {errorStyles, styles, theme} from 'styles/select';

//Types
import {RouteComponentProps} from '@reach/router';

const ContactView: React.VFC<RouteComponentProps> = () => {
    const {
        values,
        touched,
        errors,
        modalOpen,
        setModalOpen,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        subjectOptions
    } = useContactView();

    const {t} = useTranslation(['common', 'staticPage']);

    return (
        <>
            <form className={'flex flex-col'} onSubmit={handleSubmit}>
                <h1>{t('staticPage:contactForm')}</h1>

                <div className={'mt-3.5 w-full'} role={'region'} aria-label={'values.state'}>
                    <Select
                        id={'subject'}
                        name={'subject'}
                        onChange={option => setFieldValue('subject', option?.value)}
                        options={subjectOptions}
                        placeholder={t('staticPage:chooseTheSubject')}
                        className={'mt-6'}
                        styles={touched.subject && errors.subject ? errorStyles : styles}
                        aria-label={'subject'}
                        theme={theme}
                    />

                    {touched.subject && errors.subject && (
                        <span
                            className={'tracking-tight text-red text-form-helper'}
                            role={'alert'}
                            aria-label={`error`}
                        >
                            {errors.subject}
                        </span>
                    )}
                </div>

                <TextInput
                    id={'emailAddress'}
                    name={'emailAddress'}
                    value={values.emailAddress}
                    onChange={handleChange}
                    className={'mt-3.5'}
                    placeholder={t('staticPage:yourEmailAddress')}
                    error={touched.emailAddress && errors.emailAddress}
                />

                <TextArea
                    id={'message'}
                    name={'message'}
                    value={values.message}
                    onChange={handleChange}
                    className={'mt-3.5'}
                    rows={6}
                    placeholder={t('staticPage:writeUsTheMessage')}
                    maxLength={10000}
                    error={touched.message && errors.message}
                />

                <Button
                    className={'self-end mt-5'}
                    variant={'primary'}
                    type={'submit'}
                    disabled={isSubmitting}
                    outline
                    size={'xs'}
                >
                    {t('common:send')}
                </Button>
            </form>

            <ContactInfoModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
        </>
    );
};

export default ContactView;
