//Hooks
import {useTranslation} from 'react-i18next';
import {useWindowSize} from 'hooks/windowSize';
import {useFormikContext} from 'formik';
import {useInputHelperHook} from 'hooks/input';

//Components
import Options from 'components/ui/Options';
import Input, {InputIcon} from 'components/ui/Input';

//Types
import {Offer} from 'store/types';

const CreateServiceForm: React.VFC = () => {
    const {t} = useTranslation(['offers', 'common']);
    const {breakpoint} = useWindowSize();
    const {values, errors, touched, handleChange} = useFormikContext<Offer.Form>();

    const {onBlur} = useInputHelperHook();

    return (
        <>
            <h2 className={'mt-12 hidden md:flex'} id={'price'}>
                {t('offers:price')}
            </h2>

            <div className={'md:mt-3.5 mt-8 md:w-86'}>
                <Options
                    name={'offer.priceVariant'}
                    value={values.offer.priceVariant ?? 'fixed'}
                    onChange={handleChange}
                >
                    <Options.Option
                        label={t(
                            breakpoint === 'xs' || breakpoint === 'sm'
                                ? 'offers:priceFixed'
                                : 'offers:fixed'
                        )}
                        id={'price-fixed'}
                        value={'fixed'}
                    />

                    <Options.Option
                        label={t(
                            breakpoint === 'xs' || breakpoint === 'sm'
                                ? 'offers:priceRange'
                                : 'offers:range'
                        )}
                        id={'price-range'}
                        value={'range'}
                    />
                </Options>

                {values.offer.priceVariant === Offer.PriceVariant.Fixed ? (
                    <Input
                        id={'offer.priceFrom.tbbcAmount'}
                        name={'offer.priceFrom.tbbcAmount'}
                        className={'mt-3.5'}
                        value={values.offer.priceFrom?.tbbcAmount?.toString() ?? ''}
                        type={'text'}
                        showControls={false}
                        onChange={handleChange}
                        onBlur={onBlur}
                        error={
                            touched?.offer?.priceFrom?.tbbcAmount &&
                            errors?.offer?.priceFrom?.tbbcAmount
                        }
                        aria-labelledby={'price'}
                    >
                        <InputIcon placement={'right'}>
                            <span className={'text-gray-600 text-form'}>
                                {t('common:currencyShort')}
                            </span>
                        </InputIcon>
                    </Input>
                ) : (
                    <div
                        className={
                            'flex flex-row items-end tracking-tighter text-gray-800 mt-3.5 text-form'
                        }
                    >
                        <label htmlFor={'offer.priceFrom.tbbcAmount'}>{t('common:from')}</label>

                        <Input
                            id={'offer.priceFrom.tbbcAmount'}
                            name={'offer.priceFrom.tbbcAmount'}
                            className={'ml-2 w-30 mr-11'}
                            value={values.offer.priceFrom?.tbbcAmount?.toString() ?? ''}
                            onChange={handleChange}
                            onBlur={onBlur}
                            error={
                                touched?.offer?.priceFrom?.tbbcAmount &&
                                errors?.offer?.priceFrom?.tbbcAmount
                            }
                            showControls={false}
                            type={'text'}
                        >
                            <InputIcon placement={'right'}>
                                <span className={'text-gray-600 text-form'}>
                                    {t('common:currencyShort')}
                                </span>
                            </InputIcon>
                        </Input>

                        <label htmlFor={'offer.priceTo.tbbcAmount'}>{t('common:to')}</label>

                        <Input
                            id={'offer.priceTo.tbbcAmount'}
                            name={'offer.priceTo.tbbcAmount'}
                            className={'ml-2 w-30'}
                            value={values.offer.priceTo?.tbbcAmount?.toString() ?? ''}
                            onChange={handleChange}
                            onBlur={onBlur}
                            error={
                                touched?.offer?.priceTo?.tbbcAmount &&
                                errors?.offer?.priceTo?.tbbcAmount
                            }
                            showControls={false}
                            type={'text'}
                        >
                            <InputIcon placement={'right'}>
                                <span className={'text-gray-600 text-form'}>
                                    {t('common:currencyShort')}
                                </span>
                            </InputIcon>
                        </Input>
                    </div>
                )}
            </div>
        </>
    );
};

export default CreateServiceForm;
