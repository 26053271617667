//Hooks
import {useNotificationsView} from './NotificationsView.hook';
import {useTranslation} from 'react-i18next';
import {navigate} from '@reach/router';

//Components
import Footer from 'components/nav/Footer';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import SearchForm from 'components/search/SearchForm';
import Notification from 'components/ui/Notification';
import Button from 'components/ui/Button';

//Types
import {RouteComponentProps} from '@reach/router';
import {Notifications} from 'store/types';

const MessagesView: React.VFC<RouteComponentProps> = () => {
    const {notifications} = useNotificationsView();
    const {t} = useTranslation('common');

    return (
        <>
            <div className={'flex flex-col flex-grow bg-gray-200'}>
                <div className={'hidden md:block'}>
                    <SearchForm />
                </div>

                <div
                    className={
                        'px-4 my-6 md:max-h-96 max-w-292 w-full mx-auto overflow-auto flex flex-col gap-y-3'
                    }
                >
                    {notifications?.map((notification: Notifications.Notification) => (
                        <Notification key={notification?.id} notification={notification} full />
                    ))}

                    {notifications?.length === 0 && (
                        <div className={'py-20 px-12 bg-white'}>
                            <p className={'text-2xl font-bold'}>{t('notificationsEmpty')}</p>

                            <Button
                                variant={'primary'}
                                size={'xs'}
                                outline
                                onClick={() => navigate('/')}
                                className={'mt-10'}
                            >
                                {t('mainPage')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <Footer />

            <MobileBottomNav />
        </>
    );
};

export default MessagesView;
