//Hooks
import {useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Types
import {Files} from 'store/types';

export const useFileUploader = (
    value: Files.UploadedPhoto[],
    onChange: (e: Files.UploadedPhoto[]) => void
) => {
    const dispatch = useDispatch();

    const onFileUpload = async (files: Files.Photo[]) => {
        try {
            const uploadedFiles = await Promise.all(
                files.map(async file => {
                    const {fileId} = await dispatch(actions.uploadFile.request(file));
                    return {...file, fileId};
                })
            );

            onChange([...value, ...uploadedFiles]);
        } catch (err) {
            if (err.errors?.file) {
                toast.error(err.errors?.file);
            } else {
                toast.error(err.message);
            }
        }
    };

    const onFileDelete = async (deletedPhoto: Files.UploadedPhoto) => {
        try {
            await dispatch(actions.deleteFile.request(deletedPhoto));
            onChange(value.filter(photo => photo !== deletedPhoto));
        } catch (err) {
            toast.error(err.message);
        }
    };

    return {
        onFileUpload,
        onFileDelete
    };
};
