//Hooks
import {useStaticPageView} from './StaticPageView.hook';

// Components
import Skeleton from 'react-loading-skeleton';

//Types
import {RouteComponentProps} from '@reach/router';

const StaticPageView: React.FC<RouteComponentProps> = ({path}) => {
    const {isLoading, isLoaded, staticPage} = useStaticPageView(path);

    return (
        <>
            {isLoaded && staticPage.slug === path && (
                <>
                    <h1 aria-label={'Page title'}>{staticPage.title}</h1>

                    <p
                        className={'mt-7'}
                        aria-label={'Page content'}
                        dangerouslySetInnerHTML={{__html: staticPage.content}}
                    />
                </>
            )}

            {(isLoading || staticPage?.slug !== path) && (
                <div role={'region'} aria-label={'Loading'}>
                    <Skeleton height={24} className={'mb-7'} />

                    <Skeleton count={10} />
                </div>
            )}
        </>
    );
};

export default StaticPageView;
