//Hooks
import {useCallback} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from '@reach/router';

//Utils
import qs from 'querystring';
import _ from 'lodash-es';

//Types
import {Search} from 'store/types';

export const useLocalFilter = () => {
    const {t} = useTranslation('search');
    const navigate = useNavigate();
    const {values} = useFormikContext<Search.FormValues>();

    const search = qs.parse(useLocation().search.substring(1));
    const local = !!search['local'];
    const locationDenied = local && !values.geoDistance;

    const locationOptions = Object.values(Search.Location).map(value => ({
        value,
        label: t(value)
    }));

    const handleLocationChange = useCallback(
        option => {
            const query = _.pickBy(
                {
                    phrase: !!values.phrase?.length ? values.phrase : undefined,
                    categoryId: values.category?.id,
                    local: option.value === Search.Location.Local ? true : undefined
                },
                _.identity
            );
            navigate(`/search?${qs.stringify(query)}`);
        },
        [values, navigate]
    );

    return {locationOptions, locationDenied, values, handleLocationChange};
};
