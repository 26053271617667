import {locale} from 'localeApp';

export const formatPhone = (phone: string | undefined = undefined) => {
    if (!phone) {
        return phone;
    }
    if (phone.includes('+')) {
        return phone;
    }
    if (locale === 'us') {
        return `+1${phone}`;
    }
    if (locale === 'pl') {
        return `+48${phone}`;
    }
    return phone;
};

export const formatZipCode = (zipCode: string | undefined = undefined): string | undefined => {
    if (!zipCode) {
        return zipCode;
    }

    zipCode = zipCode.replace(/\s/g, '');

    if (locale === 'pl' && !zipCode.includes('-')) {
        return zipCode.substr(0, 2) + '-' + zipCode.substr(2, zipCode.length - 1);
    }

    return zipCode;
};
