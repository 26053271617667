import * as React from 'react';

//Hooks
import {useInput} from './Input.hook';
import {useTranslation} from 'react-i18next';

//Components
import InputIcon from './Icon';

//Styles
import Styles from './Input.module.scss';

//Utils
import cx from 'classnames';
import {formatNumberEvent} from 'utils/input';

//Types
import {InputIconProps} from './Icon';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

type StringInputProps = {
    maxLength?: number;
    step?: undefined;
    type?: 'text' | 'password';
    showControls?: undefined;
};

type NumberInputProps = {
    maxLength?: undefined;
    step?: number;
    type: 'number';
    showControls?: boolean;
};

export type InputProps = {
    value?: string;
    label?: string;
    tooltip?: string;
    error?: string | undefined | null | false;
    className?: string;
    chat?: boolean;
    sendMessage?: () => void;
    inputClassName?: string;
    isPagination?: boolean;
    children?:
        | React.ReactElement<InputIconProps, typeof InputIcon>
        | React.ReactElement<InputIconProps, typeof InputIcon>[]
        | false;
} & (StringInputProps | NumberInputProps) &
    Omit<
        React.HTMLProps<HTMLInputElement>,
        'value' | 'label' | 'maxLength' | 'type' | 'children'
    >;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value,
            label,
            error,
            className,
            inputClassName,
            disabled,
            maxLength,
            type,
            step,
            tooltip,
            children,
            onChange,
            chat,
            sendMessage,
            showControls = true,
            isPagination,
            ...props
        },
        ref
    ) => {
        const {
            isPasswordVisible,
            togglePasswordVisibility,
            inputRef,
            incrementValue,
            decrementValue,
            onFocus,
            onBlur
        } = useInput(ref, type);

        const {t} = useTranslation('common');

        return (
            <div
                className={cx('flex flex-col', className)}
                role={'region'}
                aria-label={props.name}
            >
                {label && (
                    <div className={'flex mb-3 justify-between items-center'}>
                        <label className={'text-sm'} htmlFor={props.id}>
                            {label}
                        </label>

                        {tooltip && (
                            <Tooltip id={'title-tooltip'}>
                                <Tooltip.Wrapper>
                                    <Icon
                                        data-tip
                                        data-for={'title-tooltip'}
                                        icon={'info'}
                                        className={'text-gray-400'}
                                    />
                                </Tooltip.Wrapper>

                                <Tooltip.Content className={'w-56 px-6'}>
                                    {tooltip}
                                </Tooltip.Content>
                            </Tooltip>
                        )}
                    </div>
                )}

                <div
                    className={cx(
                        'flex flex-row items-center bg-white',
                        {relative: chat},
                        inputClassName
                    )}
                >
                    {showControls && type === 'number' && (
                        <button
                            className={'cursor-pointer mr-2.5 focus:outline-none'}
                            onClick={decrementValue}
                            aria-label={'decrement'}
                            type={'button'}
                        >
                            <Icon icon={'minus'} size={'lg'} />
                        </button>
                    )}

                    <div
                        className={cx(
                            Styles.inputContainer,
                            {'bg-gray-200': disabled},
                            {
                                'border-red': error,
                                'border-gray-300 focus-within:border-primary': !error
                            },
                            'flex flex-row items-center px-2 flex-auto focus-within:ring-4 ring-primary ring-opacity-50 rounded border box-border transition'
                        )}
                    >
                        {React.Children.map(children, child => {
                            if (
                                !child ||
                                (child as React.ReactElement<InputIconProps, typeof InputIcon>)
                                    .props.placement !== 'left'
                            ) {
                                return null;
                            }

                            return React.cloneElement(
                                child as React.ReactElement<InputIconProps, typeof InputIcon>,
                                {
                                    role: 'figure',
                                    className: cx(
                                        'flex items-center justify-center h-12 px-2',
                                        (child as React.ReactElement<
                                            InputIconProps,
                                            typeof InputIcon
                                        >).props.className
                                    )
                                }
                            );
                        })}

                        <input
                            ref={inputRef}
                            className={cx(
                                {'text-center': type === 'number' && showControls},
                                {'bg-gray-200': disabled},
                                {'pr-16': chat},
                                {'px-3 text-right': isPagination},
                                {'px-4': !isPagination},
                                'h-12 text-sm tracking-tight text-gray-900 placeholder-gray-600 transition focus:outline-none w-full rounded'
                            )}
                            value={value}
                            disabled={disabled}
                            maxLength={maxLength}
                            onChange={e => {
                                if (onChange && type === 'number') {
                                    onChange(formatNumberEvent(e));
                                } else if (onChange) {
                                    onChange(e);
                                }
                            }}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            type={type === 'password' && isPasswordVisible ? 'text' : type}
                            step={step}
                            {...props}
                        />

                        {React.Children.map(children, child => {
                            if (
                                !child ||
                                (child as React.ReactElement<InputIconProps, typeof InputIcon>)
                                    .props.placement !== 'right'
                            ) {
                                return null;
                            }

                            return React.cloneElement(
                                child as React.ReactElement<InputIconProps, typeof InputIcon>,
                                {
                                    role: 'figure',
                                    className: cx(
                                        'flex items-center justify-center h-12 px-2',
                                        (child as React.ReactElement<
                                            InputIconProps,
                                            typeof InputIcon
                                        >).props.className
                                    )
                                }
                            );
                        })}

                        {type === 'password' && (
                            <button
                                className={
                                    'flex items-center justify-center h-12 px-2 text-sm text-gray-600 focus:outline-none'
                                }
                                onClick={togglePasswordVisibility}
                                aria-label={'show-password'}
                                type={'button'}
                            >
                                {t(isPasswordVisible ? 'hide' : 'show')}
                            </button>
                        )}
                    </div>

                    {showControls && type === 'number' && (
                        <button
                            className={'cursor-pointer ml-2.5 focus:outline-none'}
                            onClick={incrementValue}
                            aria-label={'increment'}
                            type={'button'}
                        >
                            <Icon icon={'plus'} size={'lg'} />
                        </button>
                    )}

                    {chat && (
                        <button
                            className={
                                'flex items-center justify-center h-12 pl-2 pr-4 text-sm focus:outline-none text-primary font-semibold absolute right-0'
                            }
                            onClick={sendMessage}
                        >
                            {t('send')}
                        </button>
                    )}
                </div>

                {(!!error || !!maxLength) && (
                    <div
                        className={cx(
                            {'justify-between': !!error, 'justify-end': !error},
                            {'mx-8': !!step},
                            'flex flex-row mt-2.5 text-form-helper'
                        )}
                    >
                        {error && (
                            <span
                                className={'tracking-tight text-red'}
                                role={'alert'}
                                aria-label={'error'}
                            >
                                {error}
                            </span>
                        )}

                        {maxLength && type !== 'text' && (
                            <span
                                className={'tracking-tight text-gray-600'}
                                role={'status'}
                                aria-label={'length'}
                            >
                                {`${value?.length ?? 0}/${maxLength}`}
                            </span>
                        )}
                    </div>
                )}
            </div>
        );
    }
);

export default Input;

export {InputIcon};
