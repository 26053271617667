//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Search} from 'store/types';

export type SearchAction = ActionType<typeof actions>;

export type State = Readonly<{
    result: Data<Search.Result, Search.FormValues>;
    popularSearches?: Data<any>;
}>;

export const initialState: State = {
    result: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    popularSearches: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    }
};

export default createReducer<State, SearchAction>(initialState)
    .handleAction(actions.search.request, (state, action) => ({
        ...state,
        result: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.result.data,
            props: action.payload
        }
    }))
    .handleAction(actions.search.success, (state, action) => ({
        ...state,
        result: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload,
            props: state.result.props
        }
    }))
    .handleAction(actions.search.failure, state => ({
        ...state,
        result: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null,
            props: state.result.props
        }
    }))
    .handleAction(actions.getConstants.request, state => ({
        ...state,
        popularSearches: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state?.popularSearches?.data
        }
    }))
    .handleAction(actions.getConstants.success, (state, action) => ({
        ...state,
        popularSearches: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload.popularSearches
        }
    }))
    .handleAction(actions.getConstants.failure, state => ({
        ...state,
        popularSearches: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }));
