//Hooks
import {useBaseForm} from './BaseForm.hook';
import {useTranslation} from 'react-i18next';

//Components
import Input, {InputIcon} from 'components/ui/Input';
import Tooltip from 'components/ui/Tooltip';
import Icon from 'components/ui/Icon';
import TextArea from 'components/ui/TextArea';
import CategoryPicker from 'components/ui/CategoryPicker';
import FileUploader from 'components/ui/FileUploader';
import Button from 'components/ui/Button';

//Types
import {Offer, Files} from 'store/types';

import {isMobile} from 'react-device-detect';

export type CreateBaseFormProps = {
    uploadedFiles: Files.UploadedPhoto[];
    setUploadedFiles: (files: Files.UploadedPhoto[]) => void;
    openExtendImageModal: (noteClick?: boolean) => void;
};

const CreateBaseForm: React.VFC<CreateBaseFormProps> = ({
    uploadedFiles,
    setUploadedFiles,
    openExtendImageModal
}) => {
    const {
        categories,
        areCategoriesLoaded,
        values,
        errors,
        touched,
        isEditable,
        existingOffer,
        handleChange,
        handleFilesUpload,
        handleCategoryChange,
        handleSaveBeforeExtraPhotos
    } = useBaseForm({setUploadedFiles});

    const {t} = useTranslation(['offers', 'common']);

    return (
        <>
            <h2 className={'mt-10 hidden md:flex'}>{t('offers:mainInfo')}</h2>

            <FileUploader
                value={values.offer.images}
                onChange={handleFilesUpload}
                name={'offer.images-sm'}
                className={'md:hidden'}
                multiple
                maxFiles={existingOffer?.features?.extraPhotos ? 40 : 5}
            >
                {(!isEditable || !existingOffer?.features?.extraPhotos) && (
                    <div
                        className={
                            'h-42 flex flex-col items-center justify-around border-gray-300 border rounded p-6'
                        }
                    >
                        <h2 className={'text-xs text-secondary'}>
                            {t('offers:buyAdditionalPhotos')}
                        </h2>

                        <Button
                            onClick={() => {
                                if (!isEditable) {
                                    handleSaveBeforeExtraPhotos(Offer.Status.Draft);
                                }
                                openExtendImageModal(!isEditable);
                            }}
                            type={'button'}
                            variant={'secondary'}
                            size={'xs'}
                            outline
                        >
                            {t('offers:buy')}
                        </Button>
                    </div>
                )}
            </FileUploader>

            <Input
                id={'offer.title'}
                name={'offer.title'}
                className={'md:mt-5 mt-8'}
                label={t('offers:title')}
                value={values.offer.title}
                onChange={handleChange}
                error={touched.offer?.title && errors.offer?.title}
                maxLength={70}
            >
                <InputIcon placement={'right'} className={'hidden md:flex'}>
                    <Tooltip id={'title-tooltip'}>
                        <Tooltip.Wrapper>
                            <Icon
                                data-tip
                                data-for={'title-tooltip'}
                                icon={'info'}
                                className={'text-gray-400'}
                            />
                        </Tooltip.Wrapper>

                        <Tooltip.Content className={'w-56 px-6'}>
                            {t('offers:titleTooltip')}
                        </Tooltip.Content>
                    </Tooltip>
                </InputIcon>
            </Input>

            <CategoryPicker
                id={'offer.category'}
                name={'offer.category'}
                options={categories ?? []}
                value={values.offer.category?.id ?? null}
                onChange={handleCategoryChange}
                label={t('offers:category')}
                className={'md:mt-5 mt-3 max-w-96 md:w-2/5 lg:w-full'}
                error={touched.offer?.category && errors.offer?.category}
                disabled={!areCategoriesLoaded}
                expandOnHover={!isMobile}
                preventSelectParentOption={true}
            />

            <h2 className={'mt-9 hidden md:flex'}>{t('offers:photos')}</h2>

            <div className={'mt-3 text-xs tracking-tighter text-gray-900 hidden md:flex'}>
                {t('offers:photosDescription', {
                    count: existingOffer?.features?.extraPhotos ? 40 : 5
                })}
            </div>

            <FileUploader
                className={'mt-5 hidden md:grid'}
                value={values.offer.images}
                onChange={handleFilesUpload}
                name={'offer.images'}
                multiple
                maxFiles={existingOffer?.features?.extraPhotos ? 40 : 5}
            >
                {(!isEditable || !existingOffer?.features?.extraPhotos) && (
                    <div
                        className={
                            'h-42 flex flex-col items-center justify-around border-gray-300 border rounded p-6'
                        }
                    >
                        <h2 className={'text-xs text-secondary'}>
                            {t('offers:buyAdditionalPhotos')}
                        </h2>

                        <Button
                            onClick={() => {
                                if (!isEditable) {
                                    handleSaveBeforeExtraPhotos(Offer.Status.Draft);
                                }
                                openExtendImageModal(!isEditable);
                            }}
                            variant={'secondary'}
                            type={'button'}
                            size={'xs'}
                            outline
                        >
                            {t('offers:buy')}
                        </Button>
                    </div>
                )}
            </FileUploader>

            <h2 className={'mt-10 hidden md:flex'}>{t('offers:information')}</h2>

            <div className={'mt-3 text-xs tracking-tighter text-gray-900 hidden md:flex'}>
                {t('offers:informationDescription')}
            </div>

            <TextArea
                id={'offer.description'}
                className={'md:mt-4 mt-6'}
                name={'offer.description'}
                value={values.offer.description}
                onChange={handleChange}
                label={t('offers:information')}
                placeholder={t('offers:addDescription')}
                error={touched.offer?.description && errors.offer?.description}
                maxLength={10000}
                rows={6}
            />
        </>
    );
};

export default CreateBaseForm;
