//Hooks
import {useTranslation} from 'react-i18next';
import {useAdditionalOptionsModal} from './AdditionalOptionsModal.hook';

//Components
import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';

//Images
import defaultOffer from 'assets/files/defaultOffer.jpg';

//Types
import {Offer} from 'store/types';

type AdditionalOptionsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    type: Offer.AdditionalOptions;
    offer: Offer.ListItem;
};

const AdditionalOptionsModal: React.VFC<AdditionalOptionsModalProps> = ({
    type,
    offer,
    isOpen,
    onClose
}) => {
    const {
        proceedExtraFeature,
        currentPrice,
        featureTitle,
        featureText,
        featureDescription
    } = useAdditionalOptionsModal({
        offer,
        type
    });
    const {t} = useTranslation(['common', 'offers']);

    return (
        <Modal className={'flex flex-col'} isOpen={isOpen} onClose={onClose} size={'md'}>
            <h1 className={'font-normal'}>{featureTitle}</h1>

            <div className={'flex flex-col md:flex-row justify-between mt-10'}>
                <div className={'flex'}>
                    <img
                        className={'w-12 h-auto object-cover rounded'}
                        src={!!offer.images.length ? offer.images[0].small : defaultOffer}
                        alt={offer.title}
                        aria-label={'Offer image'}
                    />

                    <div className={'flex flex-col mx-5.5'}>
                        <h3 aria-label={'subTitle'} className={'text-secondary'}>
                            {featureText}
                        </h3>

                        <p role={'paragraph'} className={'mt-2 text-xs'}>
                            {offer.title}
                        </p>
                    </div>
                </div>

                <h3 className={'text-sm'} aria-label={'price'}>
                    {currentPrice &&
                        t('offers:displayPrice', {
                            priceFrom: {
                                ...currentPrice,
                                amount: parseInt(currentPrice?.amount)
                            }
                        })}
                </h3>
            </div>

            <p className={'my-8 text-sm'}>{featureDescription}</p>

            <div className={'mt-40 flex flex-col items-center gap-y-6'}>
                <Button onClick={onClose} variant={'primary'} size={'sm'} outline>
                    {t('common:cancel')}
                </Button>

                <Button onClick={proceedExtraFeature} variant={'primary'} size={'sm'}>
                    {t('offers:proceed')}
                </Button>
            </div>
        </Modal>
    );
};

export default AdditionalOptionsModal;
