//Redux saga
import {call, put, select, takeLeading} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions/user';

//Sagas
import {request} from 'store/sagas/api';

//Types
import {User} from 'store/types';
import {AxiosResponse} from 'axios';

import {locale} from 'localeApp';

//Utils
import {formatPhone, formatZipCode} from 'utils/account';

export function* getMyStats() {
    try {
        const resp: AxiosResponse<User.Stats> = yield call(
            request,
            {
                method: 'GET',
                url: '/api/stats/my-profile'
            },
            true
        );
        yield put(actions.getMyStats.success(resp.data));
    } catch (err) {
        yield put(actions.getMyStats.failure(err));
    }
}

export function* uploadAvatar(action: ReturnType<typeof actions.uploadAvatar.request>) {
    const form = new FormData();
    form.append('upload_avatar_form[avatar]', action.payload);

    const resp: AxiosResponse<User.UploadAvatarResponse> = yield call(
        request,
        {
            method: 'POST',
            url: '/api/accounts/my/upload-avatar',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        },
        true
    );
    return resp.data;
}

export function* deleteAvatar() {
    yield call(
        request,
        {
            method: 'DELETE',
            url: '/api/accounts/my/remove-avatar'
        },
        true
    );
}

export function* editMyAddress(action: ReturnType<typeof actions.editMyAddress.request>) {
    const {firstName, lastName, companyName, vatId, ...payload} = action.payload;
    const data = {
        account: {
            firstName,
            lastName,
            companyName,
            vatId
        },
        address: {
            ...payload,
            postCode: formatZipCode(payload.postCode)
        }
    };

    yield call(
        request,
        {
            method: 'POST',
            url: '/api/accounts/my/edit-contact-data',
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return action.payload;
}

export function* editMyPhone(action: ReturnType<typeof actions.editMyPhone.request>) {
    const {phone} = action.payload;

    const {
        data: {uuid}
    } = yield call(
        request,
        {
            method: 'POST',
            url: '/api/accounts/my/update-phone',
            data: {phone: formatPhone(phone)},
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return {phone, confirmationId: uuid};
}

export function* editMyPhoneConfirm(
    action: ReturnType<typeof actions.editMyPhoneConfirm.request>
) {
    const {code} = action.payload;

    const confirmationId: string = yield select(
        state => state.user.phoneConfirmation.confirmationId
    );

    yield call(
        request,
        {
            method: 'POST',
            url: '/api/short-codes/confirm',
            data: {code, uuid: confirmationId},
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export function* editMyEmail(action: ReturnType<typeof actions.editMyEmail.request>) {
    const {email} = action.payload;

    const {
        data: {uuid}
    } = yield call(
        request,
        {
            method: 'POST',
            url: '/api/accounts/my/update-email',
            data: {email},
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return {email, confirmationId: uuid};
}

export function* editMyEmailConfirm(
    action: ReturnType<typeof actions.editMyEmailConfirm.request>
) {
    const {code} = action.payload;

    const confirmationId: string = yield select(
        state => state.user.emailConfirmation.confirmationId
    );

    yield call(
        request,
        {
            method: 'POST',
            url: '/api/short-codes/confirm',
            data: {code, uuid: confirmationId},
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export function* editMyPassword(action: ReturnType<typeof actions.editMyPassword.request>) {
    const {oldPassword, newPassword, repeatNewPassword} = action.payload;
    const data = {
        oldPassword,
        newPassword: {first: newPassword, second: repeatNewPassword}
    };

    yield call(
        request,
        {
            method: 'POST',
            url: '/api/accounts/my/update-password',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export function* editMyPayout(action: ReturnType<typeof actions.editMyPayout.request>) {
    const {holder, ...payload} = action.payload;

    if (locale === 'pl' && !payload.iban?.startsWith('PL')) {
        payload.iban = 'PL' + payload.iban;
    }

    const data = {
        account: {
            ...payload
        },
        holder
    };

    yield call(
        request,
        {
            method: 'POST',
            url: '/api/accounts/my/update-payout',
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return data;
}

export function* getCards(action: ReturnType<typeof actions.getCards.request>) {
    const {
        data: {cards}
    } = yield call(
        request,
        {
            method: 'GET',
            url: '/api/cards',
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return cards;
}

export function* setCard(action: ReturnType<typeof actions.setCard.request>) {
    yield call(
        request,
        {
            method: 'POST',
            url: '/api/cards',
            data: {
                data: {
                    paymentMethodId: action.payload
                }
            },
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export const saga = [
    takeLeading(actions.getMyStats.request, getMyStats),
    takeEveryPromiseAction(actions.uploadAvatar, uploadAvatar),
    takeEveryPromiseAction(actions.deleteAvatar, deleteAvatar),
    takeEveryPromiseAction(actions.editMyAddress, editMyAddress),
    takeEveryPromiseAction(actions.editMyPhone, editMyPhone),
    takeEveryPromiseAction(actions.editMyPhoneConfirm, editMyPhoneConfirm),
    takeEveryPromiseAction(actions.editMyEmail, editMyEmail),
    takeEveryPromiseAction(actions.editMyEmailConfirm, editMyEmailConfirm),
    takeEveryPromiseAction(actions.editMyPassword, editMyPassword),
    takeEveryPromiseAction(actions.editMyPayout, editMyPayout),
    takeEveryPromiseAction(actions.getCards, getCards),
    takeEveryPromiseAction(actions.setCard, setCard)
];
