//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Order} from 'store/types';

export type Action = ActionType<typeof actions>;

export type State = Readonly<{
    orders: Data<Order.Order[]> & {
        currentPage: number;
        pages: number | null;
    };
    orderDetails: Data<Order.OrderDetails>;
}>;

export const initialState: State = {
    orders: {
        data: null,
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        currentPage: 1,
        pages: null
    },
    orderDetails: {
        data: null,
        isLoading: false,
        isLoaded: false,
        isErrored: false
    }
};

export default createReducer<State, Action>(initialState)
    .handleAction(actions.getMyBoughtOrders.request, (state, action) => ({
        ...state,
        orders: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: null,
            currentPage: action.payload.page,
            pages: state.orders.pages
        }
    }))
    .handleAction(actions.getMyBoughtOrders.success, (state, action) => ({
        ...state,
        orders: {
            data: action.payload.items,
            isLoaded: true,
            isLoading: false,
            isErrored: false,
            currentPage: action.payload.pagination.currentPage,
            pages: action.payload.pagination.totalPages
        }
    }))
    .handleAction(actions.getMyBoughtOrders.failure, state => ({
        ...state,
        orders: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null,
            pages: null,
            currentPage: 1
        }
    }))
    .handleAction(actions.getMyBoughtOrderById.request, state => ({
        ...state,
        orderDetails: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: null
        }
    }))
    .handleAction(actions.getMyBoughtOrderById.success, (state, action) => ({
        ...state,
        orderDetails: {
            data: action.payload,
            isLoaded: true,
            isLoading: false,
            isErrored: false
        }
    }))
    .handleAction(actions.getMyBoughtOrderById.failure, state => ({
        ...state,
        orderDetails: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }))
    .handleAction(actions.completeOrder.success, (state, action) => ({
        ...state,
        orders: {
            ...state.orders,
            isLoaded: true,
            isLoading: false,
            isErrored: false,
            data: state.orders.data!.map(order =>
                order.orderId === action.payload.orderId
                    ? {...order, status: Order.Status.Completed}
                    : order
            )
        }
    }))
    .handleAction(actions.logout.success, () => initialState);
