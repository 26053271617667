//Redux saga
import {call} from 'redux-saga/effects';
import {takeLatestPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions';

//Services
import axios from 'axios';

//Types
import {AxiosResponse} from 'axios';
import {Geocoding} from 'store/types';

export function* getGeoCoordinates(
    action: ReturnType<typeof actions.getGeoCoordinates.request>
) {
    const {street, city, state} = action.payload;
    const addressString = `${street}, ${city}, ${state}`.split(' ').join('+');
    const apiKey =
        process.env.REACT_APP_GOOGLE_MAPS_GEOCODING_API_KEY ||
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const resp: AxiosResponse<Geocoding.Response> = yield call(axios.request, {
        method: 'GET',
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${apiKey}`
    });

    return resp.data;
}

export const saga = [takeLatestPromiseAction(actions.getGeoCoordinates, getGeoCoordinates)];
