//types specified by Google Maps API

export enum Status {
    OK = 'OK',
    ZeroResults = 'ZERO_RESULTS',
    OverDailyLimit = 'OVER_DAILY_LIMIT',
    OverQueryLimit = 'OVER_QUERY_LIMIT',
    RequestDenied = 'REQUEST_DENIED',
    InvalidRequest = 'INVALID_REQUEST',
    UnknownError = 'UNKNOWN_ERROR'
}

interface AddressComponent {
    longName: string;
    shortName: string;
    types: string[];
}

interface LatLng {
    lat: number;
    lng: number;
}

export interface Result {
    addressComponents: AddressComponent[];
    formattedAddress: string;
    geometry: {
        location: LatLng;
        locationType: string;
        viewport: {
            northeast: LatLng;
            southwest: LatLng;
        };
    };
    placeId: string;
    plusCode?: {
        compoundCode?: string;
        globalCode?: string;
    };
    types: string[];
}

export interface Response {
    results: Result[];
    status: Status;
}
