import {useTranslation} from 'react-i18next';

//Components
import Footer from 'components/nav/Footer';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import LinkGroup from 'components/nav/LinkGroup';
import SearchForm from 'components/search/SearchForm';
import {Link} from '@reach/router';

//Types
import {RouteComponentProps, LinkGetProps} from '@reach/router';

const ProfileView: React.FC<RouteComponentProps> = ({children}) => {
    const {t} = useTranslation('nav');

    const isActive = ({isPartiallyCurrent}: LinkGetProps) => ({
        'aria-current': isPartiallyCurrent ? 'page' : undefined
    });

    return (
        <div className={'flex flex-col h-full bg-gray-100'}>
            <SearchForm />

            <div
                className={
                    'flex flex-row justify-center flex-grow w-full md:self-center max-w-292 md:p-10 gap-x-14 gap-y-8'
                }
            >
                <LinkGroup className={'hidden w-72 min-w-72 lg:flex'}>
                    <Link to={'my-profile'}>{t('navbar.myProfile')}</Link>

                    <Link to={'my-offers'} getProps={isActive}>
                        {t('navbar.myOffers')}
                    </Link>

                    <Link to={'my-purchases'} getProps={isActive}>
                        {t('navbar.myPurchases')}
                    </Link>

                    <Link to={'account-settings'}>{t('navbar.accountSettings')}</Link>
                </LinkGroup>

                <div className={'flex-grow'}>{children}</div>
            </div>

            <Footer />

            <MobileBottomNav />
        </div>
    );
};

export default ProfileView;
