//Hooks
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

//Components
import RadioGroup from 'components/ui/RadioGroup';
import Radio from 'components/ui/Radio';

//Types
import {Search, User} from 'store/types';

const AccountTypeFilter: React.VFC = () => {
    const {values, handleChange} = useFormikContext<Search.FormValues>();

    const {t} = useTranslation('common');

    return (
        <RadioGroup
            name={'accountType'}
            value={values.accountType ?? ''}
            onChange={handleChange}
            direction={'vertical'}
        >
            <Radio id={'accountType.all'} label={t('all')} value={''} />

            <Radio id={'accountType.person'} label={t('private')} value={User.Type.Person} />

            <Radio id={'accountType.company'} label={t('business')} value={User.Type.Company} />
        </RadioGroup>
    );
};

export default AccountTypeFilter;
