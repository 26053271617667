import * as React from 'react';

//Hooks
import {useState, useRef, useMemo} from 'react';

//Types
import {CSSProperties} from 'react';
import {GalleryViewProps} from './GalleryView';

export const useGalleryView = ({
    initialImageIndex,
    children
}: Pick<GalleryViewProps, 'initialImageIndex' | 'children'>) => {
    const [activeImageIndex, setActiveImageIndex] = useState(initialImageIndex ?? 0);

    const imagePreviewRef = useRef<HTMLDivElement>(null);

    const imagesCount = React.Children.count(children);

    const handlePreviousImageChange = () => {
        activeImageIndex === 0
            ? setActiveImageIndex(imagesCount - 1)
            : setActiveImageIndex(prevState => prevState - 1);
    };

    const handleNextImageChange = () => {
        activeImageIndex === imagesCount - 1
            ? setActiveImageIndex(0)
            : setActiveImageIndex(prevState => prevState + 1);
    };

    const handleImageChange = (index: number) => () => setActiveImageIndex(index);

    const imagePreviewStyle = useMemo<CSSProperties>(
        () => ({
            transform: `translateX(-${
                (activeImageIndex / (imagesCount - 1)) *
                (imagesCount * 168 - (imagePreviewRef?.current?.clientWidth ?? 0))
            }px)`
        }),
        [activeImageIndex, imagesCount, imagePreviewRef]
    );

    return {
        activeImageIndex,
        imagePreviewRef,
        imagesCount,
        imagePreviewStyle,
        handleImageChange,
        handlePreviousImageChange,
        handleNextImageChange
    };
};
