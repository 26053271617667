//Hooks
import {useSelector} from 'hooks/store';

export const useCategoryCustomFilters = () => {
    const {data: customFormFields} = useSelector(state => state.categories.customFormFields);

    return {
        customFormFields
    };
};
