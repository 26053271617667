//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {User, Data} from 'store/types';

export type UserAction = ActionType<typeof actions>;

export type State = Readonly<{
    user: User.User | null;
    stats: Data<User.Stats>;
    phoneConfirmation?: User.UserPhoneConfirmation;
    emailConfirmation?: User.UserEmailConfirmation;
    isLoading: boolean;
    cards?: Data<any>;
}>;

export const initialState: State = {
    user: null,
    stats: {data: null, isLoading: false, isLoaded: false, isErrored: false},
    isLoading: false,
    cards: {data: null, isLoading: false, isLoaded: false, isErrored: false}
};

export default createReducer<State, UserAction>(initialState)
    .handleAction(actions.refreshAccessToken.success, (state, action) => ({
        ...state,
        user: action.payload.user
    }))
    .handleAction(actions.login.success, (state, action) => ({
        ...state,
        user: action.payload.user
    }))
    .handleAction(actions.logout.success, () => initialState)
    .handleAction(actions.getMyStats.request, state => ({
        ...state,
        stats: {data: state.stats.data, isLoading: true, isLoaded: false, isErrored: false}
    }))
    .handleAction(actions.getMyStats.success, (state, action) => ({
        ...state,
        stats: {data: action.payload, isLoading: false, isLoaded: true, isErrored: false}
    }))
    .handleAction(actions.getMyStats.failure, state => ({
        ...state,
        stats: {data: null, isLoading: false, isLoaded: false, isErrored: true}
    }))
    .handleAction(actions.uploadAvatar.success, (state, action) => ({
        ...state,
        user: {
            ...state.user,
            avatar: action.payload.avatarUrl,
            avatarFallback: action.payload.originUrl
        } as User.User
    }))
    .handleAction(actions.deleteAvatar.success, state => ({
        ...state,
        user: {...state.user, avatar: null, avatarFallback: undefined} as User.User
    }))
    .handleAction(actions.editMyAddress.request, state => ({
        ...state,
        isLoading: true
    }))
    .handleAction(actions.editMyAddress.success, (state, action) => {
        if (!state.user) {
            return state;
        }

        const {firstName, lastName, companyName, vatId, ...address} = action.payload;

        switch (state.user.type) {
            case User.Type.Person: {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        firstName: firstName!,
                        lastName: lastName!,
                        address
                    },
                    isLoading: false
                };
            }
            default: {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        companyName: companyName!,
                        vatId: vatId!,
                        address
                    },
                    isLoading: false
                };
            }
        }
    })
    .handleAction(actions.editMyPhone.request, (state, action) => ({
        ...state,
        isLoading: true
    }))
    .handleAction(actions.editMyPhone.success, (state, action) => ({
        ...state,
        phoneConfirmation: {...action.payload},
        isLoading: false
    }))
    .handleAction(actions.editMyPhoneConfirm.request, state => ({
        ...state,
        isLoading: true
    }))
    .handleAction(actions.editMyPhoneConfirm.success, state => ({
        ...state,
        user: {...state.user, phone: state?.phoneConfirmation?.phone} as User.User,
        isLoading: false
    }))
    .handleAction(actions.editMyEmail.request, state => ({
        ...state,
        isLoading: true
    }))
    .handleAction(actions.editMyEmail.success, (state, action) => ({
        ...state,
        emailConfirmation: {...action.payload},
        isLoading: false
    }))
    .handleAction(actions.editMyEmailConfirm.request, state => ({
        ...state,
        isLoading: true
    }))
    .handleAction(actions.editMyEmailConfirm.success, state => ({
        ...state,
        user: {...state.user, email: state?.emailConfirmation?.email} as User.User,
        isLoading: false
    }))
    .handleAction(actions.editMyPayout.request, state => ({
        ...state,
        isLoading: true
    }))
    .handleAction(actions.editMyPayout.success, (state, action) => ({
        ...state,
        user: {...state.user, payout: {...action.payload}} as User.User,
        isLoading: false
    }))
    .handleAction(actions.getCards.request, state => ({
        ...state,
        cards: {
            data: state.cards?.data,
            isLoading: true,
            isLoaded: false,
            isErrored: false
        }
    }))
    .handleAction(actions.getCards.success, (state, action) => ({
        ...state,
        cards: {
            data: action.payload,
            isLoading: false,
            isLoaded: true,
            isErrored: false
        }
    }))
    .handleAction(actions.getCards.failure, state => ({
        ...state,
        cards: {
            data: null,
            isLoading: false,
            isLoaded: false,
            isErrored: true
        }
    }));
