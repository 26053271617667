//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Auth, Nullable} from 'store/types';

export type AuthAction = ActionType<typeof actions>;

export type State = Readonly<
    {
        isTokenLoading: boolean;
        isTokenErrored: boolean;
        isLoggingOut: boolean;
        requestResetBy?: 'phone' | 'email';
    } & Nullable<Auth.Token> &
        Nullable<Auth.NotifuseAuthentication>
>;

export const initialState: State = {
    isTokenLoading: false,
    isTokenErrored: false,
    token: null,
    isLoggingOut: false,
    notifuseAuthentication: null,
    requestResetBy: undefined
};

export default createReducer<State, AuthAction>(initialState)
    .handleAction(actions.refreshAccessToken.request, state => ({
        ...state,
        isTokenLoading: true,
        isTokenErrored: false
    }))
    .handleAction(actions.refreshAccessToken.success, (state, action) => ({
        ...state,
        isTokenLoading: false,
        isTokenErrored: false,
        token: action.payload.token,
        notifuseAuthentication: action.payload.notifuseAuthentication
    }))
    .handleAction(actions.refreshAccessToken.failure, state => ({
        ...state,
        isTokenLoading: false,
        isTokenErrored: true
    }))
    .handleAction(actions.login.success, (state, action) => ({
        ...state,
        isTokenErrored: false,
        token: action.payload.token,
        notifuseAuthentication: action.payload.notifuseAuthentication
    }))
    .handleAction(actions.logout.request, state => ({
        ...state,
        isLoggingOut: true
    }))
    .handleAction(actions.logout.success, () => initialState)
    .handleAction(actions.logout.failure, state => ({
        ...state,
        isLoggingOut: false
    }))
    .handleAction(actions.requestPasswordReset.request, (state, action) => ({
        ...state,
        requestResetBy: action.payload.email !== '' ? 'email' : 'phone'
    }));
