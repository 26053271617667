/* istanbul ignore file */

//Hooks
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

//Utils
import {setValidationLocale} from 'services/i18n/validation';

//Types
import {TFunction} from 'react-i18next';

export const useValidationLocale = () => {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (!i18n.isInitialized) {
            return;
        }

        setValidationLocale(t as TFunction);
    }, [t, i18n.isInitialized, i18n.language]);
};
