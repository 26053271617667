import * as React from 'react';

//Hooks
import {useGalleryView} from './GalleryView.hook';

//Components
import Icon from '../../ui/Icon';

//Utils
import cx from 'classnames';

export type GalleryViewProps = {
    initialImageIndex?: number;
    children:
        | React.ReactElement<React.HTMLProps<HTMLImageElement>>
        | React.ReactElement<React.HTMLProps<HTMLImageElement>>[];
    className?: string;
} & React.HTMLProps<HTMLDivElement>;

const GalleryView: React.VFC<GalleryViewProps> = ({
    initialImageIndex,
    children,
    className,
    ...props
}) => {
    const {
        activeImageIndex,
        imagePreviewRef,
        imagesCount,
        imagePreviewStyle,
        handleImageChange,
        handlePreviousImageChange,
        handleNextImageChange
    } = useGalleryView({initialImageIndex, children});

    if (imagesCount === 1) {
        return React.cloneElement(
            React.Children.toArray(children)[0] as React.ReactElement<
                React.HTMLProps<HTMLImageElement>
            >,
            {
                className: cx('h-84 w-full rounded-xl object-cover m-auto my-2.5', className)
            }
        );
    }

    return (
        <div className={className} {...props}>
            <div className={'flex flex-row items-center justify-around my-2.5'}>
                <button
                    className={'hidden text-gray-600 cursor-pointer md:flex focus:outline-none'}
                    onClick={handlePreviousImageChange}
                    aria-label={'Previous image'}
                >
                    <Icon icon={'previous'} size={'lg'} />
                </button>

                <div
                    className={
                        'relative flex flex-row overflow-auto md:overflow-hidden md:rounded-xl md:w-112'
                    }
                >
                    {React.Children.map(children, (child, i) => (
                        <div
                            key={i}
                            className={
                                'flex-grow-0 flex-shrink-0 w-full h-full transform-gpu transition-transform duration-500'
                            }
                            style={{transform: `translateX(-${activeImageIndex * 100}%)`}}
                            role={'tab'}
                            aria-label={`Gallery image`}
                            aria-selected={activeImageIndex === i}
                        >
                            <div
                                className={
                                    'overflow-hidden md:overflow-auto h-84 rounded-xl md:rounded-none mr-1.5 md:mr-0 last:mr-0'
                                }
                            >
                                {React.cloneElement(child as React.ReactElement, {
                                    key: `gallery-image-${i + 1}`,
                                    className: 'object-cover h-full w-full'
                                })}
                            </div>
                        </div>
                    ))}

                    <div className={'absolute flex flex-row justify-center w-full top-72'}>
                        {React.Children.map(children, (_, i) => (
                            <button
                                key={i}
                                onClick={handleImageChange(i)}
                                className={cx(
                                    'w-3 h-3 m-0.75 border-1.5 border-white rounded-full cursor-pointer hidden md:flex focus:outline-none',
                                    {
                                        'bg-secondary': i === activeImageIndex
                                    }
                                )}
                                aria-label={`Image dot indicator`}
                            />
                        ))}
                    </div>
                </div>

                <button
                    className={'hidden text-gray-600 cursor-pointer md:flex focus:outline-none'}
                    onClick={handleNextImageChange}
                    aria-label={'Next image'}
                >
                    <Icon icon={'next'} size={'lg'} />
                </button>
            </div>

            <div>
                <div
                    className={
                        'relative flex-row items-center justify-start hidden overflow-hidden md:flex w-168 my-2.5'
                    }
                >
                    <div
                        ref={imagePreviewRef}
                        className={
                            'flex flex-row w-full h-full transform-gpu transition-transform duration-500'
                        }
                        style={imagePreviewStyle}
                        role={'region'}
                        aria-label={'Image preview'}
                    >
                        {React.Children.map(children, (item, i) =>
                            React.cloneElement(item as React.ReactElement, {
                                key: i,
                                className: cx(
                                    'object-cover h-28 w-36 flex-grow-0 flex-shrink-0 rounded mx-3 cursor-pointer',
                                    {
                                        'border border-secondary': i === activeImageIndex
                                    }
                                ),
                                onClick: handleImageChange(i),
                                'aria-label': 'Image preview'
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GalleryView;
