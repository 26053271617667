import * as React from 'react';

//Components
import Button, {ButtonProps} from './Button';

export type PaymentMethodInputProps = {
    value: string | number | undefined;
    onChange: React.FormEventHandler<HTMLInputElement>;
    name: string;
    children:
        | React.ReactElement<ButtonProps, typeof Button>
        | React.ReactElement<ButtonProps, typeof Button>[];
} & Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'onChange'>;

const PaymentMethodInput: React.VFC<PaymentMethodInputProps> = ({
    children,
    value,
    onChange,
    name,
    ...props
}) => (
    <div {...props} className={'flex flex-col md:flex-row justify-between'}>
        {React.Children.map(children, child => (
            <Button
                {...child.props}
                className={'my-3 mx-2.5'}
                checked={child.props.value === value}
                onChange={onChange}
                name={name}
            />
        ))}
    </div>
);

export {Button as PaymentButton};

export default PaymentMethodInput;
