//Redux saga
import {call, put, takeLeading} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions/auth';

//Sagas
import {request} from 'store/sagas/api';

//Utils
import _ from 'lodash-es';

//Types
import {AxiosResponse} from 'axios';
import {Auth, User} from 'store/types';

import {formatPhone, formatZipCode} from 'utils/account';

export function* refreshAccessToken() {
    try {
        const resp: AxiosResponse<Auth.RefreshTokenResponse> = yield call(request, {
            method: 'POST',
            url: '/api/sessions/refresh',
            data: '',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: true
        });

        yield put(actions.refreshAccessToken.success(resp.data));
    } catch (err) {
        yield put(actions.refreshAccessToken.failure(err));
    }
}

export function* login(action: ReturnType<typeof actions.login.request>) {
    const resp: AxiosResponse<Auth.LoginResponse> = yield call(request, {
        method: 'POST',
        url: '/api/sessions/create',
        data: action.payload,
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    });

    return resp.data;
}

export function* logout(action: ReturnType<typeof actions.logout.request>) {
    localStorage.removeItem('basket');
    yield call(request, {
        method: 'DELETE',
        url: '/api/sessions',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    });
}

export function* register(action: ReturnType<typeof actions.register.request>) {
    const {authType, account} = _.omit(
        action.payload,
        action.payload.account.type === User.Type.Person
            ? [
                  'checkbox1',
                  'checkbox2',
                  'checkbox3',
                  'account.data.companyName',
                  'account.data.vatId'
              ]
            : [
                  'checkbox1',
                  'checkbox2',
                  'checkbox3',
                  'account.data.firstName',
                  'account.data.lastName',
                  'account.data.birthDay'
              ]
    );

    const resp: AxiosResponse<Auth.RegisterUUID> = yield call(request, {
        method: 'POST',
        url: '/api/accounts/register',
        data: {
            authType,
            account: {
                data: {...account.data, phone: formatPhone(account?.data?.phone)},
                address: {
                    ...account.address,
                    postCode: formatZipCode(account.address.postCode)
                },
                type: account.type
            }
        },
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return resp.data;
}

export function* confirmAccount(action: ReturnType<typeof actions.confirmAccount.request>) {
    yield call(request, {
        method: 'POST',
        url: '/api/short-codes/confirm',
        data: action.payload,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function* requestPasswordReset(
    action: ReturnType<typeof actions.requestPasswordReset.request>
) {
    yield call(request, {
        method: 'POST',
        url: '/api/accounts/forgotten-password',
        data: action.payload,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function* resetPassword(action: ReturnType<typeof actions.resetPassword.request>) {
    const {id, signature, expires, newPassword} = action.payload;
    yield call(request, {
        method: 'POST',
        url: `/api/accounts/${id}/reset-password?signature=${signature}&expires=${expires}`,
        data: {newPassword},
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export const saga = [
    takeLeading(actions.refreshAccessToken.request, refreshAccessToken),
    takeEveryPromiseAction(actions.login, login),
    takeEveryPromiseAction(actions.logout, logout),
    takeEveryPromiseAction(actions.register, register),
    takeEveryPromiseAction(actions.confirmAccount, confirmAccount),
    takeEveryPromiseAction(actions.requestPasswordReset, requestPasswordReset),
    takeEveryPromiseAction(actions.resetPassword, resetPassword)
];
