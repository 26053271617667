import * as React from 'react';

//Components
import Wrapper from './Wrapper';
import Content from './Content';

//Types
import {TooltipWrapperProps} from './Wrapper';
import {TooltipContentProps} from './Content';

export type TooltipProps = {
    id: string;
    children: [
        React.ReactElement<TooltipWrapperProps, typeof Wrapper>,
        React.ReactElement<TooltipContentProps, typeof Content>
    ];
};

const Tooltip: React.VFC<TooltipProps> & {Wrapper: typeof Wrapper; Content: typeof Content} = ({
    id,
    children
}) => (
    <>
        {React.Children.map(children, child => {
            if (!child || child.type.name !== 'Wrapper') {
                return null;
            }

            return React.cloneElement(child, {...child.props, id});
        })}

        {React.Children.map(children, child => {
            if (!child || child.type.name !== 'Content') {
                return null;
            }

            return React.cloneElement(child, {...child.props, id});
        })}
    </>
);

Tooltip.Wrapper = Wrapper;
Tooltip.Content = Content;

export default Tooltip;
