//Hooks
import {useEditMyPasswordView} from './EditMyPasswordModal.hook';
import {useTranslation} from 'react-i18next';

//Components
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

export type EditMyPasswordModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const EditMyPasswordModal: React.VFC<EditMyPasswordModalProps> = ({isOpen, onClose}) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useEditMyPasswordView(onClose);
    const {t} = useTranslation('profile');

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <form className={'flex flex-col'} onSubmit={handleSubmit}>
                <div className={'flex flex-col'}>
                    <Input
                        id={'oldPassword'}
                        name={'oldPassword'}
                        value={values.oldPassword}
                        onChange={handleChange}
                        label={t('oldPassword')}
                        type={'password'}
                        error={touched.oldPassword && errors.oldPassword}
                    />

                    <Input
                        id={'newPassword'}
                        name={'newPassword'}
                        value={values.newPassword}
                        onChange={handleChange}
                        className={'mt-3.5'}
                        label={t('newPassword')}
                        type={'password'}
                        error={touched.newPassword && errors.newPassword}
                    />

                    <Input
                        id={'repeatNewPassword'}
                        name={'repeatNewPassword'}
                        value={values.repeatNewPassword}
                        onChange={handleChange}
                        className={'mt-3.5'}
                        label={t('repeatNewPassword')}
                        type={'password'}
                        error={touched.repeatNewPassword && errors.repeatNewPassword}
                    />
                </div>

                <Button
                    className={'mt-18 w-full'}
                    variant={'primary'}
                    type={'submit'}
                    disabled={isSubmitting}
                >
                    {t('save')}
                </Button>
            </form>
        </Modal>
    );
};

export default EditMyPasswordModal;
