//Hooks
import {useUserProfileView} from './UserProfileView.hook';
import {useWindowSize} from 'hooks/windowSize';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import Footer from 'components/nav/Footer';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import OfferView from 'components/offer/OfferView';
import Badge from 'components/ui/Badge';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import PaginationView from 'components/ui/PaginationView';
import RatingInput from 'components/ui/RatingInput';
import PopularSearches from 'components/nav/PopularSearches';

//Views
import NotFoundView from 'views/NotFoundView';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';

//Styles
import {styles, theme} from 'styles/select';

//Utils
import _ from 'lodash-es';

//Types
import {RouteComponentProps} from '@reach/router';
import {User} from 'store/types';

const UserProfileView: React.VFC<RouteComponentProps> = () => {
    const {
        offers,
        user,
        sortOptions,
        isUserLoading,
        isUserErrored,
        areOffersLoaded,
        areOffersLoading,
        areOffersErrored,
        sorting,
        setSorting,
        currentPage,
        handlePageChange,
        errorCode,
        contactUser,
        isMe
    } = useUserProfileView();

    const {t} = useTranslation(['common', 'auth', 'profile', 'errors', 'reviews']);
    const {breakpoint} = useWindowSize();

    if (errorCode === 404) return <NotFoundView />;

    return (
        <div className={'bg-gray-100 h-full flex flex-col'}>
            <div
                className={
                    'flex lg:flex-row flex-col md:self-center flex-grow max-w-292 w-full m-0-auto md:py-10 gap-x-14 gap-y-6'
                }
            >
                <div className={'lg:w-96 py-3 lg:py-0'}>
                    <div className={'bg-white flex flex-col px-5 pt-3'}>
                        {isUserErrored ? (
                            <h3 aria-label={'user loading failed'}>
                                {t('errors:loadingErrorMessage')}
                            </h3>
                        ) : (
                            <div className={'flex flex-row md:flex-col gap-x-5'}>
                                {isUserLoading ? (
                                    <div aria-label={'User account type loading'}>
                                        <Skeleton />
                                    </div>
                                ) : (
                                    <Badge
                                        variant={'secondary'}
                                        className={'self-end hidden md:flex'}
                                        size={'lg'}
                                    >
                                        {t(
                                            user?.type === User.Type.Person
                                                ? 'common:private'
                                                : 'common:business'
                                        )}
                                    </Badge>
                                )}

                                {isUserLoading ? (
                                    <div
                                        className={'md:w-32 w-16'}
                                        aria-label={'User avatar loading'}
                                        role={'figure'}
                                    >
                                        <Skeleton className={'md:h-32 h-16 '} />
                                    </div>
                                ) : (
                                    <img
                                        src={user?.avatar ?? defaultAvatar}
                                        alt={user?.friendlyName}
                                        className={
                                            'md:h-32 md:w-32 h-16 w-16 md:my-2 object-cover rounded-md'
                                        }
                                    />
                                )}

                                <div className={'flex flex-col justify-between flex-grow'}>
                                    <div
                                        className={
                                            'flex flex-row justify-between items-center md:py-5 md:border-b border-gray-200'
                                        }
                                    >
                                        {isUserLoading ? (
                                            <div
                                                className={'w-full'}
                                                role={'heading'}
                                                aria-level={1}
                                                aria-label={'User name loading'}
                                            >
                                                <Skeleton className={'h-6'} />
                                            </div>
                                        ) : (
                                            <>
                                                <h1 aria-label={'user name'}>
                                                    {user?.friendlyName}
                                                </h1>

                                                <span className={'text-2xs md:hidden'}>
                                                    {t(
                                                        user?.type === User.Type.Person
                                                            ? 'common:private'
                                                            : 'common:business'
                                                    )}
                                                </span>
                                            </>
                                        )}
                                    </div>

                                    <span
                                        className={
                                            'md:hidden text-2xs flex flex-row items-center gap-x-1'
                                        }
                                    >
                                        <Icon icon={'location'} size={'xs'} />

                                        {isUserLoading ? (
                                            <div
                                                className={'w-full'}
                                                aria-label={'User address loading'}
                                            >
                                                <Skeleton className={'h-2.5'} />
                                            </div>
                                        ) : (
                                            `${user?.address.city}, ${user?.address.state}`
                                        )}
                                    </span>
                                </div>

                                <div
                                    className={
                                        'border-b border-gray-200 pb-4 pt-3 hidden md:block'
                                    }
                                >
                                    {isUserLoading ? (
                                        <div
                                            className={'w-full'}
                                            aria-label={'User reviews loading'}
                                        >
                                            <Skeleton />
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                'flex justify-between text-gray-900 text-sm font-bold tracking-tight'
                                            }
                                        >
                                            <p>
                                                {t('reviews:reviewsCount', {
                                                    count: user?.reviewStats.numberOfReviews
                                                })}
                                            </p>

                                            <div className={'flex items-center'}>
                                                <span className={'mx-2'}>
                                                    {user?.reviewStats.averageReview?.toFixed(
                                                        1
                                                    ) ?? '-'}
                                                </span>

                                                <RatingInput
                                                    className={'flex'}
                                                    value={user?.reviewStats.averageReview}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <Link
                                        to={`/reviews/seller/${user?.id}`}
                                        className={
                                            'text-secondary text-xs flex justify-end gap-x-2 pt-4.5 focus:outline-none'
                                        }
                                    >
                                        {isUserLoading ? (
                                            <Skeleton width={60} />
                                        ) : (
                                            <span className={'flex items-center'}>
                                                {t('common:show')}

                                                <Icon className={'ml-1'} icon={'arrow-right'} />
                                            </span>
                                        )}
                                    </Link>
                                </div>

                                <div className={'py-3 flex-row justify-between hidden md:flex'}>
                                    {isUserLoading ? (
                                        <div
                                            className={'w-full'}
                                            aria-label={'User phone number loading'}
                                        >
                                            <Skeleton />
                                        </div>
                                    ) : (
                                        <>
                                            <span
                                                className={'font-bold text-sm tracking-tighter'}
                                            >
                                                {t('auth:phoneNumber')}
                                            </span>

                                            <span
                                                className={
                                                    'font-semibold text-xs tracking-medium-tight'
                                                }
                                            >
                                                {user?.phoneNumber}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}

                        {!isMe && !isUserLoading && (
                            <Button
                                className={'md:my-5 my-4 md:self-center self-end'}
                                size={breakpoint === 'xs' || breakpoint === 'sm' ? 'xs' : 'sm'}
                                variant={'primary'}
                                outline
                                onClick={contactUser}
                            >
                                {t('common:contact')}
                            </Button>
                        )}
                    </div>

                    <div className={'bg-white mt-3.5 py-8 px-4.5 hidden md:flex flex-col'}>
                        {isUserLoading ? (
                            <div aria-label={'User location loading'}>
                                <Skeleton height={60} />
                            </div>
                        ) : (
                            <div className={'flex items-center px-4 py-3 bg-gray-100 rounded'}>
                                <div
                                    className={
                                        'flex justify-center items-center w-10 h-10 rounded-full bg-white'
                                    }
                                >
                                    <Icon icon={'location'} size={'lg'} />
                                </div>

                                <span
                                    className={'ml-5 font-bold'}
                                    role={'complementary'}
                                    aria-label={'user address'}
                                >
                                    {`${user?.address?.city}, ${user?.address?.state}`}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={
                        'md:hidden m-4 border-gray-300 border rounded px-4 py-3 bg-white flex justify-between items-center'
                    }
                >
                    {isUserLoading ? (
                        <Skeleton width={120} />
                    ) : (
                        <div className={'flex items-center'}>
                            <span className={'mx-2 text-sm font-bold tracking-tighter'}>
                                {user?.reviewStats.averageReview?.toFixed(1) ?? '-'}
                            </span>

                            <RatingInput
                                className={'flex'}
                                size={'sm'}
                                value={user?.reviewStats.averageReview}
                                disabled
                            />
                        </div>
                    )}

                    <Link
                        to={`/reviews/seller/${user?.id}`}
                        className={'text-xs tracking-tighter gap-x-2 focus:outline-none'}
                    >
                        {isUserLoading ? (
                            <Skeleton width={60} />
                        ) : (
                            <span className={'flex items-center'}>
                                {t('reviews:reviewsCount', {
                                    count: user?.reviewStats.numberOfReviews
                                })}

                                <Icon className={'ml-1'} icon={'arrow-right'} />
                            </span>
                        )}
                    </Link>
                </div>

                <div className={'flex-grow'}>
                    <div
                        className={'flex flex-row justify-between items-end h-12 mx-4 md:mx-0'}
                    >
                        <div className={'flex flex-row'}>
                            <h1 className={'hidden md:flex'}>{t('common:offers')}</h1>

                            <h2
                                aria-label={'offers count'}
                                className={
                                    'md:text-gray-400 md:mx-8 mx-4 md:font-normal tracking-medium-tight text-sm md:text-base'
                                }
                            >
                                {isUserLoading && areOffersLoading ? (
                                    <div
                                        className={'w-20'}
                                        role={'heading'}
                                        aria-level={2}
                                        aria-label={'Offers count loading'}
                                    >
                                        <Skeleton />
                                    </div>
                                ) : (
                                    t('common:offersCount', {
                                        count:
                                            user?.offerStats.numberOfOffers ??
                                            offers?.pagination.totalItems ??
                                            0
                                    })
                                )}
                            </h2>
                        </div>

                        <div className={'flex flex-row'}>
                            {!areOffersLoading && breakpoint !== 'xs' && (
                                <>
                                    <Select
                                        className={'mr-14 bg-white w-50'}
                                        styles={styles}
                                        theme={theme}
                                        placeholder={t('common:sort')}
                                        options={sortOptions}
                                        value={sortOptions.find(opt => opt.value === sorting)}
                                        onChange={opt => setSorting(opt?.value)}
                                    />

                                    <PaginationView
                                        currentPage={currentPage}
                                        totalPages={offers?.pagination.totalPages ?? 0}
                                        onPageChange={handlePageChange}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div className={'flex flex-col gap-y-3 md:my-9 my-3 w-full'}>
                        {areOffersLoading ? (
                            _.times(4, i => <OfferView key={i} variant={'home'} loading />)
                        ) : areOffersErrored ? (
                            <h2 className={'text-center'} aria-label={'offers loading failed'}>
                                {t('errors:loadingErrorMessage')}
                            </h2>
                        ) : offers?.items.length ? (
                            offers?.items.map(offer => (
                                <OfferView key={offer.id} offer={offer} variant={'home'} />
                            ))
                        ) : (
                            <h2 className={'text-center'}>{t('profile:userHasNoOffers')}</h2>
                        )}
                    </div>

                    <div className={'flex flex-row justify-end mx-4 md:mx-0'}>
                        {areOffersLoaded && !!offers?.items.length && (
                            <PaginationView
                                currentPage={currentPage}
                                totalPages={offers?.pagination.totalPages ?? 0}
                                onPageChange={handlePageChange}
                            />
                        )}
                    </div>
                </div>
            </div>

            <PopularSearches />

            <Footer />

            <MobileBottomNav />
        </div>
    );
};

export default UserProfileView;
