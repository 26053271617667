import {fromLatLng} from 'services/geocode';
import * as actions from 'store/actions/version';
import {call, put, takeLatest} from 'redux-saga/effects';

export function* getVersion(action: ReturnType<typeof actions.getVersion.request>) {
    try {
        const {results}: {results: any[]} = yield call(fromLatLng, {
            lat: action.payload.lat,
            lng: action.payload.lng
        });
        yield put(
            actions.getVersion.success({
                short_name: results[0].address_components.find((c: any) =>
                    c.types.includes('country')
                ).short_name
            })
        );
    } catch (err) {
        yield put(actions.getVersion.failure(err));
    }
}

export const saga = [takeLatest(actions.getVersion.request, getVersion)];
