//Hooks
import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'hooks/store';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import {useLocation, useNavigate} from '@reach/router';
import {useTranslation} from 'react-i18next';

//Components
import {CardNumberElement} from '@stripe/react-stripe-js';

//Actions
import * as actions from 'store/actions';

//Utils
import toast from 'react-hot-toast';
import {validationErrorToShowInToast} from 'utils/payment';

//Types
import {FormEvent} from 'react';
import {User, Order} from 'store/types';

export const usePaymentFormView = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSuccessModalVisible, setSuccessModalVisible] = useState<boolean>(false);
    const [usedCard, setUsedCard] = useState<{label: string; value: string}>();
    const stripe = useStripe();
    const dispatch = useDispatch();
    const location = useLocation();
    const elements = useElements();
    const navigate = useNavigate();

    const {extraFeature} = useSelector(state => state.basket.payment);

    const openSuccessModal = () => setSuccessModalVisible(true);
    const closeSuccessModal = useCallback(() => {
        setSuccessModalVisible(false);
        if (extraFeature?.featureType === 'extraPhotos') {
            navigate(`/offers/edit/${extraFeature.id}`);
        } else {
            dispatch(actions.clearExtraFeature());
        }
    }, [dispatch, navigate, extraFeature]);

    const clientId = useSelector(
        state => state.payment?.payment?.payment.gatewayData.clientSecret ?? null
    );

    const savedCards = useSelector(state => state.user?.cards?.data);

    const transformedCards = savedCards?.map((item: User.Cards) => {
        return {
            label: [item.creditCardNumber.slice(0, 4), item.creditCardNumber.slice(4)].join(
                ' '
            ),
            value: item.extraCardData.paymentMethodId
        };
    });

    const feature = useSelector(state => state.basket?.payment?.extraFeature);

    const {t} = useTranslation('payment');

    const handleError = useCallback(
        (error: any) => {
            const errorToShow = validationErrorToShowInToast(error);
            if (errorToShow) {
                toast.error(errorToShow);
            } else if (error.message) {
                toast.error(error.message);
            }

            if (!errorToShow && error.code === 400) {
                navigate(-1);
            }
        },
        [navigate]
    );

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            const card = elements.getElement(CardNumberElement);
            if (!card) {
                return;
            }

            setLoading(true);

            let resp: Order.CreateOrderResponse | null = null;
            if (!location.pathname.includes('/extras')) {
                try {
                    resp = await dispatch(actions.createOrder.request());
                } catch (error) {
                    setLoading(false);
                    handleError(error);
                    return;
                }
            }

            if (!resp?.payment.gatewayData.clientSecret && !clientId) {
                setLoading(false);
                return;
            }

            const {error, paymentIntent} = await stripe.confirmCardPayment(
                resp?.payment.gatewayData.clientSecret ?? clientId!,
                {
                    payment_method: {
                        card
                    }
                }
            );

            setLoading(false);

            if (error) {
                toast.error(t('errorMsgInvalidPayment'));
            }

            if (paymentIntent) {
                toast.success(t('paymentPassed'));
                if (location.pathname.includes('/extras')) {
                    openSuccessModal();
                } else {
                    navigate('/payment/success');
                }
            }
        },
        [clientId, elements, location.pathname, navigate, stripe, t, dispatch, handleError]
    );

    const handleSavedCard = async () => {
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardNumberElement);

        if (!card) {
            return;
        }

        let resp: Order.CreateOrderResponse | null = null;
        if (!location.pathname.includes('/extras')) {
            try {
                resp = await dispatch(actions.createOrder.request());
            } catch (error) {
                setLoading(false);
                handleError(error);
                return;
            }
        }

        const {error, paymentIntent} = await stripe.confirmCardPayment(
            resp?.payment.gatewayData.clientSecret ?? clientId!,
            {
                payment_method: usedCard!.value
            }
        );

        if (error) {
            toast.error(t('errorMsgInvalidPayment'));
        }

        if (paymentIntent) {
            toast.success(t('paymentPassed'));
            if (location.pathname.includes('/extras')) {
                openSuccessModal();
            } else {
                navigate('/payment/success');
            }
        }
    };

    return {
        handleSubmit,
        isLoading,
        isSuccessModalVisible,
        closeSuccessModal,
        feature,
        savedCards,
        transformedCards,
        usedCard,
        setUsedCard,
        handleSavedCard
    };
};
