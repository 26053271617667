//Hooks
import {useTranslation} from 'react-i18next';
import {useWindowSize} from 'hooks/windowSize';
import {useMyProfileView} from './MyProfileView.hook';

//Components
import {Fragment} from 'react';
import {Link} from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import Badge from 'components/ui/Badge';
import Icon from 'components/ui/Icon';
import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';
import RatingInput from 'components/ui/RatingInput';
import OptionalLink from 'components/nav/OptionalLink';
import StatsView from 'components/stats/StatsView';
import MobileLink from './MobileLink';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';

//Types
import {RouteComponentProps} from '@reach/router';
import {User} from 'store/types';

const MyProfileView: React.FC<RouteComponentProps> = () => {
    const {
        user,
        stats,
        areStatsLoading,
        staticPages,
        displayName,
        handleUploadAvatar,
        handleDeleteAvatar,
        handleLogout,
        isModalOpen,
        setModalOpen
    } = useMyProfileView();
    const {breakpoint} = useWindowSize();
    const {t} = useTranslation(['common', 'nav', 'reviews']);

    const cameraButton = () => (
        <div
            className={
                'absolute px-3 py-2 cursor-pointer text-white border border-white rounded-md right-1 bottom-1 md:right-2 md:bottom-2 focus:outline-none filter drop-shadow-xs'
            }
        >
            <Icon
                icon={'camera'}
                size={breakpoint === 'xs' || breakpoint === 'sm' ? 'sm' : 'md'}
            />
        </div>
    );

    return (
        user && (
            <div
                className={
                    'bg-white md:border border-gray-300 rounded md:p-10 p-4 md:pt-3 flex flex-col'
                }
            >
                <Badge variant={'secondary'} className={'self-end hidden md:flex'} size={'lg'}>
                    {user?.type === User.Type.Person
                        ? t('common:private')
                        : t('common:business')}
                </Badge>

                <div className={'flex flex-col'}>
                    <div className={'flex flex-row'}>
                        <div
                            className={
                                'relative md:h-36 md:w-36 md:min-w-96 h-24 w-24 min-w-24'
                            }
                            role={'img'}
                            aria-label={displayName}
                        >
                            <img
                                src={user?.avatar ?? defaultAvatar}
                                onError={e => {
                                    e.currentTarget.src = user.avatarFallback ?? defaultAvatar;
                                }}
                                alt={displayName}
                                aria-label={'avatar'}
                                className={'object-cover rounded-md w-full h-full'}
                            />

                            {user?.avatar ? (
                                <button onClick={() => setModalOpen(true)}>
                                    {cameraButton()}
                                </button>
                            ) : (
                                <label>
                                    {cameraButton()}

                                    <input
                                        type={'file'}
                                        className={'hidden'}
                                        onChange={e => handleUploadAvatar(e.target.files)}
                                    />
                                </label>
                            )}
                        </div>

                        <div className={'md:mx-12 md:my-3 mx-5 w-full md:max-w-86'}>
                            <h1 className={'text-xl md:text-2xl tracking-tight'}>
                                {t('common:hello', {name: displayName})}
                            </h1>

                            <OptionalLink
                                to={breakpoint === 'xs' ? '/reviews/me' : ''}
                                className={
                                    'my-4 border border-gray-300 rounded px-5 py-4 flex justify-between items-center'
                                }
                            >
                                <div>
                                    <div className={'flex items-center gap-x-2'}>
                                        {areStatsLoading ? (
                                            <div aria-label={'Reviews average loading'}>
                                                <Skeleton width={90} />
                                            </div>
                                        ) : (
                                            <>
                                                <span
                                                    className={
                                                        'text-sm font-bold tracking-tighter'
                                                    }
                                                >
                                                    {stats?.reviews?.average?.toFixed(1) ?? '-'}
                                                </span>

                                                <RatingInput
                                                    className={'flex'}
                                                    value={stats?.reviews?.average}
                                                    disabled
                                                />
                                            </>
                                        )}
                                    </div>

                                    <div className={'text-xs tracking-tighter mt-3'}>
                                        {areStatsLoading ? (
                                            <div aria-label={'Reviews count loading'}>
                                                <Skeleton width={60} />
                                            </div>
                                        ) : (
                                            t('reviews:reviewsCount', {
                                                count: stats?.reviews?.counter ?? 13
                                            })
                                        )}
                                    </div>
                                </div>

                                <Link
                                    to={'/reviews/me'}
                                    className={
                                        'flex items-center gap-x-2 text-xs tracking-medium-tight'
                                    }
                                >
                                    <span className={'hidden md:flex'}>
                                        {t('common:showAll')}
                                    </span>

                                    <Icon icon={'arrow-right'} />
                                </Link>
                            </OptionalLink>
                        </div>

                        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                            <div className={'flex flex-col items-center gap-y-10'}>
                                <img
                                    src={user?.avatar ?? defaultAvatar}
                                    alt={displayName}
                                    aria-label={'avatar'}
                                    className={'object-cover rounded-md w-36 h-36'}
                                />

                                <div className={'flex flex-row w-full px-4 justify-between'}>
                                    <Button
                                        as={'label'}
                                        role={'button'}
                                        aria-labelledby={'add-photo-button-label'}
                                        size={'xs'}
                                        variant={'primary'}
                                        className={'cursor-pointer'}
                                        outline
                                    >
                                        <span className={'flex flex-row items-center gap-x-1'}>
                                            <Icon icon={'camera'} size={'md'} />

                                            <span id={'add-photo-button-label'}>
                                                {t('common:addPhoto')}
                                            </span>

                                            <input
                                                type={'file'}
                                                className={'hidden'}
                                                onChange={e =>
                                                    handleUploadAvatar(e.target.files)
                                                }
                                            />
                                        </span>
                                    </Button>

                                    <Button
                                        className={'border-white'}
                                        size={'xs'}
                                        variant={'primary'}
                                        onClick={handleDeleteAvatar}
                                        aria-labelledby={'delete-button-label'}
                                        outline
                                    >
                                        <span className={'flex flex-row items-center gap-x-1'}>
                                            <span>
                                                <Icon icon={'delete'} size={'md'} />
                                            </span>

                                            <span id={'delete-button-label'}>
                                                {t('common:delete')}
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    <StatsView className={'mt-14 hidden md:block'} />
                </div>

                <div className={'flex flex-col md:hidden mt-14'}>
                    <MobileLink to={'/profile/my-offers'}>
                        {t('nav:navbar.myOffers')}
                    </MobileLink>

                    <MobileLink to={'/profile/my-purchases'}>
                        {t('nav:navbar.myPurchases')}
                    </MobileLink>

                    <MobileLink to={'/profile/account-settings'}>
                        {t('nav:navbar.accountSettings')}
                    </MobileLink>

                    {staticPages?.map(group => (
                        <Fragment key={group.title}>
                            <span
                                className={
                                    'mt-5 font-bold text-gray-300 text-sm tracking-tight'
                                }
                            >
                                {group.title}
                            </span>

                            {group.pages.map(page => (
                                <MobileLink
                                    key={page.slug}
                                    to={`/info/${page.slug}`}
                                    aria-label={page.slug}
                                >
                                    {page.title}
                                </MobileLink>
                            ))}
                        </Fragment>
                    ))}

                    <button
                        className={
                            'border-b border-gray-200 pt-8 pb-3.5 text-sm font-semibold tracking-tighter text-left w-full'
                        }
                        onClick={handleLogout}
                    >
                        {t('nav:navbar.logout')}
                    </button>
                </div>
            </div>
        )
    );
};

export default MyProfileView;
