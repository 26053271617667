import * as React from 'react';

//Hooks
import {useAdsCarouselView} from './AdsCarouselView.hook';

//Components
import Ad, {AdProps} from './Ad';

//Utils
import cx from 'classnames';

export type AdsCarouselProps = {
    children: React.ReactElement<AdProps, typeof Ad> | React.ReactElement<AdProps, typeof Ad>[];
    intervalSeconds: number;
    className?: string;
} & React.HTMLProps<HTMLDivElement>;

const AdsCarousel: React.VFC<AdsCarouselProps> = ({
    children,
    intervalSeconds,
    className,
    ...props
}) => {
    const {activeImageIndex} = useAdsCarouselView({children, intervalSeconds});

    return (
        <div className={cx(className, 'flex flex-row overflow-hidden')} {...props}>
            {React.Children.map(children, (child, i) => (
                <div
                    key={i}
                    className={
                        'flex-grow-0 flex-shrink-0 w-full h-full transform-gpu transition-transform duration-500'
                    }
                    style={{transform: `translateX(-${activeImageIndex * 100}%)`}}
                    role={'tab'}
                    aria-label={`Advertisement`}
                    aria-selected={activeImageIndex === i}
                >
                    {React.cloneElement(child as React.ReactElement, {
                        key: `advertisement-${i + 1}`
                    })}
                </div>
            ))}
        </div>
    );
};

export {Ad};
export default AdsCarousel;
