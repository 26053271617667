//Components
import {Link} from '@reach/router';

export type OptionalLinkProps = {
    className?: string;
    to: string;
};

const OptionalLink: React.FC<OptionalLinkProps> = ({children, to, ...props}) =>
    !!to ? (
        <Link to={to} {...props}>
            {children}
        </Link>
    ) : (
        <div {...props}>{children}</div>
    );

export default OptionalLink;
