//Action creators
import {createPromiseAction} from 'redux-saga-promise-actions';
import {createAsyncAction} from 'typesafe-actions';

//Types
import {Errors, Offer} from 'store/types';

/**
 * Retrieves list of favorites.
 */
export const getFavorites = createAsyncAction(
    'GET_FAVORITES_REQUEST',
    'GET_FAVORITES_SUCCESS',
    'GET_FAVORITES_FAILURE'
)<number | undefined, Offer.FavoritesListResponse, Errors.API>();

/**
 * Returns favorite status of given offers.
 */
export const checkFavorites = createAsyncAction(
    'CHECK_FAVORITES_REQUEST',
    'CHECK_FAVORITES_SUCCESS',
    'CHECK_FAVORITES_FAILURE'
)<Offer.BaseListItem[], Offer.FavoriteCheckResult[], Errors.API>();

/**
 * Adds offer to favorites.
 */
export const addFavorite = createPromiseAction('ADD_FAVORITE')<
    Offer.BaseListItem,
    Offer.FavoriteCheckResult,
    Errors.API
>();

/**
 * Removes offer from favorites.
 */
export const removeFavorite = createPromiseAction('REMOVE_FAVORITE')<
    {favoriteId: number},
    number,
    Errors.API
>();
