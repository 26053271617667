//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Offer} from 'store/types';

export type FavoritesAction = ActionType<typeof actions>;

export type State = Readonly<{
    favorites: Offer.FavoriteCheckResult[];
    offers: Data<Offer.ListResponse>;
}>;

export const initialState: State = {
    favorites: [],
    offers: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    }
};

export default createReducer<State, FavoritesAction>(initialState)
    .handleAction(actions.getFavorites.request, state => ({
        ...state,
        offers: {data: null, isLoading: true, isLoaded: false, isErrored: false}
    }))
    .handleAction(actions.getFavorites.success, (_state, action) => ({
        favorites: action.payload.items.map(item => ({
            offerId: item.data.id,
            favoriteId: item.favoriteId
        })),
        offers: {
            data: {
                items: action.payload.items.map(item => item.data),
                pagination: action.payload.pagination
            },
            isLoaded: true,
            isLoading: false,
            isErrored: false
        }
    }))
    .handleAction(actions.getFavorites.failure, state => ({
        ...state,
        offers: {data: null, isLoading: false, isLoaded: false, isErrored: true}
    }))
    .handleAction(actions.checkFavorites.success, (state, action) => ({
        ...state,
        favorites: action.payload
    }))
    .handleAction(actions.addFavorite.success, (state, action) => ({
        ...state,
        favorites: [...state.favorites, action.payload]
    }))
    .handleAction(actions.removeFavorite.success, (state, action) => ({
        ...state,
        favorites: state.favorites.filter(fav => fav.favoriteId !== action.payload)
    }))
    .handleAction(actions.logout.success, () => initialState);
