//Hooks
import {useDatePicker} from './DatePicker.hook';

//Components
import ReactDatePicker from 'react-datepicker';
import Input from 'components/ui/Input';
import Icon from 'components/ui/Icon';

//Styles
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

//Utils
import dayjs from 'dayjs';

export type DatePickerProps = {
    onChange: (
        field: string,
        date: Date | [Date, Date] | null,
        shouldValidate?: boolean | undefined
    ) => void;
    name: string;
    id?: string;
    value?: Date;
    label?: string;
    className?: string;
    years?: [number, number];
    dateFormat?: string;
    error?: string | undefined | null | false;
};

const DatePicker: React.VFC<DatePickerProps> = ({
    id,
    name,
    value,
    onChange,
    label,
    className,
    error,
    dateFormat = 'dd-MM-yyyy'
}) => {
    const {
        isMonthDropdownVisible,
        toggleMonthDropdown,
        monthDropdownRef,
        isYearDropdownVisible,
        toggleYearDropdown,
        yearDropdownRef,
        years
    } = useDatePicker();

    return (
        <ReactDatePicker
            id={id || name}
            name={name}
            className={className}
            renderCustomHeader={({date, changeYear, changeMonth}) => (
                <div
                    className={'flex justify-center border-b border-white'}
                    role={'region'}
                    aria-label={'Header'}
                >
                    <div
                        ref={yearDropdownRef}
                        className={'relative w-6/12 py-5 border-r border-white cursor-pointer'}
                        onClick={toggleYearDropdown}
                    >
                        <div
                            className={
                                'flex items-center justify-end px-6 text-white text-datepicker color-gray-900 gap-x-6'
                            }
                        >
                            {date.getFullYear()}

                            <Icon icon={'datepicker-dropdown'} />
                        </div>

                        {isYearDropdownVisible && (
                            <div
                                className={
                                    'absolute p-4 overflow-scroll bg-white border border-gray-300 top-14 max-h-72'
                                }
                                role={'region'}
                                aria-label={'Year dropdown'}
                            >
                                {years.map(year => (
                                    <button
                                        key={year}
                                        className={
                                            'px-10 border-b border-gray-200 cursor-pointer text-datepicker py-2.5'
                                        }
                                        onClick={() => {
                                            changeYear(year);
                                            toggleYearDropdown();
                                        }}
                                    >
                                        {year}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>

                    <div
                        ref={monthDropdownRef}
                        className={'relative w-6/12 py-5 cursor-pointer'}
                        onClick={toggleMonthDropdown}
                    >
                        <div
                            className={
                                'flex items-center justify-end px-6 text-white text-datepicker color-gray-900 gap-x-6'
                            }
                        >
                            {dayjs(date).format('MMMM')}

                            <Icon icon={'datepicker-dropdown'} />
                        </div>

                        {isMonthDropdownVisible && (
                            <div
                                className={
                                    'absolute right-0 p-4 overflow-scroll bg-white border border-gray-300 top-14 max-h-72'
                                }
                                role={'region'}
                                aria-label={'Month dropdown'}
                            >
                                {dayjs.months().map((month, i) => (
                                    <button
                                        key={month}
                                        className={
                                            'px-10 border-b border-gray-200 cursor-pointer text-datepicker py-2.5'
                                        }
                                        onClick={() => {
                                            changeMonth(i);
                                            toggleMonthDropdown();
                                        }}
                                    >
                                        {month}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
            selected={(value && new Date(value)) || null}
            onChange={date => onChange(name, date)}
            dateFormat={dateFormat}
            customInput={<Input label={label} id={id || name} error={error} />}
        />
    );
};

export default DatePicker;
