//Action creators
import {createAsyncAction} from 'typesafe-actions';

//Types
import {Adverts, Errors} from 'store/types';
/**
 * Gets advertisements to display on site.
 */
export const getAdvertisements = createAsyncAction(
    'GET_ADVERTISEMENTS_REQUEST',
    'GET_ADVERTISEMENTS_SUCCESS',
    'GET_ADVERTISEMENTS_FAILURE'
)<undefined, Adverts.Advertisement[], Errors.API>();
