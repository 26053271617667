//Hooks
import {useEffect, useMemo, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'hooks/store';
import {useLocation} from '@reach/router';
import {useTranslation} from 'react-i18next';
import {useCategories} from 'hooks/categories';
import {useAdverts} from 'hooks/adverts';
import {useLocationSearchPagination} from 'hooks/locationSearchPagination';

//Actions
import * as actions from 'store/actions';

//Utils
import qs from 'querystring';

//Types
import {Categories, Search, Adverts} from 'store/types';

export type SortKey = 'priceAscending' | 'priceDescending' | 'newest' | 'oldest';
export const SortChoices: Record<SortKey, Search.Sort> = {
    priceAscending: {field: 'price', order: 'asc'},
    priceDescending: {field: 'price', order: 'desc'},
    newest: {field: 'date', order: 'desc'},
    oldest: {field: 'date', order: 'asc'}
};

export const useSearchView = () => {
    const {data: categories} = useCategories();
    const {data: allAdverts, isLoaded: areAdvertsLoaded} = useAdverts();

    const {data: result, isLoading: isResultLoading, props: filterValues} = useSelector(
        state => state.search.result
    );
    const dispatch = useDispatch();
    const {t} = useTranslation('common');

    const location = useLocation();
    const search = qs.parse(location.search.substring(1));

    const phrase = search['phrase'] as string | undefined;
    const categoryId = search['categoryId']
        ? parseInt(search['categoryId'] as string)
        : undefined;

    const sortOptions = Object.entries(SortChoices).map(([key, value]) => ({
        label: t(key as keyof typeof SortChoices),
        value
    }));
    const adverts = categoryId
        ? allAdverts?.filter?.(ad => ad.categoryIds?.includes(categoryId))
        : allAdverts;
    const advertsMobile = adverts?.filter?.(ad => ad.area === Adverts.CategoryArea.Mobile);
    const advertsDesktop = adverts?.filter?.(ad => ad.area === Adverts.CategoryArea.Web);
    const [sorting, setSorting] = useState<Search.Sort | null>(null);
    const [isFilterOpen, setFilterOpen] = useState(false);
    const [isSortOpen, setSortOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const mobileSortConfirm = () => {
        dispatch(actions.search.request({...filterValues, sort: sorting ?? undefined}));
        setSortOpen(false);
    };

    const {changePage, pageFromSearch} = useLocationSearchPagination({
        ref
    });

    const handlePageChange = (page: number) => {
        changePage(page);
        dispatch(actions.search.request({...filterValues, page}));
    };

    useEffect(() => {
        if (result && result?.pagination.currentPage !== pageFromSearch) {
            dispatch(actions.search.request({...filterValues, page: pageFromSearch}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageFromSearch]);

    useEffect(() => {
        if (filterValues && !filterValues.page && !isResultLoading) {
            const pageNumberFromPath = parseInt(
                qs.parse(window.location.search)['page'].toString() as any,
                10
            );
            changePage(pageNumberFromPath);
            dispatch(actions.search.request({...filterValues, page: pageNumberFromPath || 1}));
        }
    }, [filterValues, changePage, isResultLoading, dispatch]);

    useEffect(() => {
        if (result && result?.pagination.totalPages < pageFromSearch) {
            handlePageChange(result?.pagination.totalPages);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result, handlePageChange]);

    useEffect(() => {
        dispatch(
            actions.search.request({
                phrase,
                category: categories?.find(({id}) => id === categoryId),
                page: pageFromSearch
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phrase, categories, categoryId, dispatch]);

    const category = useMemo<Categories.Category | null>(() => {
        if (!categories) {
            return null;
        }

        return (
            categories.find(({id}) => id === parseInt(search['categoryId'] as string)) ?? null
        );
    }, [categories, search]);

    const categoriesTree = useMemo<Categories.Category[] | null>(() => {
        if (!categories || !category) {
            return null;
        }

        const tree: Categories.Category[] = [];
        let current: Categories.Category = category;
        while (true) {
            tree.push(current);
            if (!current.parentId) {
                break;
            }

            current = categories.find(({id}) => id === current.parentId)!;
        }
        tree.reverse();

        return tree;
    }, [categories, category]);

    return {
        categoriesTree,
        category,
        categories,
        result,
        isResultLoading,
        sortOptions,
        sorting,
        setSorting,
        isFilterOpen,
        setFilterOpen,
        isSortOpen,
        setSortOpen,
        mobileSortConfirm,
        handlePageChange,
        advertsMobile,
        advertsDesktop,
        areAdvertsLoaded,
        ref,
        pageFromSearch
    };
};
