//Hooks
import {useDispatch} from 'hooks/store';
import {useEffect} from 'react';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';

//Actions
import * as actions from 'store/actions';

//Utils
import {object, string} from 'yup';

//Services
import toast from 'react-hot-toast';

//Types
import {Reviews} from 'store/types';

type UseDisputeReviewModalArgs = {
    isOpen: boolean;
    reviewId: number;
    onClose: () => void;
};

export const useDisputeReviewModal = ({
    reviewId,
    isOpen,
    onClose
}: UseDisputeReviewModalArgs) => {
    const dispatch = useDispatch();

    const {t} = useTranslation('reviews');

    const validationSchema = object<Reviews.DisputeReviewValue>({
        description: string().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm
    } = useFormik<Reviews.DisputeReviewValue>({
        initialValues: {
            description: ''
        },
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.disputeReview.request({...payload, reviewId}));
                onClose();
                toast.success(t('disputeSubmitted'));
            } catch (err) {
                if (err.message) {
                    toast.error(err.message);
                }

                setErrors(err.errors ?? {});
            }
        }
    });

    useEffect(() => {
        resetForm();
    }, [isOpen, resetForm]);

    return {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    };
};
