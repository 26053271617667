//Hooks
import {useEditMyAddressView} from './EditMyAddressModal.hook';
import {useTranslation} from 'react-i18next';

//Components
import Select from 'react-select';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

//Styles
import {styles, errorStyles, theme} from 'styles/select';

//Types
import {User} from 'store/types';

export type EditMyAddressModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const EditMyAddressModal: React.VFC<EditMyAddressModalProps> = ({isOpen, onClose}) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        user,
        states,
        selectedState
    } = useEditMyAddressView(onClose);

    const {t} = useTranslation(['profile', 'common']);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <form className={'flex flex-col'} onSubmit={handleSubmit}>
                <div className={'flex flex-col'}>
                    {user?.type === User.Type.Person ? (
                        <>
                            <Input
                                id={'firstName'}
                                name={'firstName'}
                                value={values.firstName}
                                onChange={handleChange}
                                label={t('profile:firstName')}
                                error={touched.firstName && errors.firstName}
                            />

                            <Input
                                id={'lastName'}
                                name={'lastName'}
                                value={values.lastName}
                                onChange={handleChange}
                                className={'mt-3.5'}
                                label={t('profile:lastName')}
                                error={touched.lastName && errors.lastName}
                            />
                        </>
                    ) : (
                        <>
                            <Input
                                id={'companyName'}
                                name={'companyName'}
                                value={values.companyName}
                                onChange={handleChange}
                                label={t('profile:companyName')}
                                error={touched.companyName && errors.companyName}
                            />

                            <Input
                                id={'vatId'}
                                name={'vatId'}
                                value={values.vatId}
                                onChange={handleChange}
                                className={'mt-3.5'}
                                label={t('profile:vatId')}
                                error={touched.vatId && errors.vatId}
                            />
                        </>
                    )}

                    <Input
                        id={'street'}
                        name={'street'}
                        value={values.street}
                        onChange={handleChange}
                        className={'mt-3.5'}
                        label={t('profile:street')}
                        error={touched.street && errors.street}
                    />

                    <Input
                        id={'apartmentNumber'}
                        name={'apartmentNumber'}
                        value={values.apartmentNumber}
                        onChange={handleChange}
                        className={'mt-3.5'}
                        label={t('profile:apartmentNumber')}
                        error={touched.apartmentNumber && errors.apartmentNumber}
                    />

                    <Input
                        id={'city'}
                        name={'city'}
                        value={values.city}
                        onChange={handleChange}
                        className={'mt-3.5'}
                        label={t('profile:city')}
                        error={touched.city && errors.city}
                    />

                    <div className={'flex gap-x-12'}>
                        <div
                            className={'mt-3.5 w-full'}
                            role={'region'}
                            aria-label={'values.state'}
                        >
                            <label htmlFor={'values.state'} className={'block mb-3 text-sm'}>
                                {t('profile:state')}
                            </label>

                            <Select
                                id={'values.state'}
                                name={'values.state'}
                                className={'w-full'}
                                options={states}
                                noOptionsMessage={() => t('common:noOptions')}
                                value={selectedState}
                                onChange={option => setFieldValue('state', option?.value)}
                                styles={touched.state && errors.state ? errorStyles : styles}
                                theme={theme}
                            />

                            {touched.state && errors.state && (
                                <span
                                    className={'tracking-tight text-red text-form-helper'}
                                    role={'alert'}
                                    aria-label={'error'}
                                >
                                    {errors.state}
                                </span>
                            )}
                        </div>

                        <Input
                            id={'postCode'}
                            name={'postCode'}
                            value={values.postCode}
                            onChange={handleChange}
                            className={'mt-3.5'}
                            label={t('profile:postCode')}
                            error={touched.postCode && errors.postCode}
                        />
                    </div>
                </div>

                <Button
                    className={'mt-18 w-full'}
                    variant={'primary'}
                    type={'submit'}
                    disabled={isSubmitting}
                >
                    {t('profile:save')}
                </Button>
            </form>
        </Modal>
    );
};

export default EditMyAddressModal;
