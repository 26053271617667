//Hooks
import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {useWindowSize} from 'hooks/windowSize';

//Components
import Button from 'components/ui/Button';

//Types
import {Search} from 'store/types';

const FormSubmit: React.VFC<{sorting?: Search.Sort | null}> = ({sorting}) => {
    const {values, setFieldValue, handleSubmit} = useFormikContext<Search.FormValues>();
    const {breakpoint} = useWindowSize();
    const {t} = useTranslation('common');

    useEffect(() => {
        if (breakpoint !== 'xs' && breakpoint !== 'sm' && breakpoint !== 'md') {
            handleSubmit();
        }
    }, [values, breakpoint, handleSubmit]);

    useEffect(() => {
        setFieldValue('sort', sorting);
    }, [sorting, setFieldValue]);

    return (
        <Button
            variant={'primary'}
            size={'sm'}
            className={'self-center my-6 lg:hidden'}
            type={'submit'}
        >
            {t('filter')}
        </Button>
    );
};

export default FormSubmit;
