//Utils
import _ from 'lodash-es';

//Hooks
import {useMyPurchaseDetails} from './MyOffersDetailsView.hook';
import {useTranslation} from 'react-i18next';

//Components
import Button from 'components/ui/Button';
import OfferView from 'components/offer/OfferView';
import Skeleton from 'react-loading-skeleton';
import Input from 'components/ui/Input';
import Icon from 'components/ui/Icon';
import {Link} from '@reach/router';
import {Trans} from 'react-i18next';

//Types
import {RouteComponentProps} from '@reach/router';
import {Order} from 'store/types';

const MyOffersDetailsView: React.VFC<RouteComponentProps<{orderId: number}>> = ({orderId}) => {
    const {
        order,
        isLoading,
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        showShipmentLinkForm,
        setShowShipmentLinkForm
    } = useMyPurchaseDetails({orderId});
    const {t} = useTranslation(['orders', 'common']);

    return (
        <div className={'flex flex-col gap-y-5'}>
            {isLoading ? (
                <>
                    <div className={'p-3 md:p-0'}>
                        <Skeleton height={18} width={200} />
                    </div>

                    {_.times(5, () => (
                        <OfferView variant={'home'} loading />
                    ))}
                </>
            ) : (
                <>
                    <h2 className={'p-3 md:p-0'}>{t('orders:orderDetails')}</h2>

                    {order?.products?.map((product: Order.Product) => (
                        <OfferView
                            variant={'profile'}
                            product={product}
                            key={product.productId}
                            showDetails={false}
                        />
                    ))}

                    <div
                        className={
                            'bg-white py-6 px-9 flex flex-col sm:flex-row gap-x-24 gap-y-12 text-sm'
                        }
                    >
                        <div>
                            <p className={'font-semibold mb-5'}>{t('orders:contactDetails')}</p>

                            <Link to={`/user/${order?.buyer?.id}`}>
                                <Trans
                                    i18nKey={'orders:user'}
                                    values={{username: order?.buyer?.username}}
                                    components={{
                                        span: <span className={'text-secondary'} />
                                    }}
                                />
                            </Link>

                            <p>{t('orders:phone', {phone: order?.buyer?.phone})}</p>
                        </div>

                        <div>
                            <p className={'font-semibold mb-5 flex items-center gap-x-2'}>
                                <span className={'text-gray-600'}>
                                    <Icon icon={'transport'} size={'md'} />
                                </span>

                                {t('orders:shipmentDetails')}
                            </p>

                            <p>
                                {order?.shippingAddress
                                    ? t('orders:addressDetails', {
                                          street: order?.shippingAddress?.street,
                                          apNumber: order?.shippingAddress?.apartmentNumber
                                      })
                                    : t('common:pickupAtSeller')}
                            </p>

                            <p>
                                {order?.shippingAddress &&
                                    t('orders:addressBase', {
                                        city: order?.shippingAddress?.city,
                                        state: order?.shippingAddress?.state,
                                        postCode: order?.shippingAddress?.postCode
                                    })}
                            </p>
                        </div>
                    </div>

                    {order?.status === Order.Status.Confirmed && order?.shippingAddress && (
                        <div className={'bg-white py-6 px-9 text-sm'}>
                            <p className={'font-semibold mb-5'}>{t('orders:shipmentLink')}</p>

                            {showShipmentLinkForm ? (
                                <form
                                    onSubmit={handleSubmit}
                                    className={'flex gap-x-9 items-center'}
                                >
                                    <Input
                                        id={'shipmentLink'}
                                        name={'shipmentLink'}
                                        value={values.shipmentLink}
                                        onChange={handleChange}
                                        error={touched.shipmentLink && errors.shipmentLink}
                                        className={'w-full'}
                                    />

                                    <Button
                                        variant={'primary'}
                                        outline
                                        size={'xs'}
                                        type={'submit'}
                                    >
                                        {t('orders:insert')}
                                    </Button>
                                </form>
                            ) : (
                                <p
                                    className={
                                        'flex gap-x-9 items-center justify-between font-bold'
                                    }
                                >
                                    {order.shipmentLink}

                                    <Button
                                        variant={'primary'}
                                        size={'xs'}
                                        outline
                                        onClick={() => setShowShipmentLinkForm(true)}
                                    >
                                        {t('orders:change')}
                                    </Button>
                                </p>
                            )}
                        </div>
                    )}

                    <div className={'bg-white py-6 px-9 text-sm'}>
                        <p className={'font-semibold mb-5'}>{t('orders:sellerDetails')}</p>

                        <div className={'flex justify-between flex-col sm:flex-row gap-y-2'}>
                            <p>
                                <Trans
                                    i18nKey={'orders:user'}
                                    values={{username: order?.seller?.username}}
                                    components={{span: <span />}}
                                />
                            </p>

                            <p>{t('orders:phone', {phone: order?.seller?.phone})}</p>

                            <p>{t('orders:email', {email: order?.seller?.email})}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default MyOffersDetailsView;
