//Hooks
import {useTranslation} from 'react-i18next';
import {useWindowSize} from 'hooks/windowSize';

//Components
import {Link} from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import Icon from 'components/ui/Icon';
import LoadingWrapperFactory from 'components/wrappers/LoadingWrapperFactory';

//Images
import defaultPhoto from 'assets/files/defaultOffer.jpg';

//Utils
import cx from 'classnames';

//Types
import {Offer, User} from 'store/types';
import {useFeatures} from 'hooks/features';

export type BasketOfferViewProps = {
    loading?: undefined;
    size?: 'sm' | 'md';
    offer: Offer.ProductListItem;
    feature?: undefined;
    className?: string;
    onRemove?: (id: number) => void;
};

export type FeatureBasketOfferViewProps = {
    loading?: undefined;
    size?: 'sm';
    offer?: undefined;
    feature: Offer.ExtraFeature;
    className?: string;
    onRemove?: undefined;
};

export type LoadingBasketOfferViewProps = {
    loading: true;
    size?: 'sm' | 'md';
    className?: string;
    feature?: undefined;
    offer?: undefined;
    onRemove?: undefined;
};
const BasketOfferView: React.VFC<
    BasketOfferViewProps | FeatureBasketOfferViewProps | LoadingBasketOfferViewProps
> = ({size = 'md', offer, feature, loading, onRemove}) => {
    const {t} = useTranslation(['common', 'offers']);
    const {isMobile} = useWindowSize();
    const {featureTitle} = useFeatures(feature?.featureType || 'highlight');
    const totalPrice = offer ? offer.price.amount * (offer.quantity ?? 1) : null;
    const totalWithDelivery = offer
        ? !offer?.delivery || offer?.delivery.method === Offer.DeliveryMethod.Pickup
            ? totalPrice
            : totalPrice! + offer!.delivery!.price.amount
        : null;

    return (
        <div
            className={cx(
                'bg-white w-full flex flex-col border-gray-300 border md:border-0 px-4',
                {
                    'md:pl-6 md:pr-0': size === 'sm',
                    'md:px-7': size === 'md'
                }
            )}
        >
            {size === 'md' && (
                <div className={'md:hidden pt-3 pb-4 border-b border-gray-200'}>
                    <button
                        className={
                            'flex flex-row w-full items-center justify-end text-2xs text-secondary tracking-tightest focus:outline-none'
                        }
                        onClick={() => onRemove?.(offer!.id)}
                    >
                        <Icon icon={'delete'} size={'xs'} />

                        {t('common:remove')}
                    </button>
                </div>
            )}

            <div
                className={cx(
                    {'md:h-22 py-5': size === 'sm', 'md:h-32 py-6': size === 'md'},
                    'flex flex-row'
                )}
            >
                {loading ? (
                    <Skeleton
                        width={size === 'sm' ? 72 : isMobile ? 96 : 128}
                        height={size === 'sm' ? 44 : isMobile ? 60 : 80}
                        wrapper={LoadingWrapperFactory({
                            style: {
                                height: size === 'sm' ? 44 : 80,
                                marginTop: -4
                            },
                            role: 'figure',
                            'aria-label': 'Offer photo loading'
                        })}
                    />
                ) : !!offer ? (
                    <Link to={`/offer/${offer!.id}`}>
                        <img
                            src={offer!.images.length ? offer!.images[0].small : defaultPhoto}
                            className={cx('object-cover rounded-md w-24 h-15', {
                                'md:w-18 md:h-11': size === 'sm',
                                'md:w-32 md:h-20': size === 'md'
                            })}
                            alt={offer!.title}
                        />
                    </Link>
                ) : (
                    !!feature && (
                        <Link to={`/offer/${feature!.id}`}>
                            <img
                                src={
                                    feature!.images.length
                                        ? feature!.images[0].small
                                        : defaultPhoto
                                }
                                className={cx('object-cover rounded-md w-24 h-15', {
                                    'md:w-18 md:h-11': size === 'sm'
                                })}
                                alt={feature!.title}
                            />
                        </Link>
                    )
                )}

                <div className={'flex flex-col md:flex-row w-full'}>
                    <div
                        className={cx(
                            {
                                'text-xs px-4 py-0.5': size === 'sm',
                                'text-sm px-6': size === 'md'
                            },
                            'flex flex-col justify-between flex-auto'
                        )}
                    >
                        {loading ? (
                            <Skeleton
                                width={200}
                                height={size === 'sm' || isMobile ? 16 : 20}
                                style={{lineHeight: 'initial'}}
                                wrapper={LoadingWrapperFactory({
                                    role: 'heading',
                                    'aria-level': 3,
                                    'aria-label': 'Offer title loading'
                                })}
                            />
                        ) : !!offer ? (
                            <Link to={`/offer/${offer!.id}`}>
                                <h3
                                    className={cx(
                                        {'md:text-sm': size === 'md'},
                                        'text-xs tracking-tight font-normal text-gray-900'
                                    )}
                                >
                                    {offer!.title}
                                </h3>
                            </Link>
                        ) : (
                            <h3 className={'text-secondary text-xs'}>{featureTitle}</h3>
                        )}

                        {loading ? (
                            <Skeleton
                                width={150}
                                height={size === 'sm' ? 16 : 20}
                                wrapper={LoadingWrapperFactory({
                                    role: 'heading',
                                    'aria-level': 4,
                                    'aria-label': 'Offer seller loading',
                                    style: {marginTop: 4},
                                    className: cx({'hidden md:flex': size === 'md'})
                                })}
                            />
                        ) : !!offer ? (
                            offer!.account && (
                                <h4
                                    className={cx(
                                        {
                                            'text-xs': size === 'sm',
                                            'text-sm hidden md:flex': size === 'md'
                                        },
                                        'tracking-tight font-normal text-gray-400'
                                    )}
                                >
                                    <span>{t('offers:seller')}&nbsp;</span>

                                    <Link
                                        to={`/user/${offer!.account.id}`}
                                        className={'text-secondary'}
                                        aria-label={'Seller name'}
                                    >
                                        {offer!.account.type === User.Type.Person
                                            ? offer!.account.firstName
                                            : offer!.account.companyName}
                                    </Link>
                                </h4>
                            )
                        ) : (
                            <p>{feature?.title}</p>
                        )}
                    </div>

                    <div
                        className={cx(
                            'tracking-tightest text-gray-400 flex flex-row flex-initial items-center text-xs',
                            {
                                'py-0.5 justify-end': size === 'sm',
                                'md:text-sm md:flex-col justify-end md:items-end md:py-1':
                                    size === 'md'
                            }
                        )}
                    >
                        <div
                            className={cx('flex flex-col items-end justify-between', {
                                ' md:h-full': size === 'sm'
                            })}
                        >
                            <span>
                                {loading ? (
                                    <Skeleton
                                        width={size === 'sm' || isMobile ? 100 : 120}
                                        height={size === 'sm' || isMobile ? 16 : 20}
                                        wrapper={LoadingWrapperFactory({
                                            'aria-label': 'Offer price loading'
                                        })}
                                    />
                                ) : !!offer ? (
                                    `${offer!.quantity} x ${t('offers:displayPrice', {
                                        priceFrom: offer!.price
                                    })}`
                                ) : (
                                    `1 x ${t('offers:displayPrice', {
                                        priceFrom: feature?.price
                                    })}`
                                )}
                            </span>

                            {size === 'sm' && (
                                <span className={'hidden md:flex'}>
                                    {loading ? (
                                        <Skeleton
                                            width={80}
                                            height={16}
                                            wrapper={LoadingWrapperFactory({
                                                'aria-label': 'Offer shipment price loading',
                                                style: {marginTop: 4}
                                            })}
                                        />
                                    ) : (
                                        !!offer && t('offers:shipment')
                                    )}
                                </span>
                            )}
                        </div>

                        <div
                            className={cx('flex flex-col items-end justify-between min-w-20', {
                                'h-full': size === 'sm'
                            })}
                        >
                            {loading ? (
                                <Skeleton
                                    width={size === 'sm' ? 60 : 100}
                                    height={size === 'sm' ? 16 : 20}
                                    wrapper={LoadingWrapperFactory({
                                        'aria-label': 'Offer total price loading'
                                    })}
                                />
                            ) : (
                                <span
                                    className={cx('text-gray-900', {
                                        'md:text-lg text-sm font-bold md:mt-1': size === 'md',
                                        'font-semibold': size === 'sm'
                                    })}
                                >
                                    {!!offer &&
                                        t('offers:displayPrice', {
                                            priceFrom: {
                                                amount:
                                                    offer!.price.amount.toString() === '0'
                                                        ? '0'
                                                        : totalPrice,
                                                currency: offer!.price.currency
                                            }
                                        })}
                                </span>
                            )}

                            {size === 'sm' && (
                                <span className={'hidden md:flex'}>
                                    {loading ? (
                                        <Skeleton
                                            width={50}
                                            height={16}
                                            wrapper={LoadingWrapperFactory({
                                                'aria-label': 'Offer shipment price loading'
                                            })}
                                        />
                                    ) : (
                                        !!offer &&
                                        (!offer!.delivery ||
                                        offer!.delivery.method === Offer.DeliveryMethod.Pickup
                                            ? '-'
                                            : t('offers:displayPrice', {
                                                  priceFrom: offer!.delivery.price
                                              }))
                                    )}
                                </span>
                            )}
                        </div>
                    </div>

                    <div
                        className={cx(
                            {
                                'w-52 items-end': size === 'md',
                                'w-60 justify-end': size === 'sm'
                            },
                            'flex-col hidden md:flex'
                        )}
                    >
                        {size === 'sm' &&
                            (loading ? (
                                <Skeleton
                                    width={50}
                                    height={16}
                                    wrapper={LoadingWrapperFactory({
                                        'aria-label': 'Offer total price with shipment loading',
                                        className: 'ml-16',
                                        style: {marginTop: 6}
                                    })}
                                />
                            ) : (
                                <span className={'text-sm font-semibold ml-16'}>
                                    {!!offer && !feature
                                        ? t('offers:displayPrice', {
                                              priceFrom: {
                                                  amount:
                                                      offer!.price.amount.toString() === '0'
                                                          ? 0
                                                          : totalWithDelivery,
                                                  currency: offer!.price.currency
                                              }
                                          })
                                        : !!feature &&
                                          t('offers:displayPrice', {
                                              priceFrom: feature?.price
                                          })}
                                </span>
                            ))}

                        {size === 'md' && !loading && (
                            <button
                                className={
                                    'flex flex-row items-center text-2xs text-secondary tracking-tightest focus:outline-none'
                                }
                                onClick={() => onRemove?.(offer!.id)}
                            >
                                <Icon icon={'delete'} size={'xs'} />

                                {t('common:remove')}
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {size === 'md' && !loading && !!offer && offer!.account && (
                <h4
                    className={cx(
                        'tracking-tight text-xs text-gray-400 md:hidden border-t border-gray-200 py-4'
                    )}
                >
                    <span>{t('offers:seller')}&nbsp;</span>

                    <Link
                        to={`/user/${offer!.account.id}`}
                        className={'text-secondary'}
                        aria-label={'Seller name'}
                    >
                        {offer!.account.type === User.Type.Person
                            ? offer!.account.firstName
                            : offer!.account.companyName}
                    </Link>
                </h4>
            )}
        </div>
    );
};

export default BasketOfferView;
