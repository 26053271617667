import * as React from 'react';

//Utils
import cx from 'classnames';

type PropsOf<
    T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> = JSX.LibraryManagedAttributes<T, React.ComponentPropsWithRef<T>>;

interface ButtonOwnProps<T extends React.ElementType = React.ElementType> {
    variant: 'primary' | 'secondary';
    size?: 'lg' | 'sm' | 'xs';
    outline?: boolean;
    className?: string;
    children: string | React.ReactElement;
    as?: T;
}

export type ButtonProps<T extends React.ElementType = 'button'> = ButtonOwnProps<T> &
    Omit<PropsOf<T>, keyof ButtonOwnProps>;

function Button<T extends React.ElementType = 'button'>({
    variant,
    size = 'lg',
    outline = false,
    disabled,
    className,
    children,
    as,
    ...props
}: ButtonProps<T>) {
    return React.createElement(
        as ?? 'button',
        {
            className: cx(
                {
                    'bg-gray-400 border-gray-400': disabled,
                    [`bg-${variant} hover:bg-opacity-90`]: !outline,
                    'bg-white hover:border-opacity-90 hover:text-opacity-90': outline
                },
                {
                    'max-w-88 h-15': size === 'lg',
                    'max-w-64 h-14': size === 'sm',
                    'max-w-36 h-9': size === 'xs'
                },
                {
                    'border-primary ring-primary': variant === 'primary',
                    'border-secondary ring-secondary': variant === 'secondary'
                },
                `flex justify-center items-center px-4 w-full border-3 rounded-lg transition focus:ring-4 ring-opacity-50 focus:outline-none`,
                className
            ),
            disabled,
            ...props
        },
        <span
            className={cx(
                {
                    'text-white': !outline || disabled,
                    [`text-${variant}`]: outline
                },
                {
                    'text-button': size !== 'xs',
                    'text-xs-button': size === 'xs'
                },
                'font-bold'
            )}
        >
            {children}
        </span>
    );
}

export default Button;
