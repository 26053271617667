export type AdProps = {
    href?: string | null;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const Ad: React.VFC<AdProps> = ({href, ...props}) => (
    <a href={href ?? '#'}>
        <img
            alt={'Advertisement'}
            className={'object-left object-cover h-full w-full'}
            {...props}
        />
    </a>
);

export default Ad;
