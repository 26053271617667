//Utils
import dayjs from 'dayjs';
import cx from 'classnames';
import _ from 'lodash-es';

//Assets
import defaultAvatar from 'assets/files/defaultAvatar.jpg';

//Hooks
import {useChatView} from './ChatView.hook';
import {useTranslation} from 'react-i18next';

//Components
import Footer from 'components/nav/Footer';
import SearchForm from 'components/search/SearchForm';
import Input from 'components/ui/Input';
import Skeleton from 'react-loading-skeleton';

//Types
import {RouteComponentProps} from '@reach/router';

import {dateFormat} from 'localeApp';

const ChatView: React.VFC<RouteComponentProps & {id?: number}> = ({id}) => {
    const {
        chat,
        messages,
        newMessage,
        sendMessage,
        getParticipant,
        handleMessageChange,
        isConversationLoading,
        loading,
        chatRef
    } = useChatView({
        id
    });
    const {t} = useTranslation('common');

    return (
        <>
            <div className={'flex flex-col flex-grow bg-gray-200'}>
                <SearchForm />

                <div className={'flex flex-col h-full'}>
                    <div className={'bg-white max-w-292 w-full mx-auto mt-7 py-4 px-6'}>
                        {isConversationLoading ? (
                            <Skeleton height={14} width={100} />
                        ) : (
                            <>
                                <p className={'font-bold text-sm text-gray-900'}>
                                    {(chat && getParticipant(false).name) ?? t('admin')}
                                </p>

                                {chat?.conversation?.subjectName && (
                                    <p className={'text-sm text-gray-900'}>
                                        {chat.conversation.subjectName}
                                    </p>
                                )}
                            </>
                        )}
                    </div>

                    <div
                        ref={chatRef}
                        className={
                            'bg-white max-w-292 w-full h-chat mx-auto mt-6 py-4 px-6 overflow-scroll flex flex-col justify-start flex-grow'
                        }
                    >
                        {isConversationLoading || loading ? (
                            <>
                                {_.times(5, i => (
                                    <div
                                        key={i}
                                        className={cx(
                                            'py-6 flex gap-x-2 flex cursor-pointer relative',
                                            {'self-end': i % 2 === 0}
                                        )}
                                    >
                                        <Skeleton circle={true} height={48} width={48} />

                                        <div className={'flex flex-col gap-y-1'}>
                                            <div className={'border-b border-gray-200'}>
                                                <Skeleton width={150} height={14} />
                                            </div>

                                            <Skeleton width={300} height={12} />
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                {messages?.map((message: any, index: number) => (
                                    <div
                                        key={index}
                                        className={cx('w-full mb-4 last:mb-0 flex flex-col')}
                                    >
                                        <div
                                            className={cx('flex gap-x-2', {
                                                'self-end w-5/12':
                                                    getParticipant().externalUid ===
                                                    message?.state?.memberSid
                                            })}
                                        >
                                            {chat &&
                                                getParticipant(false).externalUid ===
                                                    message?.state?.memberSid && (
                                                    <img
                                                        src={
                                                            getParticipant(false).avatarUrl ??
                                                            defaultAvatar
                                                        }
                                                        alt={'user'}
                                                        className={
                                                            'h-12 w-12 rounded-full object-cover'
                                                        }
                                                    />
                                                )}

                                            <div
                                                className={cx('flex flex-col w-5/12', {
                                                    'self-end w-full':
                                                        getParticipant().externalUid ===
                                                        message?.state?.memberSid
                                                })}
                                            >
                                                <p
                                                    className={
                                                        'pb-2.5 border-b border-gray-200 text-sm text-gray-900 w-full'
                                                    }
                                                >
                                                    {message?.state?.body}
                                                </p>

                                                <div className={'flex justify-between'}>
                                                    {getParticipant().externalUid ===
                                                        message?.state?.memberSid && (
                                                        <p
                                                            className={cx(
                                                                'text-xs-checkbox text-gray-400 mt-1.5'
                                                            )}
                                                        >
                                                            {t('you')}
                                                        </p>
                                                    )}

                                                    <p
                                                        className={cx(
                                                            'text-xs-checkbox text-gray-400 mt-1.5',
                                                            {
                                                                'self-end':
                                                                    getParticipant()
                                                                        .externalUid ===
                                                                    message?.state?.memberSid
                                                            }
                                                        )}
                                                    >
                                                        {dayjs(
                                                            message?.state?.timestamp.toString()
                                                        ).format(`${dateFormat} HH:mm`)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>

                    <div className={'bg-white mb-7 max-w-292 w-full mx-auto p-6'}>
                        <Input
                            placeholder={t('sendMessage')}
                            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (event.key === 'Enter') {
                                    sendMessage();
                                }
                            }}
                            value={newMessage}
                            onChange={handleMessageChange}
                            chat
                            sendMessage={sendMessage}
                        />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default ChatView;
