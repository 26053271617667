//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Categories} from 'store/types';

export type CategoriesAction = ActionType<typeof actions>;

export type State = Readonly<{
    categories: Data<Categories.Category[]>;
    customFormFields: Data<Categories.CustomFormField.Fields, Categories.Category>;
}>;

export const initialState: State = {
    categories: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    customFormFields: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    }
};

export default createReducer<State, CategoriesAction>(initialState)
    .handleAction(actions.getConstants.request, state => ({
        ...state,
        categories: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.categories.data
        }
    }))
    .handleAction(actions.getConstants.success, (state, action) => ({
        ...state,
        categories: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload.menu
        }
    }))
    .handleAction(actions.getConstants.failure, state => ({
        ...state,
        categories: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }))
    .handleAction(actions.getCategoryCustomFormFields.request, (state, action) => ({
        ...state,
        customFormFields: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.customFormFields.data,
            props: action.payload
        }
    }))
    .handleAction(actions.getCategoryCustomFormFields.success, (state, action) => ({
        ...state,
        customFormFields: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload,
            props: state.customFormFields.props
        }
    }))
    .handleAction(actions.getCategoryCustomFormFields.failure, state => ({
        ...state,
        customFormFields: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null,
            props: state.customFormFields.props
        }
    }))
    .handleAction(actions.resetCategoryCustomFormFields, state => ({
        ...state,
        customFormFields: {
            isLoading: false,
            isLoaded: false,
            isErrored: false,
            data: null
        }
    }));
