//Components
import Icon from '../Icon';

//Styles
import Styles from './RatingInput.module.scss';

//Utils
import cx from 'classnames';
import _ from 'lodash-es';

export type RatingInputProps = {
    size?: 'sm' | 'lg' | 'xl';
    value?: number | null;
    onChange?: (value: number) => void;
    className?: string;
    disabled?: boolean;
};

const RatingInput: React.VFC<RatingInputProps> = ({
    size = 'sm',
    value,
    onChange,
    className,
    disabled
}) => (
    <div
        className={cx(
            Styles.container,
            {
                [Styles.withValue]: !!value,
                [Styles.disabled]: disabled
            },
            {
                '-mx-0.5 h-3': size === 'sm',
                '-mx-1 h-5.5': size === 'lg',
                '-mx-1.5 h-10': size === 'xl'
            },
            className
        )}
        role={'group'}
    >
        {_.times(5, i => (
            <button
                key={i}
                className={cx(
                    Styles.button,
                    {
                        [Styles.active]: value && i + 1 === Math.floor(value)
                    },
                    {
                        'px-0.5': size === 'sm',
                        'px-1': size === 'lg',
                        'px-1.5': size === 'xl'
                    },
                    'h-full focus:outline-none'
                )}
                aria-label={(i + 1).toString()}
                onClick={() => onChange?.(i + 1)}
                type={'button'}
                disabled={disabled}
            >
                <Icon icon={'star'} size={size} />
            </button>
        ))}
    </div>
);

export default RatingInput;
