import * as React from 'react';

//Components
import Radio from 'components/ui/Radio';

//Utils
import cx from 'classnames';

//Types
import {RadioProps} from 'components/ui/Radio';

export type RadioGroupProps = {
    value: string;
    onChange: React.FormEventHandler<HTMLInputElement>;
    name?: string;
    children:
        | React.ReactElement<RadioProps, typeof Radio>
        | React.ReactElement<RadioProps, typeof Radio>[];
    direction?: 'horizontal' | 'vertical';
    className?: string;
};

const RadioGroup: React.VFC<RadioGroupProps> = ({
    value,
    onChange,
    name,
    children,
    direction = 'horizontal',
    className
}) => (
    <div
        className={cx(
            {
                'flex-row -mx-8': direction === 'horizontal',
                'flex-col -my-1.5': direction === 'vertical'
            },
            'flex',
            className
        )}
        role={'radiogroup'}
    >
        {React.Children.map(children, child => (
            <Radio
                {...child.props}
                className={cx(
                    {
                        'mx-8': direction === 'horizontal',
                        'my-1.5': direction === 'vertical'
                    },
                    child.props.className
                )}
                checked={value === child.props.value}
                onChange={onChange}
                name={name}
            />
        ))}
    </div>
);

export default RadioGroup;
