//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Offer, Order} from 'store/types';

export type PaymentAction = ActionType<typeof actions>;

export type State = Readonly<{
    savedOrderData: Order.SavedOrderData | null;
    payment: null | Order.CreateOrderResponse | Offer.BuyFeatureResponse;
    offerFeaturePrices: Data<Offer.OfferFeturePrices>;
    orderValidationErrors: any | null;
}>;

export const initialState: State = {
    savedOrderData: null,
    payment: null,
    offerFeaturePrices: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    orderValidationErrors: null
};

export default createReducer<State, PaymentAction>(initialState)
    .handleAction(actions.saveOrderData, (state, action) => ({
        ...state,
        savedOrderData: action.payload
    }))
    .handleAction(actions.createOrder.success, (state, action) => ({
        ...state,
        payment: action.payload,
        orderValidationErrors: null
    }))
    .handleAction(actions.createOrder.failure, (state, action) => ({
        ...state,
        orderValidationErrors: action.payload.code === 400 ? action.payload.errors : null
    }))
    .handleAction(actions.buyAdditionalFeature.success, (state, action) => ({
        ...state,
        payment: action.payload
    }))
    .handleAction(actions.getConstants.request, state => ({
        ...state,
        offerFeaturePrices: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.offerFeaturePrices.data
        }
    }))
    .handleAction(actions.getConstants.success, (state, action) => ({
        ...state,
        offerFeaturePrices: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload.offerFeaturePrices
        }
    }))
    .handleAction(actions.getConstants.failure, state => ({
        ...state,
        offerFeaturePrices: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }));
