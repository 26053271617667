//Hooks
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Button from 'components/ui/Button';

//Types
import {RouteComponentProps} from '@reach/router';

const RegisterSuccessView: React.VFC<RouteComponentProps> = () => {
    const {t} = useTranslation('auth');

    return (
        <div className={'flex flex-grow flex-col justify-center'}>
            <h1 className={'mt-20 text-lg font-semibold text-center'}>
                {t('registerSuccessHeading')}
            </h1>

            <p className={'mt-5 text-sm text-center'}>{t('registerSuccessDescription')}</p>

            <Button
                as={Link}
                to={'/auth/login'}
                className={'self-center mt-28 w-full max-w-88'}
                variant={'primary'}
                type={'submit'}
            >
                {t('backToLogin')}
            </Button>
        </div>
    );
};

export default RegisterSuccessView;
