//Services
import axios from 'services/axios';

//Types
import {AxiosTransformer} from 'axios';
import {Order, Pagination} from 'store/types';

type ReceivedOrder = Omit<Order.Order, 'boughtAt' | 'validUntil'> & {
    boughtAt: string;
    validUntil: string;
};

type ReceivedOrderList = {
    pagination: Pagination;
    items: ReceivedOrder[];
};

export const transformResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    ({items, ...data}: ReceivedOrderList) => ({
        ...data,
        items: items.map(({boughtAt, validUntil, ...offer}) => ({
            ...offer,
            boughtAt: boughtAt ? new Date(boughtAt) : boughtAt,
            validUntil: validUntil ? new Date(validUntil) : validUntil
        }))
    })
];
