//Hooks
import {useEffect} from 'react';

//Services
import TagManager from 'react-gtm-module';

const ReactAnalyticsTracker: React.VFC = () => {
    useEffect(() => {
        TagManager.initialize({
            gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_GMT_ID as string,
            events: {
                sendUserInfo: 'userInfo'
            }
        });
    });

    return null;
};

export default ReactAnalyticsTracker;
