//Components
import GoogleMapReact from 'google-map-react';
import Icon from 'components/ui/Icon';
import Marker from './Marker';

//Utils
import cx from 'classnames';

export type MapProps = {
    zoom?: number;
    center: {
        city: string;
        state: string | null;
        lat: number;
        lng: number;
    };
    className?: string;
    mapClassName?: string;
    label?: string;
};

const Map: React.VFC<MapProps> = ({
    center,
    zoom = 14,
    className,
    mapClassName,
    label = 'label'
}) => (
    <div className={cx(className, 'flex flex-col')}>
        <div className={'flex items-center px-4 py-3 bg-gray-100 rounded'}>
            <div className={'flex justify-center items-center w-10 h-10 rounded-full bg-white'}>
                <Icon icon={'location'} size={'lg'} />
            </div>

            <span className={'ml-5 font-bold'} role={'complementary'} aria-label={label}>
                {`${center.city}${center.state ? `, ${center.state}` : ''}`}
            </span>
        </div>

        <div
            className={cx(mapClassName, 'rounded overflow-hidden mt-3')}
            role={'region'}
            aria-label={'map'}
        >
            <GoogleMapReact
                bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}}
                defaultCenter={center}
                defaultZoom={zoom}
            >
                <Marker lat={center.lat} lng={center.lng} />
            </GoogleMapReact>
        </div>
    </div>
);

export default Map;
