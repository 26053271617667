//Services
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

//Translations
import en from './en';
import pl from './pl';
import {currentLanguage} from '../../localeApp';

//Utils
import {setValidationLocale} from './validation';
import dayjs from 'dayjs';

//Types
import './i18n';

i18n.use({
    type: 'postProcessor',
    name: 'trim',
    process: function (value: string) {
        return value.trim();
    }
})
    .use(initReactI18next)
    .init(
        {
            ns: ['common', 'nav'],
            defaultNS: 'common',
            resources: {
                en,
                pl
            },
            lng: currentLanguage,
            fallbackLng: currentLanguage,
            react: {
                useSuspense: true
            },
            postProcess: ['trim'],
            interpolation: {
                format: (value, format) => {
                    if (value instanceof Date) return dayjs(value).format(format);
                    if (format === 'money') return value ? (value / 100).toFixed(2) : '0';
                    return value;
                }
            }
        },
        (_, t) => setValidationLocale(t)
    );

export default i18n;

declare module 'react-i18next' {
    type DefaultResources = typeof en;
    interface Resources extends DefaultResources {}
}
