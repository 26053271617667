//Hooks
import {useCallback} from 'react';
import {useFormikContext} from 'formik';
import {useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

//Types
import {Offer, Geocoding, User} from 'store/types';

export const useCoordinates = () => {
    const {setFieldValue, setFieldError, values} = useFormikContext<Offer.Form>();

    const dispatch = useDispatch();

    const handleGetCoords = useCallback(
        async (address: User.Address) => {
            setFieldError('address.geoCoordinate', undefined);
            if (!address?.street || !address?.city || !address?.state) {
                setFieldValue('address.geoCoordinate', undefined);
                return;
            }
            try {
                setFieldValue(
                    'lastCheckedAddress',
                    `${address.street}, ${address.city}, ${address.state}`
                );
                const resp = await dispatch(actions.getGeoCoordinates.request(address));
                if (resp.status === Geocoding.Status.OK) {
                    setFieldValue(
                        'address.geoCoordinate.lat',
                        resp.results[0].geometry.location.lat
                    );
                    setFieldValue(
                        'address.geoCoordinate.lon',
                        resp.results[0].geometry.location.lng
                    );
                } else {
                    setFieldValue('address.geoCoordinate', undefined);
                }
            } catch (err) {
                setFieldValue('address.geoCoordinate', undefined);
            }
        },
        [dispatch, setFieldValue, setFieldError]
    );

    return {
        handleGetCoords,
        lastCheckedAddress: values.lastCheckedAddress
    };
};
