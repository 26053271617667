//Styles
import Styles from './Option.module.scss';

//Utils
import cx from 'classnames';

export type OptionProps = {
    className?: string;
    label: string;
    value: string | number;
} & Omit<React.HTMLProps<HTMLInputElement>, 'label'>;

const Option: React.VFC<OptionProps> = ({className, label, ...props}) => (
    <div
        className={cx(className, 'flex-grow')}
        role={'tab'}
        aria-selected={props.checked ? 'true' : 'false'}
        tabIndex={props.tabIndex}
    >
        <input {...props} type={'radio'} className={'appearance-none hidden'} />

        <div className={cx(Styles.labelContainer, 'relative flex text-xs rounded font-bold')}>
            <div
                className={cx(
                    Styles.background,
                    'absolute -inset-px rounded transition-colors'
                )}
                role={'figure'}
                aria-label={'background'}
            />

            <label
                htmlFor={props.id}
                className={
                    'flex-grow relative text-center p-1 cursor-pointer transition-colors'
                }
            >
                {label}
            </label>
        </div>
    </div>
);

export default Option;
