//Redux saga
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions/favorites';

//Utils
import {transformers} from 'utils';
import {partition} from 'lodash-es';

//Services
import i18n from 'services/i18n';

//Sagas
import {request} from 'store/sagas/api';

//Types
import {AxiosResponse} from 'axios';
import {Offer} from 'store/types';
import {SagaIterator} from 'redux-saga';

export function* deleteFavourite(id: number): SagaIterator {
    yield call(
        request,
        {
            method: 'DELETE',
            url: `/api/favourites/${id}`,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
    return id;
}

export function* getFavorites(action: ReturnType<typeof actions.getFavorites.request>) {
    try {
        const resp: AxiosResponse<Offer.FavoritesListResponse> = yield call(
            request,
            {
                method: 'GET',
                url: `/api/favourites${action.payload ? `?page=${action.payload}` : ''}`,
                transformResponse: transformers.favorites.transformResponse
            },
            true
        );

        const [removedItems, correctItems] = partition(resp.data.items, fav => !fav.data.id);

        if (!!removedItems.length) {
            yield all(removedItems.map(fav => call(deleteFavourite, fav.favoriteId)));
        }

        yield put(
            actions.getFavorites.success({
                items: correctItems,
                pagination: resp.data.pagination
            })
        );
    } catch (err) {
        yield put(actions.getFavorites.failure(err));
    }
}

export function* checkFavorites(action: ReturnType<typeof actions.checkFavorites.request>) {
    const token: string = yield select(state => state.auth.token);
    if (!token || action.payload.length === 0) return;
    const queryString = action.payload.map(offer => `ids[]=${offer.id}`).join('&');
    try {
        const resp: AxiosResponse<{checkFavourites: Offer.FavoriteCheckResult[]}> = yield call(
            request,
            {
                method: 'GET',
                url: `/api/favourites/check?${queryString}`
            },
            true
        );
        yield put(actions.checkFavorites.success(resp.data.checkFavourites));
    } catch (err) {
        yield put(actions.checkFavorites.failure(err));
    }
}

export function* addFavorite(action: ReturnType<typeof actions.addFavorite.request>) {
    try {
        const resp: AxiosResponse<Offer.AddFavoriteResponse> = yield call(
            request,
            {
                method: 'POST',
                url: `/api/favourites/${action.payload.id}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            true
        );
        return {offerId: action.payload.id, favoriteId: resp.data.favoriteId};
    } catch (err) {
        if (err.code === 400 || err.response?.status === 400) {
            err.message = i18n.t('errors:cantAddOfferToFavorites');
        }
        throw err;
    }
}

export function* removeFavorite(action: ReturnType<typeof actions.removeFavorite.request>) {
    yield call(deleteFavourite, action.payload.favoriteId);

    return action.payload.favoriteId;
}

export const saga = [
    takeLatest(actions.getFavorites.request, getFavorites),
    takeLatest(actions.checkFavorites.request, checkFavorites),
    takeEveryPromiseAction(actions.addFavorite, addFavorite),
    takeEveryPromiseAction(actions.removeFavorite, removeFavorite)
];
