//Hooks
import {useTranslation} from 'react-i18next';

//Components
import Skeleton from 'react-loading-skeleton';
import Icon from 'components/ui/Icon';
import RatingInput from 'components/ui/RatingInput';
import LoadingWrapperFactory from 'components/wrappers/LoadingWrapperFactory';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';
import defaultPhoto from 'assets/files/defaultOffer.jpg';

//Utils
import cx from 'classnames';
import _ from 'lodash-es';

//Types
import {Reviews, User} from 'store/types';

export type MyReviewRatingViewProps = {
    stats: Reviews.Stats;
    user: User.User | null;
    offer?: undefined;
    seller?: undefined;
    loading?: undefined;
};

export type SellerReviewRatingViewProps = {
    stats: Reviews.Stats;
    seller: Reviews.Seller;
    offer?: undefined;
    loading?: undefined;
    user?: undefined;
};

export type OfferReviewRatingViewProps = {
    stats: Reviews.Stats;
    offer: Reviews.Offer;
    seller?: undefined;
    loading?: undefined;
    user?: undefined;
};

export type LoadingReviewRatingViewProps = {
    loading: true;
    stats?: undefined;
    seller?: undefined;
    offer?: undefined;
    user?: undefined;
};

type ReviewRatingViewProps =
    | SellerReviewRatingViewProps
    | OfferReviewRatingViewProps
    | MyReviewRatingViewProps
    | LoadingReviewRatingViewProps;

const ReviewRatingView: React.VFC<ReviewRatingViewProps> = ({
    user,
    offer,
    seller,
    stats,
    loading
}) => {
    const {t} = useTranslation('reviews');
    return (
        <div className={'flex flex-col md:flex-row w-full max-w-292 md:h-52'}>
            <div
                className={
                    'flex flex-row flex-grow px-4 pt-3 mb-3 md:w-88 h-18 md:h-full md:pt-9'
                }
            >
                {(offer || seller) && (
                    <img
                        className={cx('w-12 h-12 md:h-15 md:w-15 object-cover rounded-md', {
                            'md:rounded-full': !offer
                        })}
                        src={
                            offer
                                ? offer.imageUrl?.small ?? defaultPhoto
                                : seller?.avatarUrl ?? defaultAvatar
                        }
                        alt={offer?.title ?? seller?.name}
                        aria-label={offer ? 'Offer image' : 'Seller profile photo'}
                    />
                )}

                {user && (
                    <img
                        className={cx(
                            'w-12 h-12 md:h-15 md:w-15 object-cover rounded-md md:rounded-full',
                            {}
                        )}
                        src={user?.avatar ?? defaultAvatar}
                        alt={user?.username}
                        aria-label={'User image'}
                    />
                )}

                {loading && (
                    <Skeleton
                        width={60}
                        height={60}
                        wrapper={LoadingWrapperFactory({
                            role: 'figure',
                            'aria-label': 'Image loading'
                        })}
                        circle
                    />
                )}

                <div className={'flex flex-col justify-between px-3 h-11 md:pt-1.5 md:h-15'}>
                    {(offer || seller || user) && stats && (
                        <>
                            <h4
                                className={
                                    'text-lg font-bold tracking-tighter md:text-sm md:tracking-tightest'
                                }
                                aria-label={
                                    user ? 'User name' : offer ? 'Offer title' : 'Seller name'
                                }
                            >
                                {offer?.title ??
                                    seller?.name ??
                                    (user?.type === 'company'
                                        ? user?.companyName
                                        : `${user?.firstName} ${user?.lastName}`)}
                            </h4>

                            <p className={'flex flex-row items-baseline tracking-tighter'}>
                                <Icon
                                    className={'md:mr-1 hidden md:block'}
                                    icon={'star'}
                                    size={'md'}
                                />

                                <Icon
                                    className={'md:mr-1 block md:hidden'}
                                    icon={'star'}
                                    size={'sm'}
                                />

                                <span
                                    className={'text-sm font-bold md:text-rate mx-1.5'}
                                    role={'note'}
                                    aria-label={'Average review rate'}
                                >
                                    {stats.average?.toFixed(1) || '-'}
                                </span>

                                <span className={'text-xs md:text-sm'}>{t('averageRate')}</span>
                            </p>
                        </>
                    )}

                    {loading && (
                        <>
                            <Skeleton
                                width={125}
                                wrapper={LoadingWrapperFactory({
                                    role: 'figure',
                                    'aria-label': 'Name loading'
                                })}
                            />

                            <Skeleton
                                width={200}
                                wrapper={LoadingWrapperFactory({
                                    className: 'text-sm',
                                    role: 'figure',
                                    'aria-label': 'Average review rate loading'
                                })}
                            />
                        </>
                    )}
                </div>
            </div>

            <div
                className={
                    'flex flex-col flex-grow p-4 tracking-tighter md:text-sm text-2xs md:w-1/2 md:pt-9 md:pb-0'
                }
            >
                {stats &&
                    Object.entries(_.omit(stats, ['count', 'average']))
                        .map(([key, value]) => [parseInt(key), value])
                        .sort(([a], [b]) => b - a)
                        .map(([key, value]) => {
                            const width = stats.count ? (value / stats.count) * 100 : 0;

                            return (
                                <div
                                    key={key}
                                    className={'flex flex-row items-center my-1'}
                                    role={'region'}
                                    aria-label={`${key} rate`}
                                >
                                    <span
                                        className={'hidden mx-4 font-semibold md:inline-block'}
                                    >
                                        {key}
                                    </span>

                                    <RatingInput
                                        className={'hidden md:block'}
                                        value={key}
                                        size={'lg'}
                                        disabled
                                    />

                                    <RatingInput
                                        className={'block md:hidden'}
                                        value={key}
                                        size={'sm'}
                                        disabled
                                    />

                                    <div
                                        className={
                                            'flex-1 mx-6 overflow-hidden bg-gray-200 rounded-full min-h-1.5 md:min-h-3'
                                        }
                                    >
                                        <div
                                            className={'min-h-1.5 md:min-h-3 bg-primary'}
                                            style={{width: `${width}%`}}
                                            role={'progressbar'}
                                        />
                                    </div>

                                    <span role={'note'} aria-label={'Reviews count'}>
                                        {value}
                                    </span>
                                </div>
                            );
                        })}

                {loading &&
                    _.times(5, i => (
                        <div key={i} className={'my-1'}>
                            <Skeleton
                                width={'100%'}
                                height={22}
                                wrapper={LoadingWrapperFactory({
                                    role: 'figure',
                                    'aria-label': `${i + 1} rate loading`
                                })}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ReviewRatingView;
