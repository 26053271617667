//Action creators
import {createPromiseAction} from 'redux-saga-promise-actions';

//Types
import {Errors, Offer} from 'store/types';

export const getOfferById = createPromiseAction('GET_OFFER_BY_ID')<
    string,
    Offer.OfferDetailed,
    Errors.API
>();
