//Hooks
import {usePaymentMethod} from 'hooks/basket';
import {useWindowSize} from 'hooks/windowSize';
import {usePaymentFormView} from 'hooks/payment';

//Types

export const usePaymentMethodView = () => {
    const {payment, isExtras, isFree, isShipment} = usePaymentMethod();
    const {isMobile} = useWindowSize();

    const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting
    } = usePaymentFormView();

    return {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isMobile,
        isExtras,
        payment,
        isFree,
        isShipment,
        openShipmentModal: () => setFieldValue('isShipmentModalOpen', true),
        closeShipmentModal: () => setFieldValue('isShipmentModalOpen', false)
    };
};
