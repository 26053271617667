//Hooks
import {useRef, useEffect} from 'react';
import useOnClickOutside from 'use-onclickoutside';

type useModalArgs = {
    isOpen: boolean;
    onClose: () => void;
};

export const useModal = ({isOpen, onClose}: useModalArgs) => {
    const modalRef = useRef(null);
    const element = useRef(document.createElement('div')).current;

    const modalRoot = document.getElementById('root-modal');

    useOnClickOutside(modalRef, onClose);

    useEffect(() => {
        modalRoot?.appendChild(element);

        return () => {
            modalRoot?.removeChild(element);
        };
    }, [modalRoot, element]);

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return {modalRef, element};
};
