//Hooks
import {usePWAPromptWrapper} from './PWAPromptWrapper.hook';

//Components
// @ts-ignore
import PWAPrompt from 'react-ios-pwa-prompt';

const PWAPromptWrapper: React.VFC = () => {
    const {isPWAPromptVisible, handleClose} = usePWAPromptWrapper();

    if (!isPWAPromptVisible) {
        return null;
    }

    return <PWAPrompt promptOnVisit={1} timesToShow={1} onClose={handleClose} debug />;
};

export default PWAPromptWrapper;
