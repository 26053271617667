//Hooks
import {useState, useRef} from 'react';
import useOnClickOutside from 'use-onclickoutside';

export const useDropdown = () => {
    const [isOpen, setOpen] = useState(false);

    const menuRef = useRef(null);

    useOnClickOutside(menuRef, () => setOpen(false));

    const open = () => setOpen(true);

    return {
        isOpen,
        open,
        menuRef
    };
};
