//Hooks
import {useOfferDetailsView} from './OfferDetailsView.hook';
import {useWindowSize} from 'hooks/windowSize';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import {Trans} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/ui/Button';
import Badge from 'components/ui/Badge';
import Tag from 'components/ui/Tag';
import GalleryView from 'components/offer/GalleryView';
import Icon from 'components/ui/Icon';
import Breadcrumb from 'components/nav/Breadcrumb';
import SearchForm from 'components/search/SearchForm';
import Footer from 'components/nav/Footer';
import FavoriteButton from 'components/ui/FavoriteButton';
import Counter from 'components/ui/Counter';
import RatingInput from 'components/ui/RatingInput';
import OptionalLink from 'components/nav/OptionalLink';
import Map from 'components/ui/Map';
import CreateReviewModal from 'components/modal/CreateReviewModal';
import PopularSearches from 'components/nav/PopularSearches';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';
import takedownForm from 'assets/files/takedownForm.doc';

//Utils
import dayjs from 'dayjs';

//Types
import {RouteComponentProps} from '@reach/router';
import {Offer, User} from 'store/types';
import AdditionalOptionsModal from 'components/modal/AdditionalOptionsModal';
import {MoneySerialized} from '../../store/types/offer';

const OfferDetailsView: React.VFC<RouteComponentProps<{id: string}>> = ({id}) => {
    const {
        isLoading,
        isSellerLoaded,
        offer,
        stats,
        seller,
        counter,
        isBuyable,
        isOwnOffer,
        categoriesPath,
        isFavorite,
        isOfferInBasket,
        addOfferToBasket,
        isFavoriteDisabled,
        onFavoriteToggle,
        displayPrice,
        onIncreaseQuantity,
        onDecreaseQuantity,
        displayPhoneNumber,
        isPhoneNumberVisible,
        togglePhoneNumberVisibility,
        isCreateReviewModalVisible,
        openCreateReviewModal,
        closeCreateReviewModal,
        isExtendOfferModalVisible,
        closeExtendOfferModal,
        openExtendOfferModal,
        contactUser,
        user
    } = useOfferDetailsView({id});

    const {breakpoint} = useWindowSize();
    const {t} = useTranslation(['offers', 'common', 'reviews', 'auth']);

    const format4digitsInPLN = (priceFormatted: string): string => {
        const priceSplit = priceFormatted.split(',');
        const priceSplitLeft = Array.from(priceSplit[0]);
        if (priceSplitLeft.length === 4) {
            priceSplitLeft.splice(1, 0, ' ');
        }
        return priceSplitLeft.join('').concat(',').concat(priceSplit[1]);
    };

    const formatPrice = (priceItem: MoneySerialized): string => {
        const numberFormat = new Intl.NumberFormat(
            priceItem.currency === 'PLN' ? 'pl-PL' : 'en-US',
            {
                style: 'currency',
                currency: priceItem.currency,
                currencyDisplay: 'narrowSymbol'
            }
        );

        const priceFormatted = numberFormat.format(priceItem.amount / 100);
        return priceItem.currency === 'PLN'
            ? format4digitsInPLN(priceFormatted)
            : priceFormatted;
    };

    return (
        <>
            <div className={'bg-gray-100 flex flex-col h-full'}>
                <div className={'flex-col hidden md:flex'}>
                    <SearchForm />
                </div>

                <div className={'bg-white py-6.5 border-b border-gray-200 hidden md:block'}>
                    <div className={'max-w-292 flex justify-end m-0-auto px-4'}>
                        <Button
                            as={Link}
                            to={user ? '/offers/create' : '/auth/login'}
                            variant={'primary'}
                            state={{from: `/offer/${id}`}}
                        >
                            {t('common:addOffer')}
                        </Button>
                    </div>
                </div>

                <div className={'max-w-292 w-full m-0-auto md:py-11 md:px-4'}>
                    <div className={'flex-col hidden md:flex'}>
                        {isLoading ? (
                            <Skeleton width={200} />
                        ) : (
                            <Breadcrumb aria-label={'Categories tree'}>
                                {categoriesPath?.map((category, i) => (
                                    <span key={i}>{category?.name}</span>
                                ))}
                            </Breadcrumb>
                        )}
                    </div>

                    <div
                        className={
                            'flex gap-x-7.5 gap-y-6 md:mt-11 flex-col-reverse lg:flex-row'
                        }
                    >
                        <div className={'flex flex-col flex-grow gap-y-6 lg:max-w-88 w-full'}>
                            <div
                                className={
                                    'bg-white sm:border sm:border-gray-300 rounded pt-10 px-5 pb-8 flex flex-col'
                                }
                                role={'region'}
                                aria-label={'Seller profile'}
                            >
                                {isLoading ? (
                                    <div
                                        className={'self-center flex justify-center'}
                                        aria-label={'Seller account type loading'}
                                    >
                                        <Skeleton width={144} />
                                    </div>
                                ) : (
                                    offer?.account && (
                                        <Badge
                                            className={'self-center flex justify-center w-36'}
                                            variant={'secondary'}
                                            aria-label={'account type'}
                                        >
                                            {t(
                                                offer.account.type === User.Type.Person
                                                    ? 'common:private'
                                                    : 'common:business'
                                            )}
                                        </Badge>
                                    )
                                )}

                                <div className={'flex items-center mt-6 gap-x-4'}>
                                    {isLoading ? (
                                        <div
                                            aria-label={'Seller avatar loading'}
                                            role={'figure'}
                                        >
                                            <Skeleton circle height={56} width={56} />
                                        </div>
                                    ) : (
                                        <img
                                            src={offer?.account?.avatar ?? defaultAvatar}
                                            alt={'avatar'}
                                            className={'object-cover rounded-full h-14 w-14'}
                                        />
                                    )}

                                    {isLoading ? (
                                        <div
                                            className={'w-full'}
                                            role={'heading'}
                                            aria-level={1}
                                            aria-label={'Seller name loading'}
                                        >
                                            <Skeleton height={20} />
                                        </div>
                                    ) : (
                                        <h1 className={'text-xl'} aria-label={'account name'}>
                                            {offer?.account?.type === User.Type.Company
                                                ? offer?.account?.companyName
                                                : offer?.account?.firstName}
                                        </h1>
                                    )}
                                </div>

                                <div
                                    className={'border-t border-gray-300 mt-4 pt-3'}
                                    role={'region'}
                                    aria-label={'Seller review statistics'}
                                >
                                    {isSellerLoaded && offer?.account?.id ? (
                                        <div
                                            className={
                                                'flex justify-between text-gray-900 text-sm font-bold tracking-tight'
                                            }
                                        >
                                            <p>
                                                {t('reviews:reviewsCount', {
                                                    count:
                                                        seller?.reviewStats?.numberOfReviews ??
                                                        0
                                                })}
                                            </p>

                                            <div className={'flex items-center'}>
                                                <span className={'mx-2'}>
                                                    {seller?.reviewStats?.averageReview?.toFixed(
                                                        1
                                                    ) ?? '-'}
                                                </span>

                                                <RatingInput
                                                    className={'flex'}
                                                    value={seller?.reviewStats?.averageReview}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div aria-label={'Seller review statistics loading'}>
                                            <Skeleton />
                                        </div>
                                    )}

                                    {isSellerLoaded && offer?.account?.id ? (
                                        <Link
                                            to={`/reviews/seller/${offer?.account?.id}`}
                                            className={
                                                'text-secondary text-xs flex justify-end gap-x-2 pt-4.5 focus:outline-none'
                                            }
                                        >
                                            <span className={'flex items-center'}>
                                                {t('common:show')}

                                                <Icon className={'ml-1'} icon={'arrow-right'} />
                                            </span>
                                        </Link>
                                    ) : (
                                        <div className={'flex justify-end'}>
                                            <Skeleton width={40} />
                                        </div>
                                    )}
                                </div>

                                <div
                                    className={
                                        'flex justify-between items-center pt-3 pb-6 border-t border-gray-300 text-gray-900 mt-4'
                                    }
                                    role={'region'}
                                    aria-label={'Seller offer statistics'}
                                >
                                    {isLoading ? (
                                        <div
                                            className={'w-full'}
                                            aria-label={'Seller offer statistics loading'}
                                        >
                                            <Skeleton />
                                        </div>
                                    ) : (
                                        <>
                                            <p className={'text-sm font-bold tracking-tight'}>
                                                {t('common:offersCount', {
                                                    count: stats?.offers
                                                })}
                                            </p>

                                            <Link
                                                to={`/user/${offer?.account?.id}`}
                                                className={
                                                    'text-secondary text-xs flex items-center gap-x-2 focus:outline-none'
                                                }
                                            >
                                                <span className={'flex items-center'}>
                                                    {t('common:show')}

                                                    <Icon
                                                        className={'ml-1'}
                                                        icon={'arrow-right'}
                                                    />
                                                </span>
                                            </Link>
                                        </>
                                    )}
                                </div>

                                <div
                                    className={
                                        'flex justify-between items-center pt-3 pb-6 border-t border-gray-300 text-gray-900'
                                    }
                                >
                                    {isLoading ? (
                                        <div
                                            className={'w-full'}
                                            aria-label={'Seller phone number loading'}
                                        >
                                            <Skeleton />
                                        </div>
                                    ) : (
                                        <>
                                            <p className={'text-sm font-bold tracking-tight'}>
                                                {t('auth:phoneNumber')}
                                            </p>

                                            <div className={'flex gap-x-4 items-center'}>
                                                <p className={'text-xs text-gray-400'}>
                                                    {displayPhoneNumber}
                                                </p>

                                                <button
                                                    className={
                                                        'text-secondary text-xs focus:outline-none'
                                                    }
                                                    onClick={togglePhoneNumberVisibility}
                                                >
                                                    {isPhoneNumberVisible
                                                        ? t('common:hide')
                                                        : t('common:show')}
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>

                                {!isOwnOffer && !isLoading && (
                                    <Button
                                        className={'self-center'}
                                        size={'sm'}
                                        variant={'primary'}
                                        outline
                                        onClick={contactUser}
                                    >
                                        {t('common:contact')}
                                    </Button>
                                )}
                            </div>

                            {offer?.address?.geoCoordinate && (
                                <div
                                    className={
                                        'bg-white sm:border sm:border-gray-300 rounded p-4 flex flex-col'
                                    }
                                >
                                    <Map
                                        center={{
                                            city: offer.address.city,
                                            state: offer.address.state,
                                            lat: parseFloat(offer.address.geoCoordinate.lat),
                                            lng: parseFloat(offer.address.geoCoordinate.lon)
                                        }}
                                        mapClassName={'h-80'}
                                        label={'offer address'}
                                    />
                                </div>
                            )}
                        </div>

                        <div
                            className={
                                'bg-white sm:border sm:border-gray-300 rounded max-w-offer w-full px-4 md:px-12 py-6'
                            }
                            role={'region'}
                            aria-label={'Offer details'}
                        >
                            {isLoading ? (
                                <>
                                    <Skeleton height={30} />

                                    <Skeleton className={'mt-6'} />

                                    <Skeleton className={'my-4'} height={50} />

                                    <Skeleton count={6} />
                                </>
                            ) : (
                                <>
                                    <div className={'flex'}>
                                        <Tag className={'mr-1'}>
                                            {t('offers:published', {
                                                date: dayjs(offer?.createdAt).format(
                                                    'DD/MM/YYYY'
                                                )
                                            })}
                                        </Tag>

                                        {offer?.expiredAt && (
                                            <Tag>
                                                {t('offers:validTill', {
                                                    date: dayjs(offer.expiredAt).format(
                                                        'DD/MM/YYYY'
                                                    )
                                                })}
                                            </Tag>
                                        )}

                                        {isOwnOffer && offer?.status !== Offer.Status.Closed && (
                                            <Button
                                                size={'xs'}
                                                variant={'secondary'}
                                                outline
                                                className={'ml-3 h-8'}
                                                onClick={openExtendOfferModal}
                                            >
                                                {t('offers:extendtheOffer')}
                                            </Button>
                                        )}
                                    </div>

                                    {!!offer?.images.length && (
                                        <GalleryView
                                            className={'mt-4 relative overflow-hidden'}
                                        >
                                            {offer?.images.map(
                                                ({
                                                    id,
                                                    name,
                                                    small,
                                                    medium,
                                                    big
                                                }: Offer.Image) => (
                                                    <div key={id}>
                                                        {offer?.features?.highlight && (
                                                            <Badge
                                                                variant={'lightgreen'}
                                                                size={'lg'}
                                                                className={
                                                                    'absolute left-2/4 transform -translate-x-2/4 top-2.5'
                                                                }
                                                            >
                                                                {t('offers:promoted')}
                                                            </Badge>
                                                        )}

                                                        <img
                                                            key={id}
                                                            src={big ?? medium ?? small}
                                                            alt={name}
                                                            className={'h-full m-auto'}
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </GalleryView>
                                    )}

                                    <div className={'flex-col flex md:hidden'}>
                                        {isLoading ? (
                                            <Skeleton width={200} />
                                        ) : (
                                            <Breadcrumb aria-label={'Categories tree'}>
                                                {categoriesPath?.map((category, i) => (
                                                    <span key={i}>{category?.name}</span>
                                                ))}
                                            </Breadcrumb>
                                        )}
                                    </div>

                                    <p className={'text-sm text-gray-400 mt-6'}>
                                        {t('offers:offerId', {id: offer?.id})}
                                    </p>

                                    <div className={'flex justify-between items-center mt-4'}>
                                        <h2
                                            className={'text-offer-title'}
                                            aria-label={'offer title'}
                                        >
                                            {offer?.title}
                                        </h2>

                                        <FavoriteButton
                                            id={`favorite-offer-${offer?.id}`}
                                            className={'self-start'}
                                            checked={isFavorite}
                                            onChange={onFavoriteToggle}
                                            size={'md'}
                                            disabled={isFavoriteDisabled}
                                            aria-label={'Favorite button'}
                                        />
                                    </div>

                                    <h1 className={'text-2xl mt-6'} aria-label={'offer price'}>
                                        {displayPrice}
                                    </h1>

                                    {!isOwnOffer && (
                                        <>
                                            {isBuyable && (
                                                <div
                                                    className={
                                                        'flex flex-col align-center justify-between mt-8 md:hidden md:flex-row gap-4'
                                                    }
                                                >
                                                    {offer?.type === 'product' && (
                                                        <Counter
                                                            maxQuantity={offer?.quantity ?? 0}
                                                            onIncrease={onIncreaseQuantity}
                                                            onDecrease={onDecreaseQuantity}
                                                            counter={counter}
                                                        />
                                                    )}

                                                    <Button
                                                        variant={'primary'}
                                                        onClick={addOfferToBasket}
                                                        disabled={isOfferInBasket}
                                                    >
                                                        {isOfferInBasket
                                                            ? t('offers:inBasket')
                                                            : t('offers:buy')}
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <p
                                        className={
                                            'text-sm my-6 text-gray-900 whitespace-pre-wrap'
                                        }
                                        aria-label={'offer description'}
                                    >
                                        {offer?.description}
                                    </p>

                                    {offer?.type && (
                                        <Badge
                                            size={'lg'}
                                            variant={'gray-200'}
                                            aria-label={'offer type'}
                                        >
                                            {t(`offers:${offer?.type}` as const)}
                                        </Badge>
                                    )}

                                    {isBuyable && !isOwnOffer && (
                                        <div
                                            className={
                                                'align-center justify-between mt-16 hidden md:flex'
                                            }
                                        >
                                            {offer?.type === 'product' && (
                                                <Counter
                                                    maxQuantity={offer?.quantity ?? 0}
                                                    onIncrease={onIncreaseQuantity}
                                                    onDecrease={onDecreaseQuantity}
                                                    counter={counter}
                                                />
                                            )}

                                            <Button
                                                variant={'primary'}
                                                onClick={addOfferToBasket}
                                                disabled={isOfferInBasket}
                                            >
                                                {isOfferInBasket
                                                    ? t('offers:inBasket')
                                                    : t('offers:buy')}
                                            </Button>
                                        </div>
                                    )}

                                    {offer?.delivery?.method ===
                                        Offer.DeliveryMethod.Pickup && (
                                        <p
                                            className={
                                                'mt-10 pt-7 border-t border-gray-200 text-sm font-semibold'
                                            }
                                        >
                                            {t('offers:shipment')}

                                            <span
                                                className={'font-bold ml-12'}
                                                aria-label={'shipment price'}
                                            >
                                                {t('common:pickupAtSeller')}
                                            </span>
                                        </p>
                                    )}

                                    {offer?.delivery?.method ===
                                        Offer.DeliveryMethod.Courier && (
                                        <p
                                            className={
                                                'mt-10 pt-7 border-t border-gray-200 text-sm font-semibold'
                                            }
                                        >
                                            {t('offers:shipmentCost')}

                                            <span
                                                className={'font-bold ml-12'}
                                                aria-label={'shipment price'}
                                            >
                                                {offer?.delivery?.price?.amount
                                                    ? t('offers:displayPrice', {
                                                          priceFrom: formatPrice(
                                                              offer?.delivery?.price
                                                          )
                                                      })
                                                    : t('common:forFree')}
                                            </span>
                                        </p>
                                    )}

                                    <div className={'border-t border-gray-200 mt-10'} />

                                    <OptionalLink
                                        to={
                                            breakpoint === 'xs'
                                                ? `/reviews/offer/${offer?.id}`
                                                : ''
                                        }
                                        className={
                                            'border border-gray-300 my-8 lg:py-7 lg:px-9 py-2.5 px-4 flex flex-col gap-y-3 rounded md:rounded-none'
                                        }
                                    >
                                        <div className={'flex items-center'}>
                                            <div
                                                className={
                                                    'text-xs tracking-tight font-bold w-20 text-right'
                                                }
                                            >
                                                {t('reviews:reviewsCount', {
                                                    count: stats?.reviewsCount
                                                })}
                                            </div>

                                            <div
                                                className={
                                                    'flex flex-grow justify-between ml-10'
                                                }
                                            >
                                                <div className={'flex items-center gap-x-2'}>
                                                    <span
                                                        className={
                                                            'text-sm md:font-bold tracking-tighter'
                                                        }
                                                    >
                                                        {stats?.reviewsAverage?.toFixed(1) ??
                                                            '-'}
                                                    </span>

                                                    <RatingInput
                                                        className={'flex'}
                                                        value={stats?.reviewsAverage}
                                                        disabled
                                                    />
                                                </div>

                                                <Link
                                                    to={`/reviews/offer/${offer?.id}`}
                                                    className={
                                                        'sm:text-secondary flex items-center gap-x-2 text-xs tracking-medium-tight'
                                                    }
                                                >
                                                    <span className={'hidden sm:flex'}>
                                                        {t('common:show')}
                                                    </span>

                                                    <Icon icon={'arrow-right'} />
                                                </Link>
                                            </div>
                                        </div>

                                        {stats?.leadReviews?.map(review => (
                                            <div key={review.id} className={'hidden lg:flex'}>
                                                <div
                                                    className={
                                                        'text-xs tracking-medium-tight w-20 min-w-20 text-right'
                                                    }
                                                >
                                                    {dayjs(review.createdAt).format('YY/MM/DD')}
                                                </div>

                                                <div
                                                    className={'flex flex-col flex-grow mx-10'}
                                                >
                                                    <RatingInput
                                                        className={'flex'}
                                                        value={review.rate}
                                                        disabled
                                                    />

                                                    <p
                                                        className={
                                                            'text-xs tracking-medium-tight py-2'
                                                        }
                                                    >
                                                        {review.comment}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </OptionalLink>

                                    {!!offer &&
                                        offer.type !== Offer.Type.Product &&
                                        !isOwnOffer && (
                                            <div className={'flex justify-end mt-8'}>
                                                <Button
                                                    variant={'primary'}
                                                    onClick={openCreateReviewModal}
                                                    outline
                                                >
                                                    {t('reviews:reviewOffer')}
                                                </Button>
                                            </div>
                                        )}

                                    <div className={'border-t border-gray-200 mt-10 text-sm '}>
                                        <p className={'font-semibold my-5'}>
                                            {t('offers:reportOffer')}
                                        </p>

                                        <Trans
                                            i18nKey={'offers:reportOfferDescription'}
                                            components={{
                                                a: (
                                                    //eslint-disable-next-line jsx-a11y/anchor-has-content
                                                    <a
                                                        className={'text-secondary'}
                                                        href={takedownForm}
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <PopularSearches />

                <Footer />
            </div>

            {offer && (
                <>
                    <CreateReviewModal
                        isOpen={isCreateReviewModalVisible}
                        onClose={closeCreateReviewModal}
                        offer={offer}
                    />

                    <AdditionalOptionsModal
                        isOpen={isExtendOfferModalVisible}
                        onClose={closeExtendOfferModal}
                        offer={offer}
                        type={'offerPeriodExtension'}
                    />
                </>
            )}
        </>
    );
};

export default OfferDetailsView;
