//Action creators
import {createPromiseAction} from 'redux-saga-promise-actions';
import {createAsyncAction, createAction} from 'typesafe-actions';

//Types
import {Errors, Offer} from 'store/types';

/**
 * Gets all offer list from basket.
 */
export const getBasketOffers = createPromiseAction('GET_BASKET')<
    undefined,
    Offer.ProductListItem[],
    Errors.API
>();

/**
 * Add offer to basket.
 */
export const addOfferToBasket = createAsyncAction(
    'ADD_OFFER_TO_BASKET_REQUEST',
    'ADD_OFFER_TO_BASKET_SUCCESS',
    'ADD_OFFER_TO_BASKET_FAILURE'
)<Offer.ProductListItem, Offer.ProductListItem, Errors.API>();

/**
 * Remove offer from basket.
 */
export const removeBasketOffer = createAsyncAction(
    'REMOVE_BASKET_OFFER_REQUEST',
    'REMOVE_BASKET_OFFER_SUCCESS',
    'REMOVE_BASKET_OFFER_FAILURE'
)<number, number, Errors.API>();

/**
 * Clear basket.
 */
export const clearBasket = createAction('CLEAR_BASKET')();

/**
 * Add offers to payment.
 */
export const selectOffersToProceed = createAction(
    'SELECT_OFFERS_TO_PROCEED',
    (offers: Offer.ProductListItem[]) => offers
)();

/**
 * Add extra feature for offer to payment.
 */
export const addExtraFeatureOfferToProceed = createAction(
    'ADD_EXTRA_FEATURE_OFFER_TO_PROCEED',
    (extraFeature: Offer.ExtraFeature) => extraFeature
)();

/**
 * Clear extra feature.
 */
export const clearExtraFeature = createAction('CLEAR_EXTRA_FEATURE')();
