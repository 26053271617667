//Hooks
import {useNavigate} from '@reach/router';
import {useFeatures} from 'hooks/features';
import {useOfferFeaturePrices} from 'hooks/payment';
import {useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

//Types
import {Offer} from 'store/types';

type UseAdditionalOptionsModalProps = {
    type: Offer.AdditionalOptions;
    offer: Offer.ListItem;
};

export const useAdditionalOptionsModal = ({type, offer}: UseAdditionalOptionsModalProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {data: prices} = useOfferFeaturePrices();
    const {featureTitle, featureText, featureDescription} = useFeatures(type);

    const currentPrice =
        type === 'highlight'
            ? prices?.priceForHighlight
            : type === 'extraPhotos'
            ? prices?.priceForExtraImages
            : prices?.priceForPeriodExtension;

    const proceedExtraFeature = () => {
        dispatch(
            actions.addExtraFeatureOfferToProceed({
                ...offer,
                featureType: type,
                price: currentPrice!
            })
        );
        navigate('/payment/method/extras');
    };

    return {proceedExtraFeature, currentPrice, featureTitle, featureText, featureDescription};
};
