//Hooks
import {useSearchForm} from './SearchForm.hook';
import {useTranslation} from 'react-i18next';

//Components
import Select from 'react-select';
import Input, {InputIcon} from 'components/ui/Input';
import Icon from 'components/ui/Icon';
import CategoryPicker from 'components/ui/CategoryPicker';
import Button from 'components/ui/Button';

//Styles
import {styles, theme} from 'styles/select';

//Types
import {Search} from 'store/types';

import {isMobile} from 'react-device-detect';

const SearchForm: React.VFC = () => {
    const {
        categories,
        values,
        locationOptions,
        locationDenied,
        handleChange,
        handleCategoryChange,
        handleLocationChange,
        handleSubmit
    } = useSearchForm();

    const {t} = useTranslation('search');

    return (
        <form
            className={
                'flex justify-center py-5 md:py-9 md:bg-white md:border-t md:border-b border-gray-300'
            }
            onSubmit={handleSubmit}
        >
            <div className={'flex flex-col w-full max-w-292 px-4'}>
                <div className={'flex items-center gap-3'}>
                    <Input
                        id={'phrase'}
                        name={'phrase'}
                        className={'flex-grow bg-white'}
                        value={values.phrase}
                        onChange={handleChange}
                        placeholder={t('whatAreYouLookingFor')}
                        // @ts-ignore
                        enterKeyHint={'search'}
                    >
                        <InputIcon placement={'left'}>
                            <Icon icon={'search'} />
                        </InputIcon>
                    </Input>

                    <CategoryPicker
                        aria-label={'category select'}
                        className={'hidden lg:block w-72'}
                        options={categories ?? []}
                        value={values.category?.id ?? null}
                        onChange={handleCategoryChange}
                        expandOnHover={!isMobile}
                    />

                    <Select
                        role={'searchbox'}
                        aria-label={'local search select'}
                        options={locationOptions}
                        value={locationOptions.find(
                            opt =>
                                opt.value ===
                                (!!values.geoDistance
                                    ? Search.Location.Local
                                    : Search.Location.Everywhere)
                        )}
                        onChange={handleLocationChange}
                        styles={styles}
                        theme={theme}
                        className={'w-48 bg-white hidden md:block'}
                    />

                    <Button
                        className={'hidden lg:block'}
                        variant={'primary'}
                        size={'sm'}
                        type={'submit'}
                    >
                        {t('search')}
                    </Button>
                </div>

                {locationDenied && (
                    <p className={'mt-2 tracking-tight text-red text-form-helper'}>
                        {t('locationError')}
                    </p>
                )}
            </div>

            <input type={'submit'} className={'lg:hidden w-0 h-0'} />
        </form>
    );
};

export default SearchForm;
