//Hooks
import {useTranslation} from 'react-i18next';
import {useWindowSize} from 'hooks/windowSize';
import {useMyPurchases} from './MyPurchasesView.hook';

//Components
import OfferView from 'components/offer/OfferView';
import PaginationView from 'components/ui/PaginationView';

//Utils
import _ from 'lodash-es';

//Types
import {RouteComponentProps} from '@reach/router';

const MyPurchasesView: React.VFC<RouteComponentProps> = () => {
    const {
        orders,
        isLoading,
        isLoaded,
        pageFromSearch,
        pages,
        handlePageChange,
        handleComplete,
        ref
    } = useMyPurchases();

    const {breakpoint} = useWindowSize();

    const {t} = useTranslation(['profile', 'nav']);

    return (
        <>
            <div>
                <h2 className={'my-5 mx-4 md:hidden'}>{t('nav:navbar.myPurchases')}</h2>

                {isLoaded && !orders?.length ? (
                    <div className={'text-center my-18'}>
                        <h2 className={'font-semibold'} aria-label={'No purchases'}>
                            {t('profile:myPurchases.emptyPurchasesTitle')}
                        </h2>

                        <span className={'text-xs block mt-12'}>
                            {t('profile:myPurchases.emptyPurchasesDescription')}
                        </span>
                    </div>
                ) : (
                    <div className={'flex flex-col my-2.5 gap-y-3'}>
                        <span ref={ref} />

                        {breakpoint !== 'xs' && (
                            <div className={'self-end'}>
                                <PaginationView
                                    currentPage={pageFromSearch}
                                    totalPages={pages ?? 0}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        )}

                        {isLoading
                            ? _.times(4, i => (
                                  <OfferView key={i} variant={'profile'} loading loadingOrder />
                              ))
                            : orders?.map(order => (
                                  <OfferView
                                      variant={'profile'}
                                      order={order}
                                      key={order.orderId}
                                      isBuyer
                                      onComplete={() => handleComplete(order)}
                                  />
                              ))}

                        <div className={'self-end'}>
                            <PaginationView
                                currentPage={pageFromSearch}
                                totalPages={pages ?? 0}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default MyPurchasesView;
