//Hooks
import {useFormikContext} from 'formik';

//Types
import {Offer} from 'store/types';
import {useEffect} from 'react';

export const useRealEstateForm = () => {
    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue
    } = useFormikContext<Offer.Form>();

    useEffect(() => {
        setFieldValue('offer.quantity', 1);
    }, [setFieldValue]);

    const handleChangePrice = (e: React.FormEvent<HTMLInputElement>) => {
        const tbbcAmount = e.currentTarget.value === 'free' ? 0 : '';
        setFieldValue('offer.price', {...values.offer.price, tbbcAmount});
    };

    return {
        values,
        errors,
        touched,
        handleChange,
        handleChangePrice
    };
};
