//Components
import Icon from 'components/ui/Icon';

//Hooks
import {useTranslation} from 'react-i18next';

//Types
import {Offer} from 'store/types';

type ShipmentDetailsProps = {
    openModal: () => void;
    values: Offer.PaymentFormValues;
};

const ShipmentDetails: React.VFC<ShipmentDetailsProps> = ({openModal, values}) => {
    const {t} = useTranslation(['basket', 'profile']);
    return (
        <>
            <div
                className={
                    'py-5 px-6 bg-white w-full flex flex-col border-gray-300 border-1.5 md:border-none'
                }
            >
                <div className={'flex'}>
                    <h3 className={'w-2/4'}>{t('basket:shipmentDetails')}</h3>

                    <span
                        className={
                            'flex items-center text-secondary text-xs gap-x-2 focus:outline-none cursor-pointer'
                        }
                        onClick={openModal}
                    >
                        {t('profile:edit')}

                        <Icon className={'ml-1'} icon={'arrow-right'} />
                    </span>
                </div>

                <div className={'w-full mt-1'}>
                    <p className={'text-xs'}>
                        {t('basket:streetAptNumber', {
                            street: values.shippingAddress.street,
                            apartmentNumber: values.shippingAddress.apartmentNumber
                        })}
                    </p>

                    <p
                        className={'text-xs mt-1'}
                    >{`${values.shippingAddress.city}, ${values.shippingAddress.state} ${values.shippingAddress.postCode}`}</p>
                </div>
            </div>
        </>
    );
};

export default ShipmentDetails;
