//Hooks
import {usePaymentCardView} from './PaymentCardView.hook';

//Components
import Footer from 'components/nav/Footer';
import PaymentFormView from './PaymentFormView';
import {Elements} from '@stripe/react-stripe-js';
import PaymentBasketSummaryView from 'components/offer/PaymentBasketSummaryView';
import SearchForm from 'components/search/SearchForm';
import PaymentSavedCardView from 'views/PaymentCardView/PaymentSavedCardView';

//Types
import {RouteComponentProps} from '@reach/router';

const PaymentCardView: React.VFC<RouteComponentProps> = () => {
    const {stripePromise, savedCards} = usePaymentCardView();

    return (
        <>
            <div className={'flex flex-col flex-grow pb-9 bg-white md:bg-gray-200'}>
                <SearchForm />

                <div className={'w-full max-w-292 mx-auto mt-6 md:mt-12 px-4 lg:min-h-50'}>
                    <div className={'flex flex-col lg:flex-row gap-6 md:gap-14'}>
                        <div className={'lg:block lg:w-4/12 max-w-5/12'}>
                            <PaymentBasketSummaryView />
                        </div>

                        <Elements stripe={stripePromise}>
                            <div
                                className={'w-full lg:w-8/12 min-w-7/12 flex flex-col gap-y-4'}
                            >
                                <div className={'bg-white pb-9 '}>
                                    <PaymentFormView />
                                </div>

                                {savedCards && (
                                    <div
                                        className={
                                            'bg-white flex justify-between items-center p-5'
                                        }
                                    >
                                        <PaymentSavedCardView />
                                    </div>
                                )}
                            </div>
                        </Elements>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PaymentCardView;
