//Redux saga
import {call, select} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';

//Types
import {Auth} from 'store/types';
//Services
import axios from 'services/axios';

//Types
import {AxiosTransformer} from 'axios';

export function* getNotifications() {
    const notifuseAuthentication: Auth.NotifuseAuthenticationItems = yield select(
        state => state.auth.notifuseAuthentication
    );
    const {data} = yield call(
        request,
        {
            method: 'GET',
            url: `https://api.notifuse.com/front/messages.list?projectId=${notifuseAuthentication.projectId}&userId=${notifuseAuthentication.userId}&userHmac=${notifuseAuthentication.userHmac}&skip=0&limit=15`,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return data;
}

export function* markAllAsRead() {
    const notifuseAuthentication: Auth.NotifuseAuthenticationItems = yield select(
        state => state.auth.notifuseAuthentication
    );

    const transformRequest = [
        ...(axios.defaults.transformResponse as AxiosTransformer[]),
        (data: any) => JSON.stringify(data)
    ];

    const {data} = yield call(
        request,
        {
            method: 'POST',
            url: `https://api.notifuse.com/front/messages.readAll`,
            data: notifuseAuthentication,
            transformRequest,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return data;
}

export const saga = [
    takeEveryPromiseAction(actions.getNotifications, getNotifications),
    takeEveryPromiseAction(actions.markAllAsRead, markAllAsRead)
];
