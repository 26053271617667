//Action creators
import {createAsyncAction, createAction} from 'typesafe-actions';

//Types
import {Categories, Errors} from 'store/types';

/**
 * Gets custom form fields for category.
 */
export const getCategoryCustomFormFields = createAsyncAction(
    'GET_CATEGORY_CUSTOM_FORM_FIELDS_REQUEST',
    'GET_CATEGORY_CUSTOM_FORM_FIELDS_SUCCESS',
    'GET_CATEGORY_CUSTOM_FORM_FIELDS_FAILURE'
)<Categories.Category, Categories.CustomFormField.Fields, Errors.API>();

export const resetCategoryCustomFormFields = createAction(
    'RESET_CATEGORY_CUSTOM_FORM_FIELDS'
)();
