//Hooks
import {useTranslation} from 'react-i18next';
import {useOfferFormView} from './OfferFormView.hook';

//Components
import {Redirect} from '@reach/router';
import Footer from 'components/nav/Footer';
import Button from 'components/ui/Button';
import CustomFormFields from './CustomFormFields';
import CreateBaseForm from './BaseForm';
import LocationSelector from './LocationSelector';
import JobForm from './JobForm';
import RealEstateForm from './RealEstateForm';
import ProductForm from './ProductForm';
import CreateServiceForm from './ServiceForm';
import PeriodSelect from './PeriodSelect';
import SubmitForm from './SubmitForm/SubmitForm';
import NotFoundView from '../NotFoundView';
import Badge from 'components/ui/Badge';
import PopularSearches from 'components/nav/PopularSearches';
import AdditionalOptionsModal from 'components/modal/AdditionalOptionsModal';

//Utils
import {Formik} from 'formik';

//Types
import {RouteComponentProps} from '@reach/router';
import {Offer} from 'store/types';

const OfferFormView: React.VFC<RouteComponentProps> = () => {
    const {t} = useTranslation(['offers', 'common']);
    const {
        uploadedFiles,
        validationSchema,
        initialValues,
        existingOffer,
        isOfferLoading,
        isOfferLoaded,
        isEditable,
        errorCode,
        handleSubmit,
        setUploadedFiles,
        handleCancel,
        openExtendImageModal,
        closeExtendImageModal,
        isExtendImageModalVisible,
        user,
        isUserLoading
    } = useOfferFormView();

    if ((isOfferLoading && !isOfferLoaded && isEditable) || isUserLoading) {
        return <div className={'flex flex-col h-full bg-gray-100'}></div>;
    }
    if (errorCode === 404 || errorCode === 412 || !user) return <NotFoundView />;
    if (existingOffer?.status === Offer.Status.Closed)
        return <Redirect noThrow to={'/profile/my-offers/closed'} />;
    return (
        <div className={'flex flex-col h-full bg-gray-100'}>
            <div
                className={
                    'flex flex-col justify-center items-center flex-grow pb-11 w-full py-6 mx-auto max-w-292'
                }
            >
                <Button
                    size={'xs'}
                    variant={'primary'}
                    outline
                    className={'self-end hidden my-10 md:flex mx-6 lg:mx-0'}
                    onClick={handleCancel}
                >
                    {t('common:cancel')}
                </Button>

                <div
                    className={
                        'w-full bg-white rounded md:border border-gray-300 px-4 md:px-11 md:py-7.5 py-5 box-border'
                    }
                >
                    {existingOffer?.features?.highlight && (
                        <Badge variant={'lightgreen'} size={'lg'} className={''}>
                            {t('offers:promoted')}
                        </Badge>
                    )}

                    <h1 className={'hidden md:flex mt-10'}>
                        {t(existingOffer ? 'offers:editOffer' : 'offers:newOffer')}
                    </h1>

                    <Formik<Offer.Form>
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({values, errors}) => (
                            <form>
                                <div className={'lg:w-3/5'}>
                                    <CreateBaseForm
                                        openExtendImageModal={openExtendImageModal}
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={setUploadedFiles}
                                    />

                                    <LocationSelector />

                                    {values.type === Offer.Type.Product && <ProductForm />}

                                    {values.type === Offer.Type.RealEstate && (
                                        <RealEstateForm />
                                    )}

                                    {values.type === Offer.Type.Job && <JobForm />}

                                    {values.type === Offer.Type.Service && (
                                        <CreateServiceForm />
                                    )}

                                    {values.type !== Offer.Type.Product &&
                                        values.type !== Offer.Type.RealEstate && (
                                            <PeriodSelect />
                                        )}

                                    <CustomFormFields />
                                </div>

                                <SubmitForm />

                                {!!existingOffer && !Object.keys(errors).length && (
                                    <AdditionalOptionsModal
                                        offer={existingOffer}
                                        type={'extraPhotos'}
                                        isOpen={isExtendImageModalVisible}
                                        onClose={closeExtendImageModal}
                                    />
                                )}
                            </form>
                        )}
                    </Formik>
                </div>
            </div>

            <PopularSearches />

            <Footer />
        </div>
    );
};

export default OfferFormView;
