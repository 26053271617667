import * as actions from 'store/actions';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from './store';
import {redirectedFromOtherVersion} from 'utils/version';

export const useVersion = () => {
    const dispatch = useDispatch();
    const storageVersion = localStorage.getItem('siteVersion');
    const versions = useSelector(state => state.version.versions);
    const version = useSelector(state => state.version.version);
    const fetchedByCoordinates = useSelector(state => state.version.fetchedByCoordinates);

    const originNotSame = (version: string) =>
        process.env[`REACT_APP_ORIGIN_${version}`] &&
        window.location.origin !== process.env[`REACT_APP_ORIGIN_${version}`];

    const referrerSameAsOriginRedirect = (version: string) =>
        document.referrer &&
        process.env[`REACT_APP_ORIGIN_${version}`] &&
        document.referrer !== '' &&
        document.referrer.startsWith(process.env[`REACT_APP_ORIGIN_${version}`] as string);

    const redirectToNeededVersionIfNeeded = useCallback(() => {
        if (
            storageVersion &&
            originNotSame(storageVersion) &&
            !referrerSameAsOriginRedirect(storageVersion)
        ) {
            window.location.href = process.env[`REACT_APP_ORIGIN_${storageVersion}`] as string;
        }
    }, [storageVersion]);

    const setVersionToRedux = useCallback(() => {
        const current = versions.find(v => v.origin === window.location.origin);
        let redirected = false;
        if (current) {
            dispatch(actions.setVersion(current.value));
            redirected = redirectedFromOtherVersion(versions, current);
        }

        if (current && redirected) {
            localStorage.setItem('siteVersion', current.value);
        }

        if (storageVersion && !redirected && !current) {
            dispatch(actions.setVersion(storageVersion));
        }
    }, [storageVersion, dispatch, versions]);

    const detectVersionByGeolocation = useCallback(() => {
        if (!storageVersion) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        dispatch(
                            actions.getVersion.request({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            })
                        );
                    },
                    e => {
                        console.log(e);
                    }
                );
            }
        }
    }, [storageVersion, dispatch]);

    const setToStorageVersionRedirectIfNeeded = useCallback(() => {
        if (fetchedByCoordinates && version) {
            localStorage.setItem('siteVersion', version.value);
            if (originNotSame(version.value)) {
                window.location.href = process.env[
                    `REACT_APP_ORIGIN_${version.value}`
                ] as string;
            }
        }

        if (fetchedByCoordinates && !version) {
            const current = versions.find(v => v.origin === window.location.origin);
            if (current) {
                dispatch(actions.setVersion(current.value));
                localStorage.setItem('siteVersion', current.value);
            }
        }
    }, [fetchedByCoordinates, version, versions, dispatch]);

    useEffect(() => {
        redirectToNeededVersionIfNeeded();
        setVersionToRedux();
        detectVersionByGeolocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setToStorageVersionRedirectIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedByCoordinates]);
};
