import * as React from 'react';

//Icons
import {ReactComponent as CheckMark} from 'assets/icons/ui/check-mark.svg';
import {ReactComponent as DeleteIcon} from 'assets/icons/ui/delete.svg';
import {ReactComponent as SearchIcon} from 'assets/icons/ui/search.svg';
import {ReactComponent as FilterIcon} from 'assets/icons/ui/filter.svg';
import {ReactComponent as SortIcon} from 'assets/icons/ui/sort.svg';
import {ReactComponent as PlusIcon} from 'assets/icons/ui/plus.svg';
import {ReactComponent as MinusIcon} from 'assets/icons/ui/minus.svg';
import {ReactComponent as CameraIcon} from 'assets/icons/ui/camera.svg';
import {ReactComponent as HideIcon} from 'assets/icons/ui/hide.svg';
import {ReactComponent as ShowIcon} from 'assets/icons/ui/show.svg';
import {ReactComponent as InfoIcon} from 'assets/icons/ui/info.svg';
import {ReactComponent as NotificationIcon} from 'assets/icons/ui/notification.svg';
import {ReactComponent as TransportIcon} from 'assets/icons/ui/transport.svg';
import {ReactComponent as LocationIcon} from 'assets/icons/ui/location.svg';
import {ReactComponent as StarIcon} from 'assets/icons/ui/star.svg';
import {ReactComponent as StarOutlineIcon} from 'assets/icons/ui/star-outline.svg';
import {ReactComponent as CloseIcon} from 'assets/icons/ui/close.svg';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ui/arrow-right.svg';
import {ReactComponent as MapIndicator} from 'assets/icons/ui/map-indicator.svg';
import {ReactComponent as DatePickerDropdown} from 'assets/icons/ui/datepicker-dropdown.svg';
import {ReactComponent as CreditCardIcon} from 'assets/icons/payment/credit-card.svg';
import {ReactComponent as PaypalIcon} from 'assets/icons/payment/paypal.svg';
import {ReactComponent as ChevronRight} from 'assets/icons/ui/chevron-right.svg';
import {ReactComponent as PreviousIcon} from 'assets/icons/ui/previous.svg';
import {ReactComponent as NextIcon} from 'assets/icons/ui/next.svg';
import {ReactComponent as EyeIcon} from 'assets/icons/ui/eye.svg';

//Utils
import cx from 'classnames';

const ICONS = {
    'check-mark': CheckMark,
    delete: DeleteIcon,
    search: SearchIcon,
    filter: FilterIcon,
    sort: SortIcon,
    plus: PlusIcon,
    minus: MinusIcon,
    camera: CameraIcon,
    hide: HideIcon,
    show: ShowIcon,
    info: InfoIcon,
    notification: NotificationIcon,
    transport: TransportIcon,
    location: LocationIcon,
    star: StarIcon,
    'star-outline': StarOutlineIcon,
    close: CloseIcon,
    'arrow-right': ArrowRightIcon,
    'map-indicator': MapIndicator,
    'datepicker-dropdown': DatePickerDropdown,
    'credit-card': CreditCardIcon,
    paypal: PaypalIcon,
    'chevron-right': ChevronRight,
    previous: PreviousIcon,
    next: NextIcon,
    eye: EyeIcon
};

export type IconName = keyof typeof ICONS;

export type IconProps = {
    icon: IconName;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    className?: string;
    lat?: number;
    lng?: number;
};

const Icon: React.VFC<IconProps> = ({icon, className, size, ...props}) =>
    React.createElement(ICONS[icon], {
        className: cx(
            'fill-current stroke-current',
            {
                'h-2.5 w-2.5': size === 'xs',
                'h-3 w-3': size === 'sm',
                'h-4.5 w-4.5': size === 'md',
                'h-5.5 w-5.5': size === 'lg',
                'h-10 w-10': size === 'xl'
            },
            className
        ),
        role: 'img',
        'aria-label': icon,
        ...props
    });

export default Icon;
