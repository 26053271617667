//Hooks
import {useTranslation} from 'react-i18next';

//Components
import Modal from 'components/ui/Modal';
import {Offer} from 'store/types';

export type SuccessFeatureModalProps = {
    isOpen: boolean;
    type: Offer.AdditionalOptions;
    onClose: () => void;
};

const SuccessFeatureModal: React.VFC<SuccessFeatureModalProps> = ({type, isOpen, onClose}) => {
    const {t} = useTranslation('offers');

    return (
        <Modal isOpen={isOpen} size={'lg'} onClose={onClose}>
            <div className={'flex justify-center items-center flex-col py-8'}>
                <h2 className={'text-lg font-semibold'}>{t('congratulations')}</h2>

                <p role={'paragraph'} className={'text-xs-checkbox mt-8'}>
                    {type === 'highlight'
                        ? t('offerIsPromoted')
                        : type === 'extraPhotos'
                        ? t('offerHasMorePhotos')
                        : t('offerLastMore')}
                </p>
            </div>
        </Modal>
    );
};

export default SuccessFeatureModal;
