//Hooks
import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'hooks/store';
import {useFormik} from 'formik';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, string} from 'yup';

//Assets
import countryStates from 'assets/data/states';

//Types
import {User} from 'store/types';

export const useEditMyAddressView = (closeModal: () => void) => {
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();

    const initialValues = useMemo<User.EditAddress>(() => {
        if (!user) {
            return {
                street: '',
                apartmentNumber: '',
                city: '',
                postCode: '',
                state: ''
            };
        }

        if (user.type === User.Type.Company) {
            return {
                ...user.address,
                companyName: user.companyName,
                vatId: user.vatId
            };
        }

        return {
            ...user.address,
            firstName: user.firstName,
            lastName: user.lastName
        };
    }, [user]);

    const validationSchema = object<User.EditAddress>({
        firstName: user?.type === User.Type.Person ? string().required() : string(),
        lastName: user?.type === User.Type.Person ? string().required() : string(),
        street: string().required(),
        apartmentNumber: string(),
        city: string().required(),
        state: string().required(),
        postCode: string().required(),
        companyName: user?.type === User.Type.Company ? string().required() : string(),
        vatId: user?.type === User.Type.Company ? string().required() : string()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        resetForm
    } = useFormik<User.EditAddress>({
        initialValues,
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.editMyAddress.request(payload));
                closeModal();
            } catch (err) {
                if (err.message) {
                    toast.error(err.message);
                }

                if (err.errors) {
                    setErrors({...err.errors.account, ...err.errors.address});
                }
            }
        },
        enableReinitialize: true
    });

    useEffect(() => {
        resetForm();
    }, [closeModal, resetForm]);

    const states = Object.entries(countryStates).map(([value, label]) => ({
        value,
        label
    }));

    const selectedState = Object.entries(countryStates)
        .map(([value, label]) => ({
            value,
            label
        }))
        .find(({value}) => values.state === value);

    return {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        user,
        states,
        selectedState
    };
};
