//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Offer, Data} from 'store/types';

export type OffersAction = ActionType<typeof actions>;

export type State = Readonly<Data<Offer.OfferDetailed>>;

export const initialState: State = {
    isLoading: false,
    isLoaded: false,
    isErrored: false,
    data: null
};

export default createReducer<State, OffersAction>(initialState)
    .handleAction(actions.getOfferById.request, () => ({
        isLoading: true,
        isLoaded: false,
        isErrored: false,
        data: null
    }))
    .handleAction(actions.getOfferById.success, (_state, action) => ({
        isLoading: false,
        isLoaded: true,
        isErrored: false,
        data: action.payload
    }))
    .handleAction(actions.getOfferById.failure, () => ({
        isLoading: false,
        isLoaded: false,
        isErrored: true,
        data: null
    }));
