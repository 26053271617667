//Hooks
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'hooks/store';
import {useFormik} from 'formik';

//Actions
import * as actions from 'store/actions';

//Utils
import {object, string} from 'yup';

//Services
import toast from 'react-hot-toast';

//Types
import {User} from 'store/types';

export const useEditMyPhoneModal = (onSubmit: () => void) => {
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();

    const initialValues = useMemo<User.Phone>(
        () => ({
            phone: user?.phone ?? ''
        }),
        [user]
    );

    const validationSchema = object<Pick<User.BaseUser, 'phone'>>({
        phone: string().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm
    } = useFormik<User.Phone>({
        initialValues,
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.editMyPhone.request(payload));
                onSubmit();
            } catch (err) {
                if (err.errors?.phone) {
                    toast.error(err.errors?.phone);
                } else if (err.message) {
                    toast.error(err.message);
                }

                if (err.errors) {
                    setErrors(err.errors);
                }
            }
        }
    });

    useEffect(() => {
        resetForm();
    }, [onSubmit, resetForm]);

    return {values, touched, errors, handleChange, handleSubmit, isSubmitting};
};
