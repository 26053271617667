//Utils
import _ from 'lodash-es';

//Hooks
import {useTranslation} from 'react-i18next';
import {usePopularSearches} from './PopularSearches.hook';
import {useCategoryView} from 'components/ui/CategoryView/CategoryView.hook';
import {useCategories} from 'hooks/categories';

//Components
import Skeleton from 'react-loading-skeleton';
import {Link} from '@reach/router';

//Types
import {Categories} from 'store/types';

const PopularSearches: React.VFC = () => {
    const {t} = useTranslation('nav');
    const {popularSearches, areSearchesLoading} = usePopularSearches();
    const {data: categories} = useCategories();
    const {rows} = useCategoryView(categories ?? []);

    const transformedRows = rows?.flat().filter(c => c.id > 0);

    return (
        <div
            className={
                'hidden md:flex justify-center px-4 py-11 bg-white gap-x-8 border border-b-0 border-gray-300'
            }
        >
            <div className={'max-w-xl flex flex-col gap-y-4'}>
                {areSearchesLoading ? (
                    <>
                        <Skeleton width={120} height={18} />

                        {_.times(3, i => (
                            <Skeleton key={i} width={550} height={16} />
                        ))}
                    </>
                ) : (
                    <>
                        <h2>{t('popularSearches')}</h2>

                        <div className={'flex gap-x-1.5 flex-wrap'}>
                            {popularSearches?.map((item: string, index: number) => (
                                <Link
                                    key={index}
                                    to={`/search?phrase=${item}`}
                                    className={'hover:text-primary'}
                                >
                                    {popularSearches.length === index + 1 ? item : `${item},`}
                                </Link>
                            ))}
                        </div>
                    </>
                )}
            </div>

            <div className={'max-w-xl flex flex-col gap-y-4'}>
                {areSearchesLoading ? (
                    <>
                        <Skeleton width={120} height={18} />

                        {_.times(3, i => (
                            <Skeleton key={i} width={550} height={16} />
                        ))}
                    </>
                ) : (
                    <>
                        <h2>{t('categories')}</h2>

                        <div className={'flex gap-x-1.5 flex-wrap'}>
                            {transformedRows?.map((row: Categories.Category, index: number) => (
                                <Link
                                    key={index}
                                    to={`/search?categoryId=${row.id}`}
                                    className={'hover:text-primary'}
                                >
                                    {transformedRows.length === index + 1
                                        ? row?.name
                                        : `${row?.name},`}
                                </Link>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PopularSearches;
