//Utils
import cx from 'classnames';

export type CheckboxProps = {
    size?: 'md' | 'sm';
    className?: string;
    labelClassName?: string;
    label?: string | React.ReactElement;
    required?: boolean;
} & Omit<React.HTMLProps<HTMLInputElement>, 'size' | 'label' | 'className'>;

const Checkbox: React.VFC<CheckboxProps> = ({
    className,
    size = 'md',
    label,
    labelClassName,
    required,
    ...props
}) => (
    <div className={cx('flex flex-row items-center', className)}>
        <input
            type={'checkbox'}
            className={cx(
                {
                    'w-5 h-5': size === 'md',
                    'w-4 h-4 bg-2.5': size === 'sm'
                },
                'appearance-none bg-check-mark border border-gray-900 rounded-md checked:bg-gray-900 focus:outline-none cursor-pointer bg-no-repeat bg-center'
            )}
            {...props}
        />

        <label className={cx('ml-16 tracking-tight', labelClassName)} htmlFor={props.id}>
            {label}

            {required && <span className={'text-secondary ml-0.5'}>*</span>}
        </label>
    </div>
);

export default Checkbox;
