//Store
import {configureStore} from '@reduxjs/toolkit';

//Reducers
import {
    adverts,
    auth,
    basket,
    categories,
    favorites,
    myOffers,
    myPurchases,
    notifications,
    offer,
    offers,
    payment,
    reviews,
    search,
    staticPages,
    user,
    visitedProfile,
    conversations,
    version
} from 'store/reducers';

//Redux saga
import createSagaMiddleware from 'redux-saga';
import {promiseMiddleware} from 'redux-saga-promise-actions';
import {all} from 'redux-saga/effects';

//Sagas
import {saga as advertsSaga} from 'store/sagas/adverts';
import {saga as authSaga} from 'store/sagas/auth';
import {saga as categoriesSaga} from 'store/sagas/categories';
import {saga as favoritesSaga} from 'store/sagas/favorites';
import {saga as filesSaga} from 'store/sagas/files';
import {saga as locationSaga} from 'store/sagas/location';
import {saga as offerSaga} from 'store/sagas/offer';
import {saga as offersSaga} from 'store/sagas/offers';
import {saga as ordersSaga} from 'store/sagas/orders';
import {saga as paymentSaga} from 'store/sagas/payment';
import {saga as profileSaga} from 'store/sagas/profile';
import {saga as reviewsSaga} from 'store/sagas/reviews';
import {saga as searchSaga} from 'store/sagas/search';
import {saga as conversationsSaga} from 'store/sagas/conversations';
import {saga as staticPagesSaga} from 'store/sagas/staticPages';
import {saga as basketSaga} from 'store/sagas/basket';
import {saga as userSaga} from 'store/sagas/user';
import {saga as constantsSaga} from 'store/sagas/constants';
import {saga as notificationsSaga} from 'store/sagas/notifications';
import {saga as versionSaga} from 'store/sagas/version';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        adverts,
        auth,
        basket,
        categories,
        favorites,
        myOffers,
        myPurchases,
        notifications,
        offer,
        offers,
        payment,
        reviews,
        search,
        staticPages,
        user,
        conversations,
        visitedProfile,
        version
    },
    middleware: [promiseMiddleware, sagaMiddleware],
    devTools: true
});

export default store;

function* saga() {
    yield all([
        ...advertsSaga,
        ...authSaga,
        ...basketSaga,
        ...categoriesSaga,
        ...filesSaga,
        ...locationSaga,
        ...searchSaga,
        ...conversationsSaga,
        ...constantsSaga,
        ...favoritesSaga,
        ...filesSaga,
        ...notificationsSaga,
        ...offerSaga,
        ...offersSaga,
        ...ordersSaga,
        ...paymentSaga,
        ...profileSaga,
        ...reviewsSaga,
        ...searchSaga,
        ...staticPagesSaga,
        ...userSaga,
        ...versionSaga
    ]);
}

sagaMiddleware.run(saga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
