//Hooks
import {useSelector} from 'hooks/store';
import {useEffect} from 'react';
import {useFormikContext} from 'formik';

//Utils
import _ from 'lodash-es';

//Types
import {Offer} from 'store/types';

export const useCustomFormFields = () => {
    const {
        data: customFormFields,
        isLoaded: areCustomFieldsLoaded,
        isLoading: areCustomFieldsLoading,
        props: category
    } = useSelector(state => state.categories.customFormFields);
    const {data: existingOffer} = useSelector(state => state.offers.editedOffer);
    const shouldLoadExistingFields =
        !!category && category.id === existingOffer?.categoryId && !_.isEmpty(customFormFields);
    const shouldDisplayCustomFields = areCustomFieldsLoaded && !areCustomFieldsLoading;
    const {setFieldValue} = useFormikContext<Offer.Form>();

    useEffect(() => {
        const initialAttributes = shouldLoadExistingFields
            ? existingOffer!.attributes
            : Object.fromEntries(
                  Object.entries(customFormFields ?? {}).map(([key, value]) => [
                      key,
                      value.default ?? (value.type === 'string' ? '' : undefined)
                  ])
              );
        setFieldValue('offer.attributes', initialAttributes);
    }, [customFormFields, shouldLoadExistingFields, existingOffer, setFieldValue]);

    return {customFormFields, shouldDisplayCustomFields};
};
