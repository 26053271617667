//Hooks
import {useLocationSelector} from './LocationSelector.hook';
import {useTranslation} from 'react-i18next';

//Components
import Select from 'react-select';
import GoogleMapReact from 'google-map-react';
import Marker from 'components/ui/Map/Marker';
import Input from 'components/ui/Input';
import Checkbox from 'components/ui/Checkbox';
import Button from 'components/ui/Button';

//Utils
import {getIn} from 'formik';

//Styles
import {styles, errorStyles, disabledStyles, theme} from 'styles/select';

const DEFAULT_LAT = !!process.env.REACT_APP_DEFAULT_LAT
    ? parseFloat(process.env.REACT_APP_DEFAULT_LAT)
    : 0;
const DEFAULT_LON = !!process.env.REACT_APP_DEFAULT_LON
    ? parseFloat(process.env.REACT_APP_DEFAULT_LON)
    : 0;

const LocationSelector: React.VFC = () => {
    const {
        states,
        selectedState,
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleGetCoords
    } = useLocationSelector();
    const {t} = useTranslation(['offers', 'auth', 'common']);

    return (
        <>
            <Checkbox
                id={'hideLocation'}
                name={'hideLocation'}
                className={'mt-8'}
                label={t('offers:dontShowLocation')}
                labelClassName={'text-form'}
                checked={values.hideLocation}
                onChange={handleChange}
            />

            <h2
                className={'mt-4 text-sm md:text-lg font-semibold md:font-bold'}
                id={'location-heading'}
            >
                {t('offers:location')}
            </h2>

            <div className={'flex flex-col md:flex-row gap-x-4 mt-3.5 gap-y-3.5'}>
                <Input
                    className={'flex-grow'}
                    name={'address.city'}
                    id={'address.city'}
                    label={t('auth:city')}
                    value={values.address?.city ?? ''}
                    onChange={handleChange}
                    disabled={values.hideLocation}
                    error={touched.address && getIn(errors.address, 'city')}
                />

                <div className={'flex gap-x-10 md:gap-x-4 flex-grow'}>
                    <div className={'flex-grow'} role={'region'} aria-label={'address.state'}>
                        <label id={'state-label'} className={'block mb-3 text-sm'}>
                            {t('auth:state')}
                        </label>

                        <Select
                            aria-labelledby={'state-label'}
                            id={'address.state'}
                            isDisabled={values.hideLocation}
                            noOptionsMessage={() => t('common:noOptions')}
                            name={'address.state'}
                            options={states}
                            placeholder={t('common:selectPlaceholderDefault')}
                            value={selectedState}
                            styles={
                                values.hideLocation
                                    ? disabledStyles
                                    : touched.address && getIn(errors.address, 'state')
                                    ? errorStyles
                                    : styles
                            }
                            theme={theme}
                            onChange={option => setFieldValue('address.state', option?.value)}
                        />

                        {touched.address && (
                            <span
                                className={'tracking-tight text-red text-form-helper'}
                                role={'alert'}
                                aria-label={`error`}
                            >
                                {getIn(errors.address, 'state')}
                            </span>
                        )}
                    </div>

                    <Input
                        className={'w-32'}
                        name={'address.postCode'}
                        id={'address.postCode'}
                        label={t('auth:zipCode')}
                        value={values.address?.postCode ?? ''}
                        onChange={handleChange}
                        disabled={values.hideLocation}
                        error={touched.address && getIn(errors.address, 'postCode')}
                    />
                </div>
            </div>

            <div className={'flex flex-col md:flex-row gap-x-4 mt-3.5 gap-y-3.5'}>
                <Input
                    name={'address.street'}
                    id={'address.street'}
                    className={'flex-grow'}
                    label={t('auth:street')}
                    value={values.address?.street ?? ''}
                    onChange={handleChange}
                    disabled={values.hideLocation}
                    error={touched.address && getIn(errors.address, 'street')}
                />

                <div className={'flex justify-between items-end md:gap-x-4'}>
                    <Input
                        className={'w-32'}
                        name={'address.apartmentNumber'}
                        id={'address.apartmentNumber'}
                        label={t('auth:apartmentNumber')}
                        value={values.address?.apartmentNumber ?? ''}
                        onChange={handleChange}
                        disabled={values.hideLocation}
                        error={touched.address && getIn(errors.address, 'apartmentNumber')}
                    />

                    <Button
                        variant={'primary'}
                        size={'xs'}
                        className={'my-2'}
                        outline
                        type={'button'}
                        onClick={() => handleGetCoords(values.address!)}
                    >
                        {t('offers:checkMap')}
                    </Button>
                </div>
            </div>

            <p className={'text-form-helper text-red tracking-tight block mt-4'}>
                {typeof getIn(errors.address, 'geoCoordinate') === 'string'
                    ? getIn(errors.address, 'geoCoordinate')
                    : ''}
            </p>

            <div className={'h-80 mt-6'}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}}
                    center={{
                        lat: values.address?.geoCoordinate?.lat ?? DEFAULT_LAT,
                        lng: values.address?.geoCoordinate?.lon ?? DEFAULT_LON
                    }}
                    defaultZoom={14}
                >
                    {!values.hideLocation && (
                        <Marker
                            lat={values.address?.geoCoordinate?.lat ?? DEFAULT_LAT}
                            lng={values.address?.geoCoordinate?.lon ?? DEFAULT_LON}
                        />
                    )}
                </GoogleMapReact>
            </div>
        </>
    );
};

export default LocationSelector;
