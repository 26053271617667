//Hooks
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'hooks/store';
import {useCategories} from 'hooks/categories';
import {useAdverts} from 'hooks/adverts';

//Actions
import * as actions from 'store/actions';

//Types
import {Adverts} from 'store/types';
import {SortChoices} from 'views/SearchView/SearchView.hook';

export const useHomeView = () => {
    const {data: categories, isLoaded: areCategoriesLoaded} = useCategories();
    const {data: allAdverts, isLoaded: areAdvertsLoaded} = useAdverts();
    const {data: offers, isLoading: areOffersLoading} = useSelector(
        state => state.search.result
    );
    const token = useSelector(state => state.auth.token);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.search.request({sort: SortChoices.newest}));
    }, [dispatch]);

    const advertsBottom = allAdverts?.filter(ad => ad.area === Adverts.HomeArea.WebBottom);

    const advertsMobile = allAdverts?.filter(ad => ad.area === Adverts.HomeArea.MobileMainPage);

    const advertsBetween = allAdverts?.filter(
        ad => ad.area === Adverts.HomeArea.WebBetweenOffers
    );

    const adSenseMobileAdvert = allAdverts?.find(ad => ad.area === 'mobile_google_ad');

    return {
        categories,
        areCategoriesLoaded,
        advertsBottom,
        advertsMobile,
        advertsBetween,
        areAdvertsLoaded,
        adSenseMobileAdvert,
        topOffers: offers?.items?.slice(0, 15),
        areOffersLoading,
        token
    };
};
