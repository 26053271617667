//Redux saga
import {call, takeEvery, put, select, takeLatest} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions/offers';

//Sagas
import {request} from 'store/sagas/api';

//Utils
import {transformers} from 'utils';
import _ from 'lodash-es';

//Services
import i18n from 'services/i18n';

//Types
import {AxiosResponse} from 'axios';
import {Offer} from 'store/types';

export function* getMyOffers(action: ReturnType<typeof actions.getMyOffers.request>) {
    const {status, page} = action.payload;
    try {
        const resp: AxiosResponse<Offer.ListResponse> = yield call(
            request,
            {
                method: 'GET',
                url: `/api/offers/mine?status=${status}&page=${page}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                transformResponse: transformers.search.transformResponse
            },
            true
        );

        yield put(actions.getMyOffers.success({...resp.data, status}));
    } catch (err) {
        yield put(actions.getMyOffers.failure({...err, status}));
    }
}

export function* viewOffer(action: ReturnType<typeof actions.viewOffer.request>) {
    yield call(
        request,
        {
            method: 'POST',
            url: '/api/stats/store-offers-visits',
            data: {data: [action.payload]}
        },
        true
    );
}

export function* publishOffer(action: ReturnType<typeof actions.publishOffer.request>) {
    try {
        yield call(
            request,
            {
                method: 'POST',
                url: `/api/offers/${action.payload.id}/publish`
            },
            true
        );
        const currentPage: number = yield select(state => state.myOffers.draft.currentPage);
        yield put(actions.getMyOffers.request({status: Offer.Status.Draft, page: currentPage}));
    } catch (err) {
        if (err.code === 400) {
            err.message = i18n.t('offers:notReadyForPublishing');
        }
        throw err;
    }
}

export function* closeOffer(action: ReturnType<typeof actions.closeOffer.request>) {
    yield call(
        request,
        {
            method: 'POST',
            url: `/api/offers/${action.payload.id}/close`
        },
        true
    );
    const currentPage: number = yield select(state => state.myOffers.published.currentPage);
    yield put(actions.getMyOffers.request({status: Offer.Status.Published, page: currentPage}));
}

export function* createOffer(action: ReturnType<typeof actions.createOffer.request>) {
    const resp: AxiosResponse<Offer.CreateOfferResponse> = yield call(
        request,
        {
            method: 'POST',
            url: '/api/offers',
            data: action.payload,
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: transformers.createOrEditOffer.transformRequest
        },
        true
    );

    const currentPage: number = yield select(state => state.myOffers.draft.currentPage);
    yield put(
        actions.getMyOffers.request({status: action.payload.offer.status, page: currentPage})
    );

    return resp.data;
}
export function* getEditedOffer(action: ReturnType<typeof actions.getEditedOffer.request>) {
    try {
        const resp: AxiosResponse<{offer: Offer.ListItem}> = yield call(
            request,
            {
                method: 'GET',
                url: `/api/offers/${action.payload}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            true
        );

        yield put(actions.getEditedOffer.success(resp.data.offer));
    } catch (err) {
        yield put(actions.getEditedOffer.failure(err));
    }
}
export function* editOffer(action: ReturnType<typeof actions.editOffer.request>) {
    const {offerId, data} = action.payload;
    yield call(
        request,
        {
            method: 'POST',
            url: `/api/offers/${offerId}`,
            data: _.assign(data, {offerId: offerId}),
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: transformers.createOrEditOffer.transformRequest
        },
        true
    );
}

export const saga = [
    takeEvery(actions.getMyOffers.request, getMyOffers),
    takeEveryPromiseAction(actions.publishOffer, publishOffer),
    takeEveryPromiseAction(actions.closeOffer, closeOffer),
    takeEveryPromiseAction(actions.createOffer, createOffer),
    takeLatest(actions.getEditedOffer.request, getEditedOffer),
    takeEveryPromiseAction(actions.editOffer, editOffer),
    takeEveryPromiseAction(actions.viewOffer, viewOffer)
];
