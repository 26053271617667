//Hooks
import {useLoginView} from './LoginView.hook';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';

//Types
import {RouteComponentProps} from '@reach/router';

const LoginView: React.VFC<RouteComponentProps> = () => {
    const {values, touched, errors, handleChange, handleSubmit, isSubmitting} = useLoginView();

    const {t} = useTranslation('auth');

    return (
        <form className={'flex flex-col flex-grow'} onSubmit={handleSubmit}>
            <div className={'flex flex-col flex-grow md:flex-grow-0 w-full sm:w-7/12'}>
                <Input
                    id={'username'}
                    name={'username'}
                    value={values.username}
                    onChange={handleChange}
                    label={t('nameOrEmailAddress')}
                    error={touched.username && errors.username}
                />

                <Input
                    id={'password'}
                    name={'password'}
                    value={values.password}
                    onChange={handleChange}
                    className={'mt-3.5'}
                    label={t('password')}
                    type={'password'}
                    error={touched.password && errors.password}
                />

                <span className={'self-end my-2 text-form text-secondary'}>
                    <Link to={'/auth/forgot-password'}>{t('forgotMyPassword')}</Link>
                </span>
            </div>

            <Button
                className={'self-center mt-12 w-full max-w-88'}
                variant={'primary'}
                type={'submit'}
                disabled={isSubmitting}
            >
                {t('login')}
            </Button>
        </form>
    );
};

export default LoginView;
