import {MutableRefObject, useCallback, useEffect, useState} from 'react';
import _ from 'lodash-es';
import qs from 'querystring';
import {useLocation, useNavigate} from '@reach/router';

type UsePagination = {
    ref?: MutableRefObject<any>;
};

export const useLocationSearchPagination = ({ref}: UsePagination) => {
    const location = useLocation();
    const navigate = useNavigate();
    const search = qs.parse(location.search.substring(1));
    const pageFromSearch = search['page'] ? Number(search['page']) : 1;

    const setPageInQueryString = useCallback(
        (page: number) => {
            const currentSearch = qs.parse(location.search.substring(1));
            _.assign(currentSearch, {page: page});
            navigate(`${location.pathname}?${qs.stringify(currentSearch)}`, {
                replace: page === 1
            });
        },
        [navigate, location]
    );

    useEffect(() => {
        if (!search['page']) {
            setPageInQueryString(pageFromSearch);
        }
        if (pageFromSearch < 1) {
            setPageInQueryString(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPageInQueryString, pageFromSearch]);

    const [intersecting, setIntersecting] = useState(false);
    const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
    );

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return {
        changePage: useCallback(
            (page: number) => {
                setPageInQueryString(page);
                if (ref?.current && !intersecting) {
                    ref.current.scrollIntoView({behavior: 'smooth', block: 'start'});
                }
            },
            [ref, intersecting, setPageInQueryString]
        ),
        pageFromSearch,
        setPageInQueryString
    };
};
