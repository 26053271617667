//Hooks
import {usePaymentSuccessView} from './PaymentSuccessView.hook';

//Translations
import {useTranslation} from 'react-i18next';

//Components
import Footer from 'components/nav/Footer';
import Button from 'components/ui/Button';
import SearchForm from 'components/search/SearchForm';
import {Link} from '@reach/router';

//Types
import {RouteComponentProps} from '@reach/router';

const PaymentSuccessView: React.VFC<RouteComponentProps> = () => {
    const {orderId} = usePaymentSuccessView();
    const {t} = useTranslation('payment');

    return (
        <>
            <div className={'flex flex-col flex-grow pb-9 bg-gray-200'}>
                <SearchForm />

                <div className={'flex h-full items-center justify-center py-12 '}>
                    <div
                        className={
                            'w-full h-full lg:w-7/12 min-w-7/12 px-4 flex flex-col bg-white items-center justify-evenly'
                        }
                    >
                        <h2>{t('paymentSuccessTitle')}</h2>

                        <p className={'text-center'}>
                            {t('paymentSuccessDescription', {orderId})}
                        </p>

                        <Button to={'/profile/my-purchases'} variant={'primary'} as={Link}>
                            {t('goToMyPurchases')}
                        </Button>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PaymentSuccessView;
