//Hooks
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Button from 'components/ui/Button';

//Types
import {RouteComponentProps} from '@reach/router';

const ForgotPasswordResetSuccessView: React.FC<RouteComponentProps> = () => {
    const {t} = useTranslation('auth');

    return (
        <div className={'px-8 pt-32 flex flex-col items-center md:h-116.5 h-full'}>
            <h2>{t('resetPasswordSuccessHeading')}</h2>

            <span
                className={'flex-grow mt-6 text-xs tracking-tighter text-center text-gray-900'}
            >
                {t('resetPasswordSuccessDescription')}
            </span>

            <Button
                as={Link}
                to={'/auth/login'}
                variant={'primary'}
                size={'sm'}
                className={'md:mb-22 mb-18'}
            >
                {t('backToLogin')}
            </Button>
        </div>
    );
};

export default ForgotPasswordResetSuccessView;
