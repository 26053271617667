//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Adverts} from 'store/types';

export type AdvertsAction = ActionType<typeof actions>;

export type State = Readonly<{
    adverts: Data<Adverts.Advertisement[]>;
}>;

export const initialState: State = {
    adverts: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    }
};

export default createReducer<State, AdvertsAction>(initialState)
    .handleAction(actions.getAdvertisements.request, state => ({
        ...state,
        adverts: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.adverts.data
        }
    }))
    .handleAction(actions.getAdvertisements.success, (state, action) => ({
        ...state,
        adverts: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload
        }
    }))
    .handleAction(actions.getAdvertisements.failure, state => ({
        ...state,
        adverts: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }));
