//Redux saga
import {call, put, takeLatest} from 'redux-saga/effects';
import {takeLatestPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';

//Transformers
import {transformers} from 'utils';

//Types
import {AxiosResponse} from 'axios';
import {Reviews} from 'store/types';

export function* getSellerReviews(action: ReturnType<typeof actions.getSellerReviews.request>) {
    try {
        const resp: AxiosResponse<Reviews.Response.Seller> = yield call(request, {
            method: 'GET',
            url: `/api/reviews/account/${action.payload.id}?page=${action.payload.page}`,
            transformResponse: transformers.reviews.transformGetSellerReviewsResponse
        });

        yield put(actions.getSellerReviews.success(resp.data));
    } catch (err) {
        yield put(actions.getSellerReviews.failure(err));
    }
}

export function* getOfferReviews(action: ReturnType<typeof actions.getOfferReviews.request>) {
    try {
        const resp: AxiosResponse<Reviews.Response.Offer> = yield call(request, {
            method: 'GET',
            url: `/api/reviews/offer/${action.payload.id}?page=${action.payload.page}`,
            transformResponse: transformers.reviews.transformGetOfferReviewsResponse
        });

        yield put(actions.getOfferReviews.success(resp.data));
    } catch (err) {
        yield put(actions.getOfferReviews.failure(err));
    }
}

export function* getMyReviews(action: ReturnType<typeof actions.getMyReviews.request>) {
    try {
        const resp: AxiosResponse<Reviews.Response.MyReview> = yield call(
            request,
            {
                method: 'GET',
                url: `/api/reviews/my-reviews?page=${action.payload.page}`,
                transformResponse: transformers.reviews.transformGetMyReviewsResponse
            },
            true
        );

        yield put(actions.getMyReviews.success(resp.data));
    } catch (err) {
        yield put(actions.getMyReviews.failure(err));
    }
}

export function* createSellerReview(
    action: ReturnType<typeof actions.createSellerReview.request>
) {
    const {seller, ...data} = action.payload;

    yield call(
        request,
        {
            method: 'POST',
            url: `/api/reviews/account/${seller.id}`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export function* createOfferReview(
    action: ReturnType<typeof actions.createOfferReview.request>
) {
    const {offer, ...data} = action.payload;

    yield call(
        request,
        {
            method: 'POST',
            url: `/api/reviews/offer/${offer.id}`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export function* createProductReview(
    action: ReturnType<typeof actions.createProductReview.request>
) {
    const {product, ...data} = action.payload;

    yield call(
        request,
        {
            method: 'POST',
            url: `/api/reviews/order-product/${product.orderProductId}`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export function* disputeReview(action: ReturnType<typeof actions.disputeReview.request>) {
    const {reviewId, ...data} = action.payload;

    yield call(
        request,
        {
            method: 'POST',
            url: `/api/reviews/dispute/${reviewId}`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export const saga = [
    takeLatest(actions.getSellerReviews.request, getSellerReviews),
    takeLatest(actions.getOfferReviews.request, getOfferReviews),
    takeLatest(actions.getMyReviews.request, getMyReviews),
    takeLatestPromiseAction(actions.createSellerReview, createSellerReview),
    takeLatestPromiseAction(actions.createOfferReview, createOfferReview),
    takeLatestPromiseAction(actions.createProductReview, createProductReview),
    takeLatestPromiseAction(actions.disputeReview, disputeReview)
];
