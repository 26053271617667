//Hooks
import {useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';

//Components
import NavIcon from 'components/ui/NavIcon';
import Notification from 'components/ui/Notification';
import Button from 'components/ui/Button';

//Utils
import cx from 'classnames';
import {navigate} from '@reach/router';

//Types
import {Notifications} from 'store/types';

//Styles
import Styles from './NavigationNotifications.module.scss';

export type NavigationNotificationsProps = {
    notifications: Notifications.Notifications | null;
    className?: string;
};

const NavigationNotifications: React.VFC<NavigationNotificationsProps> = ({
    notifications,
    className
}) => {
    const {t} = useTranslation('notifications');
    const [notificationsOpen, toggleNotifications] = useState(false);
    const notificationsRef = useRef(null);

    useOnClickOutside(notificationsRef, () => toggleNotifications(false));

    return (
        <div className={cx('relative', className)} ref={notificationsRef}>
            <NavIcon
                icon={'notification'}
                onClick={() => toggleNotifications(!notificationsOpen)}
                className={cx('cursor-pointer mx-3', Styles.icon)}
                notification={!!notifications?.unread}
            />

            {notificationsOpen && (
                <div
                    className={
                        'absolute z-10 flex flex-col items-center py-6 bg-white border border-gray-300 w-80 top-8 px-7'
                    }
                >
                    <div className={'w-full max-h-44 overflow-scroll'}>
                        {notifications?.messages?.length! > 0 ? (
                            notifications?.messages?.map(
                                (notification: Notifications.Notification) => (
                                    <Notification
                                        key={notification?.id}
                                        className={'w-full'}
                                        notification={notification}
                                    />
                                )
                            )
                        ) : (
                            <p className={'font-bold'}>{t('notificationsEmpty')}</p>
                        )}
                    </div>

                    <Button
                        className={'mt-6'}
                        variant={'primary'}
                        size={'xs'}
                        outline
                        onClick={() => {
                            navigate('/notifications');
                            toggleNotifications(false);
                        }}
                    >
                        {t('seeAll')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default NavigationNotifications;
