export type TooltipWrapperProps = {
    id?: string;
};

const Wrapper: React.FC<TooltipWrapperProps> = ({id, children}) => (
    <div data-tip data-for={id} aria-describedby={id}>
        {children}
    </div>
);

export default Wrapper;
