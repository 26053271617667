import {createAsyncAction} from 'typesafe-actions';
import {createPromiseAction} from 'redux-saga-promise-actions';
import {Errors, User} from 'store/types';

export const getMyStats = createAsyncAction(
    'GET_USER_STATS_REQUEST',
    'GET_USER_STATS_SUCCESS',
    'GET_USER_STATS_FAILURE'
)<undefined, User.Stats, Errors.API>();

export const uploadAvatar = createPromiseAction('UPLOAD_AVATAR')<
    File,
    User.UploadAvatarResponse,
    Errors.API
>();

export const deleteAvatar = createPromiseAction('DELETE_AVATAR')<
    undefined,
    undefined,
    Errors.API
>();

export const editMyAddress = createPromiseAction('EDIT_MY_ADDRESS')<
    User.EditAddress,
    User.EditAddress,
    Errors.API<User.EditAddress>
>();

export const editMyPhone = createPromiseAction('EDIT_MY_PHONE')<
    {phone: string},
    User.PersonPhoneReturn,
    Errors.API<User.Phone>
>();

export const editMyPhoneConfirm = createPromiseAction('EDIT_MY_PHONE_CONFIRM')<
    User.PersonPhoneConfirm,
    undefined,
    Errors.API<User.PersonPhoneConfirm>
>();

export const editMyEmail = createPromiseAction('EDIT_MY_EMAIL')<
    {email: string},
    User.PersonEmailReturn,
    Errors.API<User.Email>
>();

export const editMyEmailConfirm = createPromiseAction('EDIT_MY_EMAIL_CONFIRM')<
    User.PersonEmailConfirm,
    undefined,
    Errors.API<User.PersonEmailConfirm>
>();

export const editMyPassword = createPromiseAction('EDIT_MY_PASSWORD')<
    User.Password,
    undefined,
    Errors.API<User.Password>
>();

export const editMyPayout = createPromiseAction('EDIT_MY_PAYOUT')<
    User.Payout,
    User.PayoutReturn,
    Errors.API<User.Payout>
>();

export const getCards = createPromiseAction('GET_CARDS')<undefined, User.Cards, Errors.API>();

export const setCard = createPromiseAction('SET_CARD')<string, undefined, Errors.API>();
