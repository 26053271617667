//Hooks
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Footer from 'components/nav/Footer';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import Button from 'components/ui/Button';
import SearchForm from 'components/search/SearchForm';
import Banner from 'components/images/Banner';

//Types
import {RouteComponentProps} from '@reach/router';

const NotFoundView: React.VFC<RouteComponentProps> = () => {
    const {t} = useTranslation(['errors', 'common']);

    return (
        <div className={'flex flex-col h-full bg-gray-100'}>
            <SearchForm />

            <Banner />

            <div className={'flex flex-col flex-grow items-center text-center'}>
                <h2 className={'font-semibold mt-8'}>{t('errors:notFoundTitle')}</h2>

                <span className={'text-xs mt-12'}>{t('errors:notFoundDescription')}</span>

                <Button as={Link} to={'/'} className={'mt-16'} variant={'primary'} size={'sm'}>
                    {t('common:goToMainPage')}
                </Button>
            </div>

            <Footer />

            <MobileBottomNav />
        </div>
    );
};

export default NotFoundView;
