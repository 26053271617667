import * as React from 'react';

//Icons
import {ReactComponent as Twitter} from 'assets/icons/social/twitter.svg';
import {ReactComponent as Facebook} from 'assets/icons/social/facebook.svg';
import {ReactComponent as Instagram} from 'assets/icons/social/instagram.svg';
import {ReactComponent as LinkedIn} from 'assets/icons/social/linked-in.svg';
import {ReactComponent as TikTok} from 'assets/icons/social/tiktok.svg';

const SOCIAL_ICONS = {
    twitter: Twitter,
    facebook: Facebook,
    instagram: Instagram,
    'linked-in': LinkedIn,
    tiktok: TikTok
};

export type SocialIconProps = {
    icon: 'twitter' | 'facebook' | 'instagram' | 'linked-in' | 'tiktok';
    className?: string;
};

const SocialIcon: React.VFC<SocialIconProps> = ({icon, className}) =>
    React.createElement(SOCIAL_ICONS[icon], {className, role: 'img', 'aria-label': icon});

export default SocialIcon;
