//Redux saga
import {fork, put, call, take, actionChannel} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';
import {resolvePromiseAction, rejectPromiseAction} from 'redux-saga-promise-actions';

//Actions
import * as actions from 'store/actions/files';

//Sagas
import {request} from 'store/sagas/api';

//Types
import {Channel} from 'redux-saga';
import {AxiosResponse} from 'axios';
import {Files} from 'store/types';

function* watchUpload() {
    const channel: Channel<ReturnType<typeof actions.uploadFile.request>> = yield actionChannel(
        actions.uploadFile.request
    );

    while (true) {
        const action: ReturnType<typeof actions.uploadFile.request> = yield take(channel);
        yield call(uploadFile, action);
    }
}

export function* uploadFile(action: ReturnType<typeof actions.uploadFile.request>) {
    const form = new FormData();
    form.append('upload_image_form[file]', action.payload);

    try {
        const resp: AxiosResponse<Files.FileUploadResponse> = yield call(
            request,
            {
                method: 'POST',
                url: '/api/files/images',
                data: form,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            true
        );

        yield resolvePromiseAction(action, resp.data);
        yield put(actions.uploadFile.success(resp.data));
    } catch (err) {
        yield rejectPromiseAction(action, err);
        yield put(actions.uploadFile.failure(err));
    }
}

export function* deleteFile(action: ReturnType<typeof actions.deleteFile.request>) {
    const resp: AxiosResponse<undefined> = yield call(
        request,
        {
            method: 'DELETE',
            url: `/api/files/images/${action.payload.fileId}`
        },
        true
    );

    return resp.status;
}

export const saga = [fork(watchUpload), takeEveryPromiseAction(actions.deleteFile, deleteFile)];
