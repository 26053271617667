//Hooks
import {useBasketOfferGroup} from './BasketOfferGroup.hook';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';

//Types
import {Offer, User} from 'store/types';
import BasketOfferView from 'components/offer/BasketOfferView';

//Utils
import cx from 'classnames';

type BasketOfferGroupProps = {
    offers: Offer.GroupedOffer[];
    handleSelectSeller: (seller: User.User) => void;
};

const BasketOfferGroup: React.VFC<BasketOfferGroupProps> = ({offers, handleSelectSeller}) => {
    const {t} = useTranslation('basket');
    const {onDeleteBasketOffer} = useBasketOfferGroup();
    return (
        <>
            {offers.map((group, key) => (
                <div
                    key={group.user?.id}
                    className={cx({'mt-4 pt-8 border-t-1.5 border-gray-300': key > 0}, 'pt-4')}
                >
                    <div className={'w-full flex items-center pb-2 mb-2'}>
                        {offers.length > 1 && (
                            <div
                                role={'radio'}
                                aria-checked={group.selected}
                                onClick={() => handleSelectSeller(group.user)}
                                className={cx(
                                    {
                                        'bg-black': group.selected
                                    },
                                    'w-5 h-5 mr-4 cursor-pointer rounded-full border-1.5 border-black'
                                )}
                            />
                        )}

                        <img
                            src={group.user.avatar ?? defaultAvatar}
                            onError={e => {
                                e.currentTarget.src =
                                    group.user.avatarFallback ?? defaultAvatar;
                            }}
                            alt={group.user.username}
                            className={'h-10 w-10 rounded-full object-cover'}
                        />

                        <div className={'ml-2'}>
                            <h4 className={'tracking-tight font-normal text-gray-400 text-sm'}>
                                <Link
                                    aria-label={group.user.id?.toString()}
                                    to={`/user/${group!.user.id}`}
                                    className={'text-secondary'}
                                >
                                    {group!.user.type === User.Type.Person
                                        ? group!.user.firstName
                                        : group!.user.companyName}
                                </Link>
                            </h4>

                            <p className={'text-xs text-gray-400'}>
                                {t('productsCount', {count: group.offers.length})}
                            </p>
                        </div>
                    </div>

                    <div className={'w-full flex flex-col gap-y-3'}>
                        {group.offers.map(offer => (
                            <BasketOfferView
                                offer={offer}
                                key={offer.id}
                                onRemove={onDeleteBasketOffer}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
};

export default BasketOfferGroup;
