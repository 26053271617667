//Utils
import {createPromiseAction} from 'redux-saga-promise-actions';
import {createAction} from 'typesafe-actions';

//Types
import {Errors, Conversations} from 'store/types';
import {Client} from 'twilio-chat/lib/client';

export const getConversations = createPromiseAction('GET_CONVERSATIONS')<
    {page: number},
    Conversations.Conversations,
    Errors.API
>();

export const getConversation = createPromiseAction('GET_CONVERSATION')<
    number,
    Conversations.Item,
    Errors.API
>();

export const createConversation = createPromiseAction('CREATE_CONVERSATION')<
    Conversations.CreateConversationPayload,
    Conversations.Item,
    Errors.API
>();

export const contactAdmin = createPromiseAction('CREATE_CONVERSATION_WITH_ADMIN')<
    undefined,
    Conversations.Item,
    Errors.API
>();

export const getLastMessage = createPromiseAction('GET_LAST_MESSAGE')<
    Conversations.LastMessagePayload,
    undefined,
    Errors.API
>();

export const setClient = createAction('SET_CLIENT')<Client>();

export const clearConversation = createAction('CLEAR_CONVERSATION')();
