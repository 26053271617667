//Redux saga
import {call} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';

//Transformers
import {transformers} from 'utils';

//Types
import {AxiosResponse} from 'axios';
import {Constants} from 'store/types';

export function* getConstants() {
    const resp: AxiosResponse<Constants.ConstantResponse> = yield call(request, {
        method: 'GET',
        url: '/api/sales-channel',
        transformResponse: transformers.categories.transformResponse
    });

    return resp.data;
}

export const saga = [takeEveryPromiseAction(actions.getConstants, getConstants)];
