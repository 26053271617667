//Hooks
import {useMemo, useState} from 'react';
import {useFormik} from 'formik';
import {useDispatch} from 'hooks/store';
import {useContactSubjects} from 'hooks/staticPages';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, string} from 'yup';

//Types
import {StaticPages} from 'store/types';

export function useContactView() {
    const {data: contactSubjects} = useContactSubjects();
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);

    const subjectOptions = useMemo(
        () =>
            contactSubjects?.map(subject => ({label: subject.subject, value: subject.key})) ??
            [],
        [contactSubjects]
    );

    const validationSchema = object<StaticPages.ContactForm>({
        subject: string().required(),
        emailAddress: string().required(),
        message: string().required()
    });

    const {
        values,
        touched,
        errors,
        setFieldValue,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useFormik<StaticPages.ContactForm>({
        initialValues: {
            subject: '',
            emailAddress: '',
            message: ''
        },
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.submitContactForm.request(payload));
                await setModalOpen(true);
            } catch (err) {
                if (err.message) {
                    toast.error(err.message);
                }

                if (err.errors) {
                    setErrors(err.errors);
                }
            }
        }
    });

    return {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        modalOpen,
        setModalOpen,
        setFieldValue,
        subjectOptions
    };
}
