//Hooks
import {useFormik} from 'formik';
import {useDispatch} from 'hooks/store';
import {useNavigate} from '@reach/router';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, string} from 'yup';

//Types
import {Auth} from 'store/types';

export function useForgotPasswordView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = object<Auth.RequestPasswordReset>().shape(
        {
            email: string()
                .email()
                .when('phone', {
                    is: (phone: string) => !phone || phone.length === 0,
                    then: string().email().required()
                }),
            phone: string().when('email', {
                is: (email: string) => !email || email.length === 0,
                then: string().required()
            })
        },
        [['email', 'phone']]
    );

    return useFormik<Auth.RequestPasswordReset>({
        initialValues: {
            email: '',
            phone: ''
        },
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.requestPasswordReset.request(payload));
                await navigate('/auth/forgot-password/success');
            } catch (err) {
                if (err.message) {
                    toast.error(err.message);
                }

                if (err.errors) {
                    setErrors(err.errors);
                }
            }
        }
    });
}
