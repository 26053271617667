//Hooks
import {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'hooks/store';
import {useCategories} from 'hooks/categories';
import {useNavigate, useLocation, useParams} from '@reach/router';
import {useTranslation} from 'react-i18next';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, string, number, array, boolean, mixed, ObjectSchema} from 'yup';
import _ from 'lodash-es';
import {transformEditOfferValues} from 'utils/transformers/editOffer';

//Types
import {Offer, Files} from 'store/types';
import {FormikHelpers} from 'formik';

export function useOfferFormView() {
    const {data: customFormFields} = useSelector(state => state.categories.customFormFields);
    const {
        data: existingOffer,
        isLoading: isOfferLoading,
        isLoaded: isOfferLoaded
    } = useSelector(state => state.offers.editedOffer);
    const [isClickedExtraPhotos, setClickedExtraPhotos] = useState(false);
    const {user} = useSelector(state => state.user);
    const {isTokenLoading} = useSelector(state => state.auth);
    const errorCode = useSelector(state => state.offers.error?.code);
    const {data: categories} = useCategories();
    const [isExtendImageModalVisible, setExtendImageModalVisible] = useState<boolean>(false);
    const dispatch = useDispatch();

    const id = useParams()?.id;

    const isEditable = useLocation().pathname.includes('edit');
    const navigate = useNavigate();

    const {t} = useTranslation(['validation', 'offers']);

    useEffect(() => {
        if (!id) {
            dispatch(actions.clearOffer());
            return;
        }

        dispatch(actions.getEditedOffer.request(id));
    }, [id, dispatch]);

    const typeValidatorMapping = {
        string: string(),
        number: number(),
        integer: number().integer()
    };

    const [uploadedFiles, setUploadedFiles] = useState<Files.UploadedPhoto[]>([]);

    const getAttributesValidationSchema = (required = false) =>
        customFormFields
            ? Object.fromEntries(
                  Object.entries(customFormFields).map(([key, value]) => {
                      let validator = value.enum
                          ? mixed().oneOf(value.enum)
                          : typeValidatorMapping[value.type];
                      if (value.required && required) {
                          validator = validator.required();
                      }
                      return [key, validator];
                  })
              )
            : {};

    const validationSchema = object({
        type: mixed<Offer.Type>().required(),
        offer: object({
            title: string().when('status', {
                is: Offer.Status.Published,
                then: string().required()
            }),
            category: object({
                id: number().integer()
            })
                .typeError(t('validation:thisFieldIsRequired'))
                .required(),
            images: array()
                .of(
                    object({
                        fileId: number().integer().required()
                    })
                )
                .required(),
            description: string().when('status', {
                is: Offer.Status.Published,
                then: string().required()
            }),
            status: mixed<Offer.Status>()
                .oneOf([Offer.Status.Published, Offer.Status.Draft])
                .required(),
            attributes: object(getAttributesValidationSchema()).when('status', {
                is: Offer.Status.Published,
                then: object(getAttributesValidationSchema(true))
            })
        })
            .when('type', (type: Offer.Type, schema: ObjectSchema<any>) => {
                switch (type) {
                    case Offer.Type.Job:
                        return schema.shape({
                            showSalary: boolean().required(),
                            salaryFrom: object({
                                tbbcAmount: number()
                                    .typeError(t('validation:mustBeNumber'))
                                    .nullable()
                            }).when(['status', 'showSalary'], {
                                is: (status: string, showSalary: boolean) =>
                                    status === Offer.Status.Published && showSalary,
                                then: object({
                                    tbbcAmount: number()
                                        .typeError(t('validation:mustBeNumber'))
                                        .required()
                                })
                            }),
                            salaryTo: object({
                                tbbcAmount: number()
                                    .typeError(t('validation:mustBeNumber'))
                                    .nullable()
                            }).when(['status', 'priceVariant'], {
                                is: (status: string, priceVariant: string) =>
                                    status === Offer.Status.Published &&
                                    priceVariant === Offer.PriceVariant.Range,
                                then: object({
                                    tbbcAmount: number()
                                        .typeError(t('validation:mustBeNumber'))
                                        .required()
                                })
                            }),
                            period: mixed<Offer.Period>().when(['status', 'showSalary'], {
                                is: (status: string, showSalary: boolean) =>
                                    status === Offer.Status.Published && showSalary,
                                then: mixed<Offer.Period>().required()
                            })
                        });
                    case Offer.Type.Service:
                        return schema.shape({
                            period: mixed<Offer.Period>().when('status', {
                                is: Offer.Status.Published,
                                then: mixed<Offer.Period>().required()
                            }),
                            priceFrom: object({
                                tbbcAmount: number()
                                    .typeError(t('validation:mustBeNumber'))
                                    .nullable()
                            }).when('status', {
                                is: Offer.Status.Published,
                                then: object({
                                    tbbcAmount: number()
                                        .typeError(t('validation:mustBeNumber'))
                                        .required()
                                })
                            }),
                            priceTo: object({
                                tbbcAmount: number()
                                    .typeError(t('validation:mustBeNumber'))
                                    .nullable()
                            }).when(['status', 'priceVariant'], {
                                is: (status: string, priceVariant: string) =>
                                    status === Offer.Status.Published &&
                                    priceVariant === Offer.PriceVariant.Range,
                                then: object({
                                    tbbcAmount: number()
                                        .typeError(t('validation:mustBeNumber'))
                                        .required()
                                })
                            })
                        });
                    case Offer.Type.RealEstate:
                        return schema.shape({
                            price: object({
                                tbbcAmount: number()
                                    .typeError(t('validation:mustBeNumber'))
                                    .nullable()
                            }).when('status', {
                                is: Offer.Status.Published,
                                then: object({
                                    tbbcAmount: number()
                                        .typeError(t('validation:mustBeNumber'))
                                        .required()
                                })
                            }),
                            quantity: number().when('status', {
                                is: Offer.Status.Published,
                                then: number()
                                    .required()
                                    .moreThan(0, t('validation:thisFieldIsRequired'))
                            })
                        });
                    default:
                        return schema.shape({
                            price: object({
                                tbbcAmount: number()
                                    .typeError(t('validation:mustBeNumber'))
                                    .nullable()
                            }).when('status', {
                                is: Offer.Status.Published,
                                then: object({
                                    tbbcAmount: number()
                                        .typeError(t('validation:mustBeNumber'))
                                        .required()
                                })
                            }),
                            delivery: object({
                                method: mixed<Offer.DeliveryMethod>(),
                                price: object({
                                    tbbcAmount: number()
                                        .typeError(t('validation:mustBeNumber'))
                                        .nullable()
                                }).required()
                            }).when('status', {
                                is: Offer.Status.Published,
                                then: object({
                                    method: mixed<Offer.DeliveryMethod>().required(),
                                    price: object({
                                        tbbcAmount: number()
                                            .typeError(t('validation:mustBeNumber'))
                                            .nullable()
                                    })
                                        .required()
                                        .when('method', {
                                            is: Offer.DeliveryMethod.Courier,
                                            then: object({
                                                tbbcAmount: number()
                                                    .typeError(t('validation:mustBeNumber'))
                                                    .required()
                                            }).required()
                                        })
                                })
                            }),
                            quantity: number().when('status', {
                                is: Offer.Status.Published,
                                then: number()
                                    .required()
                                    .moreThan(0, t('validation:thisFieldIsRequired'))
                            })
                        });
                }
            })
            .required(),
        address: object({
            city: string(),
            state: string(),
            postCode: string(),
            street: string(),
            apartmentNumber: string(),
            geoCoordinate: object({lat: number(), lon: number()})
        }).when(
            ['offer.status', 'hideLocation'],
            (
                status: Offer.Status,
                hideLocation: boolean,
                schema: ObjectSchema<Offer.Address>
            ) => {
                if (status === Offer.Status.Published && !hideLocation) {
                    return object({
                        city: string().required(),
                        state: string().required(),
                        postCode: string().required(),
                        street: string().required(),
                        apartmentNumber: string(),
                        geoCoordinate: object({lat: number(), lon: number()})
                            .required(t('offers:locationError'))
                            .default(undefined)
                    });
                }
                if (!hideLocation) {
                    return object({
                        city: string(),
                        state: string(),
                        postCode: string(),
                        street: string(),
                        apartmentNumber: string(),
                        geoCoordinate: object({lat: number(), lon: number()})
                            .required(t('offers:locationError'))
                            .default(undefined)
                    });
                }
                return schema;
            }
        )
    });

    const stripOfferData = (values: Offer.Form): Offer.CreateOfferRequest => {
        if (
            values.type === Offer.Type.Job &&
            values.offer.priceVariant === Offer.PriceVariant.Fixed
        ) {
            values.offer.salaryTo = {tbbcAmount: null};
        }

        if (
            values.type === Offer.Type.Service &&
            values.offer.priceVariant === Offer.PriceVariant.Fixed
        ) {
            values.offer.priceTo = {tbbcAmount: null};
        }

        if (_.isEmpty(values.offer.attributes)) {
            delete values.offer.attributes;
        }

        switch (values.type) {
            case Offer.Type.Product:
                return {
                    type: values.type,
                    offer: _.omit(values.offer, [
                        'showSalary',
                        'salaryFrom',
                        'salaryTo',
                        'period',
                        'priceFrom',
                        'priceTo',
                        'priceVariant'
                    ]),
                    address: values.hideLocation ? undefined : values.address
                };
            case Offer.Type.RealEstate:
                return {
                    type: values.type,
                    offer: _.omit(values.offer, [
                        'showSalary',
                        'salaryFrom',
                        'salaryTo',
                        'period',
                        'priceFrom',
                        'priceTo',
                        'priceVariant',
                        'delivery'
                    ]),
                    address: values.hideLocation ? undefined : values.address
                };
            case Offer.Type.Service:
                return {
                    type: values.type,
                    offer: _.omit(values.offer, [
                        'showSalary',
                        'salaryFrom',
                        'salaryTo',
                        'price',
                        'quantity',
                        'delivery',
                        'priceVariant'
                    ]),
                    address: values.hideLocation ? undefined : values.address
                };
            default:
                return {
                    type: values.type,
                    offer: _.omit(values.offer, [
                        'price',
                        'quantity',
                        'delivery',
                        'priceFrom',
                        'priceTo',
                        'priceVariant'
                    ]),
                    address: values.hideLocation ? undefined : values.address
                };
        }
    };

    const initialValues = useMemo<Offer.Form>(() => {
        if (id && existingOffer) {
            return transformEditOfferValues(existingOffer, categories ?? []);
        }

        return {
            type: Offer.Type.Product,
            offer: {
                title: '',
                description: '',
                category: null,
                status: Offer.Status.Draft,
                images: [],
                attributes: {},
                price: {tbbcAmount: null},
                delivery: {
                    method: null,
                    price: {tbbcAmount: null}
                },
                quantity: 0,
                showSalary: true,
                priceVariant: Offer.PriceVariant.Fixed,
                salaryFrom: {tbbcAmount: null},
                salaryTo: {tbbcAmount: null},
                period: null,
                priceFrom: {tbbcAmount: null},
                priceTo: {tbbcAmount: null}
            },
            hideLocation: false,
            lastCheckedAddress: '',
            address: user?.address
        };
    }, [id, existingOffer, categories, user]);

    const handleSubmit = async (
        payload: Offer.Form,
        {setErrors}: FormikHelpers<Offer.Form>
    ) => {
        try {
            if (id && existingOffer) {
                await dispatch(
                    actions.editOffer.request({
                        offerId: id,
                        data: stripOfferData(payload)
                    })
                );
            } else {
                const result = await dispatch(
                    actions.createOffer.request(stripOfferData(payload))
                );

                if (isClickedExtraPhotos && result.offerId) {
                    await dispatch(actions.getEditedOffer.request(result.offerId));
                }
            }

            if (!isClickedExtraPhotos) {
                await navigate('/profile/my-offers');
            }
        } catch (error) {
            if (error.errors?.additionalFirstError) {
                toast.error(error.errors?.additionalFirstError);
            } else if (error.message) {
                toast.error(error.message);
            }

            if (error.errors) {
                setErrors(error.errors);
            }
        }
    };

    const deleteAllFiles = async () => {
        try {
            await Promise.all(
                uploadedFiles.map(
                    async file => await dispatch(actions.deleteFile.request(file))
                )
            );
        } catch (err) {
            console.log(err);
        }
    };

    const handleCancel = async () => {
        await deleteAllFiles();
        navigate(-1);
    };

    const openExtendImageModal = (noteClick = false) => {
        noteClick && setClickedExtraPhotos(true);
        setExtendImageModalVisible(true);
    };
    const closeExtendImageModal = () => {
        setExtendImageModalVisible(false);
        if (!isEditable && existingOffer) {
            navigate(`edit/${existingOffer.id}`);
        }
    };

    return {
        uploadedFiles,
        initialValues,
        isEditable,
        validationSchema,
        handleSubmit,
        setUploadedFiles,
        handleCancel,
        openExtendImageModal,
        closeExtendImageModal,
        isExtendImageModalVisible,
        existingOffer: id || !isEditable ? existingOffer : null,
        isOfferLoading,
        isOfferLoaded,
        errorCode,
        user,
        isUserLoading: isTokenLoading
    };
}
