import {ActionType, createReducer} from 'typesafe-actions';
import * as actions from 'store/actions';
import {Option} from 'store/types/version/option';

export type VersionAction = ActionType<typeof actions>;

export type State = Readonly<{
    version: Option | undefined;
    versions: Option[];
    fetchedByCoordinates: boolean;
}>;

export const initialState: State = {
    version: undefined,
    versions: [
        {
            label: 'US',
            value: 'US',
            origin: process.env.REACT_APP_ORIGIN_US
        },
        {
            label: 'PL',
            value: 'PL',
            origin: process.env.REACT_APP_ORIGIN_PL
        }
    ],
    fetchedByCoordinates: false
};

export default createReducer<State, VersionAction>(initialState)
    .handleAction(
        actions.getVersion.success,
        (state, action) =>
            ({
                ...state,
                version: !!state.versions.find(v => v.value === action.payload.short_name)
                    ? {
                          label: action.payload.short_name,
                          value: action.payload.short_name,
                          origin: process.env[`REACT_APP_ORIGIN_${action.payload.short_name}`]
                      }
                    : undefined,
                fetchedByCoordinates: true
            } as State)
    )
    .handleAction(
        actions.setVersion,
        (state, action) =>
            ({
                ...state,
                version: !!state.versions.find(v => v.value === action.payload)
                    ? {
                          label: action.payload,
                          value: action.payload,
                          origin: process.env[`REACT_APP_ORIGIN_${action.payload}`]
                      }
                    : undefined
            } as State)
    );
