import * as React from 'react';

//Components
import CompanyBarView from 'components/nav/CompanyBarView';

//Types
import {RouteComponentProps} from '@reach/router';

const ForgotPasswordView: React.FC<RouteComponentProps> = ({children}) => (
    <div className={'flex flex-col h-full'}>
        <div className={'flex justify-center items-center flex-grow bg-gray-100'}>
            <div
                className={
                    'w-168 bg-white md:rounded md:border border-gray-300 h-full md:h-auto'
                }
            >
                {React.isValidElement(children) &&
                    React.cloneElement(children, {className: 'h-full'})}
            </div>
        </div>

        <CompanyBarView className={'hidden sm:flex'} small />
    </div>
);

export default ForgotPasswordView;
