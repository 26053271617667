//Hooks
import {useDispatch} from 'hooks/store';
import {useFormik} from 'formik';
import {useLocation, useNavigate} from '@reach/router';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, string} from 'yup';

//Types
import {Auth} from 'store/types';

export function useLoginView() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {state} = useLocation();

    const validationSchema = object<Auth.Credentials>({
        username: string().required(),
        password: string().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useFormik<Auth.Credentials>({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.login.request(payload));
                if ((state as any)?.from) {
                    await navigate((state as any)?.from);
                } else {
                    await navigate('/');
                }
            } catch (err) {
                if (err.message) {
                    toast.error(err.message);
                }

                setErrors(err.errors ?? {});
            }
        }
    });

    return {values, touched, errors, handleChange, handleSubmit, isSubmitting};
}
