//Hooks
import {useTranslation} from 'react-i18next';
import {useForgotPasswordResetView} from './ForgotPasswordResetView.hook';

//Components
import Button from 'components/ui/Button';
import Input from 'components/ui/Input';

//Types
import {RouteComponentProps} from '@reach/router';

const ForgotPasswordResetView: React.FC<RouteComponentProps> = () => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useForgotPasswordResetView();

    const {t} = useTranslation('auth');

    return (
        <div className={'md:p-8 px-4 py-5 h-full flex flex-col'}>
            <h2 className={'py-4 md:pb-0'}>{t('resetYourPassword')}</h2>

            <span className={'inline-block md:mt-3 text-xs tracking-tighter text-gray-900'}>
                {t('resetYourPasswordDescription')}
            </span>

            <form onSubmit={handleSubmit} className={'flex-grow flex flex-col justify-between'}>
                <div className={'md:w-7/12 mt-7'}>
                    <Input
                        id={'newPassword.first'}
                        name={'newPassword.first'}
                        label={t('password')}
                        type={'password'}
                        value={values.newPassword.first}
                        onChange={handleChange}
                        error={touched.newPassword?.first && errors.newPassword?.first}
                    />

                    <Input
                        id={'newPassword.second'}
                        name={'newPassword.second'}
                        className={'mt-6'}
                        label={t('confirmPassword')}
                        type={'password'}
                        value={values.newPassword.second}
                        onChange={handleChange}
                        error={touched.newPassword?.second && errors.newPassword?.second}
                    />
                </div>

                <div className={'flex flex-row justify-center mt-8 my-14 md:mb-0'}>
                    <Button
                        variant={'primary'}
                        size={'sm'}
                        type={'submit'}
                        disabled={isSubmitting}
                    >
                        {t('resetPassword')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ForgotPasswordResetView;
