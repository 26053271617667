//Action creators
import {createAsyncAction} from 'typesafe-actions';

//Types
import {Search, Errors} from 'store/types';

/**
 * Performs a search.
 */
export const search = createAsyncAction('SEARCH_REQUEST', 'SEARCH_SUCCESS', 'SEARCH_FAILURE')<
    Search.FormValues,
    Search.Result,
    Errors.API
>();
