import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';

//Redux store
import {Provider} from 'react-redux';
import store from 'store';

//Facebook pixel
import ReactPixel from 'react-facebook-pixel';

dayjs.extend(localeData);
dayjs.extend(relativeTime);

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, undefined, {
        autoConfig: true,
        debug: false
    });
    ReactPixel.pageView();
}
