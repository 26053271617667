//Hooks
import {useDisputeReviewModal} from './DisputeReviewModal.hook';
import {useTranslation} from 'react-i18next';

//Components
import Modal from 'components/ui/Modal';
import RatingInput from 'components/ui/RatingInput';
import TextArea from 'components/ui/TextArea';
import Button from 'components/ui/Button';

//Images
import defaultAvatar from 'assets/files/defaultAvatar.jpg';

//Utils
import dayjs from 'dayjs';

//Types
import {Reviews} from 'store/types';

type DisputeReviewModalProps = {
    isOpen: boolean;
    onClose: () => void;
    review: Reviews.Review;
};

const DisputeReviewModal: React.VFC<DisputeReviewModalProps> = ({review, isOpen, onClose}) => {
    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit
    } = useDisputeReviewModal({
        reviewId: review.id,
        isOpen,
        onClose
    });
    const {t} = useTranslation('reviews');

    return (
        <Modal className={'flex flex-col'} isOpen={isOpen} onClose={onClose} size={'lg'}>
            <h1 className={'font-normal'}>{t('disputeThisReview')}</h1>

            <div className={'flex justify-between mt-10'}>
                <div className={'flex'}>
                    <img
                        className={'w-18 h-18 object-cover rounded-full'}
                        src={review.reviewer?.avatarUrl ?? defaultAvatar}
                        alt={review.reviewer?.name ?? t('accountDeactivated')}
                        aria-label={'Commenter avatar'}
                    />

                    <div className={'flex flex-col mx-5.5'}>
                        <h2 aria-label={'Commenter name'}>
                            {review.reviewer?.name ?? t('accountDeactivated')}
                        </h2>

                        <span className={'mt-1 text-xs text-gray-300'}>
                            {dayjs(review.createdAt).fromNow()}
                        </span>
                    </div>
                </div>

                <div className={'w-8/12'}>
                    <RatingInput className={'mt-2'} size={'lg'} value={review.rate} disabled />

                    <p className={'mt-4'}>{review.comment}</p>
                </div>
            </div>

            <form className={'mt-10'} onSubmit={handleSubmit}>
                <label className={'text-sm'} htmlFor={'description'}>
                    {t('letKnowWhyRemoveThisReview')}
                </label>

                <TextArea
                    id={'description'}
                    name={'description'}
                    className={'mt-4'}
                    value={values.description ?? ''}
                    onChange={handleChange}
                    error={touched.description && errors.description}
                    maxLength={300}
                />

                <div className={'flex justify-center mt-4'}>
                    <Button variant={'primary'} disabled={isSubmitting}>
                        {t('dispute')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default DisputeReviewModal;
