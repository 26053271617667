//Hooks
import {useEffect, useMemo} from 'react';
import {useDispatch} from 'hooks/store';
import {useFormik} from 'formik';
import {useNavigate, useLocation} from '@reach/router';
import {useTranslation} from 'react-i18next';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, number, string, ref} from 'yup';
import qs from 'qs';

//Types
import {Auth} from 'store/types';

export function useForgotPasswordResetView() {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const {search} = useLocation();

    const {t} = useTranslation('validation');

    const initialValues = useMemo<Auth.ResetPassword>(() => {
        const {id, expires, signature} = qs.parse(search.replace(/&amp;/g, '&').substring(1));

        return {
            id: parseInt(id as string),
            expires: parseInt(expires as string),
            signature: signature as string,
            newPassword: {
                first: '',
                second: ''
            }
        };
    }, [search]);

    const validationSchema = object<Auth.ResetPassword>({
        id: number().typeError(t('invalidLink')).required(t('invalidLink')),
        expires: number().typeError(t('invalidLink')).required(t('invalidLink')),
        signature: string().required(t('invalidLink')),
        newPassword: object({
            first: string().required(),
            second: string()
                .required()
                .oneOf([ref('first')], t('passwordsMustMatch'))
        }).required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useFormik<Auth.ResetPassword>({
        initialValues,
        validationSchema,
        onSubmit: async (payload, {setErrors}) => {
            try {
                await dispatch(actions.resetPassword.request(payload));
                await navigate('/auth/forgot-password/reset/success');
            } catch (err) {
                if (err.message) {
                    toast.error(err.message);
                }

                if (err.errors) {
                    setErrors(err.errors);
                }
            }
        },
        enableReinitialize: true
    });

    useEffect(() => {
        if (
            (touched.id && !!errors.id) ||
            (touched.expires && !!errors.expires) ||
            (touched.signature && !!errors.signature)
        ) {
            toast.error(errors.id ?? errors.expires ?? errors.signature!);
        }
    }, [touched, errors]);

    return {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    };
}
