//Hooks
import {useSelector, useDispatch} from 'hooks/store';
import {useEffect} from 'react';

//Actions
import * as actions from 'store/actions';

export const useInfoView = () => {
    const {isLoading, isLoaded, data: staticPages} = useSelector(
        state => state.staticPages.staticPages
    );

    const {isLoading: areCardsLoading, isLoaded: areCardsLoaded} =
        useSelector(state => state.user?.cards) || {};

    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoading || isLoaded) {
            return;
        }

        dispatch(actions.loadStaticPages.request());
    }, [isLoading, isLoaded, dispatch]);

    useEffect(() => {
        if (areCardsLoaded || areCardsLoading) {
            return;
        }

        dispatch(actions.getCards.request());
    }, [areCardsLoaded, areCardsLoading, dispatch]);

    return {
        isLoading,
        isLoaded,
        staticPages
    };
};
