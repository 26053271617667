//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Utils
import {uniqBy} from 'lodash-es';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Offer} from 'store/types';

export type BasketAction = ActionType<typeof actions>;

export type State = Readonly<{
    basket: Data<Offer.ProductListItem[]>;
    payment: {
        offers: Offer.ProductListItem[];
        extraFeature: Offer.ExtraFeature | null;
    };
}>;

export const initialState: State = {
    basket: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    payment: {
        offers: [],
        extraFeature: null
    }
};

export default createReducer<State, BasketAction>(initialState)
    .handleAction(actions.getBasketOffers.request, state => ({
        ...state,
        basket: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.basket.data
        }
    }))
    .handleAction(actions.getBasketOffers.success, (state, action) => ({
        ...state,
        basket: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload
        }
    }))
    .handleAction(actions.getBasketOffers.failure, state => ({
        ...state,
        basket: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }))
    .handleAction(actions.addOfferToBasket.success, (state, action) => ({
        ...state,
        basket: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: uniqBy([...(state.basket.data ?? []), action.payload], e => e.id)
        }
    }))
    .handleAction(actions.removeBasketOffer.success, (state, action) => ({
        ...state,
        basket: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: [...(state.basket.data ?? [])].filter(item => item.id !== action.payload)
        }
    }))
    .handleAction([actions.clearBasket, actions.logout.success], state => ({
        ...state,
        basket: {
            isLoading: false,
            isLoaded: false,
            isErrored: false,
            data: null
        }
    }))
    .handleAction(actions.selectOffersToProceed, (state, action) => ({
        ...state,
        payment: {
            ...state.payment,
            offers: action.payload
        }
    }))
    .handleAction(actions.addExtraFeatureOfferToProceed, (state, action) => ({
        ...state,
        payment: {
            ...state.payment,
            extraFeature: action.payload
        }
    }))
    .handleAction(actions.clearExtraFeature, state => ({
        ...state,
        payment: {
            ...state.payment,
            extraFeature: null
        }
    }));
