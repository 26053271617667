//Hooks
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

//Utils
import _ from 'lodash-es';

//Types
import {Offer, Order} from 'store/types';
import {MoneySerialized} from '../store/types/offer';

export const useDisplayPrice = ({
    offer,
    order,
    product
}: {
    offer?: Offer.ListItem;
    order?: Order.Order;
    product?: Order.Product;
}) => {
    const {t} = useTranslation(['offers', 'common']);

    const format4digitsInPLN = (priceFormatted: string): string => {
        const priceSplit = priceFormatted.split(',');
        const priceSplitLeft = Array.from(priceSplit[0]);
        if (priceSplitLeft.length === 4) {
            priceSplitLeft.splice(1, 0, ' ');
        }
        return priceSplitLeft.join('').concat(',').concat(priceSplit[1]);
    };

    const displayPrice = useMemo(() => {
        const formatPrice = (priceItem: MoneySerialized): string => {
            const numberFormat = new Intl.NumberFormat(
                priceItem.currency === 'PLN' ? 'pl-PL' : 'en-US',
                {
                    style: 'currency',
                    currency: priceItem.currency,
                    currencyDisplay: 'narrowSymbol'
                }
            );

            const priceFormatted = numberFormat.format(priceItem.amount / 100);
            return priceItem.currency === 'PLN'
                ? format4digitsInPLN(priceFormatted)
                : priceFormatted;
        };

        if (!!order || !!product) {
            return t('offers:displayPrice', {
                priceFrom: ((order?.amount ?? product?.price) as any) / 100
            });
        }
        if (!offer) {
            return '';
        }

        switch (offer.type) {
            case Offer.Type.Service:
                if (!offer.priceTo || _.isEqual(offer.priceTo, offer.priceFrom)) {
                    return t('offers:displayPrice', {
                        priceFrom: formatPrice(offer.priceFrom),
                        period: offer.period ? t(`offers:${offer.period}` as const) : ''
                    });
                }
                return t('offers:displayPriceRange', {
                    priceFrom: formatPrice(offer.priceFrom),
                    priceTo: formatPrice(offer.priceTo),
                    period: offer.period ? t(`offers:${offer.period}` as const) : ''
                });
            case Offer.Type.Job:
                if (!offer.showSalary) {
                    return '';
                }
                if (!offer.salaryTo || _.isEqual(offer.salaryTo, offer.salaryFrom)) {
                    return t('offers:displayPrice', {
                        priceFrom: formatPrice(offer.salaryFrom),
                        period: offer.period ? t(`offers:${offer.period}` as const) : ''
                    });
                }
                return t('offers:displayPriceRange', {
                    priceFrom: formatPrice(offer.salaryFrom),
                    priceTo: formatPrice(offer.salaryTo),
                    period: offer.period ? t(`offers:${offer.period}` as const) : ''
                });
            default:
                if (offer.price?.amount === 0) {
                    return t('common:forFree');
                }

                return t('offers:displayPrice', {priceFrom: formatPrice(offer.price)});
        }
    }, [offer, order, product, t]);

    return displayPrice;
};
