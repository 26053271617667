//Hooks
import React, {useState, useEffect, useCallback} from 'react';

//Types
import {PaginationViewProps} from './PaginationView';

export const usePaginationView = ({
    currentPage,
    totalPages,
    onPageChange
}: PaginationViewProps) => {
    const [value, setValue] = useState<number | ''>(currentPage);

    useEffect(() => setValue(currentPage), [currentPage]);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.value) {
                setValue('');
                return;
            }
            const page = Number(e.target.value);
            if (page <= 0 || page > totalPages) {
                return;
            }
            setValue(page);
        },
        [totalPages]
    );

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key !== 'Enter') {
                return;
            }

            if (typeof value === 'number') {
                onPageChange(value);
            }
        },
        [value, onPageChange]
    );

    const handleSubmit = useCallback(() => {
        if (typeof value === 'number') {
            onPageChange(value);
        }
    }, [value, onPageChange]);

    return {
        value,
        handleChange,
        handleKeyDown,
        handleSubmit
    };
};
