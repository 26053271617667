//Hooks
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

export const useStatsView = () => {
    const {isLoading, isLoaded, data: stats} = useSelector(state => state.user.stats);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoading || isLoaded) {
            return;
        }

        dispatch(actions.getMyStats.request());
    }, [isLoading, isLoaded, dispatch]);

    return {stats};
};
