//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Offer, User} from 'store/types';

export type VisitedProfileAction = ActionType<typeof actions>;

export type State = Readonly<{
    user: Data<User.Profile>;
    offers: Data<Offer.ListResponse>;
    error?: any;
}>;

export const initialState: State = {
    user: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    offers: {
        data: null,
        isLoading: false,
        isLoaded: false,
        isErrored: false
    }
};

export default createReducer<State, VisitedProfileAction>(initialState)
    .handleAction(actions.getUserProfile.request, state => ({
        ...state,
        user: {isLoaded: false, isLoading: true, isErrored: false, data: state.user.data}
    }))
    .handleAction(actions.getUserProfile.success, (state, action) => ({
        ...state,
        user: {isLoading: false, isLoaded: true, isErrored: false, data: action.payload}
    }))
    .handleAction(actions.getUserProfile.failure, (state, action) => ({
        ...state,
        user: {isLoaded: false, isLoading: false, isErrored: true, data: null},
        error: action.payload
    }))
    .handleAction(actions.getUserOffers.request, state => ({
        ...state,
        offers: {isLoaded: false, isLoading: true, isErrored: false, data: state.offers.data}
    }))
    .handleAction(actions.getUserOffers.success, (state, action) => ({
        ...state,
        offers: {isLoading: false, isLoaded: true, isErrored: false, data: action.payload}
    }))
    .handleAction(actions.getUserOffers.failure, state => ({
        ...state,
        offers: {isLoaded: false, isLoading: false, isErrored: true, data: null}
    }));
