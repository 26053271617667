//Redux saga
import {call, put, takeLeading} from 'redux-saga/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';

//Transformers
import {transformers} from 'utils';

//Types
import {AxiosResponse} from 'axios';
import {Offer, User} from 'store/types';

export function* getUserProfile(action: ReturnType<typeof actions.getUserProfile.request>) {
    try {
        const resp: AxiosResponse<User.Profile> = yield call(request, {
            method: 'GET',
            url: `/api/profile/${action.payload}`,
            transformResponse: transformers.profile.transformResponse
        });
        yield put(actions.getUserProfile.success(resp.data));
    } catch (err) {
        yield put(actions.getUserProfile.failure(err));
    }
}

export function* getUserOffers(action: ReturnType<typeof actions.getUserOffers.request>) {
    try {
        const {userId, page, ...data} = action.payload;
        const resp: AxiosResponse<Offer.ListResponse> = yield call(request, {
            method: 'POST',
            url: `/api/search/users/${userId}/offers${page ? `?page=${page}` : ''}`,
            data,
            transformResponse: transformers.offers.transformResponse
        });
        yield put(actions.checkFavorites.request(resp.data.items));
        yield put(actions.getUserOffers.success(resp.data));
    } catch (err) {
        yield put(actions.getUserOffers.failure(err));
    }
}

export const saga = [
    takeLeading(actions.getUserProfile.request, getUserProfile),
    takeLeading(actions.getUserOffers.request, getUserOffers)
];
