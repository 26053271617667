import * as React from 'react';

//Components
import Option from './Option';

//Utils
import cx from 'classnames';

//Types
import {OptionProps} from './Option';

export type OptionsProps = {
    value: string | number;
    onChange: React.FormEventHandler<HTMLInputElement>;
    name?: string;
    children:
        | React.ReactElement<OptionProps, typeof Option>
        | React.ReactElement<OptionProps, typeof Option>[];
    className?: string;
};

const Options: React.VFC<OptionsProps> & {Option: typeof Option} = ({
    value,
    onChange,
    name,
    children,
    className
}) => (
    <div
        className={cx(className, 'flex items-stretch border border-gray-300 rounded')}
        role={'tablist'}
    >
        {React.Children.map(children, (child, i) => (
            <Option
                {...child.props}
                key={i}
                checked={value === child.props.value}
                onChange={onChange}
                name={name}
                tabIndex={i}
            />
        ))}
    </div>
);

Options.Option = Option;

export default Options;
