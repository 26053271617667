//Hooks
import {useTranslation} from 'react-i18next';
import {useProductForm} from './ProductForm.hook';
import {useInputHelperHook} from 'hooks/input';

//Components
import Options from 'components/ui/Options';
import Radio from 'components/ui/Radio';
import Input, {InputIcon} from 'components/ui/Input';
import Tooltip from 'components/ui/Tooltip';
import Icon from 'components/ui/Icon';

//Types
import {Offer} from 'store/types';

const ProductForm: React.VFC = () => {
    const {
        values,
        errors,
        touched,
        handleChange,
        handleChangePrice,
        handleChangeDeliveryMethodPickup
    } = useProductForm();

    const {onBlur} = useInputHelperHook();

    const {t} = useTranslation(['offers', 'common']);
    return (
        <>
            <h2 className={'mt-3 hidden md:flex'} id={'price-label'}>
                {t('offers:price')}
            </h2>

            <div className={'md:mt-3.5 mt-8 md:w-86'}>
                <Options
                    name={'price-options'}
                    value={values.offer?.price?.tbbcAmount === 0 ? 'free' : 'price'}
                    onChange={e => handleChangePrice(e)}
                >
                    <Options.Option
                        label={t('offers:price')}
                        id={'price-price'}
                        value={'price'}
                    />

                    <Options.Option
                        label={t('common:forFree')}
                        id={'price-free'}
                        value={'free'}
                    />
                </Options>

                {values.offer?.price?.tbbcAmount === 0 ? (
                    <div className={'text-sm tracking-tighter mt-7'}>
                        {t('offers:willBeForFree')}
                    </div>
                ) : (
                    <Input
                        id={'offer.price.tbbcAmount'}
                        name={'offer.price.tbbcAmount'}
                        className={'mt-3.5'}
                        aria-labelledby={'price-label'}
                        value={values.offer?.price?.tbbcAmount?.toString() ?? ''}
                        onChange={handleChange}
                        onBlur={onBlur}
                        type={'text'}
                        showControls={false}
                        error={
                            touched?.offer?.price?.tbbcAmount &&
                            errors?.offer?.price?.tbbcAmount
                        }
                        data-testid={'product-price-input'}
                    >
                        <InputIcon placement={'right'}>
                            <span className={'text-gray-600 text-form'}>
                                {t('common:currencyShort')}
                            </span>
                        </InputIcon>
                    </Input>
                )}
            </div>

            <h2 className={'md:mt-10 text-sm md:text-lg font-semibold md:font-bold mt-6'}>
                {t('offers:delivery')}
            </h2>

            <Radio
                className={'mt-8'}
                id={'delivery-courier'}
                label={t('offers:shipment')}
                name={'offer.delivery.method'}
                value={Offer.DeliveryMethod.Courier}
                onChange={handleChange}
                checked={values.offer.delivery.method === Offer.DeliveryMethod.Courier}
            />

            <div
                className={
                    'mt-3 text-xs tracking-tighter text-gray-900 flex flex-row items-center'
                }
            >
                <span id={'shipment-price-label'}>{t('offers:addShipmentPrice')}</span>

                <Tooltip id={'shipping-price-tooltip'}>
                    <Tooltip.Wrapper>
                        <Icon
                            data-tip
                            data-for={'shipping-price-tooltip'}
                            icon={'info'}
                            className={'text-gray-400 md:hidden mx-3.5'}
                        />
                    </Tooltip.Wrapper>

                    <Tooltip.Content className={'px-6 w-68'}>
                        <div className={'mb-8 font-bold'}>
                            {t('offers:shippingPriceTooltip')}
                        </div>

                        {t('offers:shippingPriceTooltipDescription')}
                    </Tooltip.Content>
                </Tooltip>
            </div>

            <div className={'flex flex-row items-center'}>
                <Input
                    id={'offer.delivery.price.tbbcAmount'}
                    name={'offer.delivery.price.tbbcAmount'}
                    className={'md:mt-1 md:mr-3 md:w-86 w-full mt-2'}
                    aria-labelledby={'shipment-price-label'}
                    disabled={values.offer?.delivery?.method !== Offer.DeliveryMethod.Courier}
                    onChange={handleChange}
                    onBlur={onBlur}
                    value={values.offer?.delivery?.price?.tbbcAmount?.toString() ?? ''}
                    type={'text'}
                    showControls={false}
                    error={
                        touched?.offer?.delivery?.price?.tbbcAmount &&
                        errors?.offer?.delivery?.price?.tbbcAmount
                    }
                >
                    <InputIcon placement={'right'}>
                        <span className={'text-gray-600 text-form'}>
                            {t('common:currencyShort')}
                        </span>
                    </InputIcon>
                </Input>

                <Tooltip id={'shipping-price-tooltip'}>
                    <Tooltip.Wrapper>
                        <Icon
                            data-tip
                            data-for={'shipping-price-tooltip'}
                            icon={'info'}
                            className={'text-gray-400 hidden md:flex'}
                        />
                    </Tooltip.Wrapper>

                    <Tooltip.Content className={'px-6 w-68'}>
                        <div className={'mb-8 font-bold'}>
                            {t('offers:shippingPriceTooltip')}
                        </div>

                        {t('offers:shippingPriceTooltipDescription')}
                    </Tooltip.Content>
                </Tooltip>
            </div>

            <Radio
                className={'mt-4'}
                id={'delivery-pickup'}
                label={t('offers:pickupOnly')}
                name={'offer.delivery.method'}
                value={Offer.DeliveryMethod.Pickup}
                onChange={e => handleChangeDeliveryMethodPickup(e)}
                checked={values.offer.delivery.method === Offer.DeliveryMethod.Pickup}
            />

            {touched?.offer?.delivery?.method && (
                <span
                    className={'tracking-tight text-red text-form-helper'}
                    role={'alert'}
                    aria-label={'delivery-error'}
                >
                    {errors?.offer?.delivery?.method}
                </span>
            )}

            <h2 className={'md:mt-8 mt-6 text-sm md:text-lg font-semibold md:font-bold'}>
                {t('offers:amount')}
            </h2>

            <Input
                id={'offer.quantity'}
                name={'offer.quantity'}
                className={'mt-1 mr-3'}
                inputClassName={'w-52'}
                label={t('offers:piecesAvailable')}
                placeholder={t('common:value')}
                type={'number'}
                min={1}
                onChange={handleChange}
                value={values.offer.quantity?.toString() ?? ''}
                error={touched.offer?.quantity && errors.offer?.quantity}
            />
        </>
    );
};

export default ProductForm;
