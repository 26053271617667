//Utils
import {setLocale} from 'yup';

//Types
import {TFunction} from 'react-i18next';

export const setValidationLocale = (t: TFunction) => {
    setLocale({
        mixed: {
            required: t('validation:thisFieldIsRequired')
        },
        string: {
            email: t('validation:invalidEmailAddress')
        }
    });
};
