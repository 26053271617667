//Hooks
import {useEditShipmentAddressModal} from './EditShipmentAddressModal.hook';
import {useTranslation} from 'react-i18next';

//Components
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';
import Input from 'components/ui/Input';
import Select from 'react-select';
import {errorStyles, styles, theme} from 'styles/select';

export type EditShipmentAddressModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const EditShipmentAddressModal: React.VFC<EditShipmentAddressModalProps> = ({
    isOpen,
    onClose
}) => {
    const {
        onCancel,
        values,
        handleChange,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        states,
        selectedState
    } = useEditShipmentAddressModal(isOpen);
    const {t} = useTranslation(['profile', 'common']);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onCancel();
                onClose();
            }}
        >
            <form className={'flex flex-col'}>
                <Input
                    id={'shippingAddress.street'}
                    name={'shippingAddress.street'}
                    value={values.shippingAddress.street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={'mt-2'}
                    label={t('profile:street')}
                    error={touched.shippingAddress?.street && errors.shippingAddress?.street}
                />

                <Input
                    id={'shippingAddress.apartmentNumber'}
                    name={'shippingAddress.apartmentNumber'}
                    value={values.shippingAddress.apartmentNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={'mt-2'}
                    label={t('profile:apartmentNumber')}
                    error={
                        touched.shippingAddress?.apartmentNumber &&
                        errors.shippingAddress?.apartmentNumber
                    }
                />

                <Input
                    id={'shippingAddress.city'}
                    name={'shippingAddress.city'}
                    value={values.shippingAddress.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={'mt-2'}
                    label={t('profile:city')}
                    error={touched.shippingAddress?.city && errors.shippingAddress?.city}
                />

                <div className={'flex gap-x-10'}>
                    <div
                        className={'mt-3.5 w-full'}
                        role={'region'}
                        aria-label={'shippingAddress.state'}
                    >
                        <label
                            htmlFor={'shippingAddress.state'}
                            className={'block mb-3 text-sm'}
                        >
                            {t('profile:state')}
                        </label>

                        <Select
                            id={'shippingAddress.state'}
                            name={'shippingAddress.state'}
                            className={'w-full'}
                            options={states}
                            value={selectedState}
                            noOptionsMessage={() => t('common:noOptions')}
                            onChange={option =>
                                setFieldValue('shippingAddress.state', option?.value)
                            }
                            styles={
                                touched.shippingAddress?.state && errors.shippingAddress?.state
                                    ? errorStyles
                                    : styles
                            }
                            theme={theme}
                        />

                        {touched.shippingAddress?.state && errors.shippingAddress?.state && (
                            <span
                                className={'tracking-tight text-red text-form-helper'}
                                role={'alert'}
                                aria-label={'error'}
                            >
                                {errors.shippingAddress?.state}
                            </span>
                        )}
                    </div>

                    <Input
                        id={'shippingAddress.postCode'}
                        name={'shippingAddress.postCode'}
                        value={values.shippingAddress.postCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={'mt-2 w-2/4'}
                        label={t('profile:postCode')}
                        error={
                            touched.shippingAddress?.postCode &&
                            errors.shippingAddress?.postCode
                        }
                    />
                </div>

                <Button
                    onClick={onClose}
                    className={'mt-18 w-full'}
                    variant={'primary'}
                    type={'button'}
                >
                    {t('profile:save')}
                </Button>
            </form>
        </Modal>
    );
};

export default EditShipmentAddressModal;
