//Redux saga
import {call} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';

//Types
import {AxiosResponse} from 'axios';
import {Offer} from 'store/types';
import {SagaIterator} from 'redux-saga';
import {transformers} from 'utils';

export function* fetchOfferById(id: string, ignoreError = false): SagaIterator {
    try {
        const resp: AxiosResponse<Offer.OfferDetailed> = yield call(
            request,
            {
                method: 'GET',
                url: `/api/search/offers/${id}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                transformResponse: transformers.offerDetails.transformResponse
            },
            true
        );

        return resp.data;
    } catch (e) {
        if (ignoreError) {
            return {data: null, id};
        }
        throw e;
    }
}

export function* getOfferById(action: ReturnType<typeof actions.getOfferById.request>) {
    const id = action.payload;

    const data: Offer.OfferDetailed = yield call(fetchOfferById, id);
    return data;
}

export const saga = [takeEveryPromiseAction(actions.getOfferById, getOfferById)];
