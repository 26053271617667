//Utils
import cx from 'classnames';

export type CounterButtonProps = {
    size: 'md';
    label: string;
    disabled?: boolean;
    onClick?: () => void;
};

const CounterButton: React.VFC<CounterButtonProps> = ({label, size, disabled, onClick}) => (
    <button
        onClick={onClick}
        className={cx(
            {'border-gray-900': !disabled},
            {'w-7.5 h-7.5 hover:bg-gray-200': size === 'md'},
            {'bg-gray-400 border-gray-400 text-white': disabled},
            'rounded-7.5 border-1.5 focus:outline-none'
        )}
        disabled={disabled}
    >
        {label}
    </button>
);

export default CounterButton;
