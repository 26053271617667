//Hooks
import {useEffect} from 'react';

//Utils
import {loadStripe} from '@stripe/stripe-js';
import {useDispatch, useSelector} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

export const usePaymentCardView = () => {
    const dispatch = useDispatch();

    const {isLoading: areCardsLoading, isLoaded: areCardsLoaded, data: savedCards} =
        useSelector(state => state.user?.cards) || {};

    useEffect(() => {
        if (areCardsLoaded || areCardsLoading) {
            return;
        }

        dispatch(actions.getCards.request());
    }, [areCardsLoaded, areCardsLoading, dispatch]);

    return {
        savedCards,
        stripePromise: loadStripe(process.env.REACT_APP_STRIPE_KEY || '')
    };
};
