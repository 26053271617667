//Translations
import auth from 'assets/translations/en/auth.json';
import basket from 'assets/translations/en/basket.json';
import common from 'assets/translations/en/common.json';
import errors from 'assets/translations/en/errors.json';
import nav from 'assets/translations/en/nav.json';
import notifications from 'assets/translations/en/notifications.json';
import staticPage from 'assets/translations/en/static.json';
import offers from 'assets/translations/en/offers.json';
import orders from 'assets/translations/en/orders.json';
import payment from 'assets/translations/en/payment.json';
import reviews from 'assets/translations/en/reviews.json';
import search from 'assets/translations/en/search.json';
import stats from 'assets/translations/en/stats.json';
import profile from 'assets/translations/en/profile.json';
import validation from 'assets/translations/en/validation.json';

const en = {
    auth,
    basket,
    common,
    errors,
    nav,
    notifications,
    offers,
    orders,
    profile,
    payment,
    reviews,
    search,
    staticPage,
    stats,
    validation
};

export default en;
