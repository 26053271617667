//Components
import BasketOfferView from 'components/offer/BasketOfferView';
import SearchForm from 'components/search/SearchForm';
import Footer from 'components/nav/Footer';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import Skeleton from 'react-loading-skeleton';
import Message from 'components/ui/Message';
import {Link} from '@reach/router';

//Hooks
import useBasketView from './BasketView.hook';
import {useWindowSize} from 'hooks/windowSize';

//Types
import {RouteComponentProps} from '@reach/router';
import {useTranslation} from 'react-i18next';
import Button from 'components/ui/Button';
import BasketOfferRow from './BasketOfferRow';

//Utils
import _ from 'lodash-es';
import BasketOfferGroup from './BasketOfferGroup';

const BasketView: React.VFC<RouteComponentProps> = () => {
    const {
        isLoaded,
        isLoading,
        groupedOffers,
        totalBasketCost,
        handleProcced,
        handleSelectSeller
    } = useBasketView();

    const {breakpoint} = useWindowSize();
    const {t} = useTranslation(['offers', 'basket', 'common']);
    return (
        <>
            <div className={'flex flex-col flex-grow pb-10 bg-white md:bg-gray-200'}>
                <div className={'hidden md:block'}>
                    <SearchForm />
                </div>

                <div className={'w-full px-4 max-w-292 mx-auto mt-12'}>
                    <div className={'flex flex-col-reverse lg:flex-row gap-14'}>
                        {isLoading || (isLoaded && !!groupedOffers.length) ? (
                            <>
                                {isLoaded ? (
                                    <>
                                        <div className={'lg:block lg:w-4/12 max-w-5/12'}>
                                            <div className={'bg-white '}>
                                                <div
                                                    className={
                                                        'p-4 border-gray-300 border-1.5 md:border-none'
                                                    }
                                                >
                                                    <h1 className={'font-semibold mb-6'}>
                                                        {t('basket:yourBasket')}
                                                    </h1>

                                                    <ul>
                                                        {groupedOffers.map(
                                                            offer =>
                                                                offer.selected &&
                                                                offer.offers.map(item => (
                                                                    <BasketOfferRow
                                                                        key={item.id}
                                                                        offer={item}
                                                                    />
                                                                ))
                                                        )}
                                                    </ul>

                                                    <div
                                                        className={
                                                            'flex mt-3 pt-4 justify-between border-gray-300 border-t-1.5'
                                                        }
                                                    >
                                                        <h3>{t('basket:total')}</h3>

                                                        <h3>
                                                            {t('offers:displayPrice', {
                                                                priceFrom: {
                                                                    ...totalBasketCost,
                                                                    amount: totalBasketCost!!.amount.toString()
                                                                }
                                                            })}
                                                        </h3>
                                                    </div>
                                                </div>

                                                <div
                                                    className={
                                                        'flex justify-center align-center p-4'
                                                    }
                                                >
                                                    <Button
                                                        className={'mt-16 h-15'}
                                                        variant={'primary'}
                                                        size={
                                                            breakpoint === 'xs' ||
                                                            breakpoint === 'sm'
                                                                ? 'sm'
                                                                : 'lg'
                                                        }
                                                        onClick={handleProcced}
                                                    >
                                                        {t('basket:goToPaymentMethod')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                'w-full lg:w-8/12 min-w-7/12 flex flex-col gap-y-3'
                                            }
                                        >
                                            {groupedOffers.length > 1 && (
                                                <Message variant={'info'}>
                                                    {t(
                                                        'basket:tryToPurchaseProductsFromDiffSellers'
                                                    )}
                                                </Message>
                                            )}

                                            <BasketOfferGroup
                                                handleSelectSeller={handleSelectSeller}
                                                offers={groupedOffers}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={'lg:block lg:w-4/12 max-w-5/12 '}>
                                            <div
                                                className={
                                                    'p-5 bg-white border-gray-300 border-1.5 lg:border-none'
                                                }
                                            >
                                                <h1 className={'font-semibold mb-6'}>
                                                    {t('basket:yourBasket')}
                                                </h1>

                                                <ul>
                                                    {_.times(2, i => (
                                                        <BasketOfferRow key={i} loading />
                                                    ))}
                                                </ul>

                                                <Skeleton height={70} />
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                'w-full lg:w-8/12 min-w-7/12 flex flex-col gap-y-3'
                                            }
                                        >
                                            <Message loading />

                                            <div className={'w-20 h-10 flex bg-white px-2'}>
                                                <Skeleton
                                                    width={30}
                                                    height={30}
                                                    style={{borderRadius: '50%'}}
                                                />
                                            </div>

                                            {_.times(2, i => (
                                                <BasketOfferView loading key={i} />
                                            ))}
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <div className={'flex w-full flex-col items-center'}>
                                <h2 className={'font-semibold mt-8'}>
                                    {t('basket:noOffersInBasket')}
                                </h2>

                                <span className={'text-xs mt-12'}>
                                    {t('basket:noOffersInBasketDescription')}
                                </span>

                                <Button
                                    as={Link}
                                    to={'/'}
                                    variant={'primary'}
                                    size={'sm'}
                                    className={'mt-16'}
                                >
                                    {t('common:goToMainPage')}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Footer />

            <MobileBottomNav />
        </>
    );
};

export default BasketView;
