import * as React from 'react';

//Hooks
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Tabs from 'components/nav/Tabs';
import CompanyBarView from 'components/nav/CompanyBarView';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import Card from 'components/ui/Card';

//Types
import {RouteComponentProps, LinkGetProps} from '@reach/router';

type AuthViewProps = {
    children: React.ReactElement<any, any> | React.ReactElement<any, any>[];
};

const AuthView: React.VFC<RouteComponentProps & AuthViewProps> = ({children}) => {
    const {t} = useTranslation('auth');

    const isActive = ({isPartiallyCurrent}: LinkGetProps) => ({
        'aria-current': isPartiallyCurrent ? 'page' : undefined
    });

    return (
        <div className={'flex flex-col h-full'}>
            <div className={'flex justify-center items-center flex-grow bg-gray-100 sm:py-32'}>
                <Card className={'flex flex-col h-full sm:h-auto w-168 px-8 py-10'}>
                    <Card.Body>
                        <Tabs>
                            <Link to={'/auth/login'} getProps={isActive}>
                                {t('login')}
                            </Link>

                            <Link to={'/auth/register'} getProps={isActive}>
                                {t('register')}
                            </Link>
                        </Tabs>

                        <div className={'mt-12 flex flex-grow sm:flex-grow-0'}>
                            {React.Children.map(children, child =>
                                React.cloneElement(child, {className: 'flex flex-grow'})
                            )}
                        </div>
                    </Card.Body>
                </Card>
            </div>

            <CompanyBarView className={'hidden sm:flex'} small />

            <MobileBottomNav />
        </div>
    );
};

export default AuthView;
