//Actions
import {createAction} from 'typesafe-actions';
import {createPromiseAction} from 'redux-saga-promise-actions';

//Types
import {Errors, Offer, Order} from 'store/types';

export const saveOrderData = createAction('SAVE_ORDER_DATA')<Order.SavedOrderData>();

export const createOrder = createPromiseAction('CREATE_ORDER')<
    undefined,
    Order.CreateOrderResponse | null,
    Errors.API
>();

export const buyAdditionalFeature = createPromiseAction('BUY_ADDITIONAL_FEATURES')<
    Offer.BuyFeatureRequest,
    Offer.BuyFeatureResponse,
    Errors.API
>();
