//Hooks
import {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from '@reach/router';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'hooks/store';
import {useLocationSearchPagination} from 'hooks/locationSearchPagination';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Types
import {Offer, Order} from 'store/types';

export const useMyOffersView = () => {
    const {status} = useParams();
    const dispatch = useDispatch();
    const [selectedOffer, setSelectedOffer] = useState<Offer.ListItem | undefined>(undefined);
    const {t} = useTranslation(['offers', 'orders']);
    const [isStatusChanging, setStatusChanging] = useState(false);

    const offerStatus: 'sold' | 'draft' | 'published' | 'closed' =
        status && ['sold', 'draft', 'closed'].includes(status) ? status : 'published';

    const myOffers = useSelector(state => state.myOffers);
    const {isLoading, pages} = myOffers[offerStatus];
    const ref = useRef<HTMLButtonElement>(null);
    const {changePage, pageFromSearch} = useLocationSearchPagination({
        ref
    });

    useEffect(() => {
        if (pages && pages < pageFromSearch) {
            changePage(pages);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages, changePage]);
    let orders: Order.Order[] | null, offers: Offer.ListItem[] | null;
    if (offerStatus === 'sold') {
        orders = myOffers.sold.data;
        offers = null;
    } else {
        orders = null;
        offers = myOffers[offerStatus].data;
    }

    useEffect(() => {
        if (offerStatus === 'sold') {
            dispatch(actions.getMySoldOrders.request({page: pageFromSearch}));
        } else {
            dispatch(
                actions.getMyOffers.request({
                    status: offerStatus as Offer.Status,
                    page: pageFromSearch
                })
            );
        }
    }, [dispatch, offerStatus, pageFromSearch]);

    const handlePageChange = (page: number) => changePage(page);

    const handlePublish = async (offer: Offer.ListItem) => {
        setStatusChanging(true);
        try {
            await dispatch(actions.publishOffer.request(offer));
            toast.success(t('offers:publishedSuccessfully'));
        } catch (err) {
            if (err.message) {
                toast.error(err.message);
            }
        }
        setStatusChanging(false);
    };

    const handleStopPublishing = async (offer: Offer.ListItem) => {
        setStatusChanging(true);
        try {
            await dispatch(actions.closeOffer.request(offer));
            toast.success(t('offers:closedSuccessfully'));
        } catch (err) {
            if (err.message) {
                toast.error(err.message);
            }
        }
        setStatusChanging(false);
    };

    const handleConfirm = async (order: Order.Order) => {
        setStatusChanging(true);
        try {
            await dispatch(actions.confirmOrder.request(order));
            toast.success(t('orders:confirmedSuccessfully'));
        } catch (err) {
            if (err.message) {
                toast.error(err.message);
            }
        }
        setStatusChanging(false);
    };

    const handleCancel = async (order: Order.Order) => {
        setStatusChanging(true);
        try {
            await dispatch(actions.cancelOrder.request(order));
            toast.success(t('orders:canceledSuccessfully'));
        } catch (err) {
            if (err.message) {
                toast.error(err.message);
            }
        }
        setStatusChanging(false);
    };

    const openPromoteOfferModal = useCallback((offer: Offer.ListItem) => {
        setSelectedOffer(offer);
    }, []);

    const closePromoteOfferModal = useCallback(() => {
        setSelectedOffer(undefined);
    }, []);

    return {
        offers,
        orders,
        pages,
        pageFromSearch,
        isLoading: isLoading || isStatusChanging,
        displayOrders: offerStatus === 'sold',
        openPromoteOfferModal,
        closePromoteOfferModal,
        selectedOffer,
        handlePageChange,
        handlePublish,
        handleStopPublishing,
        handleConfirm,
        handleCancel,
        ref
    };
};
