//Hooks
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

//Components
import Input, {InputIcon} from 'components/ui/Input';
import RadioGroup from 'components/ui/RadioGroup';
import Radio from 'components/ui/Radio';
import Switch from 'components/ui/Switch';
import Section from '../Section';
import AccountTypeFilter from '../AccountTypeFilter';

//Types
import {Offer, Search} from 'store/types';

const ProductFilters: React.VFC = () => {
    const {values, handleChange} = useFormikContext<Search.FormValues>();

    const {t} = useTranslation(['search', 'common']);

    return (
        <>
            <Section title={t('search:priceFilter')}>
                <div className={'flex flex-row gap-8'}>
                    <div className={'flex flex-row items-end gap-2 text-sm tracking-tighter'}>
                        <span>{t('common:from')}</span>

                        <Input
                            id={'product.priceFrom'}
                            name={'product.priceFrom'}
                            value={values.product?.priceFrom?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            disabled={values.product?.free}
                            aria-label={'Price from'}
                        >
                            <InputIcon placement={'right'} className={'text-gray-400'}>
                                {t('common:currencyShort')}
                            </InputIcon>
                        </Input>
                    </div>

                    <div className={'flex flex-row items-end gap-2 text-sm tracking-tighter'}>
                        <span>{t('common:to')}</span>

                        <Input
                            id={'product.priceTo'}
                            name={'product.priceTo'}
                            value={values.product?.priceTo?.toString() ?? ''}
                            onChange={handleChange}
                            type={'number'}
                            showControls={false}
                            disabled={values.product?.free}
                            aria-label={'Price to'}
                        >
                            <InputIcon placement={'right'} className={'text-gray-400'}>
                                {t('common:currencyShort')}
                            </InputIcon>
                        </Input>
                    </div>
                </div>
            </Section>

            <Section title={t('search:offerType')}>
                <AccountTypeFilter />
            </Section>

            <Section title={t('search:offerForFree')}>
                <Switch
                    id={'product.free'}
                    name={'product.free'}
                    checked={values.product?.free}
                    onChange={handleChange}
                    label={t('common:forFree')}
                />
            </Section>

            <Section title={t('search:delivery')}>
                <RadioGroup
                    name={'product.deliveryMethod'}
                    value={values.product?.deliveryMethod ?? ''}
                    onChange={handleChange}
                    direction={'vertical'}
                >
                    <Radio
                        id={'product.deliveryMethod.all'}
                        label={t('common:all')}
                        value={''}
                    />

                    <Radio
                        id={'product.deliveryMethod.courier'}
                        label={t('common:delivery')}
                        value={Offer.DeliveryMethod.Courier}
                    />

                    <Radio
                        id={'product.deliveryMethod.pickup'}
                        label={t('common:pickupAtSeller')}
                        value={Offer.DeliveryMethod.Pickup}
                    />
                </RadioGroup>
            </Section>
        </>
    );
};

export default ProductFilters;
