//Action creators
import {createAsyncAction} from 'typesafe-actions';
import {createPromiseAction} from 'redux-saga-promise-actions';

//Types
import {Auth, Errors} from '../../types';

/**
 * Refreshes access token and saves new refresh token to localStorage.
 */
export const refreshAccessToken = createAsyncAction(
    'REFRESH_ACCESS_TOKEN_REQUEST',
    'REFRESH_ACCESS_TOKEN_SUCCESS',
    'REFRESH_ACCESS_TOKEN_FAILURE'
)<undefined, Auth.RefreshTokenResponse, Errors.API>();

/**
 * Creates an account with provided credentials.
 */
export const register = createPromiseAction('REGISTER')<
    any,
    Auth.RegisterUUID,
    Errors.API<Auth.RegisterInfo>
>();

/**
 * Sends confirmation code to user via email or sms.
 */
export const confirmAccount = createPromiseAction('CONFIRM_ACCOUNT')<
    Auth.ConfirmRequest,
    undefined,
    Errors.API<Auth.ConfirmRequest>
>();

/**
 * Logs in with provided credentials.
 */
export const login = createPromiseAction('LOGIN')<
    Auth.Credentials,
    Auth.LoginResponse,
    Errors.API<Auth.Credentials>
>();

/**
 * Triggers forgot password link for provided email/phone number.
 */
export const requestPasswordReset = createPromiseAction('REQUEST_PASSWORD_RESET')<
    Auth.RequestPasswordReset,
    undefined,
    Errors.API<Auth.RequestPasswordReset>
>();

/**
 * Resets password for provided user.
 */
export const resetPassword = createPromiseAction('RESET_PASSWORD')<
    Auth.ResetPassword,
    undefined,
    Errors.API<Auth.ResetPassword>
>();

/**
 * Logs out the logged in user.
 */
export const logout = createPromiseAction('LOGOUT')<
    undefined,
    undefined,
    Errors.API<undefined>
>();
