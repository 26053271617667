//Action creators
import {createPromiseAction} from 'redux-saga-promise-actions';

//Types
import {Errors} from '../../types';
import {StaticPages} from 'store/types';

export const loadStaticPages = createPromiseAction('LOAD_STATIC_PAGES')<
    undefined,
    StaticPages.StaticPages[],
    Errors.API
>();

export const loadStaticPage = createPromiseAction('LOAD_STATIC_PAGE')<
    string,
    StaticPages.StaticPage,
    Errors.API
>();

export const submitContactForm = createPromiseAction('SUBMIT_CONTACT_FORM')<
    StaticPages.ContactForm,
    StaticPages.ContactFormResponse,
    Errors.API
>();
