//Hooks
import {useFormikContext} from 'formik';

//Types
import {Offer} from 'store/types';

export const useProductForm = () => {
    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue
    } = useFormikContext<Offer.Form>();

    const handleChangePrice = (e: React.FormEvent<HTMLInputElement>) => {
        const tbbcAmount = e.currentTarget.value === 'free' ? 0 : '';
        setFieldValue('offer.price', {...values.offer.price, tbbcAmount});
    };

    const handleChangeDeliveryMethodPickup = (e: React.FormEvent<HTMLInputElement>) => {
        handleChange(e);
        setFieldValue('offer.delivery.price.tbbcAmount', null);
    };

    return {
        values,
        errors,
        touched,
        handleChange,
        handleChangePrice,
        handleChangeDeliveryMethodPickup
    };
};
