//Types
import {Categories, Offer, User} from 'store/types';

export interface Sort {
    field: 'date' | 'price';
    order: 'asc' | 'desc';
}

export interface JobFilters<P extends Offer.Period> {
    salaryFrom?: number;
    salaryTo?: number;
    period: P;
}

export interface ServiceFilters {
    priceFrom?: number;
    priceTo?: number;
    period: Offer.Period.Hourly;
}

export interface ProductFilters {
    priceFrom?: number;
    priceTo?: number;
    free: boolean;
    deliveryMethod?: Offer.DeliveryMethod;
}

type Attributes = Record<string, string | string[] | number>;

export enum Location {
    Local = 'local',
    Everywhere = 'everywhere'
}

export interface Geodistance {
    lat: number;
    lon: number;
    distance: 'nearby';
}

export interface FormValues {
    category?: Categories.Category;
    accountType?: User.Type;
    phrase?: string;
    sort?: Sort;
    job?: JobFilters<Offer.Period.Hourly | Offer.Period.Monthly>[];
    service?: ServiceFilters;
    product?: ProductFilters;
    attributes?: Attributes;
    page?: number;
    geoDistance?: Geodistance;
}
