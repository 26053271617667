//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Offer, Data, Order} from 'store/types';
import {Status} from 'store/types/offer';

export type Action = ActionType<typeof actions>;

type OffersData = Data<Offer.ListItem[]> & {
    currentPage: number;
    pages: number | null;
};

export type State = Readonly<{
    published: OffersData;
    sold: Data<Order.Order[]> & {
        currentPage: number;
        pages: number | null;
    };
    draft: OffersData;
    closed: OffersData;
    soldDetails: Data<Order.OrderDetails>;
    lastEditedOfferStatus?: undefined | Status;
}>;

const initialOfferState: OffersData = {
    data: null,
    isLoading: false,
    isLoaded: false,
    isErrored: false,
    currentPage: 1,
    pages: null
};

export const initialState: State = {
    published: initialOfferState,
    sold: initialOfferState,
    draft: initialOfferState,
    closed: initialOfferState,
    soldDetails: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    lastEditedOfferStatus: undefined
};

export default createReducer<State, Action>(initialState)
    .handleAction(actions.getMyOffers.request, (state, action) => ({
        ...state,
        [action.payload.status]: {
            ...state[action.payload.status],
            isLoading: true,
            currentPage: action.payload.page
        }
    }))
    .handleAction(actions.getMyOffers.success, (state, action) => ({
        ...state,
        [action.payload.status]: {
            data: action.payload.items,
            isLoaded: true,
            isLoading: false,
            isErrored: false,
            currentPage: action.payload.pagination.currentPage,
            pages: action.payload.pagination.totalPages
        }
    }))
    .handleAction(actions.getMyOffers.failure, (state, action) => ({
        ...state,
        [action.payload.status]: {
            ...state[action.payload.status],
            isLoading: false,
            isErrored: true
        }
    }))
    .handleAction(actions.getMySoldOrders.request, (state, action) => ({
        ...state,
        sold: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: null,
            currentPage: action.payload.page,
            pages: state.sold.pages
        }
    }))
    .handleAction(actions.getMySoldOrders.success, (state, action) => ({
        ...state,
        sold: {
            data: action.payload.items,
            isLoaded: true,
            isLoading: false,
            isErrored: false,
            currentPage: action.payload.pagination.currentPage,
            pages: action.payload.pagination.totalPages
        }
    }))
    .handleAction(actions.getMySoldOrders.failure, state => ({
        ...state,
        sold: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null,
            pages: null,
            currentPage: 1
        }
    }))
    .handleAction(actions.publishOffer.success, state => ({
        ...state,
        published: {...state.published, isLoaded: false, data: null}
    }))
    .handleAction(actions.closeOffer.success, state => ({
        ...state,
        closed: {...state.closed, isLoaded: false, data: null}
    }))
    .handleAction(actions.getMySoldOrderById.request, state => ({
        ...state,
        soldDetails: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: null
        }
    }))
    .handleAction(actions.getMySoldOrderById.success, (state, action) => ({
        ...state,
        soldDetails: {
            data: action.payload,
            isLoaded: true,
            isLoading: false,
            isErrored: false
        }
    }))
    .handleAction(actions.getMySoldOrderById.failure, state => ({
        ...state,
        soldDetails: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }))
    .handleAction(actions.confirmOrder.success, (state, action) => ({
        ...state,
        sold: {
            ...state.sold,
            isLoading: false,
            isErrored: false,
            isLoaded: true,
            data: state.sold.data!.map(order =>
                order.orderId === action.payload.orderId
                    ? {...order, status: Order.Status.Confirmed}
                    : order
            )
        }
    }))
    .handleAction(actions.cancelOrder.success, (state, action) => ({
        ...state,
        sold: {
            ...state.sold,
            isLoading: false,
            isErrored: false,
            isLoaded: true,
            data: state.sold.data!.map(order =>
                order.orderId === action.payload.orderId
                    ? {...order, status: Order.Status.Canceled}
                    : order
            )
        }
    }))
    .handleAction(actions.setShipmentLink.success, (state, action) => ({
        ...state,
        soldDetails: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: {
                ...state.soldDetails.data,
                shipmentLink: action.payload.shipmentLink ?? ''
            } as Order.OrderDetails
        }
    }))
    .handleAction(actions.logout.success, () => initialState)
    .handleAction(actions.editOffer.request, (state, action) => ({
        ...state,
        lastEditedOfferStatus: action.payload.data.offer.status
    }))
    .handleAction(actions.editOffer.success, state => {
        if (state.lastEditedOfferStatus) {
            return {
                ...state,
                [state.lastEditedOfferStatus]: {
                    ...state[state.lastEditedOfferStatus],
                    isLoaded: false,
                    data: null
                },
                lastEditedOfferStatus: undefined
            };
        }
        return state;
    });
