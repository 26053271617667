//Hooks
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDropzone} from 'react-dropzone';

//Components
import Button from 'components/ui/Button';

//Services
import toast from 'react-hot-toast';

//Utils
import _ from 'lodash-es';

//Types
import {FileWithPath, FileRejection} from 'react-dropzone';

export type DropzoneProps = {
    label: string | React.ReactElement;
    onFileUpload: (files: Array<FileWithPath & {preview: string}>) => void;
    className?: string;
    acceptedFileTypes?: string;
    multiple?: boolean;
    maxFiles?: number;
};

const Dropzone: React.VFC<DropzoneProps> = ({
    label,
    onFileUpload,
    className,
    acceptedFileTypes,
    multiple,
    maxFiles
}) => {
    const {t} = useTranslation('validation');
    const onDrop = useCallback(
        (acceptedFiles, fileRejections: FileRejection[]) => {
            if (!!fileRejections && fileRejections.length > 0) {
                const errors = _.flatten(
                    fileRejections.map(({errors}) => errors.map(e => e.code))
                );
                if (errors.includes('too-many-files')) {
                    toast.error(t('tooManyFiles'));
                }
            }
            if (!!acceptedFiles && acceptedFiles?.length > 0) {
                const filesWithPreview = acceptedFiles.map((file: FileWithPath) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                );
                onFileUpload(filesWithPreview);
            }
        },
        [onFileUpload, t]
    );

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        multiple,
        maxFiles,
        accept: acceptedFileTypes
    });

    return (
        <div {...getRootProps()} className={className}>
            <input {...getInputProps()} role={'region'} />

            <Button variant={'primary'} size={'xs'} outline type={'button'}>
                {label}
            </Button>
        </div>
    );
};

export default Dropzone;
