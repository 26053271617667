//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {StaticPages, Data} from 'store/types';

export type StaticPagesAction = ActionType<typeof actions>;

export type State = Readonly<{
    staticPages: Data<StaticPages.StaticPages[]>;
    staticPage: Data<StaticPages.StaticPage>;
    contactSubjects: Data<StaticPages.ContactSubject[]>;
}>;

export const initialState: State = {
    staticPages: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    staticPage: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    },
    contactSubjects: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    }
};

export default createReducer<State, StaticPagesAction>(initialState)
    .handleAction(actions.loadStaticPages.request, state => ({
        ...state,
        staticPages: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.staticPages.data
        }
    }))
    .handleAction(actions.loadStaticPages.success, (state, action) => ({
        ...state,
        staticPages: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload
        }
    }))
    .handleAction(actions.loadStaticPages.failure, state => ({
        ...state,
        staticPages: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }))
    .handleAction(actions.loadStaticPage.request, state => ({
        ...state,
        staticPage: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.staticPage.data
        }
    }))
    .handleAction(actions.loadStaticPage.success, (state, action) => ({
        ...state,
        staticPage: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload
        }
    }))
    .handleAction(actions.loadStaticPage.failure, state => ({
        ...state,
        staticPage: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }))
    .handleAction(actions.getConstants.request, state => ({
        ...state,
        contactSubjects: {
            isLoading: true,
            isLoaded: false,
            isErrored: false,
            data: state.contactSubjects.data
        }
    }))
    .handleAction(actions.getConstants.success, (state, action) => ({
        ...state,
        contactSubjects: {
            isLoading: false,
            isLoaded: true,
            isErrored: false,
            data: action.payload.contactSubjects
        }
    }))
    .handleAction(actions.getConstants.failure, state => ({
        ...state,
        contactSubjects: {
            isLoading: false,
            isLoaded: false,
            isErrored: true,
            data: null
        }
    }));
