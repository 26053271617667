//Hooks
import {useCategoryPicker} from './CategoryPicker.hook';
import {useTranslation} from 'react-i18next';

//Components
import {Fragment} from 'react';
import Icon from 'components/ui/Icon';
import Menu from './Menu';

//Utils
import cx from 'classnames';

//Types
import {Categories} from 'store/types';

export type CategoryPickerProps = {
    options: Categories.Category[];
    className?: string;
    value: number | null;
    onChange: (id: number) => void;
    disabled?: boolean;
    label?: string;
    error?: string | undefined | null | false;
    expandOnHover?: boolean;
    preventSelectParentOption?: boolean;
} & Omit<
    React.HTMLProps<HTMLInputElement>,
    'value' | 'onChange' | 'className' | 'disabled' | 'label'
>;

const CategoryPicker: React.VFC<CategoryPickerProps> = ({
    className,
    options,
    value,
    onChange,
    disabled,
    label,
    error,
    expandOnHover,
    preventSelectParentOption,
    ...props
}) => {
    const {
        pickerRef,
        isMenuOpen,
        displayValue,
        handleClick,
        handleOptionSelect
    } = useCategoryPicker({
        options,
        value,
        onChange
    });

    const {t} = useTranslation('common');

    return (
        <div
            className={cx(className, 'relative')}
            ref={pickerRef}
            role={'searchbox'}
            {...props}
        >
            {label && (
                <label className={'mb-3 text-sm inline-block'} htmlFor={props.id}>
                    {label}
                </label>
            )}

            <div
                className={cx('border rounded-md', {
                    'border-gray-300': !error,
                    'border-red': error
                })}
            >
                <div
                    onClick={disabled ? () => null : handleClick}
                    className={cx(
                        'flex flex-row justify-between items-center w-full h-12 pl-6 cursor-pointer',
                        {'ring-4 ring-primary ring-opacity-50': isMenuOpen}
                    )}
                >
                    <input className={'hidden'} />

                    <div
                        className={
                            'relative flex items-center flex-grow text-sm tracking-tight text-gray-900'
                        }
                    >
                        <div
                            className={
                                'absolute w-full flex flex-row items-center overflow-hidden'
                            }
                        >
                            {value ? (
                                displayValue.map((item, i) => [
                                    <Fragment key={i}>
                                        <span
                                            className={'whitespace-nowrap'}
                                            role={'heading'}
                                            aria-level={i + 1}
                                        >
                                            {item}
                                        </span>

                                        <span className={'px-4 last:hidden'}>
                                            <Icon icon={'chevron-right'} size={'xs'} />
                                        </span>
                                    </Fragment>
                                ])
                            ) : (
                                <span
                                    className={'text-gray-600'}
                                    role={'note'}
                                    aria-label={'placeholder'}
                                >
                                    {t('chooseCategory')}
                                </span>
                            )}
                        </div>
                    </div>

                    <span className={'px-4'}>
                        <Icon
                            className={'transform rotate-90'}
                            icon={'arrow-right'}
                            size={'xs'}
                        />
                    </span>
                </div>
            </div>

            {error && !isMenuOpen && (
                <span
                    className={'tracking-tight text-red text-form-helper mt-2.5'}
                    role={'alert'}
                    aria-label={'error'}
                >
                    {error}
                </span>
            )}

            {isMenuOpen && (
                <Menu
                    options={options}
                    selectOption={handleOptionSelect}
                    expandOnHover={expandOnHover}
                    preventSelectParentOption={preventSelectParentOption}
                />
            )}
        </div>
    );
};

export default CategoryPicker;
