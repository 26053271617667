//Hooks
import {useMemo, useCallback} from 'react';
import {usePaymentMethod} from 'hooks/basket';
import {useSelector, useDispatch} from 'hooks/store';
import {useNavigate, useLocation} from '@reach/router';

//Services
import toast from 'react-hot-toast';

//Utils
import {object, string, boolean} from 'yup';
import {validationErrorToShowInToast} from 'utils/payment';

//Actions
import * as actions from 'store/actions';

//Types
import {FormikHelpers} from 'formik';
import {Offer, Order} from 'store/types';

export const usePaymentStepperView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {payment, isShipment, isFree} = usePaymentMethod();

    const user = useSelector(state => state.user.user);

    const initialValues = useMemo<Offer.PaymentFormValues>(
        () => ({
            shippingAddress: {
                street: '',
                apartmentNumber: '',
                city: '',
                postCode: '',
                state: '',
                ...user?.address
            },
            method: '',
            isShipmentModalOpen: false
        }),
        [user]
    );

    const handleSubmit = useCallback(
        async (
            values: Offer.PaymentFormValues,
            {setErrors, setFieldValue}: FormikHelpers<Offer.PaymentFormValues>
        ) => {
            if (location.pathname === '/payment/method/extras' && payment.extraFeature) {
                try {
                    let params;

                    if (payment.extraFeature.featureType === 'highlight') {
                        params = {highlight: true};
                    }

                    if (payment.extraFeature.featureType === 'extraPhotos') {
                        params = {extra_photos: true};
                    }

                    if (payment.extraFeature.featureType === 'offerPeriodExtension') {
                        params = {offer_period_extension: true};
                    }
                    await dispatch(
                        actions.buyAdditionalFeature.request({
                            offerId: payment.extraFeature.id,
                            ...params
                        })
                    );

                    await navigate('/payment/card/extras');
                } catch (error: any) {
                    if (error.errors?.additionalFirstError) {
                        toast.error(error.errors?.additionalFirstError);
                    } else if (error.message) {
                        toast.error(error.message);
                    }

                    if (error.errors) {
                        setErrors(error.errors);
                    }
                }
            } else {
                const reqDat: Order.SavedOrderData = {
                    shipping_address: isShipment ? null : values.shippingAddress,
                    basket: {
                        products: payment.offers.map(offer => ({
                            product: offer.id,
                            quantity: offer?.quantity ?? 0
                        }))
                    }
                };
                dispatch(actions.saveOrderData(reqDat));

                if (isFree) {
                    try {
                        await dispatch(actions.createOrder.request());
                    } catch (error) {
                        const errorToShow = validationErrorToShowInToast(error);
                        if (errorToShow) {
                            toast.error(errorToShow);
                        } else if (error.message) {
                            toast.error(error.message);
                        }
                        return;
                    }
                    await navigate('/payment/success');
                    return;
                }

                await navigate('/payment/card');
            }
        },
        [
            dispatch,
            isFree,
            isShipment,
            location.pathname,
            navigate,
            payment.extraFeature,
            payment.offers
        ]
    );

    const validationSchema = object<Omit<Offer.PaymentFormValues, 'method'>>({
        shippingAddress: object({
            street: string().required(),
            apartmentNumber: string(),
            city: string().required(),
            postCode: string().required(),
            state: string().required()
        }).required(),
        isShipmentModalOpen: boolean()
    });

    return {initialValues, handleSubmit, validationSchema};
};
