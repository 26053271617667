//Hooks
import {useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

const TokenLoader: React.VFC = () => {
    const isTokenLoading = useSelector(state => state.auth.isTokenLoading);
    const isTokenLoaded = useSelector(state => !!state.auth.token);
    const isTokenErrored = useSelector(state => state.auth.isTokenErrored);
    const isLoggingOut = useSelector(state => state.auth.isLoggingOut);
    const dispatch = useDispatch();

    const refreshToken = useCallback(() => {
        if (isTokenLoading || isTokenLoaded || isTokenErrored || isLoggingOut) {
            return;
        }

        dispatch(actions.refreshAccessToken.request());
    }, [isTokenLoading, isTokenLoaded, isTokenErrored, isLoggingOut, dispatch]);

    useEffect(() => {
        refreshToken();
    }, [isTokenLoaded, isTokenLoading, refreshToken]);

    return null;
};

export default TokenLoader;
