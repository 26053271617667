//Reducer
import {createReducer} from 'typesafe-actions';

//Actions
import * as actions from 'store/actions';

//Types
import {ActionType} from 'typesafe-actions';
import {Data, Notifications} from 'store/types';

export type FavoritesAction = ActionType<typeof actions>;

export type State = Readonly<{
    notifications: Data<Notifications.Notifications>;
}>;

export const initialState: State = {
    notifications: {
        isLoading: false,
        isLoaded: false,
        isErrored: false,
        data: null
    }
};

export default createReducer<State, FavoritesAction>(initialState)
    .handleAction(actions.getNotifications.request, state => ({
        ...state,
        notifications: {data: null, isLoading: true, isLoaded: false, isErrored: false}
    }))
    .handleAction(actions.getNotifications.success, (state, action) => ({
        ...state,
        notifications: {
            data: action.payload,
            isLoading: true,
            isLoaded: false,
            isErrored: false
        }
    }));
