//Hooks
import {useTranslation} from 'react-i18next';
import {useRealEstateForm} from './RealEstateForm.hook';
import {useInputHelperHook} from 'hooks/input';

//Components
import Options from 'components/ui/Options';
import Input, {InputIcon} from 'components/ui/Input';

const RealEstateForm: React.VFC = () => {
    const {values, errors, touched, handleChange, handleChangePrice} = useRealEstateForm();

    const {onBlur} = useInputHelperHook();

    const {t} = useTranslation(['offers', 'common']);
    return (
        <>
            <h2 className={'mt-3 hidden md:flex'} id={'price-label'}>
                {t('offers:price')}
            </h2>

            <div className={'md:mt-3.5 mt-8 md:w-86'}>
                <Options
                    name={'price-options'}
                    value={values.offer?.price?.tbbcAmount === 0 ? 'free' : 'price'}
                    onChange={e => handleChangePrice(e)}
                >
                    <Options.Option
                        label={t('offers:price')}
                        id={'price-price'}
                        value={'price'}
                    />

                    <Options.Option
                        label={t('common:forFree')}
                        id={'price-free'}
                        value={'free'}
                    />
                </Options>

                {values.offer?.price?.tbbcAmount === 0 ? (
                    <div className={'text-sm tracking-tighter mt-7'}>
                        {t('offers:willBeForFree')}
                    </div>
                ) : (
                    <Input
                        id={'offer.price.tbbcAmount'}
                        name={'offer.price.tbbcAmount'}
                        className={'mt-3.5'}
                        aria-labelledby={'price-label'}
                        value={values.offer?.price?.tbbcAmount?.toString() ?? ''}
                        onChange={handleChange}
                        onBlur={onBlur}
                        type={'text'}
                        showControls={false}
                        error={
                            touched?.offer?.price?.tbbcAmount &&
                            errors?.offer?.price?.tbbcAmount
                        }
                        data-testid={'product-price-input'}
                    >
                        <InputIcon placement={'right'}>
                            <span className={'text-gray-600 text-form'}>
                                {t('common:currencyShort')}
                            </span>
                        </InputIcon>
                    </Input>
                )}
            </div>
        </>
    );
};

export default RealEstateForm;
