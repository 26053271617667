//Hooks
import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import {useNavigate} from '@reach/router';

//Types
import {FormikTouched, FormikErrors, FormikHandlers} from 'formik';
import {Auth} from 'store/types';

type UseRegisterConfirmTypeViewResult<T> = {
    values: T;
    touched: FormikTouched<T>;
    errors: FormikErrors<T>;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    handleChange: FormikHandlers['handleChange'];
    isSubmitting: boolean;
};

export function useRegisterMethodView<
    T extends Auth.RegisterInfo
>(): UseRegisterConfirmTypeViewResult<T> {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        validateForm
    } = useFormikContext<T>();

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const errors = await validateForm();
            if (!Object.keys(errors).length) {
                return;
            }

            navigate('/auth/register');
        })();
    }, [validateForm, navigate]);

    return {values, touched, errors, handleChange, handleSubmit, isSubmitting};
}
