//Utils
import cx from 'classnames';

export type TitleProps = {
    className?: string;
    children: string;
};

const Title: React.VFC<TitleProps> = ({className, children}) => (
    <h2 className={cx(className, 'text-2xl mb-4')} aria-label={'title'}>
        {children}
    </h2>
);
Title.displayName = 'Title';

export default Title;
