import * as React from 'react';

//Components
import Title from './Title';
import Body from './Body';

//Utils
import cx from 'classnames';

//Types
import {TitleProps} from './Title';
import {BodyProps} from './Body';

export type CardProps = {
    className?: string;
    children:
        | React.ReactElement<BodyProps, typeof Body>
        | [
              React.ReactElement<TitleProps, typeof Title>,
              React.ReactElement<BodyProps, typeof Body>
          ];
};

const Card: React.VFC<CardProps> & {Title: typeof Title; Body: typeof Body} = ({
    className,
    children
}) => (
    <div className={cx('bg-white sm:border sm:border-gray-300 rounded', className)}>
        {React.Children.map(children, child => {
            if (!child || child.type.displayName !== 'Title') {
                return null;
            }

            return child;
        })}

        {React.Children.map(children, child => {
            if (!child || child.type.displayName !== 'Body') {
                return null;
            }

            return child;
        })}
    </div>
);

Card.Title = Title;
Card.Body = Body;

export default Card;
