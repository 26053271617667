//Hooks
import {useTranslation} from 'react-i18next';
import {useEditMyEmailConfirmModal} from './EditMyEmailConfirmModal.hook';

//Components
import Button from 'components/ui/Button';
import Input from 'components/ui/Input';
import Modal from 'components/ui/Modal';

export type EditMyEmailConfirmModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const EditMyEmailConfirmModal: React.VFC<EditMyEmailConfirmModalProps> = ({
    isOpen,
    onClose
}) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useEditMyEmailConfirmModal(onClose);
    const {t} = useTranslation(['profile', 'auth', 'common']);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <form className={'flex flex-col'} onSubmit={handleSubmit}>
                <div className={'text-lg font-semibold'}>{t('auth:authentication')}</div>

                <div className={'text-xs-checkbox mt-3.5'}>
                    {t('auth:confirmDescriptionEmail')}
                </div>

                <Input
                    id={'code'}
                    name={'code'}
                    value={values.code}
                    onChange={handleChange}
                    label={t('profile:code')}
                    error={touched.code && errors.code}
                    className={'mt-12 sm:w-7/12'}
                />

                <Button
                    className={'self-center mt-20 w-60 sm:w-full'}
                    variant={'primary'}
                    type={'submit'}
                    disabled={isSubmitting}
                >
                    {t('common:confirm')}
                </Button>
            </form>
        </Modal>
    );
};

export default EditMyEmailConfirmModal;
