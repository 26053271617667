export enum HomeArea {
    WebTop = 'web_top',
    WebBottom = 'web_bottom',
    WebBetweenOffers = 'web_between_offers',
    MobileMainPage = 'mobile_main_page',
    WebLeft = 'web_left',
    WebRight = 'web_right',
    MobileGoogleAd = 'mobile_google_ad'
}

export enum CategoryArea {
    Web = 'web_category',
    Mobile = 'mobile_category'
}

export interface Advertisement {
    area: HomeArea | CategoryArea;
    imageUrl: string;
    referenceUrl: string | null;
    categoryIds?: number[];
}

export type GetAdvertisementsResponse = {
    advertisements: Advertisement[];
};
