//Hooks
import {useTranslation} from 'react-i18next';

//Components
import {CardCvcElement, CardExpiryElement, CardNumberElement} from '@stripe/react-stripe-js';

//Styles
import Styles from './CardInput.module.scss';

const CardInput: React.VFC = () => {
    const {t} = useTranslation('payment');

    return (
        <div className={'flex flex-col pt-5 pb-10 md:px-7 gap-y-6'}>
            <label className={'text-sm font-semibold'}>{t('creditCard')}</label>

            <div className={'flex flex-col gap-y-3 max-w-86'}>
                <span className={'text-sm'}>{t('cardNumber')}</span>

                <CardNumberElement
                    options={{
                        showIcon: true,
                        classes: {
                            base: Styles.base,
                            focus: Styles.focus,
                            invalid: Styles.invalid
                        }
                    }}
                />
            </div>

            <div className={'flex justify-between max-w-86'}>
                <div className={'flex flex-col gap-y-3'}>
                    <span className={'text-sm'}>{t('expirationDate')}</span>

                    <div className={'flex items-center gap-x-2'}>
                        <CardExpiryElement
                            options={{
                                classes: {
                                    base: Styles.base,
                                    focus: Styles.focus,
                                    invalid: Styles.invalid
                                }
                            }}
                        />
                    </div>
                </div>

                <div className={'flex flex-col gap-y-3'}>
                    <span className={'text-sm'}>{t('cvvCode')}</span>

                    <CardCvcElement
                        options={{
                            classes: {
                                base: Styles.base,
                                focus: Styles.focus,
                                invalid: Styles.invalid
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CardInput;
