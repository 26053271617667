//Hooks
import {useOption} from './Option.hook';

//Components
import Icon from 'components/ui/Icon';
import Menu from '../Menu/Menu';

//Styles
import Styles from './Option.module.scss';

//Utils
import cx from 'classnames';

//Types
import {Category} from 'store/types/categories/categories';

export type OptionProps = {
    active: boolean;
    item: Category;
    options: Category[];
    selectOption: (option: Category) => void;
    setActiveOption: (optionId: number | null) => void;
    expandOnHover: boolean;
    preventSelectParentOption: boolean;
};

const Option: React.VFC<OptionProps> = ({
    active,
    item,
    options,
    selectOption,
    setActiveOption,
    expandOnHover,
    preventSelectParentOption
}) => {
    const {hasChildren, handleClick, handleMouseEnter} = useOption({
        active,
        item,
        options,
        selectOption,
        setActiveOption,
        expandOnHover,
        preventSelectParentOption
    });

    return (
        <li
            className={cx(
                Styles.option,
                'md:h-12 h-15 md:text-sm md:font-normal font-semibold text-gray-900 cursor-pointer hover:bg-primary hover:bg-opacity-20 md:relative'
            )}
        >
            <div
                className={
                    'flex flex-row items-center justify-between h-full mx-4 md:ml-10 md:mr-12'
                }
                onClick={handleClick}
                onMouseEnter={expandOnHover ? handleMouseEnter : () => null}
            >
                <span>{item.name}</span>

                {hasChildren && <Icon icon={'arrow-right'} size={'xs'} />}
            </div>

            {hasChildren && active && (
                <Menu
                    className={'absolute top-0 md:-top-12.25 md:left-full md:w-9/10'}
                    options={options}
                    parent={item}
                    selectOption={selectOption}
                    expandOnHover={expandOnHover}
                    preventSelectParentOption={preventSelectParentOption}
                />
            )}
        </li>
    );
};

export default Option;
