//Hooks
import {useSelector} from 'hooks/store';

export const usePopularSearches = () => {
    const {data: popularSearches, isLoading: areSearchesLoading} =
        useSelector(state => state?.search?.popularSearches) || {};

    return {
        popularSearches,
        areSearchesLoading
    };
};
