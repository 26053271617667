//Translations
import {useTranslation} from 'react-i18next';

//Hooks
import {usePaymentMethodView} from './PaymentMethodView.hook';

//Components
import Footer from 'components/nav/Footer';
import Button from 'components/ui/Button';
import EditShipmentAddressModal from 'components/modal/EditShipmentAddressModal';
import BasketOfferView from 'components/offer/BasketOfferView';
import ShipmentDetails from './ShipmentDetails';
import SearchForm from 'components/search/SearchForm';
import {Link} from '@reach/router';

//Types
import {RouteComponentProps} from '@reach/router';
import PaymentMethodPicker from './PaymentMethodPicker';
import PaymentBasketSummaryView from 'components/offer/PaymentBasketSummaryView';

const PaymentMethodView: React.VFC<RouteComponentProps> = () => {
    const {
        isFree,
        values,
        payment,
        isExtras,
        isMobile,
        handleSubmit,
        isSubmitting,
        isShipment,
        openShipmentModal,
        closeShipmentModal
    } = usePaymentMethodView();

    const {t} = useTranslation(['offers', 'basket', 'payment', 'common']);

    return (
        <>
            <div className={'flex flex-col flex-grow pb-9 bg-white md:bg-gray-200'}>
                <div className={'hidden md:block'}>
                    <SearchForm />
                </div>

                <div className={'w-full max-w-292 mx-auto mt-4 md:mt-12 px-4 lg:min-h-50'}>
                    <form
                        className={'flex flex-col-reverse lg:flex-row gap-6 md:gap-14'}
                        onSubmit={handleSubmit}
                    >
                        <>
                            <div className={'hidden md:block lg:w-4/12 max-w-5/12'}>
                                <PaymentBasketSummaryView>
                                    <div
                                        className={
                                            'flex mt-16 flex-col justify-center items-center'
                                        }
                                    >
                                        <Button
                                            variant={'primary'}
                                            type={'submit'}
                                            disabled={
                                                (!isFree && !values.method) || isSubmitting
                                            }
                                        >
                                            {t(
                                                isFree
                                                    ? 'basket:confirm'
                                                    : 'basket:proceedToPayment'
                                            )}
                                        </Button>

                                        <Button
                                            as={Link}
                                            to={isExtras ? '/' : '/basket'}
                                            outline
                                            className={'mt-4'}
                                            variant={'primary'}
                                        >
                                            {isExtras ? t('common:cancel') : t('common:back')}
                                        </Button>
                                    </div>
                                </PaymentBasketSummaryView>
                            </div>

                            <div
                                className={'w-full lg:w-8/12 min-w-7/12 flex flex-col gap-y-3'}
                            >
                                {isExtras
                                    ? !!payment.extraFeature && (
                                          <BasketOfferView
                                              size={'sm'}
                                              feature={payment.extraFeature}
                                          />
                                      )
                                    : !!payment.offers.length &&
                                      payment.offers.map(offer => (
                                          <BasketOfferView
                                              size={'sm'}
                                              offer={offer}
                                              key={offer.id}
                                          />
                                      ))}

                                {isMobile && <PaymentBasketSummaryView />}

                                {isExtras && (
                                    <div
                                        className={
                                            'py-5 px-6 bg-white w-full flex flex-col border-gray-300 border-1.5 md:border-none'
                                        }
                                    >
                                        <h3 className={'mb-3'}>
                                            {t('payment:paymentDetails')}
                                        </h3>

                                        <p className={'text-xs'}>Allspace</p>
                                    </div>
                                )}

                                {!isExtras && !isShipment && (
                                    <ShipmentDetails
                                        values={values}
                                        openModal={openShipmentModal}
                                    />
                                )}

                                {(!isFree || isExtras) && <PaymentMethodPicker />}

                                <div
                                    className={'flex md:hidden justify-center align-center p-4'}
                                >
                                    <Button
                                        variant={'primary'}
                                        type={'submit'}
                                        disabled={(!isFree && !values.method) || isSubmitting}
                                        size={isMobile ? 'sm' : 'lg'}
                                    >
                                        {!isFree || isExtras
                                            ? t('basket:proceedToPayment')
                                            : t('basket:confirm')}
                                    </Button>
                                </div>
                            </div>
                        </>
                    </form>
                </div>
            </div>

            <Footer />

            <EditShipmentAddressModal
                isOpen={values.isShipmentModalOpen}
                onClose={closeShipmentModal}
            />
        </>
    );
};

export default PaymentMethodView;
