//Hooks
import {useEditCreditCardView} from './EditCreditCardModal.hook';
import {useTranslation} from 'react-i18next';
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

//Components
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

import Styles from 'components/ui/CardInput/CardInput.module.scss';

export type EditCreditCardProps = {
    isOpen: boolean;
    onClose: () => void;
};

const EditCreditCardModal: React.VFC<EditCreditCardProps> = ({isOpen, onClose}) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useEditCreditCardView(onClose);

    const {t} = useTranslation('profile');

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <form className={'flex flex-col'} onSubmit={handleSubmit}>
                <div className={'flex flex-col gap-y-3.5'}>
                    <div>
                        <span className={'text-sm mb-3 block'}>{t('creditCardNumber')}</span>

                        <CardNumberElement
                            options={{
                                showIcon: true,
                                classes: {
                                    base: Styles.base,
                                    focus: Styles.focus,
                                    invalid: Styles.invalid
                                }
                            }}
                        />
                    </div>

                    <Input
                        id={'holder'}
                        name={'holder'}
                        value={values.holder}
                        onChange={handleChange}
                        label={t('cardHolderName')}
                        error={touched.holder && errors.holder}
                    />

                    <div className={'flex justify-between'}>
                        <div className={'w-5/12'}>
                            <span className={'text-sm mb-3 block'}>{t('expirationDate')}</span>

                            <CardExpiryElement
                                options={{
                                    classes: {
                                        base: Styles.base,
                                        focus: Styles.focus,
                                        invalid: Styles.invalid
                                    }
                                }}
                            />
                        </div>

                        <div className={'w-5/12'}>
                            <span className={'text-sm mb-3 block'}>{t('cvcCode')}</span>

                            <CardCvcElement
                                options={{
                                    classes: {
                                        base: Styles.base,
                                        focus: Styles.focus,
                                        invalid: Styles.invalid
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <Button
                    className={'mt-18 w-full'}
                    variant={'primary'}
                    type={'submit'}
                    disabled={isSubmitting}
                >
                    {t('save')}
                </Button>
            </form>
        </Modal>
    );
};

export default EditCreditCardModal;
