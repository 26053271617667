//Hooks
import {useTranslation} from 'react-i18next';
import {useSubmitForm} from './SubmitForm.hook';

//Components
import Button from 'components/ui/Button';

//Types
import {Offer} from 'store/types';

const SubmitForm: React.VFC = () => {
    const {status, isSubmitting, handleSubmitWithStatus} = useSubmitForm();

    const {t} = useTranslation('offers');

    return (
        <div className={'flex md:flex-row flex-col justify-center items-center mb-3 mt-9'}>
            {status !== Offer.Status.Published && (
                <Button
                    type={'button'}
                    size={'sm'}
                    variant={'primary'}
                    outline
                    className={'md:mr-9 md:mb-0 mb-8'}
                    disabled={isSubmitting}
                    onClick={() => handleSubmitWithStatus(Offer.Status.Draft)}
                >
                    {t('saveDraft')}
                </Button>
            )}

            <Button
                type={'button'}
                size={'sm'}
                variant={'primary'}
                disabled={isSubmitting}
                onClick={() => handleSubmitWithStatus(Offer.Status.Published)}
            >
                {t(status === Offer.Status.Published ? 'saveOffer' : 'addOffer')}
            </Button>
        </div>
    );
};

export default SubmitForm;
