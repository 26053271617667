//Hooks
import {useSelector, useDispatch} from 'react-redux';
import {useEffect} from 'react';

//Selectors
import {getCurrentPageSelector} from './StaticPageView.selector';

//Actions
import * as actions from 'store/actions';

export const useStaticPageView = (path?: string) => {
    const {isLoading, isLoaded, data: staticPage} = useSelector(getCurrentPageSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadStaticPage.request(path!));
    }, [path, dispatch]);

    return {
        isLoading,
        isLoaded,
        staticPage
    };
};
