//Hooks
import {useCategoryCustomFilters} from './CategoryCustomFilters.hook';
import {useTranslation} from 'react-i18next';

//Components
import Section from '../Section';
import Field from './Field';

const CategoryCustomFilters: React.VFC = () => {
    const {customFormFields} = useCategoryCustomFilters();

    const {t} = useTranslation('search');

    if (!customFormFields || !Object.entries(customFormFields).length) {
        return null;
    }

    return (
        <>
            <h2 className={'mt-4'}>{t('categoryFilters')}</h2>

            {Object.entries(customFormFields).map(([name, field]) => (
                <Section key={name} title={field.title}>
                    <Field name={name} field={field} />
                </Section>
            ))}
        </>
    );
};

export default CategoryCustomFilters;
