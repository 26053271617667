//Hooks
import {useEditMyBankAccountView} from './EditMyBankAccountModal.hook';
import {useTranslation} from 'react-i18next';

//Components
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

import {locale} from 'localeApp';

export type EditMyBankAccountModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const EditMyBankAccountModal: React.VFC<EditMyBankAccountModalProps> = ({isOpen, onClose}) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useEditMyBankAccountView(onClose);
    const {t} = useTranslation('profile');

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <form className={'flex flex-col'} onSubmit={handleSubmit}>
                <div className={'flex flex-col'}>
                    {locale === 'pl' && (
                        <Input
                            id={'iban'}
                            name={'iban'}
                            value={values.iban}
                            onChange={handleChange}
                            label={t('bankAccountNumber')}
                            error={touched.iban && errors.iban}
                        />
                    )}

                    {locale === 'us' && (
                        <Input
                            id={'accountNumber'}
                            name={'accountNumber'}
                            value={values.accountNumber}
                            onChange={handleChange}
                            label={t('bankAccountNumber')}
                            error={touched.accountNumber && errors.accountNumber}
                        />
                    )}

                    {locale === 'us' && (
                        <Input
                            id={'routingNumber'}
                            name={'routingNumber'}
                            value={values.routingNumber}
                            onChange={handleChange}
                            className={'mt-3.5'}
                            label={t('routingNumber')}
                            error={touched.routingNumber && errors.routingNumber}
                        />
                    )}

                    <Input
                        id={'holder'}
                        name={'holder'}
                        value={values.holder}
                        onChange={handleChange}
                        className={'mt-3.5'}
                        label={t('holder')}
                        error={touched.holder && errors.holder}
                    />
                </div>

                <Button
                    className={'mt-18 w-full'}
                    variant={'primary'}
                    type={'submit'}
                    disabled={isSubmitting}
                >
                    {t('save')}
                </Button>
            </form>
        </Modal>
    );
};

export default EditMyBankAccountModal;
