import {createPromiseAction} from 'redux-saga-promise-actions';

//Types
import {Errors, Notifications} from '../../types';

export const getNotifications = createPromiseAction('GET_NOTIFICATIONS')<
    undefined,
    Notifications.Notifications,
    Errors.API
>();

export const markAllAsRead = createPromiseAction('MARK_ALL_AS_READ')<
    undefined,
    undefined,
    Errors.API
>();
