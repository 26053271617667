//Hooks
import {usePaymentStepperView} from './PaymentStepperView.hook';

//Components
import {Formik} from 'formik';

//Types
import {RouteComponentProps} from '@reach/router';

const PaymentStepperView: React.FC<RouteComponentProps> = ({children}) => {
    const {initialValues, handleSubmit, validationSchema} = usePaymentStepperView();
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {children}
        </Formik>
    );
};

export default PaymentStepperView;
