//Hooks
import {useState, useCallback, useMemo, useRef, useEffect} from 'react';
import useOnClickOutside from 'use-onclickoutside';
import {useWindowSize} from 'hooks/windowSize';

//Types
import {Categories} from 'store/types';
import {CategoryPickerProps} from './CategoryPicker';

export const useCategoryPicker = ({options, value, onChange}: CategoryPickerProps) => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const pickerRef = useRef(null);
    const {isMobile} = useWindowSize();

    useOnClickOutside(pickerRef, () => setMenuOpen(false));

    const handleClick = () => setMenuOpen(open => !open);

    const handleOptionSelect = (option: Categories.Category) => {
        onChange(option.id);
        setMenuOpen(false);
    };

    const getDisplayChain = useCallback(
        (value: number | null): string[] => {
            const option = options.find(option => option.id === value);
            return !option?.parentId
                ? [option?.name ?? '']
                : [...getDisplayChain(option?.parentId), option.name];
        },
        [options]
    );

    useEffect(() => {
        document.body.style.overflow = isMenuOpen && isMobile ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isMenuOpen, isMobile]);

    const displayValue = useMemo(() => getDisplayChain(value), [value, getDisplayChain]);

    return {
        pickerRef,
        isMenuOpen,
        displayValue,
        handleClick,
        handleOptionSelect
    };
};
