//Hooks
import {useRegisterInfoView} from './RegisterInfoView.hook';
import {Trans, useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Select from 'react-select';
import RadioGroup from 'components/ui/RadioGroup';
import Radio from 'components/ui/Radio';
import Input from 'components/ui/Input';
import DatePicker from 'components/ui/DatePicker';
import Button from 'components/ui/Button';
import Checkbox from 'components/ui/Checkbox';

//Styles
import {styles, errorStyles, theme} from 'styles/select';

//Utils
import dayjs from 'dayjs';

//Types
import {RouteComponentProps} from '@reach/router';
import {User} from 'store/types';

const RegisterInfoView: React.VFC<RouteComponentProps> = () => {
    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        states,
        selectedState
    } = useRegisterInfoView();

    const {t} = useTranslation(['auth', 'common']);

    return (
        <form className={'flex flex-col'} onSubmit={handleSubmit}>
            <RadioGroup
                name={'account.type'}
                value={values.account.type}
                onChange={handleChange}
                className={'flex flex-wrap flex-grow gap-y-8'}
            >
                <Radio
                    id={'person'}
                    value={'person'}
                    label={[t('auth:privateAccount'), t('auth:forIndividualUsers')]}
                />

                <Radio
                    id={'company'}
                    value={'company'}
                    label={[t('auth:businessAccount'), t('auth:forBusinessUsers')]}
                />
            </RadioGroup>

            <h2 className={'mt-10 font-semibold'}>{t('auth:registrationInfo')}</h2>

            <div className={'flex flex-col sm:w-7/12 mt-5'}>
                <Input
                    id={'account.data.username'}
                    name={'account.data.username'}
                    value={values.account.data.username}
                    onChange={handleChange}
                    label={t('auth:username')}
                    error={touched.account?.data?.username && errors.account?.data?.username}
                />

                {values.account.type === User.Type.Person && (
                    <>
                        <Input
                            id={'account.data.firstName'}
                            name={'account.data.firstName'}
                            value={values.account.data.firstName}
                            onChange={handleChange}
                            className={'mt-3.5'}
                            label={t('auth:firstName')}
                            error={
                                touched.account?.data?.firstName &&
                                errors.account?.data?.firstName
                            }
                        />

                        <Input
                            id={'account.data.lastName'}
                            name={'account.data.lastName'}
                            value={values.account.data.lastName}
                            onChange={handleChange}
                            className={'mt-3.5'}
                            label={t('auth:lastName')}
                            error={
                                touched.account?.data?.lastName &&
                                errors.account?.data?.lastName
                            }
                        />
                    </>
                )}

                {values.account.type === User.Type.Company && (
                    <Input
                        id={'account.data.companyName'}
                        name={'account.data.companyName'}
                        value={values.account.data.companyName ?? ''}
                        onChange={handleChange}
                        className={'mt-3.5'}
                        label={t('auth:legalName')}
                        error={
                            touched.account?.data?.companyName &&
                            errors.account?.data?.companyName
                        }
                    />
                )}

                <Input
                    id={'account.data.email'}
                    name={'account.data.email'}
                    value={values.account.data.email}
                    onChange={handleChange}
                    className={'mt-3.5'}
                    label={t('auth:emailAddress')}
                    error={touched.account?.data?.email && errors.account?.data?.email}
                />

                <Input
                    id={'account.data.phone'}
                    name={'account.data.phone'}
                    value={values.account.data.phone}
                    onChange={handleChange}
                    className={'mt-3.5'}
                    label={t('auth:phoneNumber')}
                    error={touched.account?.data?.phone && errors.account?.data?.phone}
                />
            </div>

            <h6 className={'mt-8 text-sm font-semibold'}>{t('auth:address')}</h6>

            <div className={'flex flex-col flex-wrap sm:flex-row gap-x-8'}>
                <Input
                    id={'account.address.street'}
                    name={'account.address.street'}
                    value={values.account.address.street}
                    onChange={handleChange}
                    className={'mt-3.5 sm:w-7/12'}
                    label={t('auth:street')}
                    error={touched.account?.address?.street && errors.account?.address?.street}
                />

                <Input
                    id={'account.address.apartmentNumber'}
                    name={'account.address.apartmentNumber'}
                    value={values.account.address.apartmentNumber}
                    onChange={handleChange}
                    className={'mt-3.5 w-36'}
                    label={t('auth:apartmentNumber')}
                    error={
                        touched.account?.address?.apartmentNumber &&
                        errors.account?.address?.apartmentNumber
                    }
                />
            </div>

            <div className={'flex flex-col sm:w-7/12'}>
                <Input
                    id={'account.address.city'}
                    name={'account.address.city'}
                    value={values.account.address.city}
                    onChange={handleChange}
                    className={'mt-3.5'}
                    label={t('auth:city')}
                    error={touched.account?.address?.city && errors.account?.address?.city}
                />

                <div className={'flex justify-between gap-x-12'}>
                    <div
                        className={'mt-3.5 w-full'}
                        role={'region'}
                        aria-label={'account.address.state'}
                    >
                        <label
                            htmlFor={'account.address.state'}
                            className={'block mb-3 text-sm'}
                        >
                            {t('auth:state')}
                        </label>

                        <Select
                            id={'account.address.state'}
                            name={'account.address.state'}
                            className={'w-full'}
                            options={states}
                            value={selectedState}
                            noOptionsMessage={() => t('common:noOptions')}
                            placeholder={t('common:selectPlaceholderDefault')}
                            onChange={option =>
                                setFieldValue('account.address.state', option?.value)
                            }
                            styles={
                                touched.account?.address?.state &&
                                errors.account?.address?.state
                                    ? errorStyles
                                    : styles
                            }
                            theme={theme}
                        />

                        {touched.account?.address?.state && errors.account?.address?.state && (
                            <span
                                className={'tracking-tight text-red text-form-helper'}
                                role={'alert'}
                                aria-label={'error'}
                            >
                                {errors.account?.address?.state}
                            </span>
                        )}
                    </div>

                    <Input
                        id={'account.address.postCode'}
                        name={'account.address.postCode'}
                        value={values.account.address.postCode}
                        onChange={handleChange}
                        className={'mt-3.5'}
                        label={t('auth:zipCode')}
                        error={
                            touched.account?.address?.postCode &&
                            errors.account?.address?.postCode
                        }
                    />
                </div>

                {values.account.type === User.Type.Person ? (
                    <DatePicker
                        id={'account.data.birthDay'}
                        name={'account.data.birthDay'}
                        value={
                            values.account.data.birthDay
                                ? dayjs(values.account.data.birthDay).toDate()
                                : undefined
                        }
                        onChange={(_, value) =>
                            setFieldValue(
                                'account.data.birthDay',
                                dayjs(value as Date).format('YYYY-MM-DD')
                            )
                        }
                        className={'mt-3.5'}
                        label={t('auth:dateOfBirth')}
                        error={
                            touched.account?.data?.birthDay && errors.account?.data?.birthDay
                        }
                    />
                ) : (
                    <Input
                        id={'account.data.vatId'}
                        name={'account.data.vatId'}
                        value={values.account.data.vatId ?? ''}
                        onChange={handleChange}
                        className={'mt-3.5'}
                        label={t('auth:firmTaxNumber')}
                        error={touched.account?.data?.vatId && errors.account?.data?.vatId}
                    />
                )}

                <Input
                    id={'account.data.password'}
                    name={'account.data.password'}
                    value={values.account.data.password}
                    onChange={handleChange}
                    className={'mt-3.5'}
                    label={t('auth:password')}
                    type={'password'}
                    error={touched.account?.data?.password && errors.account?.data?.password}
                />
            </div>

            <h2 className={'mt-10 font-semibold'}>{t('auth:consent')}</h2>

            <div className={'flex flex-col w-11/12 mt-5 gap-y-3'}>
                <Checkbox
                    required
                    labelClassName={'w-10/12 text-xs-checkbox'}
                    label={
                        <Trans
                            i18nKey={'auth:checkbox1'}
                            components={{
                                a: (
                                    <Link
                                        className={'text-secondary'}
                                        to={'/info/terms-and-conditions'}
                                    />
                                )
                            }}
                        />
                    }
                    id={'checkbox1'}
                    name={'checkbox1'}
                    value={values.checkbox1.toString()}
                    checked={!!values.checkbox1}
                    onChange={handleChange}
                />

                <Checkbox
                    required
                    labelClassName={'w-10/12 text-xs-checkbox'}
                    label={
                        <Trans
                            i18nKey={'auth:checkbox2'}
                            components={{
                                a: (
                                    <Link
                                        className={'text-secondary'}
                                        to={'/info/seller-services-agreement'}
                                    />
                                )
                            }}
                        />
                    }
                    id={'checkbox2'}
                    name={'checkbox2'}
                    value={values.checkbox2.toString()}
                    checked={!!values.checkbox2}
                    onChange={handleChange}
                />

                <Checkbox
                    required
                    labelClassName={'w-10/12 text-xs-checkbox'}
                    label={
                        <Trans
                            i18nKey={'auth:checkbox3'}
                            components={{
                                a: (
                                    <Link
                                        className={'text-secondary'}
                                        to={'/info/privacy-policy'}
                                    />
                                )
                            }}
                        />
                    }
                    id={'checkbox3'}
                    name={'checkbox3'}
                    value={values.checkbox3.toString()}
                    checked={!!values.checkbox3}
                    onChange={handleChange}
                />

                <Checkbox
                    required
                    labelClassName={'w-10/12 text-xs-checkbox'}
                    label={t('auth:checkbox4')}
                    id={'checkbox4'}
                    name={'checkbox4'}
                    value={values.checkbox4.toString()}
                    checked={!!values.checkbox4}
                    onChange={handleChange}
                />
            </div>

            <div className={'mt-6 text-gray-400 text-xs-checkbox'}>
                <span className={'text-secondary'}>*</span>

                {t('auth:obligatory')}
            </div>

            <Button
                className={'self-center mt-12 w-full max-w-88'}
                variant={'primary'}
                type={'submit'}
                disabled={
                    !values.checkbox1 ||
                    !values.checkbox2 ||
                    !values.checkbox3 ||
                    !values.checkbox4
                }
            >
                {t('auth:createAccount')}
            </Button>
        </form>
    );
};

export default RegisterInfoView;
