import * as React from 'react';

//Components
import CounterButton from './CounterButton';

//Utils
import cx from 'classnames';

export type CounterProps = {
    size?: 'md';
    counter: number;
    maxQuantity?: number;
    className?: string;
    onIncrease: () => void;
    onDecrease: () => void;
};

const Counter: React.VFC<CounterProps> = ({
    maxQuantity,
    className,
    onIncrease,
    onDecrease,
    counter,
    size = 'md'
}) => {
    return (
        <div className={cx('flex flex-row items-center', className)}>
            <CounterButton
                disabled={counter === maxQuantity}
                size={size}
                label={'+'}
                onClick={onIncrease}
            />

            <label role={'heading'} aria-level={5} className={'mx-5 w-5 text-center'}>
                {counter}
            </label>

            <CounterButton
                size={size}
                disabled={counter === 1}
                label={'-'}
                onClick={onDecrease}
            />
        </div>
    );
};

export default Counter;
