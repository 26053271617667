//Redux saga
import {all, call, put, takeLatest, takeLeading} from 'redux-saga/effects';

//Actions
import * as actions from 'store/actions';

//Utils
import {getLocalStorageData} from 'utils/localStorage';
import toast from 'react-hot-toast';
import _ from 'lodash-es';

//Types
import {Offer} from 'store/types';
import {fetchOfferById} from '../offer';

export function* getBasketOffers(action: ReturnType<typeof actions.getBasketOffers.request>) {
    let basketData = getLocalStorageData<Offer.BasketData[]>('basket') ?? [];
    const allData: (Offer.OfferDetailed | Offer.ErrorBasketOffer)[] =
        basketData.length > 0
            ? yield all(basketData.map(idx => call(fetchOfferById, idx.id.toString(), true)))
            : [];

    if (allData.some(order => order.hasOwnProperty('id'))) {
        const outDatedOffers = (allData.filter(order =>
            order.hasOwnProperty('id')
        ) as Offer.ErrorBasketOffer[]).map(order => Number(order.id));

        basketData = _.remove(basketData, (val, idx) => {
            return !outDatedOffers.includes(val.id);
        });
        localStorage.setItem('basket', JSON.stringify(basketData));
        toast.error('Some offers have been removed, because they were out of date');
    }

    const availableOffers: Offer.OfferDetailed[] = allData.filter(
        offer => !offer.hasOwnProperty('id')
    ) as Offer.OfferDetailed[];

    const listedOffer: Offer.ProductListItem[] = availableOffers.map((offer, key) => ({
        ...offer?.offer,
        quantity: basketData[key].quantity
    })) as Offer.ProductListItem[];
    yield put(actions.getBasketOffers.success(listedOffer));
}

export function* addOfferToBasket(action: ReturnType<typeof actions.addOfferToBasket.request>) {
    const basketData = _.uniq([
        ...(getLocalStorageData<Offer.BasketData[]>('basket') ?? []),
        {id: action.payload.id, quantity: action.payload.quantity}
    ]);

    localStorage.setItem('basket', JSON.stringify(basketData));

    yield put(actions.addOfferToBasket.success(action.payload));
}

export function* removeBasketOffer(
    action: ReturnType<typeof actions.removeBasketOffer.request>
) {
    let basketData = getLocalStorageData<Offer.BasketData[]>('basket');

    basketData = basketData?.filter(item => item.id !== action.payload) ?? [];

    localStorage.setItem('basket', JSON.stringify(basketData));

    yield put(actions.removeBasketOffer.success(action.payload));
}

export function* clearBasket(action: ReturnType<typeof actions.clearBasket>) {
    yield localStorage.removeItem('basket');
}

export const saga = [
    takeLeading(actions.getBasketOffers.request, getBasketOffers),
    takeLatest(actions.addOfferToBasket.request, addOfferToBasket),
    takeLatest(actions.removeBasketOffer.request, removeBasketOffer),
    takeLatest([actions.clearBasket, actions.logout.success], clearBasket)
];
