//Hooks
import {useTranslation} from 'react-i18next';
import {useSelector} from 'hooks/store';
import {useMemo} from 'react';

//Components
import {Link} from '@reach/router';
import Button from 'components/ui/Button';

//Types
import {RouteComponentProps} from '@reach/router';

const ForgotPasswordSuccessView: React.FC<RouteComponentProps> = () => {
    const {t} = useTranslation(['auth', 'common']);
    const requestResetBy = useSelector(state => state.auth.requestResetBy);
    const translatedRequestedBy = useMemo(() => {
        return requestResetBy ? t(`common:${requestResetBy}` as const) : '';
    }, [requestResetBy, t]);

    return (
        <div className={'px-8 pt-32 flex flex-col items-center md:h-116.5 h-full'}>
            <h2>{t('auth:checkYourMailbox', {by: translatedRequestedBy})}</h2>

            <span
                className={'flex-grow mt-6 text-xs tracking-tighter text-center text-gray-900'}
            >
                {t('auth:checkYourMailboxDescription')}
            </span>

            <Button
                as={Link}
                to={'/auth/login'}
                className={'md:mb-22 mb-18'}
                variant={'primary'}
                size={'sm'}
            >
                {t('auth:backToLogin')}
            </Button>
        </div>
    );
};

export default ForgotPasswordSuccessView;
