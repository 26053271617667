import {navigate} from '@reach/router';

//Hooks
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

export const useMessagesView = () => {
    const {data: conversations, isLoading: areConversationsLoading} = useSelector(
        state => state.conversations.conversations
    );

    const dispatch = useDispatch();

    const getConversation = (id: number) => {
        navigate(`/chat/${id}`);
    };

    const onPageChange = (page: number) => dispatch(actions.getConversations.request({page}));

    const contactAdmin = async () => {
        const resp = await dispatch(actions.contactAdmin.request());
        navigate(`/chat/${resp.conversation.id}`);
    };

    useEffect(() => {
        dispatch(actions.getConversations.request({page: 1}));
        dispatch(actions.clearConversation());
    }, [dispatch]);

    return {
        conversations,
        getConversation,
        areConversationsLoading,
        onPageChange,
        contactAdmin
    };
};
