//Action creators
import {createPromiseAction} from 'redux-saga-promise-actions';

//Types
import {Errors} from '../../types';
import {Constants} from 'store/types';

export const getConstants = createPromiseAction('GET_CONSTANTS')<
    undefined,
    Constants.ConstantResponse,
    Errors.API
>();
