import * as React from 'react';

//Hooks
import {useState, useEffect, useCallback} from 'react';

//Types
import {AdsCarouselProps} from './AdsCarouselView';

export const useAdsCarouselView = ({
    children,
    intervalSeconds
}: Pick<AdsCarouselProps, 'children' | 'intervalSeconds'>) => {
    const [activeImageIndex, setActiveImageIndex] = useState(0);

    const handleNextImageChange = useCallback(() => {
        activeImageIndex === React.Children.count(children) - 1
            ? setActiveImageIndex(0)
            : setActiveImageIndex(prevState => prevState + 1);
    }, [children, setActiveImageIndex, activeImageIndex]);

    useEffect(() => {
        const moveCarousel = setInterval(handleNextImageChange, intervalSeconds * 1000);
        return () => {
            clearInterval(moveCarousel);
        };
    }, [handleNextImageChange, intervalSeconds]);

    return {activeImageIndex};
};
