//Hooks
import {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'hooks/store';

//Actions
import * as actions from 'store/actions';

//Types
import {Order} from 'store/types';

type useMyPurchaseDetailsProps = {
    orderId?: number;
};

export const useMyPurchaseDetails = ({orderId}: useMyPurchaseDetailsProps) => {
    const {data: order, isLoading} = useSelector(state => state.myPurchases.orderDetails);
    const [isDisputeOrderModalOpen, setDisputeOrderModalOpen] = useState(false);

    const dispatch = useDispatch();

    const [isCreateReviewModalVisible, setCreateReviewModalVisible] = useState(false);
    const [reviewedProduct, setReviewedProduct] = useState<Order.Product | null>(null);

    useEffect(() => {
        orderId && dispatch(actions.getMyBoughtOrderById.request(orderId));
    }, [dispatch, orderId]);

    const openDisputeOrderModal = useCallback(() => {
        setDisputeOrderModalOpen(true);
    }, [setDisputeOrderModalOpen]);

    const closeDisputeOrderModal = useCallback(() => {
        setDisputeOrderModalOpen(false);
    }, [setDisputeOrderModalOpen]);

    const openCreateReviewModal = (product: Order.Product) => () => {
        setReviewedProduct(product);
        setCreateReviewModalVisible(true);
    };

    const closeCreateReviewModal = () => setCreateReviewModalVisible(false);

    return {
        isLoading,
        order,
        reviewedProduct,
        isCreateReviewModalVisible,
        openCreateReviewModal,
        closeCreateReviewModal,
        closeDisputeOrderModal,
        openDisputeOrderModal,
        isDisputeOrderModalOpen
    };
};
