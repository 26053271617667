//Types
import {Categories, Files, Reviews, User} from 'store/types';
import {MoneySerialized, MoneyType} from 'store/types/offer/money';

export enum Type {
    Product = 'product',
    Service = 'service',
    Job = 'job_offer',
    RealEstate = 'real_estate'
}

export enum Status {
    Draft = 'draft',
    Published = 'published',
    Closed = 'closed'
}

export enum PriceVariant {
    Fixed = 'fixed',
    Range = 'range'
}

export enum Period {
    Hourly = 'hourly',
    Monthly = 'monthly'
}

export enum DeliveryMethod {
    Courier = 'courier',
    Pickup = 'pickup'
}

export type Image = {
    id: number;
    name: string;
    small: string;
    medium?: string;
    big?: string;
};

export interface Address extends User.Address {
    geoCoordinate?: {
        lat: number;
        lon: number;
    };
}

export interface BaseListItem {
    id: number;
    title: string;
    categoryId: number | undefined | null;
    status: Status;
    images: Image[];
    type: Type;
    createdAt: Date;
    expiredAt: Date | undefined | null;
    closedAt: Date | undefined | null;
    description?: string;
    account?: User.User;
    attributes?: {[key: string]: any};
    category?: {id: number; path: number[]; jsonSchema?: any};
    features?: {highlight?: boolean; extraPhotos: boolean};
    address?:
        | (User.Address & {
              geoCoordinate: {
                  lat: string;
                  lon: string;
              };
          })
        | null;
    visits?: number;
}

export interface ProductListItem extends BaseListItem {
    type: Type.Product;
    price: MoneySerialized;
    quantity?: number;
    delivery?: {
        method: DeliveryMethod;
        price: MoneySerialized;
    };
}

export interface RealEstateListItem extends BaseListItem {
    type: Type.RealEstate;
    price: MoneySerialized;
    quantity?: number;
}

export interface ServiceListItem extends BaseListItem {
    type: Type.Service;
    priceFrom: MoneySerialized;
    priceTo: MoneySerialized | null;
    period: Period | null;
}
export interface JobListItem extends BaseListItem {
    type: Type.Job;
    showSalary: boolean;
    salaryFrom: MoneySerialized;
    salaryTo: MoneySerialized | null;
    period: Period | null;
}

export interface RemovedItem {
    type: Type;
    title: string;
    id: null;
    visits?: number;
}

export type ListItem = JobListItem | ServiceListItem | ProductListItem | RealEstateListItem;

export interface ProductOfferDetails extends ProductListItem {
    delivery?: {
        method: DeliveryMethod;
        price: MoneySerialized;
    };
}

export interface ServiceOfferDetails extends ServiceListItem {
    delivery?: undefined;
}

export interface JobOfferDetails extends JobListItem {
    delivery?: undefined;
}

export interface RealEstateOfferDetails extends RealEstateListItem {
    delivery?: undefined;
}

export type OfferDetails =
    | ProductOfferDetails
    | ServiceOfferDetails
    | JobOfferDetails
    | RealEstateOfferDetails;

export type OfferDetailed = {
    offer: OfferDetails;
    stats: {
        offers: number;
        reviewsCount: number;
        reviewsAverage?: number;
        leadReviews: Reviews.Review[];
    };
    buyable: boolean;
};

interface BaseCreate {
    title: string;
    description: string;
    category: Categories.Category | null;
    status: Status;
    images: Files.UploadedPhoto[];
    attributes?: {[key: string]: any};
}

export interface ProductCreate {
    type: Type.Product;
    offer: BaseCreate & {
        price: MoneyType;
        quantity: number;
        delivery: {
            method: DeliveryMethod | null;
            price: MoneyType;
        };
    };
    address?: Address;
}
export interface RealEstateCreate {
    type: Type.RealEstate;
    offer: BaseCreate & {
        price: MoneyType;
        quantity: number;
    };
    address?: Address;
}
export interface ServiceCreate {
    type: Type.Service;
    offer: BaseCreate & {
        priceFrom: MoneyType;
        priceTo: MoneyType;
        period: Period | null;
    };
    address?: Address;
}
export interface JobCreate {
    type: Type.Job;
    offer: BaseCreate & {
        period: Period | null;
        showSalary: boolean;
        salaryFrom: MoneyType;
        salaryTo: MoneyType;
    };
    address?: Address;
}

export type Form = {
    type: Type;
    offer: ProductCreate['offer'] &
        RealEstateCreate['offer'] &
        JobCreate['offer'] &
        ServiceCreate['offer'] & {priceVariant?: PriceVariant};
    hideLocation: boolean;
    lastCheckedAddress: string;
    address?: Address;
};
