//Hooks
import {useNavigate} from '@reach/router';
import {useDispatch, useSelector} from 'hooks/store';
import {useEffect} from 'react';

//Actions
import * as actions from 'store/actions';

export const usePaymentSuccessView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const paymentResp = useSelector(state => state.payment?.payment);

    useEffect(() => {
        if (!paymentResp) {
            navigate('/');
            return;
        }

        if (!!paymentResp?.basket?.products?.length) {
            paymentResp.basket?.products.forEach(product => {
                dispatch(actions.removeBasketOffer.request(product.product));
            });
        }
    }, [paymentResp, navigate, dispatch]);

    return {orderId: paymentResp?.order?.orderId};
};
