//Hooks
import {useFormikContext} from 'formik';

//Types
import {Offer} from 'store/types';

export const useJobForm = () => {
    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue
    } = useFormikContext<Offer.Form>();

    const toggleShowSalary = () => {
        setFieldValue('offer.showSalary', !values.offer.showSalary);
    };

    return {
        values,
        errors,
        touched,
        handleChange,
        toggleShowSalary,
        setFieldValue
    };
};
