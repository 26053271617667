//Hooks
import {useAdverts} from 'hooks/adverts';

//Components
import Skeleton from 'react-loading-skeleton';
import AdsCarouselView, {Ad} from 'components/images/AdsCarouselView';

//Images
import bannerImage from 'assets/files/banner.jpg';

//Types
import {Adverts} from 'store/types';

const Banner: React.VFC = () => {
    const {data: allAdverts, isLoaded: areAdvertsLoaded} = useAdverts();
    const adverts = allAdverts?.filter(ad => ad.area === Adverts.HomeArea.WebTop);

    if (!areAdvertsLoaded) {
        return (
            <div className={'hidden md:block'} role={'region'} aria-label={'banner loading'}>
                <Skeleton height={240} />
            </div>
        );
    }

    if (!!adverts?.length) {
        return (
            <AdsCarouselView
                intervalSeconds={30}
                className={'h-60 hidden md:flex'}
                role={'region'}
                aria-label={'advertisements banner'}
            >
                {adverts.map((ad, i) => (
                    <Ad href={ad.referenceUrl} key={i} src={ad.imageUrl} />
                ))}
            </AdsCarouselView>
        );
    }

    return (
        <div className={'relative w-full overflow-hidden hidden md:block h-60'}>
            <img src={bannerImage} alt={'banner'} className={'absolute w-full h-100 -top-10'} />
        </div>
    );
};

export default Banner;
