import * as React from 'react';

//Components
import {Link} from '@reach/router';

//Styles
import Styles from './Tabs.module.scss';

//Utils
import cx from 'classnames';

//Types
import {LinkProps} from '@reach/router';

type TabsChild = React.ReactElement<LinkProps<any>, typeof Link>;

export type TabsProps = {
    className?: string;
    children: TabsChild | TabsChild[];
};

const Tabs: React.VFC<TabsProps> = ({className, children}) => (
    <div className={cx('flex items-stretch', className)} role={'tablist'}>
        {React.Children.map(children, (child, i) =>
            React.cloneElement(
                child,
                {
                    ...child.props,
                    className: cx(
                        'flex flex-grow justify-center py-2.5 border-b border-gray-200 text-tab',
                        Styles.link,
                        child.props.className
                    ),
                    role: 'tab'
                },
                child.props.children
            )
        )}
    </div>
);

export default Tabs;
