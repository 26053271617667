//Components
import Icon from 'components/ui/Icon';

type MarkerProps = {
    lat: number;
    lng: number;
};

const Marker: React.VFC<MarkerProps> = ({lat, lng}) => (
    <Icon
        icon={'map-indicator'}
        className={'text-secondary transform -translate-y-full -translate-x-2/4'}
        lat={lat}
        lng={lng}
    />
);

export default Marker;
