//Hooks
import {useCallback} from 'react';

export const usePWAPromptWrapper = () => {
    const wasPWAPromptShown = localStorage.getItem('wasPWAPromptShown') === 'true';

    const isiOS = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    const isiPadOS = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    const isStandalone =
        'standalone' in window.navigator && (window.navigator as any).standalone;

    const isPWAPromptVisible = (isiOS || isiPadOS) && !isStandalone && !wasPWAPromptShown;

    const handleClose = useCallback(() => {
        if (!isPWAPromptVisible) {
            return;
        }

        localStorage.setItem('wasPWAPromptShown', 'true');
    }, [isPWAPromptVisible]);

    return {isPWAPromptVisible, handleClose};
};
