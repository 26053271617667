//Types
import {User} from 'store/types';
import {Credentials} from './credentials';

export enum AuthType {
    Email = 'email',
    SMS = 'sms'
}

interface BaseRegisterInfo {
    checkbox1: boolean;
    checkbox2: boolean;
    checkbox3: boolean;
    checkbox4: boolean;
    authType: AuthType;
}

export interface PersonRegisterInfo extends BaseRegisterInfo {
    account: {
        type: User.Type.Person;
        data: Omit<User.Person & Credentials, 'type' | 'address' | 'payout' | 'avatar'> & {
            companyName?: undefined;
            vatId?: undefined;
        };
        address: User.Address;
    };
}

export interface CompanyRegisterInfo extends BaseRegisterInfo {
    account: {
        type: User.Type.Company;
        data: Omit<User.Company & Credentials, 'type' | 'address' | 'payout' | 'avatar'> & {
            firstName?: undefined;
            lastName?: undefined;
            birthDay?: undefined;
        };
        address: User.Address;
    };
}

export type RegisterInfo = PersonRegisterInfo | CompanyRegisterInfo;

export interface RegisterInfoFormValues {
    account: {
        type: RegisterInfo['account']['type'];
        data: RegisterInfo['account']['data'];
        address: RegisterInfo['account']['address'];
    };
    checkbox1: boolean;
    checkbox2: boolean;
    checkbox3: boolean;
    checkbox4: boolean;
}

export interface RegisterUUID {
    uuid: string;
}

export interface ConfirmRequest {
    code: string;
    uuid: string;
}

export type ConfirmFormValues = Omit<ConfirmRequest, 'uuid'>;
