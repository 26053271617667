/* eslint-disable react/jsx-newline */
//Hooks
import {usePaymentFormView} from 'hooks/payment';

// Components
import PaymentMethodInput, {
    PaymentButton
} from 'components/ui/PaymentMethodInput/PaymentMethodInput';
import {useTranslation} from 'react-i18next';

const PaymentMethodPicker: React.VFC = () => {
    const {values, handleChange} = usePaymentFormView();
    const {t} = useTranslation('payment');
    return (
        <div
            className={
                'py-5 px-2 md:px-4 bg-white w-full flex flex-col border-gray-300 border-1.5 lg:border-none'
            }
        >
            <h3 className={'px-2 mb-2'}>{t('paymentMethod')}</h3>

            <PaymentMethodInput value={values.method} onChange={handleChange} name={'method'}>
                <PaymentButton id={'credit-card'} icon={'credit-card'} value={'credit-card'}>
                    {t('creditCard')}
                </PaymentButton>
            </PaymentMethodInput>
        </div>
    );
};

export default PaymentMethodPicker;
