//Hooks
import {useToaster as useReactHotToast} from 'react-hot-toast';

export const useToaster = () => {
    const {toasts, handlers} = useReactHotToast();
    const {startPause, endPause} = handlers;

    return {
        toasts,
        handleMouseEnter: startPause,
        handleMouseLeave: endPause
    };
};
