//Hooks
import {useFileUploader} from './FileUploader.hook';
import {useTranslation} from 'react-i18next';

//Components
import Dropzone from 'components/ui/FileUploader/Dropzone';
import PhotoPreview from 'components/ui/FileUploader/PhotoPreview';
import Icon from 'components/ui/Icon';

//Utils
import cx from 'classnames';

//Types
import {UploadedPhoto} from 'store/types/files';

export type FileUploaderProps = {
    className?: string;
    multiple?: boolean;
    maxFiles?: number;
    value: UploadedPhoto[];
    onChange: (photo: UploadedPhoto[]) => void;
    name?: string;
    children?: React.ReactNode;
};

const FileUploader: React.FC<FileUploaderProps> = ({
    className,
    multiple,
    maxFiles,
    value,
    children,
    onChange,
    name
}) => {
    const {t} = useTranslation('common');
    const {onFileUpload, onFileDelete} = useFileUploader(value, onChange);

    return (
        <div
            className={cx({'grid grid-cols-3 gap-5': !!value.length}, className)}
            role={'region'}
            aria-label={name}
        >
            {(!maxFiles || value.length < maxFiles) && (
                <Dropzone
                    className={cx('flex justify-center py-12 border border-gray-300 rounded', {
                        'py-16': !!value.length
                    })}
                    label={
                        <span className={'flex flex-row items-center'}>
                            <Icon size={'md'} icon={'camera'} />

                            <span className={'mx-1'}>{t('addPhoto')}</span>
                        </span>
                    }
                    onFileUpload={onFileUpload}
                    multiple={multiple}
                    maxFiles={maxFiles ? maxFiles - value.length : 0}
                />
            )}

            {value.map(photo => (
                <PhotoPreview
                    key={photo.fileId}
                    photo={photo}
                    onPhotoDelete={() => onFileDelete(photo)}
                    className={'h-42'}
                />
            ))}

            {children!!}
        </div>
    );
};

export default FileUploader;
