//Hooks
import {useSearchView} from './SearchView.hook';
import {useTranslation} from 'react-i18next';

//Components
import Skeleton from 'react-loading-skeleton';
import SearchForm from 'components/search/SearchForm';
import Breadcrumb from 'components/nav/Breadcrumb';
import FiltersForm from 'components/search/FiltersForm';
import OfferView from 'components/offer/OfferView';
import PaginationView from 'components/ui/PaginationView';
import RadioGroup from 'components/ui/RadioGroup';
import Radio from 'components/ui/Radio';
import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';
import AdsCarouselView, {Ad} from 'components/images/AdsCarouselView';
import Banner from 'components/images/Banner';
import Footer from 'components/nav/Footer';
import Navbar from 'components/nav/Navbar';
import MobileBottomNav from 'components/nav/MobileBottomNav';
import Select from 'react-select';
import PopularSearches from 'components/nav/PopularSearches';

//Styles
import {styles, theme} from 'styles/select';

//Utils
import _ from 'lodash-es';
import cx from 'classnames';

//Types
import {RouteComponentProps} from '@reach/router';

const SearchView: React.VFC<RouteComponentProps> = () => {
    const {
        categoriesTree,
        category,
        result,
        isResultLoading,
        sortOptions,
        sorting,
        setSorting,
        isFilterOpen,
        setFilterOpen,
        isSortOpen,
        setSortOpen,
        mobileSortConfirm,
        handlePageChange,
        advertsMobile,
        advertsDesktop,
        areAdvertsLoaded,
        ref,
        pageFromSearch
    } = useSearchView();

    const {t} = useTranslation(['common', 'search']);

    return (
        <>
            <div className={'flex flex-col flex-grow pb-10 bg-gray-200'}>
                <SearchForm />

                <Banner />

                <div className={'w-full max-w-292 mx-auto md:mt-12 lg:px-4'}>
                    {categoriesTree && (
                        <Breadcrumb
                            className={'hidden md:flex px-4 lg:px-0'}
                            aria-label={'Categories tree'}
                        >
                            {categoriesTree.map((category, i) => (
                                <span key={i}>{category.name}</span>
                            ))}
                        </Breadcrumb>
                    )}

                    <div className={'flex md:mt-12 gap-14'}>
                        <div
                            className={cx('lg:w-5/12 lg:max-w-5/12 w-full lg:mt-6', {
                                'fixed top-0 h-full overflow-y-auto bg-gray-200 z-10': isFilterOpen,
                                'hidden lg:block': !isFilterOpen
                            })}
                        >
                            <div className={'lg:hidden'}>
                                <Navbar onBack={() => setFilterOpen(false)} />
                            </div>

                            <h1 className={'p-3 lg:p-0 text-xl lg:text-2xl'}>
                                {t('search:filters')}
                            </h1>

                            <FiltersForm
                                className={'md:mt-12'}
                                category={category}
                                sorting={sorting}
                                onSubmit={() => setFilterOpen(false)}
                            />

                            {areAdvertsLoaded ? (
                                !!advertsDesktop?.length && (
                                    <AdsCarouselView
                                        intervalSeconds={30}
                                        className={'h-180 pt-8 hidden md:flex'}
                                        role={'region'}
                                        aria-label={'desktop advertisements'}
                                    >
                                        {advertsDesktop.map((ad, i) => (
                                            <Ad
                                                href={ad.referenceUrl}
                                                key={i}
                                                src={ad.imageUrl}
                                            />
                                        ))}
                                    </AdsCarouselView>
                                )
                            ) : (
                                <div className={'pt-8 hidden md:block w-full'}>
                                    <Skeleton height={720} />
                                </div>
                            )}
                        </div>

                        <div className={'w-full lg:w-7/12 min-w-7/12'}>
                            <div className={'block lg:hidden'}>
                                {areAdvertsLoaded ? (
                                    !!advertsMobile?.length && (
                                        <AdsCarouselView
                                            intervalSeconds={30}
                                            className={'h-25'}
                                            role={'region'}
                                            aria-label={'mobile advertisements'}
                                        >
                                            {advertsMobile.map((ad, i) => (
                                                <Ad
                                                    href={ad.referenceUrl}
                                                    key={i}
                                                    src={ad.imageUrl}
                                                />
                                            ))}
                                        </AdsCarouselView>
                                    )
                                ) : (
                                    <div>
                                        <Skeleton height={100} />
                                    </div>
                                )}

                                <div className={'flex justify-end p-4 gap-x-3'}>
                                    <button
                                        className={
                                            'bg-white flex items-center gap-x-2 px-4 py-2 rounded-full border-gray-300 border text-sm font-semibold tracking-medium-tight'
                                        }
                                        onClick={() => setFilterOpen(true)}
                                    >
                                        <Icon icon={'filter'} />

                                        {t('common:filters')}
                                    </button>

                                    <button
                                        className={
                                            'bg-white flex items-center gap-x-2 px-4 py-2 rounded-full border-gray-300 border text-sm font-semibold tracking-medium-tight'
                                        }
                                        onClick={() => setSortOpen(true)}
                                    >
                                        <Icon icon={'sort'} />

                                        {t('common:sort')}
                                    </button>
                                </div>
                            </div>

                            <div
                                className={'flex justify-between items-end h-12.5 px-4 lg:px-0'}
                            >
                                <div ref={ref}>
                                    <h1 className={'inline-block mr-4 text-xl md:text-2xl'}>
                                        {t('search:results')}
                                    </h1>

                                    <span className={'text-sm text-gray-500'}>
                                        {t('common:offersCount', {
                                            count: result?.pagination.totalItems ?? 0
                                        })}
                                    </span>
                                </div>

                                <div
                                    className={'flex flex-row flex-grow justify-end pl-6'}
                                    role={'region'}
                                    aria-label={'Results navigation'}
                                >
                                    {!!result?.pagination.totalItems && (
                                        <>
                                            <Select
                                                options={sortOptions}
                                                className={
                                                    'bg-white w-full max-w-50 hidden lg:flex '
                                                }
                                                styles={styles}
                                                theme={theme}
                                                placeholder={t('common:sort')}
                                                onChange={opt => setSorting(opt?.value)}
                                                value={sortOptions.find(opt =>
                                                    _.isEqual(opt.value, sorting)
                                                )}
                                            />

                                            <div className={'hidden md:flex'}>
                                                <PaginationView
                                                    currentPage={pageFromSearch}
                                                    totalPages={result?.pagination.totalPages!}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className={'mt-6 md:mt-12 flex flex-col gap-y-3'}>
                                {isResultLoading
                                    ? _.times(4, i => (
                                          <OfferView key={i} variant={'home'} loading />
                                      ))
                                    : result?.items.map(offer => (
                                          <OfferView
                                              key={offer.id}
                                              offer={offer}
                                              variant={'home'}
                                          />
                                      ))}

                                <div className={'self-end mt-9'}>
                                    {!!result?.pagination.totalItems && (
                                        <PaginationView
                                            currentPage={pageFromSearch}
                                            totalPages={result?.pagination.totalPages!}
                                            onPageChange={handlePageChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isSortOpen && (
                <div
                    className={'h-full w-full fixed top-0 bg-gray-200 flex flex-col'}
                    role={'region'}
                    aria-label={'Sorting screen'}
                >
                    <Navbar onBack={() => setSortOpen(false)} />

                    <div className={'px-4 py-3 flex-grow flex flex-col justify-between'}>
                        <div>
                            <h2>{t('common:sort')}</h2>

                            <RadioGroup
                                value={JSON.stringify(sorting)}
                                onChange={e => setSorting(JSON.parse(e.currentTarget.value))}
                                direction={'vertical'}
                                className={'mt-12'}
                            >
                                {sortOptions.map((option, i) => (
                                    <Radio
                                        key={i}
                                        label={option.label}
                                        value={JSON.stringify(option.value)}
                                        id={`sort-option-${i}`}
                                    />
                                ))}
                            </RadioGroup>
                        </div>

                        <Button
                            variant={'primary'}
                            size={'sm'}
                            className={'self-center my-12'}
                            onClick={mobileSortConfirm}
                        >
                            {t('common:sort')}
                        </Button>
                    </div>
                </div>
            )}

            <PopularSearches />

            <Footer />

            <MobileBottomNav />
        </>
    );
};

export default SearchView;
