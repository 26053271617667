//Hooks
import {useOfferView} from './OfferView.hook';
import {useWindowSize} from 'hooks/windowSize';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import FavoriteButton from 'components/ui/FavoriteButton';
import Tag from 'components/ui/Tag';
import Button from 'components/ui/Button';
import Badge from 'components/ui/Badge';
import LoadingWrapperFactory from 'components/wrappers/LoadingWrapperFactory/LoadingWrapperFactory';
import Icon from 'components/ui/Icon';

//Images
import defaultPhoto from 'assets/files/defaultOffer.jpg';

//Utils
import cx from 'classnames';

//Styles
import Styles from './OfferView.module.scss';

//Types
import {Offer, Order} from 'store/types';

export type Variant = 'home' | 'profile';

export type OfferViewProps = {
    className?: string;
    variant: Variant;
    size?: 'sm' | 'md';
    offer: Offer.ListItem | Offer.RemovedItem;
    onPublish?: () => void;
    onPromote?: () => void;
    onStopPublishing?: () => void;
    order?: undefined;
    product?: undefined;
    isBuyer?: undefined;
    loadingOrder?: undefined;
    loading?: undefined;
    showDetails?: undefined;
    onConfirm?: undefined;
    onCancel?: undefined;
    onComplete?: undefined;
    onReview?: undefined;
};

export type OrderViewProps = {
    className?: string;
    variant?: 'profile';
    size?: 'md' | 'sm';
    order: Order.Base;
    isBuyer?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    onPromote?: undefined;
    onComplete?: () => void;
    loading?: undefined;
    loadingOrder?: undefined;
    offer?: undefined;
    product?: undefined;
    onPublish?: undefined;
    onStopPublishing?: undefined;
    showDetails?: boolean;
    onReview?: undefined;
};

export type ProductViewProps = {
    className?: string;
    variant?: 'profile';
    size?: 'md' | 'sm';
    product: Order.Product;
    isBuyer?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    onComplete?: () => void;
    onPromote?: undefined;
    loading?: undefined;
    loadingOrder?: undefined;
    offer?: undefined;
    order?: undefined;
    onPublish?: undefined;
    onStopPublishing?: undefined;
    showDetails?: boolean;
    onReview?: () => void;
};

export type LoadingOfferViewProps = {
    className?: string;
    variant: Variant;
    size?: 'sm' | 'md';
    loading?: true;
    loadingOrder?: boolean;
    offer?: undefined;
    order?: undefined;
    product?: undefined;
    isBuyer?: undefined;
    onPublish?: undefined;
    onPromote?: undefined;
    onStopPublishing?: undefined;
    showDetails?: undefined;
    onConfirm?: undefined;
    onCancel?: undefined;
    onComplete?: undefined;
    onReview?: undefined;
};

const OfferView: React.VFC<
    OfferViewProps | OrderViewProps | ProductViewProps | LoadingOfferViewProps
> = ({
    className,
    variant,
    offer,
    order,
    product,
    size = 'md',
    isBuyer,
    loading,
    loadingOrder,
    onPublish,
    onPromote,
    onStopPublishing,
    showDetails = true,
    onConfirm,
    onCancel,
    onComplete,
    onReview
}) => {
    const {
        displayPrice,
        displayBadge,
        tags,
        mobileTags,
        onFavoriteToggle,
        isFavorite,
        isFavoriteDisabled,
        linkToDetails
    } = useOfferView({
        variant,
        offer,
        order,
        product,
        isBuyer
    });

    const status = offer && !!offer?.id ? offer.status : order?.status;
    const {isMobile, breakpoint, width} = useWindowSize();

    const {t} = useTranslation(['offers', 'orders', 'reviews']);

    return (
        <div className={cx(className, 'flex flex-col items-center bg-white cursor-hover')}>
            <div
                className={cx(
                    {'md:px-6.5 md:py-8': size === 'md', 'md:p-5': size === 'sm'},
                    {'py-3': variant === 'home', 'py-6': variant === 'profile'},
                    'flex flex-row items-stretch w-full px-4'
                )}
            >
                {loading && !loadingOrder && (
                    <Skeleton
                        width={size === 'sm' ? 144 : isMobile ? 128 : 208}
                        height={size === 'sm' ? 88 : isMobile ? 96 : 128}
                        wrapper={LoadingWrapperFactory({
                            style: {
                                height: size === 'sm' ? 88 : isMobile ? 96 : 128,
                                marginTop: -4
                            },
                            role: 'figure',
                            'aria-label': 'Offer photo loading'
                        })}
                    />
                )}

                <a
                    href={linkToDetails}
                    className={cx(
                        'cursor-pointer flex-shrink-0 md:flex-shrink relative w-32 h-24',
                        {
                            'md:w-52 md:h-32': size === 'md',
                            'md:w-36 md:h-22': size === 'sm'
                        }
                    )}
                >
                    {offer && !!offer.id && offer?.features?.highlight && (
                        <Badge
                            variant={'lightgreen'}
                            size={'lg'}
                            className={'absolute left-2/4 transform -translate-x-2/4 top-2.5'}
                        >
                            {t('offers:promoted')}
                        </Badge>
                    )}

                    {offer && offer.id && (
                        <img
                            src={
                                offer.images[0]?.big ??
                                offer.images[0]?.medium ??
                                offer.images[0]?.small ??
                                defaultPhoto
                            }
                            className={'m-auto h-full rounded-md'}
                            alt={offer!.title}
                            aria-label={'Offer photo'}
                        />
                    )}

                    {product && (
                        <img
                            src={product?.image ?? defaultPhoto}
                            className={cx('w-32 h-24 object-cover rounded-md mr-6', {
                                'md:w-52 md:h-32': size === 'md',
                                'md:w-36 md:h-22': size === 'sm'
                            })}
                            aria-label={'Product photo'}
                        />
                    )}
                </a>

                {/* below is for offer that was deleted but still visible e.g. in favorites */}

                {offer && !offer.id && (
                    <img
                        src={defaultPhoto}
                        className={cx('w-32 h-24 object-cover rounded-md', {
                            'md:w-52 md:h-32': size === 'md',
                            'md:w-36 md:h-22': size === 'sm'
                        })}
                        alt={'default'}
                        aria-label={'Default photo'}
                    />
                )}

                <div
                    className={cx('flex flex-col flex-grow px-6 tracking-tighter', {
                        'items-center': breakpoint === 'xs',
                        'md:tracking-tightest': size === 'md',
                        'pl-0': !!order || loadingOrder
                    })}
                >
                    <div
                        className={cx('grid sm:flex sm:flex-row sm:flex-grow', {
                            'gap-2': breakpoint === 'xs'
                        })}
                    >
                        {loading ? (
                            <div className={'flex-grow'}>
                                <Skeleton
                                    width={200}
                                    height={size === 'sm' || isMobile ? 16 : 20}
                                    style={{lineHeight: 'initial'}}
                                    wrapper={LoadingWrapperFactory({
                                        role: 'heading',
                                        'aria-level': 3,
                                        'aria-label': 'Offer title loading'
                                    })}
                                />

                                <Skeleton
                                    width={125}
                                    height={size === 'sm' || isMobile ? 16 : 24}
                                    style={{lineHeight: 'initial'}}
                                    wrapper={LoadingWrapperFactory({
                                        role: 'heading',
                                        'aria-level': 4,
                                        'aria-label': 'Offer price loading'
                                    })}
                                />
                            </div>
                        ) : (
                            <a className={'cursor-pointer flex-grow'} href={linkToDetails}>
                                <div className={'flex justify-between'}>
                                    <h3
                                        className={cx(
                                            {'md:text-sm': size === 'md'},
                                            'text-xs font-normal'
                                        )}
                                        aria-label={'Offer title'}
                                    >
                                        {order
                                            ? t('orders:orderID', {
                                                  orderId: order.orderId
                                              })
                                            : offer?.title ?? product?.title}
                                    </h3>

                                    {(!!offer?.visits || offer?.visits === 0) && (
                                        <div
                                            className={
                                                'flex flex-shrink-0 md:flex-shrink items-center gap-x-1.5 text-white'
                                            }
                                        >
                                            <Icon icon={'eye'} />

                                            <p
                                                className={'text-gray-400 text-xs'}
                                                aria-label={'visits'}
                                            >
                                                {t('offers:views', {count: offer?.visits})}
                                            </p>
                                        </div>
                                    )}
                                </div>

                                <h4
                                    className={cx(
                                        {
                                            'md:text-base': size === 'md'
                                        },
                                        'text-xs font-bold'
                                    )}
                                    aria-label={'Offer price'}
                                >
                                    {displayPrice}
                                </h4>
                            </a>
                        )}

                        {!!order && status && (
                            <div
                                className={cx({
                                    'row-start-1': breakpoint === 'xs'
                                })}
                            >
                                <Badge
                                    variant={'secondary'}
                                    className={cx({
                                        'px-3': width < 375,
                                        'px-10': width >= 375
                                    })}
                                >
                                    {displayBadge}
                                </Badge>
                            </div>
                        )}
                    </div>

                    {!product && (
                        <div
                            className={cx('mt-4', {
                                'md:mt-7': size === 'md'
                            })}
                            role={'list'}
                        >
                            {loading ? (
                                <Skeleton
                                    width={size === 'sm' || isMobile ? 86 : 128}
                                    height={size === 'sm' || isMobile ? 24 : 32}
                                    wrapper={LoadingWrapperFactory({
                                        style: {
                                            position: 'relative',
                                            top: 4
                                        }
                                    })}
                                />
                            ) : (
                                <>
                                    {(isMobile ? mobileTags : tags).map((tag, i) => (
                                        <Tag
                                            key={i}
                                            size={isMobile ? 'sm' : size}
                                            className={cx('inline-block text-center', {
                                                'min-w-32 mr-1': size === 'md',
                                                'min-w-21.5 mr-4': size === 'sm'
                                            })}
                                        >
                                            {tag}
                                        </Tag>
                                    ))}
                                </>
                            )}
                        </div>
                    )}
                </div>

                {variant === 'home' && !loading && (
                    <FavoriteButton
                        id={`favorite-offer-${offer!.id}`}
                        className={'self-start'}
                        checked={isFavorite}
                        onChange={onFavoriteToggle}
                        size={isMobile ? 'sm' : size === 'md' ? 'md' : 'xs'}
                        disabled={isFavoriteDisabled}
                        aria-label={'Favorite button'}
                    />
                )}
            </div>

            {!loading && variant === 'profile' && status !== Offer.Status.Closed && (
                <div className={'px-4 md:px-6.5 w-full'}>
                    <div
                        className={cx('flex md:flex-row flex-col-reverse box-border w-full', {
                            'justify-between': !!order,
                            'justify-end': !order
                        })}
                    >
                        {!!order && showDetails && (
                            <>
                                <div
                                    className={cx(
                                        'flex-grow h-12 pt-4 border-t border-gray-200 box-content',
                                        Styles.box
                                    )}
                                >
                                    <Button
                                        as={Link}
                                        to={linkToDetails}
                                        variant={'secondary'}
                                        size={'xs'}
                                        outline
                                    >
                                        {t('orders:details')}
                                    </Button>
                                </div>

                                <div
                                    className={cx(
                                        'flex flex-row flex-grow justify-end h-12 md:w-1/2 pt-4 border-t border-gray-200 box-content',
                                        Styles.box
                                    )}
                                >
                                    {!isBuyer &&
                                        [
                                            Order.Status.Paid,
                                            Order.Status.NotPaid,
                                            Order.Status.Failed
                                        ].includes(status as Order.Status) && (
                                            <Button
                                                className={'ml-6'}
                                                variant={'primary'}
                                                size={'xs'}
                                                outline
                                                onClick={onCancel}
                                            >
                                                {t('orders:cancel')}
                                            </Button>
                                        )}

                                    {!isBuyer && status === Order.Status.Paid && (
                                        <Button
                                            className={'ml-6'}
                                            variant={'primary'}
                                            size={'xs'}
                                            onClick={onConfirm}
                                        >
                                            {t('orders:confirm')}
                                        </Button>
                                    )}

                                    {isBuyer &&
                                        (status === Order.Status.Confirmed ||
                                            status === Order.Status.Paid) && (
                                            <Button
                                                className={'ml-6'}
                                                variant={'primary'}
                                                size={'xs'}
                                                disabled={status !== Order.Status.Confirmed}
                                                onClick={onComplete}
                                            >
                                                {t('orders:received')}
                                            </Button>
                                        )}
                                </div>
                            </>
                        )}

                        {!!offer && !!offer?.id && (
                            <div className={'flex flex-row flex-grow justify-between h-12'}>
                                {status === Offer.Status.Published &&
                                    !offer.features?.highlight && (
                                        <Button
                                            className={'max-w-48'}
                                            variant={'secondary'}
                                            size={'xs'}
                                            onClick={onPromote}
                                        >
                                            {t('offers:promoteTheOffer')}
                                        </Button>
                                    )}

                                <div className={'flex flex-row flex-grow justify-end md:w-1/2'}>
                                    {status === Offer.Status.Published && (
                                        <Button
                                            className={'ml-6'}
                                            variant={'primary'}
                                            size={'xs'}
                                            outline
                                            onClick={onStopPublishing}
                                        >
                                            {t('offers:stopPublishing')}
                                        </Button>
                                    )}

                                    <Button
                                        as={Link}
                                        to={`/offers/edit/${offer?.id}`}
                                        className={'ml-6'}
                                        variant={'primary'}
                                        size={'xs'}
                                        outline={status === Offer.Status.Draft}
                                    >
                                        {t('offers:edit')}
                                    </Button>

                                    {status === Offer.Status.Draft && (
                                        <Button
                                            className={'ml-6'}
                                            variant={'primary'}
                                            size={'xs'}
                                            onClick={onPublish}
                                        >
                                            {t('offers:publish')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}

                        {onReview && (
                            <div className={'flex flex-grow justify-end pb-4'}>
                                <Button
                                    variant={'primary'}
                                    size={'xs'}
                                    onClick={onReview}
                                    outline
                                >
                                    {t('reviews:reviewOffer')}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default OfferView;
