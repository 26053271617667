//Hooks
import {useState, useRef} from 'react';

export const useInput = (ref: React.ForwardedRef<HTMLInputElement>, type?: string) => {
    const [isPasswordVisible, setPasswordVisible] = useState(false);

    const internalRef = useRef<HTMLInputElement>(null);
    const inputRef = ref && typeof ref !== 'function' ? ref : internalRef;

    const togglePasswordVisibility = () => setPasswordVisible(visible => !visible);
    const incrementValue = () => {
        inputRef.current?.stepUp();

        const event = new Event('input', {bubbles: true, cancelable: true});
        inputRef.current?.dispatchEvent(event);
    };
    const decrementValue = () => {
        inputRef.current?.stepDown();

        const event = new Event('input', {bubbles: true, cancelable: true});
        inputRef.current?.dispatchEvent(event);
    };

    const onFocus = () => {
        if (type === 'number') {
            // change type while typing to avoid premature formatting, e.g. 20.0 => 20, to allow numbers like 20.05
            inputRef.current?.setAttribute('type', 'text');
        }
    };

    const onBlur = () => {
        if (type === 'number') {
            inputRef.current?.setAttribute('type', 'number');
        }
    };

    return {
        isPasswordVisible,
        togglePasswordVisibility,
        inputRef,
        incrementValue,
        decrementValue,
        onFocus,
        onBlur
    };
};
