//Redux saga
import {call, takeEvery, put} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions/orders';

//Sagas
import {request} from 'store/sagas/api';

//Utils
import {transformers} from 'utils';

//Types
import {AxiosResponse} from 'axios';
import {Order} from 'store/types';

export function* getMySoldOrders(action: ReturnType<typeof actions.getMySoldOrders.request>) {
    try {
        const resp: AxiosResponse<Order.ListResponse> = yield call(
            request,
            {
                method: 'GET',
                url: `/api/orders/sold?page=${action.payload.page}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                transformResponse: transformers.orders.transformResponse
            },
            true
        );

        yield put(actions.getMySoldOrders.success(resp.data));
    } catch (err) {
        yield put(actions.getMySoldOrders.failure(err));
    }
}

export function* getMySoldOrderById(
    action: ReturnType<typeof actions.getMySoldOrderById.request>
) {
    const {data} = yield call(
        request,
        {
            method: 'GET',
            url: `/api/orders/sold/${action.payload}`,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
    return data.order;
}

export function* getMyBoughtOrders(
    action: ReturnType<typeof actions.getMyBoughtOrders.request>
) {
    try {
        const resp: AxiosResponse<Order.ListResponse> = yield call(
            request,
            {
                method: 'GET',
                url: `/api/orders/bought?page=${action.payload.page}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                transformResponse: transformers.orders.transformResponse
            },
            true
        );
        yield put(actions.getMyBoughtOrders.success(resp.data));
    } catch (err) {
        yield put(actions.getMyBoughtOrders.failure(err));
    }
}

export function* getMyBoughtOrderById(
    action: ReturnType<typeof actions.getMyBoughtOrderById.request>
) {
    const {data} = yield call(
        request,
        {
            method: 'GET',
            url: `/api/orders/bought/${action.payload}`,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
    return data.order;
}

export function* setShipmentLink(action: ReturnType<typeof actions.setShipmentLink.request>) {
    yield call(
        request,
        {
            method: 'POST',
            url: `/api/orders/${action.payload.orderId}/shipment-link`,
            data: {shipmentLink: action.payload.shipmentLink},
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
    return {shipmentLink: action.payload.shipmentLink};
}

export function* confirmOrder(action: ReturnType<typeof actions.confirmOrder.request>) {
    yield call(
        request,
        {
            method: 'POST',
            url: `/api/orders/${action.payload.orderId}/confirm`
        },
        true
    );
    return action.payload;
}

export function* cancelOrder(action: ReturnType<typeof actions.cancelOrder.request>) {
    yield call(
        request,
        {
            method: 'POST',
            url: `/api/orders/${action.payload.orderId}/cancel`
        },
        true
    );
    return action.payload;
}

export function* completeOrder(action: ReturnType<typeof actions.completeOrder.request>) {
    yield call(
        request,
        {
            method: 'POST',
            url: `/api/orders/${action.payload.orderId}/complete`
        },
        true
    );
    return action.payload;
}

export function* disputeOrder(action: ReturnType<typeof actions.disputeOrder.request>) {
    const {orderId, ...data} = action.payload;

    yield call(
        request,
        {
            method: 'POST',
            url: `/api/orders/${orderId}/dispute`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export const saga = [
    takeEvery(actions.getMySoldOrders.request, getMySoldOrders),
    takeEvery(actions.getMyBoughtOrders.request, getMyBoughtOrders),
    takeEveryPromiseAction(actions.getMyBoughtOrderById, getMyBoughtOrderById),
    takeEveryPromiseAction(actions.getMySoldOrderById, getMySoldOrderById),
    takeEveryPromiseAction(actions.setShipmentLink, setShipmentLink),
    takeEveryPromiseAction(actions.confirmOrder, confirmOrder),
    takeEveryPromiseAction(actions.cancelOrder, cancelOrder),
    takeEveryPromiseAction(actions.completeOrder, completeOrder),
    takeEveryPromiseAction(actions.disputeOrder, disputeOrder)
];
