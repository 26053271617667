//Hooks
import {useFormikContext} from 'formik';

//Components
import Select from 'react-select';
import Input from 'components/ui/Input';

//Styles
import {styles, errorStyles, theme} from 'styles/select';

//Utils
import {getIn} from 'formik';

//Types
import {Offer, Categories} from 'store/types';
import {useTranslation} from 'react-i18next';

type CustomFieldProps = {
    name: string;
    field: Categories.CustomFormField.Field;
};

const Field: React.VFC<CustomFieldProps> = ({name, field}) => {
    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue
    } = useFormikContext<Offer.Form>();
    const {t} = useTranslation('common');

    const error =
        getIn(touched, `offer.attributes.${name}`) && getIn(errors, `offer.attributes.${name}`);
    if (field.enum) {
        const options = field.enum.map(item => ({
            label: item,
            value: item
        }));
        return (
            <div
                key={name}
                className={'mt-4'}
                role={'region'}
                aria-label={`offer.attributes.${name}`}
            >
                <div
                    id={`${name}-label`}
                    className={'tracking-tighter text-gray-900 text-form'}
                >
                    {field.title}
                </div>

                <Select
                    aria-labelledby={`${name}-label`}
                    className={'mt-4'}
                    noOptionsMessage={() => t('noOptions')}
                    placeholder={t('selectPlaceholderDefault')}
                    styles={!!error ? errorStyles : styles}
                    theme={theme}
                    value={options.find(({value}) => value === values.offer.attributes?.[name])}
                    options={options}
                    onChange={option =>
                        setFieldValue(
                            `offer.attributes.${name}`,
                            option ? option.value : undefined
                        )
                    }
                />

                {error && (
                    <span
                        className={'tracking-tight text-red text-form-helper'}
                        role={'alert'}
                        aria-label={`error`}
                    >
                        {error}
                    </span>
                )}
            </div>
        );
    }
    return (
        <Input
            key={name}
            className={'mt-4'}
            label={field.title}
            type={['number', 'integer'].includes(field.type) ? 'number' : 'text'}
            id={`offer.attributes.${name}`}
            name={`offer.attributes.${name}`}
            onChange={handleChange}
            value={values.offer.attributes?.[name] ?? ''}
            error={error}
        />
    );
};

export default Field;
