//Hooks
import {useSelector} from 'hooks/store';

//Components
import {Link} from '@reach/router';
import NavIcon from 'components/ui/NavIcon';

//Styles
import Styles from './MobileBottomNav.module.scss';

//Utils
import cx from 'classnames';

const MobileBottomNav: React.VFC = () => {
    const isLoggedIn = useSelector(state => !!state.user.user);

    return (
        <>
            {/* empty div to allow fully scrolling to bottom */}

            <div className={'md:hidden h-10'} />

            <nav
                className={
                    'md:hidden w-full bg-white flex flex-row items-center justify-around py-2 fixed bottom-0 border-t border-gray-300'
                }
            >
                <Link to={'/'} className={Styles.link}>
                    <NavIcon icon={'home'} className={'mx-3'} />
                </Link>

                <Link
                    to={isLoggedIn ? '/favorites' : '/auth/login'}
                    className={cx({[Styles.link]: isLoggedIn})}
                >
                    <NavIcon icon={'favorite'} className={'mx-3'} />
                </Link>

                <Link
                    to={isLoggedIn ? '/offers/create' : '/auth/login'}
                    className={cx({[Styles.link]: isLoggedIn})}
                >
                    <NavIcon icon={'add'} className={'mx-3'} />
                </Link>

                <Link
                    to={isLoggedIn ? '/messages' : '/auth/login'}
                    className={cx({[Styles.link]: isLoggedIn})}
                >
                    <NavIcon icon={'message'} className={'mx-3'} />
                </Link>

                <Link
                    to={isLoggedIn ? '/profile/my-profile' : '/auth/login'}
                    className={cx({[Styles.link]: isLoggedIn})}
                >
                    <NavIcon icon={'profile'} className={'mx-3'} />
                </Link>
            </nav>
        </>
    );
};

export default MobileBottomNav;
