import i18n from 'services/i18n';

//Types
import {AxiosError} from 'axios';
import {Errors} from 'store/types';

export const transformError = (
    err: AxiosError
): Error | Errors.API<Record<string, unknown>> => {
    if (!err.response?.data) {
        if (err.response?.status === 404) {
            err.message = i18n.t('errors:failedWith404');
        }

        if (!err.response?.status && err.message) {
            err.message = i18n.t('errors:networkError');
        }
        return err;
    }

    return {
        code: err.response.data.code ?? err.response.data.status,
        message:
            err.response.data.message ??
            err.response.data.title ??
            err.response.data.errors?.[0],
        errors: transformValidationErrors(err.response.data.errors)
    };
};

const transformValidationErrors = (errors: any): any => {
    const transformedChildren = Object.entries(errors?.children ?? {}).reduce(
        (errors, [key, value]: [string, unknown]) => {
            if (Array.isArray(value)) {
                return {
                    ...errors,
                    [key]: null
                };
            }

            if (Array.isArray((value as any).errors)) {
                return {
                    ...errors,
                    [key]: (value as any).errors[0]
                };
            }

            return {
                ...errors,
                [key]: transformValidationErrors(value)
            };
        },
        {}
    );

    if (!!errors?.errors?.length) {
        return {
            ...transformedChildren,
            additionalFirstError: errors.errors[0]
        };
    }

    return transformedChildren;
};
