//Hooks
import {useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'hooks/store';
import {useTranslation} from 'react-i18next';
import {useLocationSearchPagination} from 'hooks/locationSearchPagination';

//Actions
import * as actions from 'store/actions';

//Services
import toast from 'react-hot-toast';

//Types
import {Order} from 'store/types';

export const useMyPurchases = () => {
    const {data: orders, isLoading, isLoaded, pages} = useSelector(
        state => state.myPurchases.orders
    );
    const dispatch = useDispatch();
    const ref = useRef<HTMLSpanElement>(null);
    const {changePage, pageFromSearch} = useLocationSearchPagination({
        ref
    });

    const {t} = useTranslation('orders');

    useEffect(() => {
        if (pages && pages < pageFromSearch) {
            changePage(pages);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages, changePage]);

    useEffect(() => {
        dispatch(actions.getMyBoughtOrders.request({page: pageFromSearch}));
    }, [dispatch, pageFromSearch]);

    const handlePageChange = (page: number) => changePage(page);

    const handleComplete = async (order: Order.Order) => {
        try {
            await dispatch(actions.completeOrder.request(order));
            toast.success(t('completedSuccessfully'));
        } catch (err) {
            if (err.message) {
                toast.error(err.message);
            }
        }
    };

    return {
        orders,
        isLoading,
        isLoaded,
        pageFromSearch,
        pages,
        handlePageChange,
        handleComplete,
        ref
    };
};
