//Hooks
import {useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

//Utils
import cx from 'classnames';

//Components
import Option from '../Option';

//Types
import {Category} from 'store/types/categories/categories';

type MenuProps = {
    options: Category[];
    parent?: Category;
    selectOption: (option: Category) => void;
    className?: string;
    expandOnHover?: boolean;
    preventSelectParentOption?: boolean;
};

const Menu: React.VFC<MenuProps> = ({
    options,
    parent,
    className,
    selectOption,
    expandOnHover,
    preventSelectParentOption
}) => {
    const [expandedOption, setExpandedOption] = useState<number | null>(null);

    const filteredOptions = useMemo(
        () =>
            options.filter(option => {
                if (!parent) {
                    return !option.parentId;
                }

                return option.parentId === parent.id;
            }),
        [options, parent]
    );

    const {t} = useTranslation('common');

    return (
        <ul
            className={cx(
                'md:border border-gray-300 h-full w-full top-0 md:top-auto left-0 fixed md:absolute md:h-auto bg-white box-border z-10',
                {'overflow-auto': !expandedOption},
                className
            )}
        >
            {!!options.length &&
                filteredOptions.map(option => (
                    <Option
                        key={option.id}
                        active={option.id === expandedOption}
                        item={option}
                        options={options}
                        selectOption={selectOption}
                        setActiveOption={setExpandedOption}
                        expandOnHover={expandOnHover ?? false}
                        preventSelectParentOption={preventSelectParentOption ?? false}
                    />
                ))}

            {!options.length && (
                <li
                    className={
                        'flex flex-row items-center ml-6 text-sm text-gray-600 md:h-12 h-15 md:text-sm'
                    }
                    role={'alert'}
                >
                    {t('noOptions')}
                </li>
            )}
        </ul>
    );
};

export default Menu;
