import * as React from 'react';

//Hooks
import {useCategoryView} from './CategoryView.hook';
import {useTranslation} from 'react-i18next';

//Components
import {Link} from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import Icon from 'components/ui/Icon';
import Navbar from 'components/nav/Navbar';

//Utils
import _ from 'lodash-es';
import cx from 'classnames';

//Types
import {Categories} from 'store/types';

export type CategoryViewProps = {
    categories: Categories.Category[];
    loading?: undefined;
};

export type LoadingCategoryViewProps = {
    categories?: undefined;
    loading: true;
};

type CategoryWrapperProps = {
    categoryId: number;
    setActiveId: (id: number) => void;
    children: React.ReactElement | React.ReactElement[];
    className: string;
};

const CategoryWrapper: React.VFC<CategoryWrapperProps> = ({
    categoryId,
    setActiveId,
    children,
    ...props
}) =>
    categoryId < 0 ? (
        <Link to={'/info/contact-us'} {...props}>
            {children}
        </Link>
    ) : (
        <div onClick={() => setActiveId(categoryId)} {...props}>
            {children}
        </div>
    );

const CategoryView: React.VFC<CategoryViewProps | LoadingCategoryViewProps> = ({
    categories,
    loading
}) => {
    const {
        activeCategory,
        setActiveId,
        rows,
        subcategories,
        rowLength,
        isMobile
    } = useCategoryView(categories ?? []);

    const {t} = useTranslation('common');

    return (
        <div
            className={cx('md:px-16 md:pb-10 pb-5 bg-white', {
                'overflow-hidden': activeCategory
            })}
        >
            <div className={'flex flex-row justify-between pt-5 md:pt-10 px-4 md:px-0'}>
                <h1 className={cx('text-sm md:text-2xl')}>{t('categories')}</h1>
            </div>

            {loading
                ? _.times(isMobile ? 1 : 3, i => (
                      <div key={i} className={`md:mt-7 mt-3.5 grid grid-cols-${rowLength}`}>
                          {_.times(rowLength, i => (
                              <div
                                  key={i}
                                  className={
                                      'flex flex-col items-center md:pt-4 md:w-32 md:h-38 h-35'
                                  }
                              >
                                  <div
                                      className={'w-20'}
                                      role={'figure'}
                                      aria-label={'Category image loading'}
                                  >
                                      <Skeleton height={80} />
                                  </div>

                                  <div
                                      className={'md:w-28 w-24 mt-3 rounded'}
                                      role={'heading'}
                                      aria-level={3}
                                      aria-label={'Category name loading'}
                                  >
                                      <Skeleton />
                                  </div>
                              </div>
                          ))}
                      </div>
                  ))
                : rows.map((row, i) => (
                      <React.Fragment key={i}>
                          <div className={cx(`md:mt-7 mt-3.5 grid grid-cols-${rowLength}`)}>
                              {row.map(category => (
                                  <CategoryWrapper
                                      key={category.id}
                                      categoryId={category.id}
                                      setActiveId={setActiveId}
                                      className={cx(
                                          'flex flex-col items-center box-border md:pt-4 md:w-32 md:h-38 h-35 cursor-pointer',
                                          {
                                              'md:border border-b-0 border-gray-300':
                                                  activeCategory === category
                                          }
                                      )}
                                  >
                                      <div
                                          className={
                                              'w-20 h-20 rounded bg-gray-200 grid place-content-center'
                                          }
                                      >
                                          {category.imageUrl && (
                                              <img
                                                  src={category.imageUrl}
                                                  alt={category.name}
                                                  className={'object-contain w-16 h-16'}
                                              />
                                          )}
                                      </div>

                                      <h3
                                          className={
                                              'mt-3 md:text-sm text-xs md:font-bold font-semibold tracking-tighter text-center'
                                          }
                                      >
                                          {category.name}
                                      </h3>
                                  </CategoryWrapper>
                              ))}
                          </div>

                          {!!activeCategory &&
                              !!row.find(category => category === activeCategory) && (
                                  <div
                                      className={
                                          'overflow-auto md:border pb-10 md:pb-0 border-gray-300 md:grid md:grid-cols-3 flex flex-col fixed md:static w-full h-full md:h-auto top-0 bg-white z-10'
                                      }
                                  >
                                      <div className={'md:hidden'}>
                                          <Navbar onBack={() => setActiveId(null)} />
                                      </div>

                                      <div
                                          className={
                                              'md:hidden flex flex-row justify-between items-center px-4 pt-5 pb-10'
                                          }
                                      >
                                          <div
                                              className={'flex flex-col justify-between h-full'}
                                          >
                                              <h2>{activeCategory.name}</h2>

                                              <h3
                                                  className={
                                                      'font-normal text-gray-300 tracking-medium-tight'
                                                  }
                                              >
                                                  {t('offersCount', {
                                                      count: activeCategory.offersCount
                                                  })}
                                              </h3>
                                          </div>

                                          <div
                                              className={
                                                  'h-12.5 w-12.5 bg-gray-200 grid place-content-center rounded'
                                              }
                                          >
                                              {activeCategory.hasOwnProperty('imageUrl') && (
                                                  <img
                                                      src={activeCategory.imageUrl}
                                                      alt={activeCategory.name}
                                                      className={'object-cover w-full h-full'}
                                                  />
                                              )}
                                          </div>
                                      </div>

                                      <Link
                                          to={`/search?categoryId=${activeCategory.id}`}
                                          className={cx(
                                              'hidden md:flex h-12.5 min-h-full px-6 mx-0 flex-row items-center border-gray-300 border-r justify-between',
                                              {'border-b': subcategories.length > 2}
                                          )}
                                      >
                                          <span
                                              className={
                                                  'md:text-sm text-base font-semibold tracking-tighter'
                                              }
                                          >
                                              {t('allOffers')}
                                          </span>

                                          <span className={'text-gray-300 tracking-tighter'}>
                                              {t('offersCount', {
                                                  count: activeCategory.offersCount
                                              })}
                                          </span>

                                          <Icon size={'xs'} icon={'arrow-right'} />
                                      </Link>

                                      {subcategories.map(subcategory => (
                                          <Link
                                              key={subcategory.id}
                                              to={`/search?categoryId=${subcategory.id}`}
                                              className={
                                                  'md:h-12.5 min-h-20 md:min-h-full md:px-6 px-0 mx-4 md:mx-0 flex flex-row items-center justify-between border-b border-gray-300 md:border-b-0'
                                              }
                                              aria-label={subcategory.name}
                                          >
                                              <div className={'flex flex-col'}>
                                                  <span
                                                      className={
                                                          'text-base font-semibold tracking-tighter md:text-sm md:font-normal'
                                                      }
                                                  >
                                                      {subcategory.name}
                                                  </span>

                                                  <span
                                                      className={
                                                          'text-xs tracking-tight md:hidden mt-1'
                                                      }
                                                  >
                                                      {t('offersCount', {
                                                          count: subcategory.offersCount
                                                      })}
                                                  </span>
                                              </div>

                                              <Icon size={'xs'} icon={'arrow-right'} />
                                          </Link>
                                      ))}
                                  </div>
                              )}
                      </React.Fragment>
                  ))}
        </div>
    );
};

export default CategoryView;
