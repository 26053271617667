//Hooks
import {useTranslation} from 'react-i18next';
import {useLocalFilter} from './LocalFilter.hook';

//Components
import Select from 'react-select';
import Section from '../Section';

//Styles
import {styles, theme} from 'styles/select';

//Types
import {Search} from 'store/types';

const LocalFilter: React.VFC = () => {
    const {locationOptions, values, locationDenied, handleLocationChange} = useLocalFilter();

    const {t} = useTranslation('search');

    return (
        <Section title={t('local')}>
            <Select
                role={'searchbox'}
                aria-label={'local filter select'}
                options={locationOptions}
                value={locationOptions.find(
                    opt =>
                        opt.value ===
                        (!!values.geoDistance
                            ? Search.Location.Local
                            : Search.Location.Everywhere)
                )}
                onChange={handleLocationChange}
                styles={styles}
                theme={theme}
                className={'bg-white'}
            />

            {locationDenied && (
                <p className={'mt-2 tracking-tight text-red text-form-helper'}>
                    {t('locationError')}
                </p>
            )}
        </Section>
    );
};

export default LocalFilter;
