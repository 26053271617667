//Redux saga
import {call} from 'redux-saga/effects';
import {takeEveryPromiseAction} from 'redux-saga-promise-actions/effects';

//Actions
import * as actions from 'store/actions';

//Sagas
import {request} from 'store/sagas/api';

export function* getConversations(action: ReturnType<typeof actions.getConversations.request>) {
    const {data} = yield call(
        request,
        {
            method: 'GET',
            url: `api/conversations?page=${action.payload.page}`,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return data;
}

export function* getConversation(action: ReturnType<typeof actions.getConversation.request>) {
    const {
        data: {conversation}
    } = yield call(
        request,
        {
            method: 'GET',
            url: `api/conversations/${action.payload}`,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return conversation;
}

export function* createConversation(
    action: ReturnType<typeof actions.createConversation.request>
) {
    const {
        data: {conversation}
    } = yield call(
        request,
        {
            method: 'POST',
            url: `api/conversations/${action.payload.userId}${
                action.payload?.offerId ? `/${action.payload?.offerId}` : ''
            }`,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return conversation;
}

export function* contactAdmin() {
    const {
        data: {conversation}
    } = yield call(
        request,
        {
            method: 'POST',
            url: `api/conversations/contact-administration`,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );

    return conversation;
}

export function* getLastMessage(action: ReturnType<typeof actions.getLastMessage.request>) {
    yield call(
        request,
        {
            method: 'POST',
            url: 'api/conversations/message-sent',
            data: action.payload,
            headers: {
                'Content-Type': 'application/json'
            }
        },
        true
    );
}

export const saga = [
    takeEveryPromiseAction(actions.getConversations, getConversations),
    takeEveryPromiseAction(actions.getConversation, getConversation),
    takeEveryPromiseAction(actions.createConversation, createConversation),
    takeEveryPromiseAction(actions.getLastMessage, getLastMessage),
    takeEveryPromiseAction(actions.contactAdmin, contactAdmin)
];
