//Hooks
import {useBasket} from 'hooks/basket';
import {useDispatch} from 'hooks/store';
import {useNavigate} from '@reach/router';
import {useCallback, useEffect, useMemo, useState} from 'react';

//Actions
import * as actions from 'store/actions';

//Types
import {Offer, User} from 'store/types';
import {DeliveryMethod} from 'store/types/offer';

const useBasketView = () => {
    const {basketOffers, isLoaded, isLoading} = useBasket();
    const [groupedOffers, setGrouped] = useState<Offer.GroupedOffer[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const grouped = basketOffers?.reduce((acc, curr, key) => {
            if (acc.some(item => item.user?.id === curr.account?.id)) {
                acc.map(
                    offer => offer.user?.id === curr.account?.id && offer.offers.push(curr)
                );
            } else {
                acc.push({
                    user: curr?.account!!,
                    offers: [curr],
                    selected: key === 0
                });
            }

            return acc;
        }, [] as Offer.GroupedOffer[]);

        setGrouped(grouped);
    }, [basketOffers]);

    useEffect(() => {
        if (!basketOffers.length) {
            !!groupedOffers.length && setGrouped([]);
            return;
        }
    }, [basketOffers, groupedOffers]);

    const totalBasketCost = useMemo(() => {
        if (!groupedOffers.length) {
            return;
        }

        const totalCostList = groupedOffers.reduce(
            (acc, curr) =>
                curr.selected
                    ? curr.offers.map(offer => {
                          const deliveryAmount =
                              offer.delivery?.method === DeliveryMethod.Pickup
                                  ? 0
                                  : Number(offer.delivery?.price?.amount);
                          return {
                              amount:
                                  deliveryAmount +
                                  Number(offer.price.amount) * Number(offer.quantity),
                              currency: offer?.price?.currency ?? 'USD'
                          };
                      })
                    : acc,
            [] as Offer.MoneySerialized[]
        );

        const totalCost: Offer.MoneySerialized = totalCostList.reduce((a, b) => ({
            amount: Number(a?.amount ?? 0) + Number(b?.amount ?? 0),
            currency: b?.currency ?? 'USD'
        }));

        return totalCost;
    }, [groupedOffers]);

    const handleSelectSeller = useCallback(
        (seller: User.User) => {
            const grouped = groupedOffers.map(item => {
                item.selected = item.user?.id === seller?.id;
                return item;
            });
            setGrouped(grouped);
        },
        [groupedOffers]
    );

    const handleProcced = () => {
        const offers = groupedOffers.find(item => item.selected)?.offers;

        dispatch(actions.selectOffersToProceed(offers!!));
        navigate('/payment/method');
    };

    return {
        handleSelectSeller,
        handleProcced,
        groupedOffers,
        basketOffers,
        totalBasketCost,
        isLoaded,
        isLoading
    };
};

export default useBasketView;
