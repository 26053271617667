//Services
import axios from 'services/axios';

//Types
import {AxiosTransformer} from 'axios';
import {Offer} from 'store/types';

const getPeriod = (type: Offer.Type, data: any, period: Offer.Period | null) => {
    if (type === Offer.Type.Service && period) {
        return period;
    }

    return !data.showSalary && period ? null : period;
};

export const transformRequest = [
    ({
        type,
        offer: {category, images, salaryFrom, salaryTo, period, ...data},
        address,
        offerId
    }: Offer.Form & {offerId?: string}) => {
        return {
            type: !!offerId ? undefined : type,
            offer: {
                category: category?.id,
                images: images.map(img => img.fileId),
                period: getPeriod(type, data, period),
                salaryFrom: !data.showSalary && salaryFrom ? {tbbcAmount: null} : salaryFrom,
                salaryTo: !data.showSalary && salaryTo ? {tbbcAmount: null} : salaryTo,
                ...data
            },
            address
        };
    },
    ...(axios.defaults.transformRequest as AxiosTransformer[])
];
