//Hooks
import {useFormikContext} from 'formik';
import {useNavigate} from '@reach/router';

//Utils
import {setNestedObjectValues} from 'formik';

//Assets
import countryStates from 'assets/data/states';

//Types
import {Auth} from 'store/types';

export function useRegisterInfoView() {
    const {
        values,
        touched,
        errors,
        setFieldValue,
        handleChange,
        validateForm,
        setTouched
    } = useFormikContext<Auth.RegisterInfoFormValues>();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const errors = await validateForm();
            if (Object.keys(errors).length) {
                setTouched(setNestedObjectValues(errors, true));
                return;
            }

            navigate('/auth/register/method');
        } catch (err) {}
    };

    const states = Object.entries(countryStates).map(([value, label]) => ({
        value,
        label
    }));

    const selectedState = Object.entries(countryStates)
        .map(([value, label]) => ({
            value,
            label
        }))
        .find(({value}) => values.account.address.state === value);

    return {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        states,
        selectedState
    };
}
