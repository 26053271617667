//Hooks
import {useTranslation} from 'react-i18next';

//Components
import Logo from 'components/images/Logo';

export type CompanyBarViewProps = {
    small?: boolean;
    className?: string;
};

const CompanyBarView: React.VFC<CompanyBarViewProps> = ({small, className}) => {
    const {t} = useTranslation('nav');

    return (
        <div className={'hidden sm:flex flex-col items-center px-4 py-7 bg-secondary'}>
            <Logo className={'w-50 h-auto'} white />

            {!small && (
                <p
                    className={'mt-7 max-w-screen-md text-sm text-white text-center'}
                    role={'paragraph'}
                >
                    {t('companyBar.content')}
                </p>
            )}
        </div>
    );
};

export default CompanyBarView;
