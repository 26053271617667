//Services
import axios from 'services/axios';

//Types
import {AxiosTransformer} from 'axios';
import {Offer, Pagination} from 'store/types';

type ReceivedOffer = Omit<Offer.ListItem, 'createdAt' | 'closedAt' | 'expiredAt'> & {
    createdAt: string;
    closedAt: string;
    expiredAt: string;
};

type ReceivedFavoritesList = {
    pagination: Pagination;
    items: {
        data: ReceivedOffer;
        favoriteId: number;
    }[];
};

export const transformResponse = [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    (data: ReceivedFavoritesList) => ({
        ...data,
        items: data.items.map(({data, ...item}) => ({
            ...item,
            data: {
                ...data,
                createdAt: new Date(data.createdAt),
                expiredAt: data.expiredAt ? new Date(data.expiredAt) : data.expiredAt,
                closedAt: data.closedAt ? new Date(data.closedAt) : data.closedAt
            }
        }))
    })
];
