//Actions
import * as actions from 'store/actions';

//Hooks
import {useDispatch} from 'hooks/store';

export const useBasketOfferGroup = () => {
    const dispatch = useDispatch();

    const onDeleteBasketOffer = (id: number) => {
        dispatch(actions.removeBasketOffer.request(id));
    };
    return {onDeleteBasketOffer};
};
