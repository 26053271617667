import * as React from 'react';

//Components
import {Link} from '@reach/router';
import Skeleton from 'react-loading-skeleton';

//Types
import {LinkProps} from '@reach/router';

//Styles
import Styles from './LinkGroup.module.scss';

//Utils
import cx from 'classnames';

type BaseLinkGroupProps = {
    className?: string;
    children:
        | React.ReactElement<LinkProps<any>, typeof Link>
        | React.ReactElement<LinkProps<any>, typeof Link>[];
};

export type LinkGroupProps = BaseLinkGroupProps & {
    title?: string;
    loading?: undefined;
};

export type LoadingLinkGroupProps = BaseLinkGroupProps & {
    loading: true;
    title?: undefined;
};

const LinkGroup: React.VFC<LinkGroupProps | LoadingLinkGroupProps> = ({
    title,
    className,
    children,
    loading,
    ...props
}) => (
    <div className={cx(className, 'flex flex-col gap-y-3')} role={'navigation'} {...props}>
        {title && <h3>{title}</h3>}

        {loading && (
            <h3>
                <Skeleton width={150} />
            </h3>
        )}

        <div className={'flex flex-col gap-y-2'}>
            {React.Children.map(children, child =>
                React.cloneElement(child, {
                    ...child.props,
                    className: cx(
                        child.props,
                        Styles.link,
                        'my-2 px-16 py-6 text-sm border border-gray-200 rounded-md bg-white'
                    )
                })
            )}
        </div>
    </div>
);

export default LinkGroup;
