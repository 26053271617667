//Action creators
import {createPromiseAction} from 'redux-saga-promise-actions';
import {createAsyncAction} from 'typesafe-actions';

//Types
import {Reviews, Errors} from 'store/types';

/**
 * Gets seller reviews.
 */
export const getSellerReviews = createAsyncAction(
    'GET_SELLER_REVIEWS_REQUEST',
    'GET_SELLER_REVIEWS_SUCCESS',
    'GET_SELLER_REVIEWS_FAILURE'
)<Reviews.Request, Reviews.Response.Seller, Errors.API>();

/**
 * Gets offer reviews.
 */
export const getOfferReviews = createAsyncAction(
    'GET_OFFER_REVIEWS_REQUEST',
    'GET_OFFER_REVIEWS_SUCCESS',
    'GET_OFFER_REVIEWS_FAILURE'
)<Reviews.Request, Reviews.Response.Offer, Errors.API>();

/**
 * Gets my reviews.
 */
export const getMyReviews = createAsyncAction(
    'GET_MY_REVIEWS_REQUEST',
    'GET_MY_REVIEWS_SUCCESS',
    'GET_MY_REVIEWS_FAILURE'
)<Reviews.Request, Reviews.Response.MyReview, Errors.API>();

/**
 * Creates seller review.
 */
export const createSellerReview = createPromiseAction('CREATE_SELLER_REVIEW')<
    Reviews.CreateSellerReviewRequest,
    undefined,
    Errors.API<Reviews.CreateSellerReviewRequest>
>();

/**
 * Creates offer review.
 */
export const createOfferReview = createPromiseAction('CREATE_OFFER_REVIEW')<
    Reviews.CreateOfferReviewRequest,
    undefined,
    Errors.API<Reviews.CreateOfferReviewRequest>
>();

/**
 * Creates order review.
 */
export const createProductReview = createPromiseAction('CREATE_PRODUCT_REVIEW')<
    Reviews.CreateProductReviewRequest,
    undefined,
    Errors.API<Reviews.CreateProductReviewRequest>
>();

/**
 * Dispute review.
 */
export const disputeReview = createPromiseAction('DISPUTE_REVIEW')<
    Reviews.DisputeReviewRequest,
    undefined,
    Errors.API
>();
