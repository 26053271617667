//Hooks
import {useWindowSize} from 'hooks/windowSize';
import {usePaymentFormView} from './PaymentFormView.hook';

//Translations
import {useTranslation} from 'react-i18next';

//Components
import CardInput from 'components/ui/CardInput';
import Button from 'components/ui/Button';
import {Link} from '@reach/router';
import SuccessFeatureModal from 'components/modal/SuccessFeatureModal';

const PaymentFormView: React.VFC = () => {
    const {breakpoint} = useWindowSize();
    const {t} = useTranslation(['payment', 'common']);
    const {
        handleSubmit,
        isLoading,
        isSuccessModalVisible,
        closeSuccessModal,
        feature
    } = usePaymentFormView();

    return (
        <>
            <form onSubmit={handleSubmit}>
                <CardInput />

                <div className={'flex w-full items-center justify-evenly'}>
                    <Button
                        type={'submit'}
                        disabled={isLoading}
                        size={'sm'}
                        variant={'primary'}
                    >
                        {t('payment:pay')}
                    </Button>

                    {!(breakpoint === 'xs' || breakpoint === 'sm') && (
                        <Button
                            as={Link}
                            to={'/payment/method'}
                            outline
                            size={'sm'}
                            variant={'primary'}
                        >
                            {t('common:back')}
                        </Button>
                    )}
                </div>
            </form>

            {!!feature && (
                <SuccessFeatureModal
                    type={feature.featureType}
                    onClose={closeSuccessModal}
                    isOpen={isSuccessModalVisible}
                />
            )}
        </>
    );
};

export default PaymentFormView;
