//Hooks
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';

//Components
import Select from 'react-select';

//Styles
import {styles, errorStyles, theme} from 'styles/select';

//Types
import {Offer} from 'store/types';

const PeriodSelect: React.VFC = () => {
    const {t} = useTranslation(['offers', 'common']);
    const {values, errors, touched, setFieldValue} = useFormikContext<Offer.Form>();
    const periodOptions = Object.values(Offer.Period).map(value => ({
        label: t(`offers:${value}` as const),
        value
    }));

    return (
        <>
            <label
                id={'period-label'}
                className={'tracking-tighter text-gray-900 text-form mt-7'}
            >
                {t('offers:period')}
            </label>

            <Select
                aria-labelledby={'period-label'}
                noOptionsMessage={() => t('common:noOptions')}
                placeholder={t('common:selectPlaceholderDefault')}
                className={'mt-4 md:w-30'}
                styles={touched.offer?.period && errors.offer?.period ? errorStyles : styles}
                theme={theme}
                isDisabled={values.type === Offer.Type.Job && !values.offer.showSalary}
                options={periodOptions}
                value={periodOptions.find(opt => opt.value === values.offer.period)}
                onChange={option => setFieldValue('offer.period', option?.value)}
            />

            {touched.offer?.period && (
                <span
                    className={'tracking-tight text-red text-form-helper'}
                    role={'alert'}
                    aria-label={'period-error'}
                >
                    {errors.offer?.period}
                </span>
            )}
        </>
    );
};
export default PeriodSelect;
