//Hooks
import {useRegisterView} from './RegisterView.hook';

//Components
import {Formik} from 'formik';

//Types
import {RouteComponentProps} from '@reach/router';

const RegisterView: React.FC<RouteComponentProps> = ({children}) => {
    const {initialValues, handleSubmit, validationSchema} = useRegisterView();

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {children}
        </Formik>
    );
};

export default RegisterView;
