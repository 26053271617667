//Hooks
import {useState, useEffect, useCallback, useMemo} from 'react';
import {usePaymentFormView} from 'hooks/payment';
import {useSelector} from 'hooks/store';

//Assets
import countryStates from 'assets/data/states';

//Types
import {Order, User, Offer} from 'store/types';

import {setNestedObjectValues} from 'formik';

export const useEditShipmentAddressModal = (isOpen: boolean) => {
    const {
        values,
        handleChange,
        touched,
        errors,
        handleBlur,
        setFieldValue,
        setErrors,
        setTouched,
        orderValidationErrors
    } = usePaymentFormView<Offer.PaymentFormValues>();

    const [backup, setBackup] = useState<User.Address | undefined>(undefined);
    const orderData: Order.SavedOrderData | null = useSelector(
        state => state.payment.savedOrderData
    );
    const addressErrorsIsSet = useMemo(
        () =>
            !!orderValidationErrors?.shippingAddress &&
            Object.values(orderValidationErrors.shippingAddress).some(v => !!v),
        [orderValidationErrors]
    );

    useEffect(() => {
        if (isOpen && !backup) {
            setBackup(values.shippingAddress);
        }

        if (!isOpen && !!backup) {
            setBackup(undefined);
        }
    }, [values.shippingAddress, backup, isOpen]);

    useEffect(() => {
        setErrors(orderValidationErrors || {});
        if (orderData?.shipping_address) {
            setFieldValue('shippingAddress', orderData.shipping_address);
        }
        setFieldValue('isShipmentModalOpen', addressErrorsIsSet);
        setTouched(setNestedObjectValues(orderValidationErrors || {}, true));
    }, [
        orderValidationErrors,
        setTouched,
        setErrors,
        setFieldValue,
        orderData,
        addressErrorsIsSet
    ]);

    const states = Object.entries(countryStates).map(([value, label]) => ({
        value,
        label
    }));

    const selectedState = Object.entries(countryStates)
        .map(([value, label]) => ({
            value,
            label
        }))
        .find(({value}) => values.shippingAddress.state === value);

    const onCancel = useCallback(() => {
        if (backup && backup !== values.shippingAddress) {
            values.shippingAddress = backup;
        }
    }, [backup, values]);

    return {
        onCancel,
        values,
        handleChange,
        touched,
        errors,
        handleBlur,
        setFieldValue,
        states,
        selectedState
    };
};
