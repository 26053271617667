//Hooks
import {useSelector, useDispatch} from 'hooks/store';
import {useParams, useNavigate} from '@reach/router';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

//Actions
import * as actions from 'store/actions';

//Types
import {Sort} from 'store/types/search';

export const sortValues: Record<
    'priceAscending' | 'priceDescending' | 'newest' | 'oldest',
    Sort
> = {
    priceAscending: {field: 'price', order: 'asc'},
    priceDescending: {field: 'price', order: 'desc'},
    newest: {field: 'date', order: 'desc'},
    oldest: {field: 'date', order: 'asc'}
};

export const useUserProfileView = () => {
    const id = parseInt(useParams().id);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation('common');

    const sortOptions = Object.keys(sortValues).map(key => ({
        label: t(key as keyof typeof sortValues),
        value: key
    }));

    const {data: user, isLoading: isUserLoading, isErrored: isUserErrored} = useSelector(
        state => state.visitedProfile.user
    );
    const errorCode = useSelector(state => state.visitedProfile.error?.code);
    const loggedInUser = useSelector(state => state.user.user);
    const {
        data: offers,
        isLoaded: areOffersLoaded,
        isLoading: areOffersLoading,
        isErrored: areOffersErrored
    } = useSelector(state => state.visitedProfile.offers);

    const currentPage = offers?.pagination.currentPage ?? 1;
    const [sorting, setSorting] = useState<keyof typeof sortValues | null>(null);

    useEffect(() => {
        dispatch(actions.getUserProfile.request(id));
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(
            actions.getUserOffers.request({
                userId: id,
                page: currentPage,
                sort: sorting ? (sortValues[sorting] as Sort) : undefined
            })
        );
    }, [id, dispatch, sorting, currentPage]);

    const handlePageChange = (page: number) =>
        dispatch(
            actions.getUserOffers.request({
                userId: id,
                page,
                sort: sorting ? (sortValues[sorting] as Sort) : undefined
            })
        );

    const contactUser = async () => {
        const resp = await dispatch(actions.createConversation.request({userId: user!.id}));
        await navigate(`/chat/${resp.conversation.id}`);
    };

    const isMe = user?.phoneNumber === loggedInUser?.phone;

    return {
        offers,
        user,
        isUserLoading,
        isUserErrored,
        areOffersLoaded,
        areOffersLoading,
        areOffersErrored,
        sortOptions,
        sorting,
        setSorting,
        currentPage,
        handlePageChange,
        errorCode: isUserErrored ? errorCode : undefined,
        isMe,
        contactUser
    };
};
