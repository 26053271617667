//Action creators
import {createAsyncAction} from 'typesafe-actions';
import {createPromiseAction} from 'redux-saga-promise-actions';

//Types
import {Errors, Order} from 'store/types';

/**
 * Fetches sold orders of currently logged in user.
 */
export const getMySoldOrders = createAsyncAction(
    'GET_MY_SOLD_ORDERS_REQUEST',
    'GET_MY_SOLD_ORDERS_SUCCESS',
    'GET_MY_SOLD_ORDERS_FAILURE'
)<{page: number}, Order.ListResponse, Errors.API>();

export const getMySoldOrderById = createPromiseAction('GET_MY_SOLD_ORDER_BY_ID')<
    number,
    Order.OrderDetails,
    Errors.API
>();

/**
 * Fetches bought orders of currently logged in user.
 */
export const getMyBoughtOrders = createAsyncAction(
    'GET_MY_BOUGHT_ORDERS_REQUEST',
    'GET_MY_BOUGHT_ORDERS_SUCCESS',
    'GET_MY_BOUGHT_ORDERS_FAILURE'
)<{page: number}, Order.ListResponse, Errors.API>();

export const getMyBoughtOrderById = createPromiseAction('GET_MY_BOUGHT_ORDER_BY_ID')<
    number,
    Order.OrderDetails,
    Errors.API
>();

export const setShipmentLink = createPromiseAction('SET_SHIPMENT_LINK')<
    {orderId?: number; shipmentLink?: string},
    {shipmentLink?: string},
    Errors.API
>();
/**
 * Confirms an order by seller.
 */
export const confirmOrder = createPromiseAction('CONFIRM_ORDER')<
    Order.Order,
    Order.Order,
    Errors.API
>();

/**
 * Cancels an order by seller.
 */
export const cancelOrder = createPromiseAction('CANCEL_ORDER')<
    Order.Order,
    Order.Order,
    Errors.API
>();

/**
 * Completes an order by buyer - confirm product was received.
 */
export const completeOrder = createPromiseAction('COMPLETE_ORDER')<
    Order.Order,
    Order.Order,
    Errors.API
>();

/**
 * Dispute order by buyer
 */
export const disputeOrder = createPromiseAction('DISPUTE_ORDER')<
    Order.DisputeOrderRequest,
    undefined,
    Errors.API
>();
